import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

type styleProps = {
   activeStep: number;
};

export const useApplyStyles = makeStyles<Theme, styleProps>((theme: Theme) =>
   createStyles({
      formControl: {
         width: "-webkit-fill-available",
      },
      selectEmpty: {
         marginTop: theme.spacing(2),
      },
      stepContainer: {
         padding: "24px",
         overflowY: "auto",
         overflowX: "hidden",
         borderRadius: "8px",
      },
      buttonContainer: {
         display: "flex",
         justifyContent: "flex-end",
         paddingBottom: "40px",
      },
      nextButton: (props) => ({
         "&.MuiButtonBase-root": {
            marginLeft: props.activeStep === 0 ? "0px" : "28px",
         },
      }),
      backButton: {
         "&.MuiButtonBase-root": {
            color: "#043F60",
            backgroundColor: "#F8F9FD",
            "&:hover": {
               backgroundColor: "#dfe1e8",
            },
         },
      },
      commonButton: {
         "&.MuiButton-root": {
            padding: "8px 24px",
            fontWeight: 500,
            fontSize: "14px",
            border: "1px solid #043F60",
         },
      },
      buttonProgress: {
         color: green[500],
      },
      stepLabel: {
         fontSize: "16px",
      },
      dialogWarning: {
         "& .MuiDialog-paperWidthSm": {
            maxWidth: "720px",
         },
         "& .MuiDialogTitle-root": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "& .MuiTypography-root": {
               fontSize: "2rem",
               textAlign: "center",
               lineHeight: "1",
            },
         },
         "& .MuiDialogContent-root": {
            padding: "30px 24px",
         },
         "& .MuiDialogActions-root": {
            margin: "0px 24px 22px",
         },
      },
      contectTextDialog: {
         fontSize: "1.2rem",
         color: "#000",
      },
   }),
);
