import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";
import { useTradeinfoStyles } from "./styles";

type ReferenceHeaderProps = {
   index: number;
   disabled: boolean;
   removeReference: (index: number) => void;
};

export default function ReferenceHeader({
   index,
   disabled,
   removeReference,
}: ReferenceHeaderProps): React.ReactElement {
   const classes = useTradeinfoStyles();

   const isOptional = index > 2;
   const altText = index === 0 ? "(minimum 3 shipping industry references must be provided)" : "";
   const optionalText = isOptional ? "(optional)" : "";

   return (
      <Grid container direction="row" alignItems="center" justifyContent="center">
         <Grid component={"div"} item xs={isOptional ? 8 : 12} className={classes.sectionTitleContainer}>
            <Typography className={classes.sectionTitle}>{`Reference ${
               index + 1
            } ${optionalText} ${altText}`}</Typography>
         </Grid>
         {isOptional ? (
            <Grid item xs={4} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
               <Box display={"grid"} justifyContent={"end"}>
                  <Button
                     id={`btn-delete-${index.toString()}`}
                     variant="text"
                     color="primary"
                     disabled={disabled}
                     onClick={() => removeReference(index)}
                     className={classes.commonButton}
                     startIcon={<CancelIcon />}
                  >
                     REMOVE REFERENCE
                  </Button>
               </Box>
            </Grid>
         ) : null}
      </Grid>
   );
}
