import React from "react";
import { emailToWriteWhenExpired, expirationLogo, headerSubTitle, keyOffIcon } from "../const";
import { useExpirationStyle } from "./style";

interface Props {
   division: keyof typeof headerSubTitle;
}

export default function ActivationExpired({ division }: Props) {
   const style = useExpirationStyle({ division });
   const ref = React.useRef(null);
   return (
      <section className={style.container}>
         <div className={style.card}>
            <img className={style.imgContainer} src={expirationLogo[division]} alt={headerSubTitle[division]} />
            <div className={style.title}>
               <img src={keyOffIcon[division]} alt="key off icon" style={{ marginRight: "16px" }} />
               <div>Authentication invalid</div>
            </div>
            <hr className={style.hr} />
            <p className={style.paragrapText}>
               You are seeing this page because the link in your email has expired. Please reach out to{" "}
               <a href={`mailto:${emailToWriteWhenExpired[division]}?subject=Authentication invalid`}>
                  {emailToWriteWhenExpired[division]}
               </a>{" "}
               to request a new customer activation form.
            </p>
         </div>
         <div className={style.bottonImage}>
            <img src="/backgroundActivationExpired.png" alt="image expired" />
         </div>
      </section>
   );
}

{
}
