import { Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import { emailReadIcon, redirectPageAfterSubmit } from "../const";
import { useActivationSubmittedStyles } from "./style";

type Props = {
   applicationNumber?: string;
   division: keyof typeof emailReadIcon;
};

export default function ConfirmSubmit({ applicationNumber, division }: Props) {
   const ref = React.useRef();
   const classes = useActivationSubmittedStyles();

   const handleClick = () => {
      const url = redirectPageAfterSubmit[division];
      window.location.assign(url);
   };

   return (
      <section className={classes.container}>
         <div className={classes.imgContainer}>
            <img src={emailReadIcon[division]} alt="email Icon" />
         </div>
         <h2 className={classes.subTitleText}>Activation Form Submitted</h2>
         <h1 className={classes.titleText}>Form #: {applicationNumber}</h1>
         <p className={classes.paragrapText}>A PDF Version Of Your Activation Form Has Been Emailed To You</p>
         <div className={classes.sectionButton}>
            <Button
               variant="contained"
               color="primary"
               style={{
                  fontSize: "14px",
                  fontWeight: 500,
               }}
               size="large"
               onClick={handleClick}
               endIcon={<DoneIcon />}
            >
               FINISH
            </Button>
         </div>
      </section>
   );
}
