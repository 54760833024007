/* eslint-disable react/react-in-jsx-scope */
import { Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSubHeaderStyles } from "./Styles";
/* eslint-disable prettier/prettier */
import { Card, Chip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import { useErrorHandler } from "react-error-boundary";
import { Contacts } from "../../../types/Contacts";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { DateTimeFormatterShortYear } from "../../../utilities/DateTimeFormatterShortYear";

type Props = {
   selectedCustomerActivation: CustomerActivation;
   contactList: Contacts[];
   selectedDivision: string;
};

function SentSubHeader({ selectedCustomerActivation, contactList, selectedDivision }: Props) {
   const classes = useSubHeaderStyles();
   const handleError = useErrorHandler();

   function checkIfExpired(date: Date) {
      try {
         const formattedDate = new Date(date);

         if (formattedDate.setDate(formattedDate.getDate() + 30) < Date.now()) {
            return true;
         }

         return false;
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <>
         <Grid container direction={"row"}>
            <Grid direction={"column"} item xs={6}>
               <Button
                  variant="outlined"
                  color="primary"
                  className={classes.primaryButton}
                  endIcon={<CreateIcon />}
                  style={{ marginRight: "12px" }}
               >
                  Edit Email
               </Button>
               <Button
                  variant="outlined"
                  color="primary"
                  className={classes.primaryButton}
                  endIcon={<CreateIcon />}
                  style={{ marginRight: "12px" }}
               >
                  Update Customer Code
               </Button>
            </Grid>
            <Grid direction={"column"} item xs={6} justifyContent="flex-end">
               <Grid container direction={"row"} alignItems="center" justifyContent="flex-end">
                  <Button
                     variant="outlined"
                     color="primary"
                     className={classes.primaryButton}
                     endIcon={<ReplyAllIcon />}
                     style={{ marginRight: "12px" }}
                  >
                     Resend
                  </Button>
                  <Button
                     variant="outlined"
                     color="primary"
                     className={classes.primaryButton}
                     endIcon={<LinkIcon />}
                     style={{ marginRight: "12px" }}
                  >
                     App Link
                  </Button>
                  <Button
                     variant="outlined"
                     className={classes.notesButton}
                     endIcon={<SpeakerNotesIcon />}
                     style={{ marginRight: "12px" }}
                  >
                     Notes
                  </Button>
                  <Button variant="outlined" className={classes.deleteButton} endIcon={<DeleteIcon />}>
                     Delete
                  </Button>
               </Grid>
            </Grid>
         </Grid>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Date
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company | Division
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Customer Code
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Point of Contact
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Status
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  justifyContent="space-between"
                  style={{
                     height: "50px",
                  }}
               >
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {DateTimeFormatterShortYear(selectedCustomerActivation.addDate)}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedDivision}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedCustomerActivation.companyCode}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {contactList.map((contact, index) => {
                           return (
                              <>
                                 {contact.name}
                                 {index < contactList.length - 1 ? ", " : ""}
                              </>
                           );
                        })}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <div className={classes.chipContainer}>
                        {selectedCustomerActivation.addDate ? (
                           checkIfExpired(selectedCustomerActivation.addDate) === true ? (
                              //Expired
                              <Chip
                                 key={selectedCustomerActivation._id}
                                 label="Expired"
                                 size="small"
                                 clickable={false}
                                 className={classes.chipRed}
                                 variant="outlined"
                              />
                           ) : selectedCustomerActivation.applicationOpened ? (
                              //Opened
                              <Chip
                                 key={selectedCustomerActivation._id}
                                 label="Opened"
                                 size="small"
                                 clickable={false}
                                 className={classes.chipGreen}
                                 variant="outlined"
                              />
                           ) : (
                              //Sent
                              <Chip
                                 key={selectedCustomerActivation._id}
                                 label="Sent"
                                 size="small"
                                 clickable={false}
                                 color="primary"
                                 variant="outlined"
                              />
                           )
                        ) : (
                           //Expired. This is a safety measure.
                           <Chip
                              key={selectedCustomerActivation._id}
                              label="Sent"
                              size="small"
                              color="primary"
                              clickable={false}
                           />
                        )}
                     </div>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { SentSubHeader };
