import {
   ActivationBillingCpgInformationType,
   ActivationFormFieldError,
   ActivationFormProperties,
   ActivationModelForm,
} from "../../../../types/Activation/ActivationDTO";
import { ValidationBuilder } from "../../../../utilities/ValidationTools";

type FormProperties = ActivationFormProperties<ActivationBillingCpgInformationType>;
type Checkbox = { name: string; value: string };

export function billingInformationCpgReducer(
   state: Partial<ActivationModelForm>,
   action: { type: ActivationBillingCpgInformationType | "forceValidation"; payload: unknown },
): Partial<ActivationModelForm> {
   const Validation = new ValidationBuilder();
   switch (action.type) {
      case "AccountsPayableName":
         const companyContactErrorMessage = Validation.validateNotEmptyString(
            "Please enter the account payable name",
            action.payload as string,
         )
            .maximumLength(100)
            .getMessageError();
         return {
            ...state,
            AccountsPayableName: {
               value: action.payload as string,
               error: !!companyContactErrorMessage,
               message: companyContactErrorMessage,
            },
         };
      case "AccountsPayableEmail":
         const accountsPayableEmailErrorMessage = Validation.validateEmail(action.payload as string).getMessageError();
         return {
            ...state,
            AccountsPayableEmail: {
               value: action.payload as string,
               error: !!accountsPayableEmailErrorMessage,
               message: accountsPayableEmailErrorMessage,
            },
         };
      case "MethodAux":
         const methodAuxErrorMessage = Validation.validateNotEmptyString(
            "Please enter the method",
            action.payload as string,
         ).getMessageError();

         //Method value
         const MethodValidation = new ValidationBuilder();
         let methodErrorMessageAux = "";

         if (action.payload == "Email" && !methodErrorMessageAux) {
            methodErrorMessageAux = MethodValidation.validateNotEmptyString(
               "If email, enter the address for invoicing here",
               state.Method?.value as string,
            )
               .validateEmail()
               .getMessageError();
         }
         if (action.payload == "Website" && !methodErrorMessageAux) {
            methodErrorMessageAux = MethodValidation.validateWebSite(state.Method?.value as string).getMessageError();
         }

         if (action.payload === "Other" && !methodErrorMessageAux) {
            methodErrorMessageAux = MethodValidation.validateNotEmptyString(
               "Please enter the method",
               state.Method?.value as string,
            ).getMessageError();
         }

         return {
            ...state,
            MethodAux: {
               value: action.payload as string,
               error: !!methodAuxErrorMessage,
               message: methodAuxErrorMessage,
            },
            Method: {
               value: state.Method?.value as string,
               error: !!methodErrorMessageAux,
               message: methodErrorMessageAux,
            },
         };
      case "Method":
         let methodErrorMessage = "";

         if (state.MethodAux?.value == "Email" && !methodErrorMessage) {
            methodErrorMessage = Validation.validateNotEmptyString(
               "If email, enter the address for invoicing here",
               action.payload as string,
            )
               .validateEmail()
               .getMessageError();
         }

         if (state.MethodAux?.value == "Website" && !methodErrorMessage) {
            methodErrorMessage = Validation.validateNotEmptyString("Please enter a valid URL", action.payload as string)
               .validateWebSite(action.payload as string)
               .getMessageError();
         }

         if (state.MethodAux?.value === "Other" && !methodErrorMessage) {
            methodErrorMessage = Validation.validateNotEmptyString(
               "Please enter the method",
               action.payload as string,
            ).getMessageError();
         }

         return {
            ...state,
            Method: {
               value: action.payload as string,
               error: !!methodErrorMessage,
               message: methodErrorMessage,
            },
         };
      case "Documents":
         const { name, value } = action.payload as Checkbox;
         const set = new Set(state.Documents?.value as string[]);

         if (value) set.add(name);
         else set.delete(name);

         return {
            ...state,
            Documents: {
               value: Array.from(set),
               error: false,
            },
         };
      case "forceValidation":
         const properties: FormProperties = { ...defaultValues };
         // filling the properties in.
         for (const field of Object.keys(
            action.payload as ActivationBillingCpgInformationType,
         ) as (keyof FormProperties)[]) {
            properties[field] = (action.payload as FormProperties)[field]?.value
               ? (action.payload as FormProperties)[field]
               : defaultValues[field];
         }

         return {
            ...properties,
         };
      default:
         throw "Invalid action";
   }
}

const defaultValues: Record<ActivationBillingCpgInformationType, ActivationFormFieldError<string | string[]>> = {
   AccountsPayableName: {
      value: "",
      error: true,
      message: "Please enter the account payable name",
   },
   AccountsPayableEmail: {
      value: "",
      error: true,
      message: "Please enter the account payable email",
   },
   Method: {
      value: "",
      error: true,
      message: "Please enter the method",
   },
   MethodAux: {
      value: "",
      error: true,
      message: "Please select an method",
   },
   Documents: {
      value: [],
      error: false,
      message: undefined,
   },
};

export const initialBillinCpgValues: Record<
   ActivationBillingCpgInformationType,
   ActivationFormFieldError<string | string[]>
> = {
   AccountsPayableName: {
      value: "",
      error: true,
   },
   AccountsPayableEmail: {
      value: "",
      error: true,
   },
   Method: {
      value: "",
      error: true,
   },
   MethodAux: {
      value: "",
      error: true,
   },
   Documents: {
      value: [],
      error: false,
   },
};
