import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useEsignStyles = makeStyles((theme: Theme) =>
   createStyles({
      termsContainer: {
         padding: "8px 16px",
         backgroundColor: "#EEEEEE",
         textAlign: "justify",
         fontSize: "16px",
         "& ol": {
            paddingLeft: "1.25rem",
            margin: "0px",
         },
      },
      agreementContainer: {
         "& .MuiFormControlLabel-root .MuiTypography-root": {
            fontSize: "16px",
         },
      },
      note: {
         padding: "8px 16px",
         backgroundColor: "#EEEEEE",
         textAlign: "center",
         fontSize: "16px",
         marginTop: "45px",
         marginBottom: "25px",
         fontWeight: "bold",
      },
   }),
);
