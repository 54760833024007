import { createTheme } from "@material-ui/core";

export const theme = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#002D72",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

export const themeMiddleBay = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#8F9864",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

export const themeBristol = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#1E376B",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

export const themeUWL_WDS = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#043F60",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

export const themeActivationUWL_WDS = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#2E5A46",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontSize: 14,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});
