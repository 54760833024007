import { Box, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { saveAs } from "file-saver";
import React from "react";
import InputMask from "react-input-mask";
import { getTradeReferencesPdf } from "../../../api/ApplicationApi";
import { TradeRefInfo } from "../../../types/tradeRefInfo";
import { countryList } from "../../../utilities/CountryList";
import ReferenceHeader from "./ReferenceHeader";
import TradeReferenceOptions, { TypeParams } from "./TradeReferenceOptions";
import { useTradeinfoStyles } from "./styles";

type TradeReferenceProps = {
   tradeRefInfo: TradeRefInfo[];
   tradeReferencesSkipped?: boolean;
   tradeReferencesFileName?: string;
   handleChange: (value: string | boolean, type: TypeParams, index: number) => void;
   addTradeRefInfo: () => void;
   removeTradeRefInfo: (index: number) => void;
   errorHighlight: TradeReferenceError[];
   applicationID: string;
   saving: boolean;
   isThereSomeError: boolean;
};

type TradeReferenceError = {
   company: { error: false; message: "" };
   address: { error: false; message: "" };
   city: { error: false; message: "" };
   state: { error: false; message: "" };
   country: { error: false; message: "" };
   phone: { error: false; message: "" };
   email: { error: false; message: "" };
   contact: { error: false; message: "" };
   website: { error: false; message: "" };
   ctpatNumber: { error: false; message: "" };
};

const OptionsHeaders: React.FC<any> = ({
   handleChange,
   tradeReferencesSkipped = false,
   tradeReferencesFileName = "",
   applicationID,
   fillFormIn,
   setFillFormIn,
   loading,
   isThereSomeError,
   setLoading,
   saving,
}: any) => {
   const classes = useTradeinfoStyles();

   const handleOptionChange = (value: string | boolean, type: TypeParams) => {
      handleChange(value, type, 0);
      setLoading(true);
   };

   function handleDownloadClick(event: React.MouseEvent, fileName: string): void {
      event.preventDefault();
      try {
         getTradeReferencesPdf(applicationID).then((response) => {
            if (response.data) {
               saveAs(response.data, getFileNameFromPath(fileName));
            }
         });
      } catch (error) {}
   }

   const getFileNameFromPath = (path: string) => {
      // Get extension from path
      const pathSplited = path.split(".");
      const ext = pathSplited[pathSplited.length - 1];
      pathSplited.pop();

      const fileName = pathSplited.join(".");

      // Get fileName from path
      const fileNameSplited = fileName.split("_");
      fileNameSplited.pop();
      const name = fileNameSplited.join("_");
      return `${name}.${ext}`;
   };

   const isRedColor = !fillFormIn && !tradeReferencesFileName && !tradeReferencesSkipped && isThereSomeError;

   return (
      <>
         <Grid component={"div"} item xs={12} className={classes.sectionTitleContainer}>
            <Typography className={classes.sectionTitle} color={isRedColor ? "error" : "inherit"}>
               Please select one of the options below
            </Typography>
         </Grid>

         <Box sx={{ width: "100%", margin: "25px 0px" }}>
            <TradeReferenceOptions
               tradeReferencesSkipped={tradeReferencesSkipped}
               tradeReferencesFileName={tradeReferencesFileName}
               fillForm={fillFormIn}
               setFillFormIn={setFillFormIn}
               applicationID={applicationID}
               handleChange={handleOptionChange}
               loading={loading}
               setLoading={setLoading}
               saving={saving}
            />
            <Box component={"p"} sx={{ fontSize: "14px", fontWeight: "bold", margin: "35px 15px" }}>
               {tradeReferencesFileName ? (
                  <Box sx={{ fontWeight: "500", mb: "8px" }}>
                     Download trade references document:{" "}
                     <a href="" onClick={(e) => handleDownloadClick(e, tradeReferencesFileName)}>
                        {" "}
                        {getFileNameFromPath(tradeReferencesFileName)}
                     </a>{" "}
                  </Box>
               ) : (
                  ""
               )}
               Please add the information using the fields below or upload a file with your trade references in a .PDF
               or .DOCX format.
            </Box>
         </Box>
      </>
   );
};

export const TradeReference: React.FC<any> = ({
   handleChange,
   addTradeRefInfo,
   removeTradeRefInfo,
   errorHighlight,
   tradeRefInfo,
   tradeReferencesSkipped = false,
   tradeReferencesFileName = "",
   applicationID,
   saving,
   isThereSomeError,
}: TradeReferenceProps): JSX.Element => {
   const classes = useTradeinfoStyles();

   const [fillFormIn, setFillFormIn] = React.useState(!!tradeRefInfo[0]?.company);
   const [loading, setLoading] = React.useState(false);

   const handleLocalChange = (value: string | boolean, type: TypeParams) => {
      setLoading(true);
      handleChange(value, type, 0);
   };

   React.useEffect(() => {
      if (fillFormIn) {
         const value = tradeRefInfo[0]?.company || "";
         handleChange(value, "company", 0);
      }
   }, [fillFormIn]);

   const firstRenderEmpyForm = !tradeReferencesSkipped && !tradeReferencesFileName && !fillFormIn;
   const firstRenderDirtyForm = (tradeReferencesSkipped || tradeReferencesFileName) && !fillFormIn;

   if (firstRenderEmpyForm || firstRenderDirtyForm) {
      return (
         <OptionsHeaders
            handleChange={handleChange}
            tradeReferencesSkipped={tradeReferencesSkipped}
            tradeReferencesFileName={tradeReferencesFileName}
            applicationID={applicationID}
            fillFormIn={fillFormIn}
            setFillFormIn={setFillFormIn}
            loading={loading}
            setLoading={setLoading}
            saving={saving}
            isThereSomeError={isThereSomeError}
         />
      );
   }

   return (
      <>
         <OptionsHeaders
            handleChange={handleLocalChange}
            tradeReferencesSkipped={tradeReferencesSkipped}
            tradeReferencesFileName={tradeReferencesFileName}
            applicationID={applicationID}
            fillFormIn={fillFormIn}
            setFillFormIn={setFillFormIn}
            loading={loading}
            setLoading={setLoading}
            saving={saving}
            isThereSomeError={isThereSomeError}
         />
         <section className={classes.referencesContainer}>
            {tradeRefInfo.map((trade, index) => {
               return (
                  <div key={`tr-company-${index.toString()}`}>
                     <ReferenceHeader index={index} removeReference={removeTradeRefInfo} disabled={!fillFormIn} />
                     <div key={`paper-${index}`} className={classes.itemContainer}>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                           {/* Company Name */}
                           <Grid item xs={6}>
                              <TextField
                                 id={`tr-company-${index.toString()}`}
                                 label="Company"
                                 variant="outlined"
                                 onChange={(event) => handleChange(event.target.value, "company", index)}
                                 value={trade.company}
                                 disabled={!fillFormIn}
                                 helperText={
                                    errorHighlight[index].company.error
                                       ? errorHighlight[index].company.message
                                       : errorHighlight[index].email.error
                                       ? " "
                                       : null
                                 }
                                 error={errorHighlight[index].company.error ? true : false}
                                 inputProps={{ maxLength: 100 }}
                              />
                           </Grid>
                           {/* Email */}
                           <Grid item xs={6}>
                              <TextField
                                 id={`tr-email-${index.toString()}`}
                                 label="Email"
                                 variant="outlined"
                                 onChange={(event) => handleChange(event.target.value, "email", index)}
                                 disabled={!fillFormIn}
                                 value={trade.email}
                                 helperText={
                                    errorHighlight[index].email.error
                                       ? errorHighlight[index].email.message
                                       : errorHighlight[index].company.error
                                       ? " "
                                       : null
                                 }
                                 error={errorHighlight[index].email.error ? true : false}
                              />
                           </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                           {/* Contact */}
                           <Grid item xs={4}>
                              <TextField
                                 id={`tr-contact-${index.toString()}`}
                                 label="Contact"
                                 variant="outlined"
                                 onChange={(event) => handleChange(event.target.value, "contact", index)}
                                 disabled={!fillFormIn}
                                 value={trade.contact}
                                 helperText={
                                    errorHighlight[index].contact.error
                                       ? errorHighlight[index].contact.message
                                       : errorHighlight[index].contact.error || errorHighlight[index].contact.error
                                       ? " "
                                       : null
                                 }
                                 error={!!errorHighlight[index].contact.error}
                                 inputProps={{ maxLength: 100 }}
                              />
                           </Grid>
                           {/* phone */}
                           <Grid item xs={3}>
                              <InputMask
                                 mask="999-999-9999"
                                 value={trade.phone}
                                 disabled={!fillFormIn}
                                 onChange={(event) => handleChange(event.target.value, "phone", index)}
                              >
                                 {() => (
                                    <TextField
                                       id={`tr-phone-${index.toString()}`}
                                       label="Phone"
                                       variant="outlined"
                                       disabled={!fillFormIn}
                                       error={errorHighlight[index].phone.error ? true : false}
                                       inputProps={{
                                          autoComplete: "phone",
                                          form: {
                                             autoComplete: "off",
                                          },
                                       }}
                                    />
                                 )}
                              </InputMask>
                              <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                                 {errorHighlight[index].phone.error
                                    ? errorHighlight[index].phone.message
                                    : errorHighlight[index].country.error || errorHighlight[index].contact.error
                                    ? " "
                                    : null}
                              </FormHelperText>
                           </Grid>
                           {/* Country */}
                           <Grid item xs={5}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                 <InputLabel
                                    id={`lbl-country-${index.toString()}`}
                                    style={{
                                       color: errorHighlight[index].country.error ? "red" : "inherit",
                                    }}
                                 >
                                    Country
                                 </InputLabel>
                                 <Select
                                    labelId={`lbl-country-${index.toString()}`}
                                    id={`tr-country-${index.toString()}`}
                                    className="country"
                                    variant="outlined"
                                    disabled={!fillFormIn}
                                    onChange={(event) => handleChange(event.target.value as string, "country", index)}
                                    defaultValue={""}
                                    value={trade.country}
                                    label="Country"
                                    error={errorHighlight[index].country.error ? true : false}
                                 >
                                    {countryList.map((country, index) => {
                                       return (
                                          <MenuItem key={index} value={country.name}>
                                             {country.name}
                                          </MenuItem>
                                       );
                                    })}
                                 </Select>
                                 <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                                    {errorHighlight[index].country.error
                                       ? errorHighlight[index].country.message
                                       : errorHighlight[index].phone.error || errorHighlight[index].contact.error
                                       ? " "
                                       : null}
                                 </FormHelperText>
                              </FormControl>
                           </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                           {/* Adress */}
                           <Grid item xs={6}>
                              <TextField
                                 id={`tr-address-${index.toString()}`}
                                 label="Address"
                                 variant="outlined"
                                 onChange={(event) => handleChange(event.target.value, "address", index)}
                                 value={trade.address}
                                 disabled={!fillFormIn}
                                 helperText={
                                    errorHighlight[index].address.error
                                       ? errorHighlight[index].address.message
                                       : errorHighlight[index].city.error || errorHighlight[index].state.error
                                       ? " "
                                       : null
                                 }
                                 error={!!errorHighlight[index].address.error}
                                 inputProps={{ maxLength: 100 }}
                              />
                           </Grid>
                           {/* City */}
                           <Grid item xs={3}>
                              <TextField
                                 id={`tr-city-${index.toString()}`}
                                 label="City"
                                 variant="outlined"
                                 onChange={(event) => handleChange(event.target.value, "city", index)}
                                 value={trade.city}
                                 disabled={!fillFormIn}
                                 helperText={
                                    errorHighlight[index].city.error
                                       ? errorHighlight[index].city.message
                                       : errorHighlight[index].address.error || errorHighlight[index].state.error
                                       ? " "
                                       : null
                                 }
                                 error={errorHighlight[index].city.error ? true : false}
                                 inputProps={{ maxLength: 50 }}
                              />
                           </Grid>
                           {/* State */}
                           <Grid item xs={3}>
                              <TextField
                                 id={`tr-state-${index.toString()}`}
                                 label="State"
                                 variant="outlined"
                                 onChange={(event) => handleChange(event.target.value, "state", index)}
                                 disabled={!fillFormIn}
                                 value={trade.state}
                                 helperText={
                                    errorHighlight[index].state.error
                                       ? errorHighlight[index].state.message
                                       : errorHighlight[index].address.error || errorHighlight[index].city.error
                                       ? " "
                                       : null
                                 }
                                 error={errorHighlight[index].state.error ? true : false}
                                 inputProps={{ maxLength: 2 }}
                              />
                           </Grid>
                        </Grid>
                     </div>
                  </div>
               );
            })}
         </section>
         <div style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={8} className={classes.sectionTitleContainer}>
               <Typography className={classes.sectionTitle}>Add more references (optional)</Typography>
            </Grid>
            <Grid item xs={4}>
               <Box display={"grid"} justifyContent={"end"}>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => addTradeRefInfo()}
                     disabled={!fillFormIn}
                     className={classes.commonButton}
                     endIcon={<AddIcon />}
                  >
                     ADD REFERENCE
                  </Button>
               </Box>
            </Grid>
         </div>
      </>
   );
};

export default TradeReference;
