import React, { createContext, Dispatch, useReducer } from "react";
import { Companies } from "../../types/Companies";
import { CompanyActions, companyReducer } from "./Reducers";

type InitialStateType = {
   Companies: Companies[];
};

const initialState = {
   Companies: [],
};

const CompanyContext = createContext<{
   state: InitialStateType;
   dispatch: Dispatch<CompanyActions>;
}>({
   state: initialState,
   dispatch: () => null,
});

const mainReducer = ({ Companies }: InitialStateType, action: CompanyActions) => ({
   Companies: companyReducer(Companies, action),
});

// eslint-disable-next-line react/prop-types
const CompanyProvider: React.FC = ({ children }) => {
   const [state, dispatch] = useReducer(mainReducer, initialState);
   return <CompanyContext.Provider value={{ state, dispatch }}>{children}</CompanyContext.Provider>;
};

export { CompanyProvider, CompanyContext };
//BUG IN prettier.
