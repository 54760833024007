export const validateEmailAddress = async (email: string): Promise<string> => {
   try {
      let topLevelDomain = "";

      if (email === null || email === "") {
         return `Enter an Email`;
      }

      if (email.indexOf(" ") >= 0) {
         return `Invalid Email. Email With Leading, Trailing, or Spaces in The Email Are Not Allowed`;
      }

      if ((email.match(new RegExp("@", "g")) || []).length === 0) {
         return `Invalid Email. "@" Character is Required`;
      }

      if ((email.match(new RegExp("@", "g")) || []).length > 1) {
         return `Invalid Email. Only One "@" Character Allowed`;
      }

      if ((email.match(new RegExp("[.]", "g")) || []).length === 0) {
         return `Invalid Email. "." Character is Required`;
      }

      if ((email.match(new RegExp(`["(),:;<>]`, "g")) || []).length > 0) {
         return `Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`;
      }

      if ((email.match(new RegExp(`[[]`, "g")) || []).length > 0) {
         return `Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`;
      }

      if ((email.match(new RegExp(`]`, "g")) || []).length > 0) {
         return `Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`;
      }

      if (email.indexOf(`\\`) >= 0) {
         return `Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`;
      }

      if ((email.substring(0, 1).match(new RegExp(`[!#$%&'*+-/=?^_{}|]`, "g")) || []).length > 0) {
         return `Invalid Email. Special characters !#$%&'*+-/=?^_{}| Are Not Allowed As The First Character`;
      }

      if ((email.substring(0, 1).match(new RegExp("[`]", "g")) || []).length > 0) {
         return "Invalid Email. Special characters ` Are Not Allowed As The First Character";
      }

      if (email.substring(0, 1) === "@") {
         return `Invalid Email. Recipient Name is Required. Email Cannot Begin With @ Character`;
      }

      if ((email.substring(email.indexOf("@")).match(new RegExp("[.]", "g")) || []).length === 0) {
         return `Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`;
      }

      topLevelDomain = email.substring(email.indexOf("@"));

      if (topLevelDomain.indexOf(".") === 1) {
         return `Invalid Email. Domain Name (@gmail, @yahoo, @outlook) Required`;
      }

      topLevelDomain = topLevelDomain.substring(topLevelDomain.indexOf(".") + 1);

      if (topLevelDomain === "" || topLevelDomain === null) {
         return `Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`;
      }

      if (email.substring(email.length - 1, email.length).match(new RegExp(`[^a-zA-Z0-9]`, "g"))) {
         return `Invalid Email. Email Cannot End With a Special Character`;
      }

      return ``;
   } catch (err) {
      return `An error has occurred. Please reach out to administration for assistance.`;
   }
};
