import { Box, Card, Typography } from "@material-ui/core";
import React from "react";

export const CardList: React.FC = ({ children }) => (
   <Box style={{ display: "flex", flexDirection: "column", gap: "7px" }}>{children}</Box>
);

export const CardItem: React.FC = ({ children }) => (
   <Card
      style={{
         display: "flex",
         padding: "16px 36px",
         justifyContent: "space-between",
         alignItems: "center",
         alignSelf: "stretch",
         borderRadius: "24px",
         background: "#FFF",
         boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.12)",
      }}
   >
      {children}
   </Card>
);

export const CardWrapper: React.FC = ({ children }) => (
   <Box style={{ width: "100%", display: "flex", gap: "20px", justifyContent: "space-between", alignItems: "center" }}>
      {children}
   </Box>
);

export const CardColumn: React.FC = ({ children }) => (
   <Box
      style={{
         display: "flex",
         flexDirection: "column",
         gap: "10px",
         alignItems: "flex-start",
         padding: "0 15px",
         overflow: "hidden",
         whiteSpace: "nowrap",
      }}
   >
      {children}
   </Box>
);

export const CardColumnTitle: React.FC = ({ children }) => (
   <Typography
      style={{
         color: "#767676",
         fontStyle: "normal",
         fontWeight: "700",
         lineHeight: "20px",
         letterSpacing: "0.17px",
      }}
   >
      {children}
   </Typography>
);

export const CardColumnTextContent: React.FC = ({ children }) => (
   <Typography
      style={{
         color: "#010440",
         fontSize: "16px",
         fontStyle: "normal",
         fontWeight: "400",
         lineHeight: "23px",
         letterSpacing: "0.17px",
      }}
   >
      {children}
   </Typography>
);

export const CardColumnTextContentEllipsis: React.FC = ({ children }) => (
   <Typography
      style={{
         color: "#010440",
         fontSize: "16px",
         fontStyle: "normal",
         fontWeight: "400",
         lineHeight: "23px",
         letterSpacing: "0.17px",
         textOverflow: "ellipsis",
         width: "100%",
         overflow: "hidden",
         display: "-webkit-box",
         WebkitLineClamp: 2,
         WebkitBoxOrient: "vertical",
      }}
   >
      {children}
   </Typography>
);

export const StatusChip: React.FC<{ status: "SENT" | "OPENED" | "EXPIRED" }> = ({ children, status }) => (
   <Box
      style={{
         backgroundColor: "#FFFFFF",
         color: status === "SENT" ? "#002D72" : status === "OPENED" ? "#4caf50" : "#f44336",
         border: `1.5px solid ${status === "SENT" ? "#002D72" : status === "OPENED" ? "#4caf50" : "#f44336"}`,
         borderRadius: "100px",
         padding: "5px",
         textAlign: "center",
         minWidth: "70px",
      }}
   >
      {children}
   </Box>
);
