import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { headerIcon, headerSubTitle, truckIcon } from "../const";
import { useValidationHeaderStyles } from "./Styles";

type Props = {
   division: keyof typeof headerSubTitle;
};

function ActivationHeader({ division }: Props) {
   const [a, b] = React.useState();
   const classes = useValidationHeaderStyles();
   const matches = useMediaQuery("(min-width: 1000px)");

   return (
      <section className={classes.container}>
         <div className={classes.sectionLogo}>
            <img src={headerIcon[division]} width={160} height={69} alt="Logo" />
            <h1>Customer Activation Request</h1>
         </div>
         <div className={classes.hr}></div>
         <h3 className={classes.section2Title}>
            Thank you for choosing{" "}
            <span className={classes.cursiveText}>{headerSubTitle[division]?.toUpperCase()}</span>
         </h3>
         <article className={classes.section2}>
            <div>
               <h3 style={{ fontSize: "16px" }}>We are excited to begin the onboarding process!</h3>
               <p className={classes.section2Paragraph}>
                  Please complete the fields below. Note that all fields with an asterisk (*) are required and all
                  others are optional during this process. After reviewing our Terms & Conditions, please sign, date,
                  and submit your Customer Activation Form. Your completed form will be promptly reviewed, and your
                  dedicated onboarding contact will reach out with next steps.
               </p>
               <p className={classes.section2Paragraph}>
                  Thank you, again, from all of us at{" "}
                  <span className={classes.cursiveText}> {headerSubTitle[division]}</span>. We look forward to working
                  with you!
               </p>
            </div>

            {matches ? <img src={truckIcon[division]} alt="truck" /> : null}
         </article>
      </section>
   );
}

export { ActivationHeader };
