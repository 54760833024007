import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { headerSubTitle } from "../const";

interface Props {
   division: keyof typeof headerSubTitle;
}

export const useExpirationStyle = makeStyles<Theme, Props>((theme) =>
   createStyles({
      container: {
         backgroundColor: "#FFF",
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
         alignItems: "center",
         height: "100%",
      },
      card: {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         maxWidth: "750px",
         height: "100%",
         padding: "150px 30px 0px 30px",
         [theme.breakpoints.down("sm")]: {
            paddingTop: "100px",
         },
      },
      imgContainer: (props) => ({
         width: props.division === "CPG" ? "517px" : "",
         height: props.division === "CPG" ? "149px" : "",
         marginBottom: props.division === "Bristol" ? "1px" : "30px",
      }),
      title: {
         color: theme.palette.primary.main,
         fontSize: "32px",
         fontWeight: 600,
         display: "flex",
         justifyContent: "center",
      },
      paragrapText: {
         fontSize: "20px",
         padding: "0px",
         margin: "0px",
      },
      email: {
         color: theme.palette.primary.main,
      },
      hr: {
         height: "2px",
         margin: "24px 0px",
         width: "100%",
         backgroundColor: theme.palette.primary.main,
      },
      bottonImage: {
         marginTop: "auto",
         "& img": {
            width: "100%",
            height: "100%",
            opacity: "0.7",
         },
      },
   }),
);
