import _ from "lodash";
import React, { useRef, useState } from "react";

import {
   Chip,
   FormControl,
   FormHelperText,
   InputAdornment,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { CompanyContext } from "../../../../stores/Companies/Context";
import { DivisionContext } from "../../../../stores/Divisions/Context";
import { Contacts } from "../../../../types/Contacts";
import { ActivationBaseDialogHOC } from "../ActivationBaseDialogHOC";

export type ApprovalParams = {
   customerCode: string;
   approvedAmount: string;
   contacts: string[];
   paymentTerms?: string;
};

interface Props {
   open: boolean; //TODO: replace this any type
   divisionId: string;
   companyId: string;
   paymentTerms?: string;

   customerCode: string;
   approvedAmount: number;
   contacts: string[];

   handleClose: () => void;
   callback: (approval: ApprovalParams) => Promise<void>;
}

interface FormStateErrors {
   customerCode: string;
   contacts: string;
}

export const ActivationApproveDialog: React.FC<Props> = ({
   open,
   customerCode,
   approvedAmount,
   contacts,
   divisionId,
   companyId,
   paymentTerms,
   handleClose,
   callback,
}) => {
   const [isSaving, setIsSaving] = useState(false);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);
   // Context
   const { state: companyState } = React.useContext(CompanyContext);
   const { state: divisionState } = React.useContext(DivisionContext);
   // form fields
   const [customValue, setCustomValue] = useState<string>(customerCode);
   const [paymentApprovalTerms, setPaymentApprovalTerms] = useState<string | undefined>(paymentTerms);
   const [approvedAmountValue, setApprovedAmountValue] = useState<string | number>(approvedAmount);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const contactRef = useRef<Contacts[]>([]);
   const [errors, setErrors] = useState<FormStateErrors>({
      customerCode: "",
      contacts: "",
   });

   const handleSave = async () => {
      setIsSaving(true);

      let flag = true;
      // validate form fields
      if (!customValue) {
         setErrors((prev) => ({ ...prev, customerCode: "Customer code is required" }));
         flag = false;
      }
      if (selectedContactsList.length <= 0) {
         setErrors((prev) => ({ ...prev, contacts: "Contact is required" }));
         flag = false;
      }

      if (!flag) {
         setIsSaving(false);
         return;
      }

      await callback({
         customerCode: customValue,
         approvedAmount: approvedAmountValue + "",
         contacts: selectedContactsList.map((x) => x._id),
         paymentTerms: paymentApprovalTerms,
      });
      setIsSaving(false);
      // clear form fields
      setCustomValue("");
      setApprovedAmountValue(0);
      setSelectedContactsList([]);
      handleClose();
   };

   const handleCloseDialog = () => {
      // clear form fields
      setCustomValue("");
      setApprovedAmountValue(0);
      setSelectedContactsList([]);
      handleClose();
   };

   const handleApprovedAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // prevent non numeric values
      if (isNaN(+event.target.value)) return;
      setApprovedAmountValue(+event.target.value);
   };

   function handleChipDelete(_id: string) {
      // add to contact dropdown list
      const contactToAdd = contactRef.current.find((x) => x._id === _id) as Contacts;
      setContactList(_.orderBy([...contactList, contactToAdd], [(contact) => contact.name.toLowerCase()], ["asc"]));

      // remove from contact selected
      setSelectedContactsList(selectedContactsList.filter((x) => x._id !== _id));
   }

   function handleSelectContactChange(contactIds: (string | Contacts)[]) {
      // Add to contacts selected
      const contactsAux: Contacts[] = [];
      contactIds.forEach((id) => {
         if (typeof id === "string") {
            const contact = contactRef.current.find((x) => x._id === id);
            contact && contactsAux.push(contact);
         } else {
            const contact = contactRef.current.find((x) => x._id === id._id);
            contact && contactsAux.push(contact);
         }
         setErrors((prev) => ({ ...prev, contacts: "" }));
      });

      setSelectedContactsList(contactsAux);

      // remove the contact selected from contactList
      const contactListAux = contactList.filter((x) => !contactsAux.includes(x));
      setContactList(contactListAux);
   }

   const handleSelectContactBlur = () => {
      const result = selectedContactsList.length <= 0 ? "Contact is required" : "";
      setErrors((prev) => ({ ...prev, contacts: result }));
   };

   const handleCustomValueBlur = () => {
      const result = !customValue ? "Customer code is required" : "";
      setErrors((prev) => ({ ...prev, customerCode: result }));
   };

   // get default amount approved
   React.useEffect(() => {
      // get quick credit amount from company or division
      if (divisionId) {
         const divisionAssigned = _.filter(divisionState.Divisions, (x) => x._id.toString() === divisionId?.toString());

         if (divisionAssigned.length > 0) {
            const quickCreditAmount = divisionAssigned[0].quickCreditAmount;
            setApprovedAmountValue(quickCreditAmount);
         }
      } else {
         const companyAssigned = _.filter(companyState.Companies, (x) => x._id.toString() === companyId?.toString());

         if (companyAssigned.length > 0) {
            const quickCreditAmount = companyAssigned[0].quickCreditAmount;
            setApprovedAmountValue(quickCreditAmount);
         }
      }
   }, [divisionId, companyId]);

   //Loading Use Effect.
   React.useEffect(() => {
      if (!open) return;

      if (divisionId) {
         const divisionAssigned = _.filter(divisionState.Divisions, (x) => x._id.toString() === divisionId?.toString());

         if (divisionAssigned.length > 0) {
            contactRef.current = _.orderBy(
               _.filter(divisionAssigned[0].contacts, (x) => x.active === true),
               [(contact) => contact.name.toLowerCase()],
               ["asc"],
            );
         }
      } else {
         const companyAssigned = _.filter(companyState.Companies, (x) => x._id.toString() === companyId?.toString());

         if (companyAssigned.length > 0) {
            contactRef.current = _.orderBy(
               _.filter(companyAssigned[0].contacts, (x) => x.active === true),
               [(contact) => contact.name.toLowerCase()],
               ["asc"],
            );
         }
      }

      // Select contact by default
      const contactsSelected = contactRef.current.filter((x) => contacts.includes(x._id));
      const contactListAux = contactRef.current.filter((x) => !contacts.includes(x._id));

      setContactList(contactListAux);
      setSelectedContactsList(contactsSelected);

      return () =>
         // clear errors on unmount
         setErrors({
            customerCode: "",
            contacts: "",
         });
   }, [open]);

   return (
      <ActivationBaseDialogHOC
         open={open}
         title="Activation Approval"
         primaryButtonTitle="SAVE CHANGES"
         secondaryButtonTitle="DISCARD CHANGES"
         isSaving={isSaving}
         handleClose={handleCloseDialog}
         handleAction={handleSave}
      >
         <form>
            <TextField
               id="amountApproved"
               label="Amount Approved (optional)"
               name="amountApproved"
               onChange={handleApprovedAmountChange}
               value={approvedAmountValue}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position="start">
                        <AttachMoneyIcon />
                     </InputAdornment>
                  ),
               }}
            />
            <TextField
               id="custom-code"
               label="Customer Code"
               name="customerCode"
               multiline
               onChange={(event) => setCustomValue(event.target.value)}
               onBlur={handleCustomValueBlur}
               value={customValue}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               error={Boolean(errors.customerCode)}
               helperText={errors.customerCode}
            />
            <FormControl
               variant="outlined"
               style={{ marginTop: "0.75rem", width: "100%" }}
               error={Boolean(errors.contacts)}
            >
               <InputLabel>Contact</InputLabel>
               <Select
                  id="select-contact"
                  label="Contact"
                  multiple
                  MenuProps={{
                     getContentAnchorEl: null,
                     transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                     },
                  }}
                  value={selectedContactsList}
                  onChange={(event) => handleSelectContactChange(event.target.value as string[])}
                  onBlur={handleSelectContactBlur}
                  disabled={contactList.length <= 0}
               >
                  {contactList.map((contact) => {
                     return (
                        <MenuItem key={contact._id} value={contact._id}>
                           {contact.name}
                        </MenuItem>
                     );
                  })}
               </Select>
               <FormHelperText>{errors.contacts}</FormHelperText>
            </FormControl>
            <TextField
               id="payment-terms"
               label="Payment Terms (optional)"
               name="paymentTerms"
               multiline
               onChange={(event) => setPaymentApprovalTerms(event.target.value)}
               value={paymentTerms}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
            />
            {selectedContactsList.map((contact) => {
               return (
                  <Chip
                     key={contact._id}
                     label={contact.name}
                     variant="outlined"
                     color="primary"
                     onDelete={() => handleChipDelete(contact._id)}
                     style={{ marginTop: ".5rem", marginRight: ".5rem", fontSize: "0.75rem" }}
                  />
               );
            })}
         </form>
      </ActivationBaseDialogHOC>
   );
};
