import React from "react";

import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import { ActivationCpgBillingInformationFormDTO } from "../../../../types/Activation/ActivationDTO";
import { useActivationStyles } from "../../styles";
import { billingInformationCpgReducer, initialBillinCpgValues } from "./reducer";

const methodOptions = [
   {
      Code: "Email",
      Value: "Email",
   },
   {
      Code: "Website",
      Value: "Website/Web Portal",
   },
   {
      Code: "Other",
      Value: "Other",
   },
];

type Props = {
   wasSubmit: boolean;
   changeFormState: (
      from: "billing" | "companyInformation" | "signAndComplete" | "billingCpg",
      isValid: boolean,
   ) => void;
   updateActivationObject: (obj: ActivationCpgBillingInformationFormDTO) => void;
};

const ActivationCpgBillingInformation = ({ wasSubmit, changeFormState, updateActivationObject }: Props) => {
   const [state, dispatch] = React.useReducer(billingInformationCpgReducer, initialBillinCpgValues);
   const classes = useActivationStyles();

   const refFormState = React.useRef(false);

   /**
    * Validate fields, return true if the form is valid
    */
   const validateForm = (): boolean => {
      if (state.AccountsPayableName?.error) return false;
      if (state.AccountsPayableEmail?.error) return false;
      if (state.Method?.error) return false;
      if (state.PaymentMethod?.error) return false;
      return true;
   };

   React.useEffect(() => {
      // validate fields
      if (!wasSubmit) return;

      dispatch({ type: "forceValidation", payload: state });
   }, [wasSubmit]);

   React.useEffect(() => {
      const isValid = validateForm();

      // Create object
      const billingInformation: ActivationCpgBillingInformationFormDTO = {
         AccountsPayableName: state.AccountsPayableName?.value as string,
         AccountsPayableEmail: state.AccountsPayableEmail?.value as string,
         Method: {
            Code: state.MethodAux?.value as string,
            Value: state.Method?.value as string,
         },
         PaymentMethod: state.PaymentMethod?.value as string,
         Documents: state.Documents?.value as string[],
      };
      // update parent object
      updateActivationObject(billingInformation);

      // sent to the parent component when the form change its state between valid and invalid
      if (isValid !== refFormState.current) {
         refFormState.current = isValid;
         changeFormState("billingCpg", isValid);
      }
   }, [state]);

   return (
      <section className={classes.container}>
         <h1 className={classes.title}>
            Billing Information <span>(AP Contact)</span>
         </h1>
         <hr />
         <div>
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
               {/* Accounts Payable Contact */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="payable-contact-name"
                     label="Accounts Payable Contact Name"
                     autoComplete="ActivationBillingAccountsPayableName"
                     name="activationBillingAccountsPayableName"
                     placeholder="Accounts Payable Contact Name"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "AccountsPayableName", payload: event.target.value })}
                     value={state.AccountsPayableName?.value}
                     helperText={state.AccountsPayableName?.error && state.AccountsPayableName?.message}
                     error={!!(state.AccountsPayableName?.error && state.AccountsPayableName?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               {/* Accounts Payable Email */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="payable-contact-email"
                     label="Accounts Payable Contact Email"
                     autoComplete="ActivationBillingAccountsPayableEmail"
                     name="activationBillingAccountsPayableEmail"
                     placeholder="Accounts Payable Contact Email"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "AccountsPayableEmail", payload: event.target.value })}
                     value={state.AccountsPayableEmail?.value}
                     helperText={state.AccountsPayableEmail?.error && state.AccountsPayableEmail?.message}
                     error={!!(state.AccountsPayableEmail?.error && state.AccountsPayableEmail?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Preferred Method */}
               <Grid item lg={6} md={6} xs={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                     <InputLabel
                        id="type-method"
                        style={{ color: state.MethodAux?.error && state.MethodAux?.message ? "red" : "inherit" }}
                     >
                        Preferred Method for Receiving Invoices
                     </InputLabel>
                     <Select
                        required
                        labelId="methodAux"
                        id="methodAux"
                        name="methodAux"
                        variant="outlined"
                        defaultValue={""}
                        onChange={(event) => dispatch({ type: "MethodAux", payload: event.target.value })}
                        value={state.MethodAux?.value || ""}
                        label="Preferred Method for Receiving Invoices"
                        error={!!(state.MethodAux?.error && state.MethodAux?.message)}
                     >
                        {methodOptions.map((method, i) => {
                           return (
                              <MenuItem key={method.Code} value={method.Code}>
                                 {method.Value}
                              </MenuItem>
                           );
                        })}
                     </Select>
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {state.MethodAux?.error && state?.MethodAux?.message ? state?.MethodAux.message : null}
                     </FormHelperText>
                  </FormControl>
               </Grid>

               {/* Preferred Method Email or URL */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="preferred-method-email-or-url"
                     autoComplete="ActivationBillingPreferredMethod"
                     label="Enter Preferred Method: Email/URL/Other"
                     name="activationBillingPreferredMethod"
                     placeholder="Enter Preferred Method: Email/URL/Other"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Method", payload: event.target.value })}
                     value={state.Method?.value}
                     helperText={state.Method?.error && state.Method?.message}
                     error={!!(state.Method?.error && state.Method?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
            </Grid>

            {/* Documents */}
            <div style={{ padding: "0px 0px 0px 11px" }}>
               <h3 className={classes.labelText}>Document required with invoice</h3>
               <FormGroup row>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="Work Order"
                              color="primary"
                           />
                        }
                        label="Work Order"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              size="medium"
                              name="Scale Ticket"
                              color="primary"
                           />
                        }
                        label="Scale Ticket"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="Accessorial Approval"
                              color="primary"
                           />
                        }
                        label="Accessorial Approval"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="Rate Confirmation"
                              color="primary"
                           />
                        }
                        label="Rate Confirmation"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="Customs Paperwork"
                              color="primary"
                           />
                        }
                        label="Rate Confirmation"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="POD/BOL"
                              color="primary"
                           />
                        }
                        label="POD/BOL"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="Interchange In/Out"
                              color="primary"
                           />
                        }
                        label="Interchange In/Out"
                     />
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={6}>
                     <FormControlLabel
                        className={classes.controlLabel}
                        control={
                           <Checkbox
                              onChange={(event) =>
                                 dispatch({
                                    type: "Documents",
                                    payload: { name: event.target.name, value: event.target.checked },
                                 })
                              }
                              name="Driver Detention"
                              color="primary"
                           />
                        }
                        label="Driver Detention"
                     />
                  </Grid>
               </FormGroup>
            </div>
         </div>
      </section>
   );
};

export { ActivationCpgBillingInformation };
