import { createStyles, makeStyles } from "@material-ui/core";

export const useHeaderStyles = makeStyles((theme) =>
   createStyles({
      inviteButton: {
         borderRadius: "100px",
         fontSize: "15px",
         fontStyle: "normal",
         fontWeight: 500,
         lineHeight: "normal",
         textTransform: "capitalize",
         backgroundColor: "#5357a4",
      },
   }),
);
