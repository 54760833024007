import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import { useMediaQuery } from "@material-ui/core/useMediaQuery";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import "../ApplyUWLWDS.css";

type CreditRequestError = {
   amountReq: { error: boolean; message: string };
   numberEmployees: { error: boolean; message: string };
   annualRevenue: { error: boolean; message: string };
   ownership: { error: boolean; message: string };
};

export type CreditRequest = {
   amountReq: number;
   numberEmployees: number;
   annualRevenue: number;
   ownership: string;
};

type CreditRequestProps = {
   application: CreditRequest;
   handleChange: (value: string, type: keyof CreditRequest) => void;
   errorHighlight: CreditRequestError;
};
export const CreditRequest: React.FC<any> = ({
   application,
   errorHighlight,
   handleChange,
}: CreditRequestProps): JSX.Element => {
   return (
      <>
         <Grid container direction="row" alignContent="space-around" alignItems="center" justify="center">
            <Grid item xs={6}>
               <TextField
                  id="cr-creditAmountRequested"
                  label="Credit Amount Requested (Optional)"
                  onChange={(event) => handleChange(event.target.value as string, "amountReq")}
                  value={application.amountReq.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  helperText={
                     errorHighlight.amountReq.error
                        ? errorHighlight.amountReq.message
                        : errorHighlight.numberEmployees.error || errorHighlight.annualRevenue.error
                        ? " "
                        : null
                  }
                  error={errorHighlight.amountReq.error ? true : false}
                  variant="outlined"
                  inputProps={{ maxLength: 9 }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachMoneyIcon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Grid>
            <Grid item xs={3}>
               <TextField
                  id="cr-numberOfEmployees"
                  variant="outlined"
                  label="# of Employees"
                  onChange={(event) => handleChange(event.target.value as string, "numberEmployees")}
                  value={application.numberEmployees.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  helperText={
                     errorHighlight.numberEmployees.error
                        ? errorHighlight.numberEmployees.message
                        : errorHighlight.amountReq.error || errorHighlight.annualRevenue.error
                        ? " "
                        : null
                  }
                  error={errorHighlight.numberEmployees.error ? true : false}
                  inputProps={{ maxLength: 9 }}
               />
            </Grid>
            <Grid item xs={3}>
               <TextField
                  id="cr-annualRevenue"
                  variant="outlined"
                  label="Annual Revenue"
                  onChange={(event) => handleChange(event.target.value as string, "annualRevenue")}
                  value={application.annualRevenue.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  helperText={
                     errorHighlight.annualRevenue.error
                        ? errorHighlight.annualRevenue.message
                        : errorHighlight.amountReq.error || errorHighlight.numberEmployees.error
                        ? " "
                        : null
                  }
                  error={errorHighlight.annualRevenue.error ? true : false}
                  inputProps={{ maxLength: 19 }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachMoneyIcon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Grid>
         </Grid>
         <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth={true}>
               <InputLabel>Type of Business</InputLabel>
               <Select
                  id="select-typeOfBusiness"
                  label="Type of Business"
                  defaultValue=""
                  MenuProps={{
                     anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                     },
                     getContentAnchorEl: null,
                  }}
                  value={application.ownership}
                  onChange={(event) => handleChange(event.target.value as string, "ownership")}
               >
                  <MenuItem key={"Corporation"} value={"Corporation"}>
                     {"Corporation"}
                  </MenuItem>
                  <MenuItem key={"Partnership"} value={"Partnership"}>
                     {"Partnership"}
                  </MenuItem>
                  <MenuItem key={"Individual"} value={"Individual"}>
                     {"Individual"}
                  </MenuItem>
               </Select>
               <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                  {errorHighlight.ownership.error ? errorHighlight.ownership.message : null}
               </FormHelperText>
            </FormControl>
         </Grid>
      </>
   );
};

export default CreditRequest;
