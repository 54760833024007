import { Companies } from "../types/Companies";
import { ApiRequest } from "../types/Requests/ApiRequest";
import { ApiResponse } from "../types/Responses/ApiResponse";
import { HandleError, HandleResponse } from "./ApiUtilities";

export const getCompanyList = async (getApplicationCount = false): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/getCompanyList`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         getApplicationCount: getApplicationCount,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getCompanyById = async (id: string, getApplicationCount = false): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/getCompanyById`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
         getApplicationCount: getApplicationCount,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getCompany = async (company: Companies): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/getCompany`,
      method: "POST",
      body: JSON.stringify(company),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getPacketLastModifiedDate = async (CompanyId: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/getPacketLastModifiedDate`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         CompanyId: CompanyId,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setCompany = async (company: Companies): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/setCompany`,
      method: "POST",
      body: JSON.stringify(company),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setDocument = async (document: FormData | null | undefined): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/setOnboardingPacket`,
      method: "POST",
      body: document,
      headers: {},
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const removeCompany = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Company/removeCompany/`,
      method: "DELETE",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};
