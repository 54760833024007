import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyDetails from "./CompanyDetails";
import CompanyView from "./CompanyView";

export const Company: React.FC<any> = (): JSX.Element => {
   return (
      <>
         <Switch>
            <Route path="/companies" exact component={CompanyView} />
            <Route path="/companies/details/:id?" exact component={CompanyDetails} />
         </Switch>
      </>
   );
};

export default Company;
