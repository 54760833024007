import { Button, ButtonGroup, Collapse, IconButton, makeStyles, TextField, useTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import React, { useContext, useState } from "react";
import { setApplication as setApplicationApi } from "../../api/ApplicationApi";
import { UserContext } from "../../stores/Users/Context";
import { Applications } from "../../types/Applications";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
}));

export const NotesDialog: React.FC<any> = (props: {
   open: boolean;
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   handleClose: () => void;
   onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "companyCode" | "notes") => void;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");

   const { state: userState } = useContext(UserContext);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function saveApplication() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to change the application is denied!", "warning");
            return;
         }

         setButtonClicked(true);

         const applicationObj = { ...props.application };

         if (applicationObj.status === "NEW") {
            applicationObj.status = "PENDING";
         }

         const responseData = await setApplicationApi(applicationObj, "");

         if (responseData.success) {
            openAlertMessage("Successfully updated application notes.", "success");
            setButtonClicked(false);
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
         setButtonClicked(false);
         //handleError(err);
      }
   }

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         fullScreen={mobileScreen}
         fullWidth
         maxWidth={"md"}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            Application Notes
         </DialogTitle>
         <DialogContent>
            <Collapse in={alertMessage ? true : false}>
               <Alert
                  variant="filled"
                  severity={alertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setAlertMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <TextField
               id="tf-applicationNotes"
               label="Application Notes"
               name="applicationNotes"
               multiline
               rows={15}
               rowsMax={15}
               onChange={(event) => props.onChange(event, "notes")}
               value={props.application.notes}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
            />
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={saveApplication}
                  variant="contained"
                  color="primary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={buttonClicked || userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SaveIcon />}
                  Save
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default NotesDialog;
