/* eslint-disable react/react-in-jsx-scope */
import { Divider, Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import Grid from "@material-ui/core/Grid";
/* eslint-disable prettier/prettier */
import { Card } from "@material-ui/core";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { PhoneFormatter } from "../../../utilities/PhoneFormatter";
import { useBodyStyles } from "./Styles";

type Props = {
   selectedCustomerActivation: CustomerActivation;
};

function BankingInformation({ selectedCustomerActivation }: Props) {
   const classes = useBodyStyles();

   return (
      <>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px", marginLeft: "2px", marginRight: "2px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginTop: "16px",
                     marginLeft: "36px",
                     marginRight: "36px",
                     marginBottom: "36px",
                  }}
               >
                  <Typography variant="h6" color="primary" style={{ fontWeight: 700 }}>
                     {"Banking Information"}
                  </Typography>
                  <Divider className={classes.dividerBody} />
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Bank Name
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Name of Principal
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Account Number
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Contact
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.bankName}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.principalName ? "YES" : "NO"}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.accountNumber}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.contact}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Phone
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Address
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        City
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        State
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {PhoneFormatter(selectedCustomerActivation.bankInfo.phone)}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.address}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.city}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.state}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Zip Code
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Country
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.zip}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.bankInfo.country}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { BankingInformation };
