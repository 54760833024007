import { Box, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

export type AlertProps = {
   isOpen: boolean;
   message: string;
   variant: AlertVariants | "";
};

export enum AlertVariants {
   SUCCESS = "SUCCESS",
   ERROR = "ERROR",
}

export const ActivationAlert: React.FC<AlertProps> = ({ isOpen, message, variant }) => {
   return (
      <>
         {isOpen && (
            <Box
               style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                  height: "48px",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  background: `${
                     variant === AlertVariants.SUCCESS ? "rgba(233,242,233,255)" : "rgba(251,233,234,255)"
                  }`,
               }}
            >
               {variant === AlertVariants.SUCCESS ? (
                  <CheckCircleOutlineIcon style={{ color: "rgba(46, 125, 50, 1)" }} />
               ) : (
                  <ErrorOutlineIcon style={{ color: "rgba(211, 47, 47, 1)" }} />
               )}
               <Typography
                  style={{
                     fontSize: "14px",
                     color: `${variant === AlertVariants.SUCCESS ? "rgba(19,50,21,255)" : "rgba(84,19,19,255)"}`,
                  }}
               >
                  {message}
               </Typography>
            </Box>
         )}
      </>
   );
};
