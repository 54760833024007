import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import InputMask from "react-input-mask";
import { Applications } from "../../types/Applications";
import { countryList } from "../../utilities/CountryList";
import "./Apply.css";

export const BankingInfo: React.FC<any> = (props: {
   application: Applications;
   handleChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "duns" | "contact" | "phone" | "bankName" | "principalName" | "accountNumber",
   ) => void;
   handleChangeAddress: (
      event:
         | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value: "bankAddress" | "bankCity" | "bankState" | "bankZip" | "bankCountry",
   ) => void;
   errorHighlight: {
      dunsNumber: { error: boolean; message: string };
      bankName: { error: boolean; message: string };
      principalName: { error: boolean; message: string };
      bankAddress: { error: boolean; message: string };
      bankCity: { error: boolean; message: string };
      bankState: { error: boolean; message: string };
      bankZip: { error: boolean; message: string };
      bankCountry: { error: boolean; message: string };
      contact: { error: boolean; message: string };
      bankPhoneNumber: { error: boolean; message: string };
      accountNumber: { error: boolean; message: string };
   };
}): JSX.Element => {
   const useStyles = makeStyles((theme: Theme) =>
      createStyles({
         formControl: {
            width: "-webkit-fill-available",
         },
         selectEmpty: {
            marginTop: theme.spacing(2),
         },
         outerPaper: {
            padding: "1%",
         },
      }),
   );
   const classes = useStyles();

   return (
      <>
         <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
               <h1>Banking Information</h1>
            </Grid>
         </Grid>
         <form noValidate autoComplete="off">
            <Grid container direction="row" justify="space-between" alignItems="center">
               <Grid item xs={6}>
                  <InputMask
                     mask="99-999-9999"
                     value={props.application.bankInfo.duns}
                     onChange={(event) => props.handleChange(event, "duns")}
                  >
                     {() => (
                        <TextField
                           required={false}
                           id="tf-duns"
                           label="D&B D-U-N-S Number"
                           variant="outlined"
                           error={props.errorHighlight.dunsNumber.error ? true : false}
                           inputProps={{
                              autocomplete: "new-password",
                              form: {
                                 autocomplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "red" }}>
                     {props.errorHighlight.dunsNumber.error ? props.errorHighlight.dunsNumber.message : " "}
                  </FormHelperText>
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     required={true}
                     id="tf-bankName"
                     label="Bank Name"
                     variant="outlined"
                     onChange={(event) => props.handleChange(event, "bankName")}
                     value={props.application.bankInfo.bankName}
                     helperText={props.errorHighlight.bankName.error ? props.errorHighlight.bankName.message : " "}
                     error={props.errorHighlight.bankName.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
               <Grid item xs={6}>
                  <TextField
                     required={true}
                     id="tf-principalName"
                     label="Name of Principal"
                     variant="outlined"
                     onChange={(event) => props.handleChange(event, "principalName")}
                     value={props.application.bankInfo.principalName}
                     helperText={
                        props.errorHighlight.principalName.error ? props.errorHighlight.principalName.message : " "
                     }
                     error={props.errorHighlight.principalName.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     required={true}
                     id="tf-accountNumber"
                     label="Account Number"
                     variant="outlined"
                     onChange={(event) => props.handleChange(event, "accountNumber")}
                     value={props.application.bankInfo.accountNumber}
                     helperText={
                        props.errorHighlight.accountNumber.error ? props.errorHighlight.accountNumber.message : " "
                     }
                     error={props.errorHighlight.accountNumber.error ? true : false}
                     inputProps={{ maxLength: 30 }}
                  />
               </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justify="space-between">
               <Grid item xs={5}>
                  <TextField
                     required={true}
                     id="tf-address"
                     label="Address"
                     variant="outlined"
                     onChange={(event) => props.handleChangeAddress(event, "bankAddress")}
                     value={props.application.bankInfo.address1}
                     helperText={
                        props.errorHighlight.bankAddress.error ? props.errorHighlight.bankAddress.message : " "
                     }
                     error={props.errorHighlight.bankAddress.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               <Grid item xs={3}>
                  <TextField
                     required={true}
                     id="tf-City"
                     label="City"
                     variant="outlined"
                     onChange={(event) => props.handleChangeAddress(event, "bankCity")}
                     value={props.application.bankInfo.city}
                     helperText={props.errorHighlight.bankCity.error ? props.errorHighlight.bankCity.message : " "}
                     error={props.errorHighlight.bankCity.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               <Grid item xs={1}>
                  <TextField
                     required={true}
                     id="tf-state"
                     label="State"
                     variant="outlined"
                     onChange={(event) => props.handleChangeAddress(event, "bankState")}
                     value={props.application.bankInfo.state}
                     helperText={props.errorHighlight.bankState.error ? props.errorHighlight.bankState.message : " "}
                     error={props.errorHighlight.bankState.error ? true : false}
                     inputProps={{ maxLength: 2 }}
                  />
               </Grid>
               <Grid item xs={3}>
                  <TextField
                     required={true}
                     id="tf-Zip"
                     label="Zip"
                     variant="outlined"
                     onChange={(event) => props.handleChangeAddress(event, "bankZip")}
                     value={props.application.bankInfo.zip}
                     helperText={props.errorHighlight.bankZip.error ? props.errorHighlight.bankZip.message : " "}
                     error={props.errorHighlight.bankZip.error ? true : false}
                     inputProps={{ maxLength: 10 }}
                  />
               </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
               <Grid item xs={5}>
                  <TextField
                     required={true}
                     id="tf-Contact"
                     label="Contact"
                     variant="outlined"
                     onChange={(event) => props.handleChange(event, "contact")}
                     value={props.application.bankInfo.contact}
                     helperText={props.errorHighlight.contact.error ? props.errorHighlight.contact.message : " "}
                     error={props.errorHighlight.contact.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               <Grid item xs={3}>
                  <FormControl variant="outlined" className={classes.formControl}>
                     <InputLabel
                        id="lbl-country"
                        style={{ color: props.errorHighlight.bankCountry.error ? "red" : "inherit" }}
                     >
                        Country *
                     </InputLabel>
                     <Select
                        required={true}
                        labelId="lbl-country"
                        id="tf-country"
                        className="country"
                        variant="outlined"
                        onChange={(event) => props.handleChangeAddress(event, "bankCountry")}
                        defaultValue={""}
                        value={props.application.bankInfo.country}
                        label="Country"
                        error={props.errorHighlight.bankCountry.error ? true : false}
                     >
                        {countryList.map((country, index) => {
                           return (
                              <MenuItem key={index} value={country.name}>
                                 {country.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                     <FormHelperText style={{ color: "red" }}>
                        {props.errorHighlight.bankCountry.error ? props.errorHighlight.bankCountry.message : " "}
                     </FormHelperText>
                  </FormControl>
               </Grid>
               <Grid item xs={3}>
                  <InputMask
                     mask="999-999-9999"
                     value={props.application.bankInfo.phone}
                     onChange={(event) => props.handleChange(event, "phone")}
                  >
                     {() => (
                        <TextField
                           required={true}
                           id="tf-phone"
                           label="Phone #"
                           variant="outlined"
                           error={props.errorHighlight.bankPhoneNumber.error ? true : false}
                           inputProps={{
                              autocomplete: "new-password",
                              form: {
                                 autocomplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "red" }}>
                     {props.errorHighlight.bankPhoneNumber.error ? props.errorHighlight.bankPhoneNumber.message : " "}
                  </FormHelperText>
               </Grid>
            </Grid>
         </form>
      </>
   );
};

export default BankingInfo;
