import { createStyles, makeStyles } from "@material-ui/core";

export const useValidationHeaderStyles = makeStyles((theme) =>
   createStyles({
      container: {
         borderRadius: "8px",
         backgroundColor: "#FFF",
         padding: "24px",
         // color: "rgba(37, 37, 37, 0.87)",
      },
      sectionLogo: {
         display: "flex",
         padding: "24px",
         flexWrap: "wrap",
         "& h1": {
            color: theme.palette.primary.main,
            fontSize: "32px",
            fontWeight: "700",
         },
         "& img": {
            marginRight: "16px",
         },
      },
      section2: {
         display: "flex",
         justifyContent: "space-between",
         gap: "34px",
      },
      cursiveText: {
         color: theme.palette.primary.main,
         fontStyle: "italic",
         fontWeight: 600,
      },
      section2Title: {
         fontSize: "24px",
         fontWeight: 400,
         letterSpacing: "0.1px",
         "& span": {
            color: theme.palette.primary.main,
            fontWeight: "500",
         },
      },
      section2Paragraph: {
         fontSize: "14px",
      },
      hr: {
         backgroundColor: theme.palette.primary.main,
         height: "2px",
      },
   }),
);
