import { VpnKey } from "@material-ui/icons";
import React from "react";
import "./Expired.css";

export const ExpiredContainerPort: React.FC<any> = (): JSX.Element => {
   return (
      <div className="grid-container">
         <div className="grid-item-border-containerport"></div>
         <div className="grid-item-spacer"></div>
         <div className="grid-item-icon">
            <img
               src="https://worldgroup.blob.core.windows.net/cpglogos/ContainerPort_Logo_White_Full_Size.png"
               width="500em"
               alt="CPG"
            />
         </div>
         <div className="grid-item-title">
            <h2>
               <VpnKey className="key-icon-containerport"></VpnKey>Authentication Invalid
            </h2>
         </div>
         <div className="grid-item-message">
            <div className="textContainer">
               <p>
                  You are seeing this page because the link in your email has expired. <br /> Please reach out to the
                  person who invited you to the application.
               </p>
            </div>
         </div>
         <div className="grid-item-spacer"></div>
         <div className="grid-item-border-containerport"></div>
      </div>
   );
};

export default ExpiredContainerPort;
