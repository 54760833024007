export function DateTimeFormatterShortYear(value: any) {
   try {
      const dateString = value;
      const newDate = new Date(dateString);
      const year = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(newDate);
      const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
      const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
      const hour = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(newDate).substring(0, 2);
      const minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(newDate).padStart(2, "0");
      const AMPM = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(newDate).substring(2, 5);

      return month + "/" + day + "/" + year + " " + hour + ":" + minute + AMPM;
   } catch {
      return "00/00/00 00:00AM";
   }
}
