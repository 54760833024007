/* eslint-disable react/react-in-jsx-scope */
import { IconButton } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import { useSubHeaderStyles } from "./Styles";
/* eslint-disable prettier/prettier */
import { Collapse, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { Contacts } from "../../../types/Contacts";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { ApprovedSubHeader } from "./ApprovedSubHeader";
import { DeniedSubHeader } from "./DeniedSubHeader";
import { ActivationDetailsButtonType, NewSubHeader } from "./NewSubHeader";
import { SentSubHeader } from "./SentSubHeader";

type Props = {
   selectedCustomerActivation: CustomerActivation;
   contactList: Contacts[];
   selectedDivision: string;
   alertMessage: string;
   alertType: "error" | "info" | "success" | "warning";
   isWorking?: ActivationDetailsButtonType;
   setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
   saveCustomerActivation(
      resendCustomerActivation: boolean,
      fieldChanged: ActivationDetailsButtonType,
      value: string,
   ): Promise<void>;

   openDialog: (type: ActivationDetailsButtonType) => void;
};

function SubHeader({
   selectedCustomerActivation,
   contactList,
   selectedDivision,
   alertMessage,
   alertType,
   isWorking,
   setAlertMessage,
   openDialog,
}: Props) {
   const classes = useSubHeaderStyles();

   function getSubheaderByStatus() {
      if (selectedCustomerActivation.status.toLowerCase() === "sent") {
         return (
            <SentSubHeader
               selectedCustomerActivation={selectedCustomerActivation}
               contactList={contactList}
               selectedDivision={selectedDivision}
            />
         );
      }

      if (
         selectedCustomerActivation.status.toLowerCase() === "new" ||
         selectedCustomerActivation.status.toLowerCase() === "pending"
      ) {
         return (
            <NewSubHeader
               selectedCustomerActivation={selectedCustomerActivation}
               contactList={contactList}
               selectedDivision={selectedDivision}
               isWorking={isWorking}
               openDialog={openDialog}
            />
         );
      }

      if (selectedCustomerActivation.status.toLowerCase() === "approved") {
         return (
            <ApprovedSubHeader
               selectedCustomerActivation={selectedCustomerActivation}
               contactList={contactList}
               selectedDivision={selectedDivision}
            />
         );
      }

      if (selectedCustomerActivation.status.toLowerCase() === "denied") {
         return (
            <DeniedSubHeader
               selectedCustomerActivation={selectedCustomerActivation}
               contactList={contactList}
               selectedDivision={selectedDivision}
            />
         );
      }
   }

   return (
      <>
         <Divider className={classes.divider} />
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         {getSubheaderByStatus()}
      </>
   );
}

export { SubHeader };
