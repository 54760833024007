import React from "react";
import { Route, Switch } from "react-router-dom";
import UserDetails from "./UserDetails";
import UserView from "./UserView";

export const User: React.FC<any> = (): JSX.Element => {
   return (
      <>
         <Switch>
            <Route path="/users" exact component={UserView} />
            <Route path="/users/details/:id?" exact component={UserDetails} />
         </Switch>
      </>
   );
};

export default User;
