import {
   Box,
   Button,
   Checkbox,
   CircularProgress,
   Collapse,
   FormControlLabel,
   FormGroup,
   Grid,
   Paper,
   Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import { saveAs } from "file-saver";
import React from "react";
import { getInviteApplicationPdf, setApplicationReferral } from "../../../api/ApplicationApi";
import { Applications } from "../../../types/Applications";
import { useReviewStyles } from "./Styles";

type ReviewProps = {
   application: Applications;
   handleCheckChange: (value: string, type: CheckApplicationType) => void;
};

export type CheckApplicationType =
   | "Per Diem"
   | "Work Order"
   | "POD/BOL"
   | "Scale Ticket"
   | "Interchange In/Out"
   | "Accessorial Approval"
   | "Rate Confirmation"
   | "Customs Paperwork"
   | "Driver Detention"
   | "Referral"
   | "Advertisement"
   | "Trade Show/Conference"
   | "Social Media"
   | "Website"
   | "Salesperson"
   | "ctpatNumber";

type AlertProps = "error" | "info" | "success" | "warning";

export default function Review({ application, handleCheckChange }: ReviewProps): React.ReactElement {
   const [alertMessage, setAlertMessage] = React.useState<string>("");
   const [surveyMessage, setSurveyMessage] = React.useState<string>("");
   const [surveyAlertType, setSurveyAlertType] = React.useState<AlertProps>("warning");
   const [alertType, setAlertType] = React.useState<AlertProps>("warning");
   const [viewing, setViewing] = React.useState<boolean>(false);
   const [saving, setSaving] = React.useState<boolean>(false);
   const [downloading, setDownloading] = React.useState<boolean>(false);

   const classes = useReviewStyles();

   async function handleViewApplicationReceipt() {
      try {
         setViewing(true);

         const responseData = await getInviteApplicationPdf(application._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob));
            });
         } else {
            if (responseData.message) {
               openAlertMessage(responseData.message, "error");
            } else {
               openAlertMessage(
                  "Error loading PDF application. Please contact your administrator for assistance!",
                  "error",
               );
            }
         }

         setViewing(false);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleDownload() {
      try {
         setDownloading(true);

         const responseData = await getInviteApplicationPdf(application._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               saveAs(blob, `Application # ${application.applicationNumber}.pdf`);
            });
         } else {
            if (responseData.message) {
               openAlertMessage(responseData.message, "error");
            } else {
               openAlertMessage(
                  "Error loading PDF application. Please contact your administrator for assistance!",
                  "error",
               );
            }
         }

         setDownloading(false);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
      }
   }

   async function submitReferralClicked() {
      setSaving(true);
      const responseData = await setApplicationReferral(application);
      setSaving(false);

      if (!responseData.success) {
         alert("Data was unable to save successfully. Please refresh the page and try again.");
         return;
      }
      openSurveyAlertMessage("The survey saved successfully.", "success");
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function openSurveyAlertMessage(alertMessage: string, alertType: AlertProps) {
      setSurveyMessage(alertMessage);
      setSurveyAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setSurveyMessage("");
         }, 3000);
      }
   }

   return (
      <>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <Paper elevation={0} className={classes.stepContainer}>
            <div className={classes.container}>
               <div className={classes.containerIcon}>
                  <div className={classes.activeIcon}>
                     <img src={"/review2.png"} alt="icon" />
                  </div>
                  <div className={classes.highText}>
                     <p>Application Submitted!</p>
                     <p>Application #: {application.applicationNumber}</p>
                  </div>
               </div>
               <div className={classes.buttonContainer}>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => handleViewApplicationReceipt()}
                     className={clsx(classes.commonButton, classes.viewBotton)}
                     disabled={viewing}
                     endIcon={
                        viewing ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SearchIcon />
                     }
                  >
                     VIEW APPLICATION RECEIPT
                  </Button>
                  <Button
                     variant="text"
                     color="primary"
                     onClick={() => handleDownload()}
                     className={classes.commonButton}
                     disabled={downloading}
                     endIcon={
                        downloading ? (
                           <CircularProgress size={20} className={classes.buttonProgress} />
                        ) : (
                           <ArrowDownwardIcon />
                        )
                     }
                  >
                     DOWNLOAD APPLICATION RECEIPT
                  </Button>
               </div>
            </div>
            <div>
               <Grid component={"div"} xs={12} className={classes.sectionTitleContainer} style={{ marginTop: "33px" }}>
                  <Typography className={classes.sectionTitle}>
                     A PDF version of your credit application has been emailed to you.
                  </Typography>
               </Grid>
            </div>
         </Paper>
         {/* Survey */}
         <Paper elevation={0} style={{ marginTop: "28px" }}>
            <Collapse in={!!surveyMessage}>
               <Alert
                  variant="filled"
                  severity={surveyAlertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setSurveyMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {surveyMessage}
               </Alert>
            </Collapse>
            <Box className={classes.stepContainer}>
               <Grid component={"div"} xs={12} className={classes.sectionTitleContainer}>
                  <Typography className={classes.sectionTitle}>How did you hear about us?.</Typography>
               </Grid>
               <FormGroup>
                  <Grid container direction="row" className={classes.optionsContainer}>
                     <Grid item xs={3}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={application.referral.indexOf("Referral") > -1 ? true : false}
                                 color="primary"
                                 value={"Referral"}
                                 onChange={(event) => handleCheckChange(event.target.value, "Referral")}
                                 name="Referral"
                                 disableRipple
                              />
                           }
                           label="Referral"
                        />
                     </Grid>

                     <Grid item xs={3}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={application.referral.indexOf("Advertisement") > -1 ? true : false}
                                 color="primary"
                                 value={"Advertisement"}
                                 onChange={(event) => handleCheckChange(event.target.value, "Advertisement")}
                                 name="Advertisement"
                                 disableRipple
                              />
                           }
                           label="Advertisement"
                        />
                     </Grid>

                     <Grid item xs={3}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={application.referral.indexOf("Trade Show/Conference") > -1 ? true : false}
                                 color="primary"
                                 value={"Trade Show/Conference"}
                                 onChange={(event) => handleCheckChange(event.target.value, "Trade Show/Conference")}
                                 name="Trade Show/Conference"
                                 disableRipple
                              />
                           }
                           label="Trade Show/Conference"
                        />
                     </Grid>

                     <Grid item xs={3}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={application.referral.indexOf("Website") > -1 ? true : false}
                                 color="primary"
                                 value={"Website"}
                                 onChange={(event) => handleCheckChange(event.target.value, "Website")}
                                 name="Website"
                                 disableRipple
                              />
                           }
                           label="Website"
                        />
                     </Grid>

                     <Grid item xs={3}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={application.referral.indexOf("Salesperson") > -1 ? true : false}
                                 color="primary"
                                 value={"Salesperson"}
                                 onChange={(event) => handleCheckChange(event.target.value, "Salesperson")}
                                 name="Salesperson"
                                 disableRipple
                              />
                           }
                           label="Salesperson"
                        />
                     </Grid>

                     <Grid item xs={3}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={application.referral.indexOf("Social Media") > -1 ? true : false}
                                 value={"Social Media"}
                                 color="primary"
                                 onChange={(event) => handleCheckChange(event.target.value, "Social Media")}
                                 name="Social Media"
                                 disableRipple
                              />
                           }
                           label="Social Media"
                        />
                     </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"end"}>
                     <Button
                        color="primary"
                        variant="contained"
                        disabled={application.referral.length === 0 || saving}
                        onClick={() => submitReferralClicked()}
                        className={classes.commonButton}
                        endIcon={
                           saving ? (
                              <CircularProgress size={20} className={classes.buttonProgress} />
                           ) : (
                              <ArrowForwardIcon />
                           )
                        }
                     >
                        Submit
                     </Button>
                  </Box>
               </FormGroup>
            </Box>
         </Paper>
      </>
   );
}
