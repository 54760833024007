import {
   Box,
   Chip,
   FormControl,
   FormHelperText,
   FormLabel,
   Grid,
   MenuItem,
   Select,
   TextField,
} from "@material-ui/core";
import _ from "lodash";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../../../stores/Companies/Context";
import { DivisionContext } from "../../../../stores/Divisions/Context";
import { UserContext } from "../../../../stores/Users/Context";
import { Companies } from "../../../../types/Companies";
import { Contacts } from "../../../../types/Contacts";
import { CustomerActivation } from "../../../../types/CustomerActivation";
import { Divisions } from "../../../../types/Divisions";
import { validateEmailAddress } from "../../../../utilities/validateEmailAddress";
import { ActivationBaseDialogHOC } from "../ActivationBaseDialogHOC";

export type TypeCopyActivation = {
   selectedCompany: string;
   emailAddress: string;
   selectedContacts: string[];
};

interface Props {
   open: boolean;
   activationSelected: CustomerActivation;
   handleClose: () => void;
   callback: ({ selectedCompany, emailAddress, selectedContacts }: TypeCopyActivation) => Promise<void>;
}

interface FormStateErrors {
   email: string;
   company: string;
   contacts: string;
}

export const ActivationCopyDialog: React.FC<Props> = ({ open, activationSelected, handleClose, callback }) => {
   const [isSaving, setIsSaving] = useState(false);

   const [companyList, setCompanyList] = useState<Divisions[]>([]);

   const [email, setEmail] = useState("");
   const [selectedCompany, setSelectedCompany] = useState("");
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);

   // context
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   // errors
   const [errors, setErrors] = useState<FormStateErrors>({
      email: "",
      company: "",
      contacts: "",
   });

   const handleSave = async () => {
      if (!email || !selectedCompany || !selectedContacts?.length) {
         setErrors({
            email: !email ? "Email is required." : "",
            company: !selectedCompany ? "You must select at least one company or division." : "",
            contacts: !selectedContacts?.length ? "You must select at least one contact." : "",
         });
         return;
      }

      setIsSaving(true);
      await callback({
         emailAddress: email,
         selectedCompany,
         selectedContacts,
      });
      setIsSaving(false);
      handleClearButtonClick();
   };

   const handleCompanyOrDivisionSelectChange = async (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
   ) => {
      const selectedValue = event.target.value ? String(event.target.value) : "";
      setSelectedCompany(selectedValue);

      const assignedDivision = divisionState.Divisions.find((x) => x._id.toString() === selectedValue);
      const assignedCompany = companyState.Companies.find((x) => x._id.toString() === selectedValue);

      const assignedContacts = assignedDivision
         ? assignedDivision.contacts
         : assignedCompany
         ? assignedCompany.contacts
         : [];

      const filteredContacts = assignedContacts.filter((x) => x.active === true);

      const sortedContacts = _.orderBy(filteredContacts, [(contact) => contact.name.toLowerCase()], ["asc"]);

      setContactList(sortedContacts);
      setSelectedContacts([]);
      setSelectedContactsList([]);
   };

   const handleCompanyOrDivisionSelectBlur = async () => {
      // validate company or division on blur
      const result = !selectedCompany ? "You must select at least one company or division." : "";
      setErrors((errors) => ({ ...errors, company: result }));
   };

   const handleEmailInputBlur = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // validate email on blur
      const result = await validateEmailAddress(event.target.value);
      setErrors((errors) => ({ ...errors, email: result }));
   };

   const handleContactsSelectChange = async (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const contacts = event.target.value ? (event.target.value as string[]) : [];
      setSelectedContacts(contacts);

      const contactAssigned = contactList.filter((contact) => contacts.includes(contacts ? contact._id : contacts));
      setSelectedContactsList(contactAssigned);
   };

   const handleContactsSelectBlur = async () => {
      // validate contacts on blur
      const result = selectedContacts.length === 0 ? "You must select at least one contact." : "";
      setErrors((errors) => ({ ...errors, contacts: result }));
   };

   const handleChipDelete = (id: string) => () => {
      const selectedContactAux = selectedContacts.filter((_id) => _id !== id);
      setSelectedContactsList(selectedContactsList.filter((contact) => contact._id !== id));
      setSelectedContacts(selectedContactAux);

      // validate erros
      setErrors((errors) => ({ ...errors, contacts: "You must select at least one contact." }));
   };

   const handleClearButtonClick = () => {
      // clear all fields
      setEmail("");
      setSelectedCompany("");
      setSelectedContacts([]);
      setSelectedContactsList([]);
      // clear errors
      setErrors((errors) => ({ ...errors, email: "", company: "", contacts: "" }));

      handleClose();
   };

   // set companies and divisions for select
   useEffect(() => {
      // merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         let newCompanyList: Divisions[] = [];

         companyState.Companies.forEach(function (company: Companies) {
            if (company.active === false || company.deleteDate !== null) {
               return;
            }

            const divisionsAssigned = _.filter(
               divisionState.Divisions,
               (x) => x.company_id === company._id && x.active === true && x.deleteDate === null,
            );

            if (divisionsAssigned.length > 0) {
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: [],
                     contacts: [],
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         newCompanyList = _.uniqBy(newCompanyList, "code");
         newCompanyList = _.orderBy(newCompanyList, ["code"], ["asc"]);

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState]);

   useEffect(() => {
      if (!activationSelected) return;
      setEmail(activationSelected?.email);
   }, [activationSelected]);

   return (
      <ActivationBaseDialogHOC
         open={open}
         title={`Copy Activation ${activationSelected?.applicationNumber}`}
         primaryButtonTitle="COPY"
         secondaryButtonTitle="DISCARD CHANGES"
         isSaving={isSaving}
         handleClose={handleClearButtonClick}
         handleAction={handleSave}
      >
         <Box component={"h2"} sx={{ mt: 0, pt: 0, mb: 2 }}>
            Which company|division would you like to copy this activation to?
         </Box>
         {/* Company | divison */}
         <Box sx={{ mb: 3 }}>
            <FormLabel>Company | Division</FormLabel>
            <FormControl size="small" variant="outlined" fullWidth error={Boolean(errors.company)}>
               <Select
                  id="activation-copy-division"
                  name="company"
                  value={selectedCompany}
                  onChange={handleCompanyOrDivisionSelectChange}
                  onBlur={handleCompanyOrDivisionSelectBlur}
               >
                  {companyList.map((company) => (
                     <MenuItem
                        key={company._id ? company._id : company.company_id}
                        value={company._id ? company._id : company.company_id}
                     >
                        {company.code + " | " + company.name}
                     </MenuItem>
                  ))}
               </Select>
               <FormHelperText>{errors.company}</FormHelperText>
            </FormControl>
         </Box>
         {/* Email */}
         <Box sx={{ mb: 3 }}>
            <TextField
               id="activation-copy-email"
               label="Email"
               name="email"
               variant="outlined"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               onBlur={handleEmailInputBlur}
               error={Boolean(errors.email)}
               helperText={errors.email}
            />
         </Box>
         {/* Contact */}
         <Box sx={{ mb: 3 }}>
            <FormLabel>Contacts</FormLabel>
            <FormControl size="small" variant="outlined" fullWidth error={Boolean(errors.contacts)}>
               <Select
                  multiple
                  id="activation-copy-contact"
                  name="contacts"
                  disabled={contactList.length === 0}
                  value={selectedContacts}
                  onChange={handleContactsSelectChange}
                  onBlur={handleContactsSelectBlur}
               >
                  {contactList.map((contact) => (
                     <MenuItem key={contact._id} value={contact._id}>
                        {contact.name}
                     </MenuItem>
                  ))}
               </Select>
               <FormHelperText>{errors.contacts}</FormHelperText>
            </FormControl>
         </Box>
         {/* Chips */}
         <Grid container spacing={1}>
            {selectedContactsList.map((contact) => (
               <Grid key={contact._id} item xs={4}>
                  <Chip
                     label={contact.name}
                     variant="outlined"
                     color="primary"
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 6px",
                     }}
                     onDelete={handleChipDelete(contact._id)}
                  />
               </Grid>
            ))}
         </Grid>
      </ActivationBaseDialogHOC>
   );
};
