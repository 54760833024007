import { Collapse } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { resetPassword as resetPasswordApi } from "../../api/UserApi";
import { Users } from "../../types/Users";
import { validatePassword } from "../../utilities/ValidationTools";
import "./PasswordReset.css";

export const PasswordReset: React.FC<any> = ({
   match,
}: RouteComponentProps<{ _id: string; invite: string }>): JSX.Element => {
   const handleError = useErrorHandler();
   const history = useHistory();
   const [confirmPassword, setConfirmPassword] = useState("");
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");

   const userId = match.params._id;
   const token = match.params.invite;

   const [user, setUser] = useState<Users>({
      _id: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      active: false,
      companies: [],
      divisions: [],
      security: "",
      invite: "",
      maximumApprovalAmount: 0,
      department: "",
      addDate: new Date(),
      deleteDate: null,
   });

   const resetPassword = async () => {
      try {
         if (user.password == confirmPassword) {
            const invalidPasswordReason = validatePassword(user.password);

            if (invalidPasswordReason.length) {
               openAlertMessage(invalidPasswordReason, "warning");
               return;
            } else {
               user._id = userId;
               user.invite = token;

               const responseData = await resetPasswordApi(user);

               if (responseData.success == false) {
                  openAlertMessage(
                     "An error has occurred. Please reach out to administration for assistance.",
                     "error",
                  );
                  return;
               } else {
                  history.push("/login");
               }
            }
         } else {
            openAlertMessage("Passwords must match.", "warning");
            return;
         }
      } catch (err) {
         handleError(err);
      }
   };

   const onChange = async (
      key: "password",
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
   ) => {
      try {
         const newUser = _.cloneDeep(user);
         newUser[key] = event?.target?.value ?? "";
         setUser(newUser);
      } catch (err) {
         handleError(err);
      }
   };

   const onConfirmationChange = async (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
   ) => {
      try {
         let newConfirmPassword = _.cloneDeep(confirmPassword);
         newConfirmPassword = event?.target?.value ?? "";
         setConfirmPassword(newConfirmPassword);
      } catch (err) {
         handleError(err);
      }
   };

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <div className={"divbody-passwordReset grid-container-passwordReset"}>
            <div className="blurred-passwordReset grid-item-content-passwordReset">
               <div className="passwordReset">
                  <h1>Password Reset</h1>
                  <p>Please enter your new password.</p>
                  <FormControl>
                     <Input
                        className="inputTextbox"
                        name="password"
                        type="password"
                        autoFocus={true}
                        placeholder="New Password"
                        value={user?.password}
                        onChange={(event) => onChange("password", event)}
                     />
                  </FormControl>
                  <FormControl>
                     <Input
                        className="inputTextbox"
                        name="password"
                        type="password"
                        autoFocus={true}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(event) => onConfirmationChange(event)}
                     />
                  </FormControl>
                  <Button id="resetButton" variant="contained" color="primary" onClick={() => resetPassword()}>
                     Reset Password
                  </Button>
               </div>
            </div>
         </div>
      </>
   );
};

export default PasswordReset;
