import { createStyles, makeStyles } from "@material-ui/core";

export const useActivationDialogStyles = makeStyles((theme) =>
   createStyles({
      container: {
         "& .MuiPaper-root": {
            minWidth: "420px",
         },
      },
      dialogTitle: {
         padding: "30px 30px 0px 30px",
         "& .MuiTypography-root": {
            fontWeight: 600,
         },
      },
      dialogActions: {
         padding: "30px",
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-end",
      },
      button: {
         borderRadius: "100px",
         fontWeight: 600,
      },
   }),
);
