import {
   ActivationCompanyInformationFormDTO,
   ActivationCompanyInformationType,
   ActivationFormProperties,
   ActivationModelForm,
} from "../../../types/Activation/ActivationDTO";
import { ValidationBuilder } from "../../../utilities/ValidationTools";

type FormProperties = ActivationFormProperties<ActivationCompanyInformationType>;

export function companyInformationReducer(
   state: Partial<ActivationModelForm>,
   action: { type: ActivationCompanyInformationType | "forceValidation"; payload: unknown; UWL_WDS: boolean },
): Partial<ActivationModelForm> {
   const Validation = new ValidationBuilder();
   switch (action.type) {
      case "CompanyName":
         const companyErrorMessage = Validation.validateNotEmptyString(
            "Please enter the company name",
            action.payload as string,
         )
            .maximumLength(100, action.payload as string)
            .getMessageError();

         return {
            ...state,
            CompanyName: {
               value: action.payload as string,
               error: !!companyErrorMessage,
               message: companyErrorMessage,
            },
         };
      case "CompanyContact":
         const companyContactErrorMessage = Validation.validateNotEmptyString(
            "Please enter the company contact",
            action.payload as string,
         )
            .maximumLength(100)
            .getMessageError();
         return {
            ...state,
            CompanyContact: {
               value: action.payload as string,
               error: !!companyContactErrorMessage,
               message: companyContactErrorMessage,
            },
         };
      case "CompanyEstablishedDate":
         const companyEstablishedErrorMessage = Validation.validateDateFormat(action.payload as Date).getMessageError();
         return {
            ...state,
            CompanyEstablishedDate: {
               value: action.payload as Date,
               error: !!companyEstablishedErrorMessage,
               message: companyEstablishedErrorMessage,
            },
         };
      case "CompanyEmail":
         const companyEmailErrorMessage = Validation.validateEmail(action.payload as string).getMessageError();
         return {
            ...state,
            CompanyEmail: {
               value: action.payload as string,
               error: !!companyEmailErrorMessage,
               message: companyEmailErrorMessage,
            },
         };
      case "CompanyPhone":
         let phone = (action.payload as string).replaceAll("_", "") || "";
         phone = phone?.replaceAll("-", "") || "";
         const companyPhoneErrorMessage = Validation.validateNotEmptyString("Please enter the company phone", phone)
            .validateExaclyStringLength("Please enter a valid number (10 number characters)", 10)
            .getMessageError();
         return {
            ...state,
            CompanyPhone: {
               value: phone,
               error: !!companyPhoneErrorMessage,
               message: companyPhoneErrorMessage,
            },
         };
      case "DUNS":
         let duns = (action.payload as string)?.replaceAll("_", "") || "";
         duns = duns?.replaceAll("-", "") || "";

         const dunsErrorMessage = Validation.maximumLength(100, duns).getMessageError();
         return {
            ...state,
            DUNS: {
               value: duns,
               error: !!dunsErrorMessage,
               message: dunsErrorMessage,
            },
         };
      case "FedTaxId":
         let federalTaxNumber = (action.payload as string).replaceAll("_", "") || "";
         federalTaxNumber = federalTaxNumber?.replaceAll("-", "") || "";

         let defTaxIdErrorMessage = "";
         if (action.UWL_WDS) {
            defTaxIdErrorMessage = Validation.validateNotEmptyString(
               "Please enter the federal tax id",
               federalTaxNumber,
            )
               .validateExaclyStringLength("Please enter a valid number (9 number characters)", 9)
               .maximumLength(9)
               .getMessageError();
         } else {
            defTaxIdErrorMessage = Validation.validateExaclyStringLength(
               "Please enter a valid number (9 number characters)",
               9,
               federalTaxNumber,
            )
               .maximumLength(9)
               .getMessageError();
         }

         return {
            ...state,
            FedTaxId: {
               value: action.payload as string,
               error: !!defTaxIdErrorMessage,
               message: defTaxIdErrorMessage,
            },
         };
      case "CtpatNumber":
         return {
            ...state,
            CtpatNumber: {
               value: action.payload as string,
               error: false,
            },
         };
      case "CompanyWebsite":
         let webSideErrorMessage = "";
         if (action.payload) {
            webSideErrorMessage = Validation.validateWebSite(action.payload as string).getMessageError();
         }
         return {
            ...state,
            CompanyWebsite: {
               value: action.payload as string,
               error: !!webSideErrorMessage,
               message: webSideErrorMessage,
            },
         };
      case "Address1":
         const address1ErrorMessage = Validation.validateNotEmptyString(
            "Please enter the address",
            action.payload as string,
         )
            .maximumLength(100)
            .allowSomeSpecialCharWithHashtag()
            .getMessageError();
         return {
            ...state,
            Address1: {
               value: action.payload as string,
               error: !!address1ErrorMessage,
               message: address1ErrorMessage,
            },
         };
      case "Address2":
         const address2ErrorMessage = Validation.maximumLength(100, action.payload as string)
            .allowSomeSpecialCharWithHashtag()
            .getMessageError();
         return {
            ...state,
            Address2: {
               value: action.payload as string,
               error: !!address2ErrorMessage,
               message: address2ErrorMessage,
            },
         };
      case "City":
         const cityErrorMessage = Validation.validateNotEmptyString("Please enter the city", action.payload as string)
            .allowSomeSpecialCharWithHashtag()
            .getMessageError();
         return {
            ...state,
            City: {
               value: action.payload as string,
               error: !!cityErrorMessage,
               message: cityErrorMessage,
            },
         };
      case "State":
         const stateErrorMessage = Validation.validateNotEmptyString("Please enter the state", action.payload as string)
            .maximumLength(2)
            .allowSomeSpecialCharWithHashtag()
            .getMessageError();
         return {
            ...state,
            State: {
               value: action.payload as string,
               error: !!stateErrorMessage,
               message: stateErrorMessage,
            },
         };
      case "Zip":
         const zipErrorMessage = Validation.validateNotEmptyString(
            "Please enter the zip code",
            action.payload as string,
         )
            .allowSomeSpecialCharOnlyDash()
            .getMessageError();
         return {
            ...state,
            Zip: {
               value: action.payload as string,
               error: !!zipErrorMessage,
               message: zipErrorMessage,
            },
         };
      case "Country":
         const countryErrorMessage = Validation.validateNotEmptyString(
            "Please select a valid country",
            action.payload as string,
         ).getMessageError();
         return {
            ...state,
            Country: {
               value: action.payload as string,
               error: !!countryErrorMessage,
               message: countryErrorMessage,
            },
         };
      case "Email":
         const validationMessage = Validation.validateEmail(action.payload as string).getMessageError();
         return {
            ...state,
            Email: {
               value: action.payload as string,
               error: !!validationMessage,
               message: validationMessage,
            },
         };
      case "forceValidation":
         const defaultValues = getDefaultValues(action.UWL_WDS);
         const properties: FormProperties = { ...defaultValues };

         // filling the properties in.
         for (const field of Object.keys(
            action.payload as ActivationCompanyInformationFormDTO,
         ) as (keyof FormProperties)[]) {
            properties[field] = (action.payload as FormProperties)[field]?.value
               ? (action.payload as FormProperties)[field]
               : defaultValues[field];
         }

         return {
            ...properties,
         };
      default:
         throw "Invalid action";
   }
}

const getDefaultValues = (UWL_WDS: boolean): FormProperties => ({
   CompanyName: {
      value: "",
      error: true,
      message: "Please enter the company name",
   },
   CompanyContact: {
      value: "",
      error: true,
      message: "Please enter the company contact",
   },
   CompanyPhone: {
      value: "",
      error: true,
      message: "Please enter the company phone",
   },
   Email: {
      value: "",
      error: true,
      message: "Please enter the email",
   },
   CompanyEstablishedDate: {
      value: new Date(),
      error: false,
   },
   CompanyEmail: {
      value: "",
      error: true,
      message: "Please enter the company email",
   },
   DUNS: {
      value: "",
      error: false,
   },
   FedTaxId: {
      value: "",
      error: UWL_WDS,
      message: UWL_WDS ? "Please enter the federal tax id" : "",
   },
   CtpatNumber: {
      value: "",
      error: false,
   },
   CompanyWebsite: {
      value: "",
      error: false,
   },
   Address1: {
      value: "",
      error: true,
      message: "Please enter the address",
   },
   Address2: {
      value: "",
      error: false,
   },
   City: {
      value: "",
      error: true,
      message: "Please enter the city",
   },
   State: {
      value: "",
      error: true,
      message: "Please enter the state",
   },
   Zip: {
      value: "",
      error: true,
      message: "Please enter the zip code",
   },
   Country: {
      value: "",
      error: true,
      message: "Please select a valid country",
   },
});

export const initialCompanyValues = (isCpgMbt_Bri: boolean): FormProperties => {
   return {
      CompanyName: {
         value: "",
         error: true,
      },
      CompanyContact: {
         value: "",
         error: true,
      },
      CompanyPhone: {
         value: "",
         error: true,
      },
      Email: {
         value: "",
         error: true,
      },
      CompanyEstablishedDate: {
         value: null,
         error: false,
      },
      CompanyEmail: {
         value: "",
         error: true,
      },
      DUNS: {
         value: "",
         error: false,
      },
      FedTaxId: {
         value: "",
         error: !isCpgMbt_Bri,
      },
      CtpatNumber: {
         value: "",
         error: false,
      },
      CompanyWebsite: {
         value: "",
         error: false,
      },
      Address1: {
         value: "",
         error: true,
      },
      Address2: {
         value: "",
         error: false,
      },
      City: {
         value: "",
         error: true,
      },
      State: {
         value: "",
         error: true,
      },
      Zip: {
         value: "",
         error: true,
      },
      Country: {
         value: "",
         error: true,
      },
   };
};
