import React from "react";
import styles from "./LoaderAnimation.module.css";

export const LoaderAnimation: React.FC = () => {
   return (
      <div className={styles["loader-wrapper"]}>
         <div className={styles["relative-wrapper"]}>
            <div className={styles["check-wrapper-fullscreen"]}>
               <div className={styles["check"]}>
                  <div className={styles["check-container"]}></div>
                  <div className={styles["line1-write"]}>~~/~~/~~~~</div>
                  <div className={styles["line1"]}></div>
                  <div className={styles["line2-write"]}>~~~/~~//~~~~~~~~~//` /~~~~~~~~``//~~~</div>
                  <div className={styles["line2"]}></div>
                  <div className={styles["line3-write"]}>~~//~~~~./~/~</div>
                  <div className={styles["line3"]}></div>
                  <div className={styles["line4-write"]}>/~~~~~`/~~~~~~///~~/~~~//``~~~~/~~/~/~~~~~~``/``~~~/~`/</div>
                  <div className={styles["line4"]}></div>
                  <div className={styles["line5-write"]}>~~///~```///~~~~~~~~/`?``?``~~?</div>
                  <div className={styles["line5"]}></div>
                  <div className={styles["line6-write"]}>~~??.`/~~~~~/?~~/?`?``~~~~~/</div>
                  <div className={styles["line6"]}></div>
               </div>
            </div>
         </div>
      </div>
   );
};
