export function getCookie(cookieName: string) {
   const name = cookieName + "=";
   const decodedCookie = decodeURIComponent(document.cookie);
   const ca = decodedCookie.split(";");

   for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == " ") {
         c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
         return c.substring(name.length, c.length);
      }
   }

   return "";
}

export function checkCookie(cookieName: string) {
   const cookie = getCookie(cookieName);

   if (cookie != "") {
      return true;
   } else {
      return false;
   }
}

export function setCookie(cookieName: string, cookieValue: string) {
   document.cookie = cookieName + "=" + cookieValue + ";path=/";
}

export function deleteCookie(cookieName: string) {
   document.cookie = cookieName + "=;expires=" + new Date().toUTCString() + ";path=/";
}
