import React from "react";

export const UploadSvgIcon = (props: React.SVGProps<any>) => (
   <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
      <path
         fill={props.color ? props.color : "#fff"}
         d="M2.5 16c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 .5 14v-3h2v3h12v-3h2v3c0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 14.5 16h-12Zm5-4V3.85l-2.6 2.6L3.5 5l5-5 5 5-1.4 1.45-2.6-2.6V12h-2Z"
      />
   </svg>
);
