import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { ActivationBaseDialogHOC } from "./ActivationBaseDialogHOC";

interface Props {
   open: boolean;
   email: string;
   handleClose: () => void;
   callback: (email: string) => Promise<void>;
}

export const ActivationEmailDialog: React.FC<Props> = ({ open, email, handleClose, callback }) => {
   const [isSaving, setIsSaving] = useState(false);
   const [value, setValue] = useState(email);
   const [emailError, setEmailError] = useState(false);

   const handleSave = async () => {
      if (emailError) return;
      setIsSaving(true);
      await callback(value);
      setIsSaving(false);
      handleClose();
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
      validateEmail(event.target.value);
   };

   const closeEvent = () => {
      setValue(email);
      validateEmail(email);

      handleClose();
   };

   const validateEmail = (text: string) => {
      // validate if input is a valid email
      const regex =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setEmailError(!regex.test(text));
   };

   React.useEffect(() => {
      setValue(email);
      validateEmail(email);
   }, [email]);

   return (
      <ActivationBaseDialogHOC
         open={open}
         title="Update Email"
         primaryButtonTitle="SAVE CHANGES"
         secondaryButtonTitle="DISCARD CHANGES"
         isSaving={isSaving}
         handleClose={closeEvent}
         handleAction={handleSave}
      >
         <TextField
            id="activation-email"
            label=" Email"
            name="activationEmail"
            type="email"
            variant="outlined"
            value={value}
            helperText={emailError ? "Please enter a valid email" : null}
            error={emailError}
            onChange={handleChange}
         />
      </ActivationBaseDialogHOC>
   );
};
