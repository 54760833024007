/* eslint-disable prettier/prettier */
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
   SetType,
   deleteCustomerActivation as deleteCustomerActivationApi,
   getCustomerActivationById,
   getCustomerActivationPdf,
   sendInvite as sendInviteApi,
   // PRETTIER ISSUE
   // eslint-disable-next-line prettier/prettier
   setCustomerActivation,
} from "../../api/CustomerActivationApi";
import { ActivationSnackbar } from "../../components/Activation/ActivationSnackbar/ActivationSnackbar";
import { ApprovalParams } from "../../components/Activation/Dialogs/ActivarionApproveDialog/ActivationApproveDialog";
import { ActivationApproveDialogDetails } from "../../components/Activation/Dialogs/ActivationApproveDialogDetails/ActivationApproveDialogDetails";
import { DenialParams } from "../../components/Activation/Dialogs/ActivationDenialDialog/ActivationDenialDialog";
import { ActivationDenialDialogDetails } from "../../components/Activation/Dialogs/ActivationDenialDialogDetails/ActivationDenialDialogDetails";
import { ActivationEmailDialog } from "../../components/Activation/Dialogs/ActivationEmailDialog";
import { ActivationNotesDialog } from "../../components/Activation/Dialogs/ActivationNotesDialog/ActivationNotesDialog";
import { CustomerCodeDialog } from "../../components/Activation/Dialogs/CustomerCodeDialog/CustomerCodeDialog";
import { Body } from "../../components/ActivationDetails/Body/Body";
import { Header } from "../../components/ActivationDetails/Header/Header";
import { ActivationDetailsButtonType } from "../../components/ActivationDetails/SubHeader/NewSubHeader";
import { SubHeader } from "../../components/ActivationDetails/SubHeader/SubHeader";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Contacts } from "../../types/Contacts";
import { CustomerActivation, Status } from "../../types/CustomerActivation";
import { DenialCodes } from "../../types/DenialCodes";
import { validateEmailAddress } from "../../utilities/validateEmailAddress";
import styles from "./ActivationDetails.module.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 320,
         marginRight: 80,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7) + 80,
         marginRight: 80,
      },
   }),
);

type AlertProps = {
   isOpen: boolean;
   message: string;
   variant: "success" | "error" | "warning" | "info";
};

export const ActivationDetails: React.FC<any> = ({ match }: RouteComponentProps<{ id: string }>): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: drawerState } = useContext(DrawerContext);
   const [selectedCustomerActivation, setSelectedCustomerActivation] = useState<CustomerActivation>({
      _id: "",
      applicationNumber: "",
      status: Status.Blank,
      companyCode: "",
      email: "",
      contacts: [],
      companyName: "",
      companyContact: "",
      companyEstablishedDate: null,
      companyEmail: "",
      companyPhone: "",
      companyWebsite: "",
      duns: "",
      fedTaxId: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      accountsPayableName: "",
      accountsPayableEmail: "",
      method: {
         _id: "",
         code: "",
         value: "",
      },
      documents: [],
      paymentMethod: "",
      chb: {
         _id: "",
         uwlHandleChb: null,
         achImporter: false,
         companyName: "",
         contact: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
         email: "",
         phone: "",
      },
      bankInfo: {
         _id: "",
         bankName: "",
         principalName: "",
         accountNumber: "",
         contact: "",
         phone: "",
         address: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      amountReq: 0,
      approvedAmount: 0,
      paymentTerms: "",
      approvedBy_id: null,
      company_id: "",
      division_id: null,
      notifiedBy_id: null,
      notifiedDate: null,
      docuSign: {
         _id: "",
         envelope_id: "",
         completed: false,
      },
      approvedOrDeniedDate: null,
      denialCode: "",
      applicationOpened: false,
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      notes: "",
      addDate: new Date(),
      deleteDate: null,
   });

   const [originalSelectedCustomerActivation, setOriginalSelectedCustomerActivation] = useState<CustomerActivation>({
      _id: "",
      applicationNumber: "",
      status: Status.Blank,
      companyCode: "",
      email: "",
      contacts: [],
      companyName: "",
      companyContact: "",
      companyEstablishedDate: null,
      companyEmail: "",
      companyPhone: "",
      companyWebsite: "",
      duns: "",
      fedTaxId: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      accountsPayableName: "",
      accountsPayableEmail: "",
      method: {
         _id: "",
         code: "",
         value: "",
      },
      documents: [],
      paymentMethod: "",
      chb: {
         _id: "",
         uwlHandleChb: null,
         achImporter: false,
         companyName: "",
         contact: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
         email: "",
         phone: "",
      },
      bankInfo: {
         _id: "",
         bankName: "",
         principalName: "",
         accountNumber: "",
         contact: "",
         phone: "",
         address: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      amountReq: 0,
      approvedAmount: 0,
      paymentTerms: "",
      approvedBy_id: null,
      company_id: "",
      division_id: null,
      notifiedBy_id: null,
      notifiedDate: null,
      docuSign: {
         _id: "",
         envelope_id: "",
         completed: false,
      },
      approvedOrDeniedDate: null,
      denialCode: "",
      applicationOpened: false,
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      notes: "",
      addDate: new Date(),
      deleteDate: null,
   });

   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [resendClicked, setResendClicked] = useState<boolean>(false);
   const [uploadClicked, setUploadClicked] = useState<boolean>(false);
   const [loading, setLoading] = useState(true);

   const [notesModal, setNotesModal] = useState<boolean>(false);
   const [approvalModal, setApprovalModal] = useState<boolean>(false);
   const [denialModal, setDenialModal] = useState<boolean>(false);
   const [saveModal, setSaveModal] = useState<boolean>(false);
   const [emailModal, setEmailModal] = useState<boolean>(false);

   const [emailing, setEmailing] = useState<boolean>(false);
   const [printing, setPrinting] = useState<boolean>(false);
   const [docuSign, setDocuSign] = useState<boolean>(false);
   const [deleting, setDeleting] = useState<boolean>(false);
   const [linkClicked, setLinkClicked] = useState<boolean>(false);
   const [deleteModal, setDeleteModal] = useState<boolean>(false);
   const [selectedDivision, setSelectedDivision] = useState("");
   const [changeEmailText, setChangeEmailText] = useState("");
   const [deleteText, setDeleteText] = useState<string>("");
   const [selectedApplicationId, setSelectedApplicationId] = useState<string>("");
   const [denialCodesList, setDenialCodesList] = useState<DenialCodes[]>([]);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [isWorking, setIsWorking] = useState<ActivationDetailsButtonType>();
   const [fileUpload, setFileUpload] = useState<File | null>();
   const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
   const [fileDate, setFileDate] = useState<Date | null>(null);
   const [downloading, setDownloading] = useState<boolean>(false);

   const [notesValue, setNotesValue] = useState<string>();
   const [companyCode, setCompanyCode] = useState<string>();

   const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const [isSavingCustomerDialog, setIsSavingCustomerDialog] = useState(false);

   const { state: userState } = useContext(UserContext);

   const [alert, setAlert] = useState<AlertProps>({
      isOpen: false,
      message: "",
      variant: "success",
   });

   useEffect(() => {
      getApplication();
   }, []);

   useEffect(() => {
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0 && selectedCustomerActivation._id) {
         if (selectedCustomerActivation.division_id) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id === selectedCustomerActivation.division_id,
            );

            if (divisionAssigned.length > 0) {
               setSelectedDivision(divisionAssigned[0].name);
               setDenialCodesList(divisionAssigned[0].denialCodes);

               const contactAssigned = _.filter(divisionAssigned[0].contacts, (x) =>
                  selectedCustomerActivation.contacts.includes(
                     selectedCustomerActivation.contacts ? x._id : selectedCustomerActivation.contacts,
                  ),
               );

               setContactList(contactAssigned);
            }
         } else {
            const companyAssigned = _.filter(
               companyState.Companies,
               (x) => x._id === selectedCustomerActivation.company_id,
            );

            if (companyAssigned.length > 0) {
               setSelectedDivision(companyAssigned[0].name);
               setDenialCodesList(companyAssigned[0].denialCodes);

               const contactAssigned = _.filter(companyAssigned[0].contacts, (x) =>
                  selectedCustomerActivation.contacts.includes(
                     selectedCustomerActivation.contacts ? x._id : selectedCustomerActivation.contacts,
                  ),
               );

               setContactList(contactAssigned);
            }
         }

         setLoading(false);
      }
   }, [selectedCustomerActivation, companyState, divisionState]);

   async function getApplication() {
      try {
         const responseData = await getCustomerActivationById(match.params.id);

         if (responseData.success) {
            setSelectedCustomerActivation(responseData.data);
            setOriginalSelectedCustomerActivation(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function onChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "companyCode" | "notes" | "approvedAmount" | "companyCode" | "email",
   ) {
      try {
         if (value === "notes") {
            const activationObj = { ...selectedCustomerActivation };
            activationObj[value] = event.target.value;
            setSelectedCustomerActivation(activationObj);
         } else if (value === "approvedAmount") {
            const activationObj = { ...selectedCustomerActivation };
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            activationObj.approvedAmount = onlyNums ? +onlyNums : 0;
            setSelectedCustomerActivation(activationObj);
         } else {
            const activationObj = { ...selectedCustomerActivation };
            activationObj[value] = event.target.value;
            setSelectedCustomerActivation(activationObj);
         }
      } catch (err) {
         handleError(err);
      }
   }

   // save Notes
   async function handleSaveNotes() {
      return saveCustomerActivation(false, "notes", notesValue as string);
   }

   // save approval
   async function handleSaveApproval(approval: ApprovalParams) {
      return saveCustomerActivation(false, "approvedAmount", approval, true);
   }

   // save company code
   async function handleSaveCompanyCode() {
      return saveCustomerActivation(false, "companyCode", companyCode || "");
   }

   // save approval
   async function handleSaveDenial(denial: DenialParams) {
      return saveCustomerActivation(false, "denialCode", denial, true);
   }

   // save email
   async function handleSaveEmail(email: string) {
      return saveCustomerActivation(false, "email", email);
   }

   async function onSelectChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const activationObj = { ...selectedCustomerActivation };
         activationObj.denialCode = event.target.value ? (event.target.value as string) : "";
         setSelectedCustomerActivation(activationObj);
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function saveCustomerActivation(
      resendCustomerActivation: boolean,
      fieldChanged: ActivationDetailsButtonType,
      value: string | ApprovalParams | DenialParams,
      fromApproveOrDenial?: boolean,
   ) {
      let activationUpdated = false;
      try {
         setIsSaving(true);
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to change the customer activation is denied!", "warning");
            setIsSaving(false);
            setOpenCustomerDialog(false);
            return;
         }

         if (selectedCustomerActivation.status.toLowerCase() === "sent") {
            const emailResults = await validateEmailAddress(selectedCustomerActivation.email);

            if (emailResults) {
               openAlertMessage(emailResults, "warning");
               setIsSaving(false);
               setOpenCustomerDialog(false);
               return;
            }

            if (
               selectedCustomerActivation.email.toLowerCase() !==
                  originalSelectedCustomerActivation.email.toLowerCase() &&
               saveModal === false
            ) {
               setChangeEmailText(
                  `The customer activation was originally sent to ${originalSelectedCustomerActivation.email}. You are about to change it to ${selectedCustomerActivation.email}.`,
               );
               setSaveModal(true);
               setIsSaving(false);
               setOpenCustomerDialog(false);
               return;
            }
         }

         if (resendCustomerActivation === true && saveModal === false) {
            setResendClicked(true);
         } else {
            setButtonClicked(true);
         }

         const activationObj = { ...selectedCustomerActivation };

         if (activationObj.status === "NEW") {
            activationObj.status = Status.Pending;
         }

         if (fieldChanged === "approvedAmount") {
            const { customerCode, approvedAmount, contacts, paymentTerms } = value as ApprovalParams;
            const onlyNums = approvedAmount.replace(/[^0-9]/g, "");
            activationObj.approvedAmount = onlyNums ? +onlyNums : 0;
            activationObj.companyCode = customerCode;
            activationObj.contacts = contacts;
            activationObj.paymentTerms = paymentTerms || "";
            // update status to approved
            if (activationObj.status !== "APPROVED" && activationObj.status !== "DENIED") {
               activationObj.status = "APPROVED" as Status.Approved;
            }
         } else if (fieldChanged === "denialCode") {
            const { denialCode, contacts, notes } = value as DenialParams;
            activationObj.notes = notes;
            activationObj.denialCode = denialCode;
            activationObj.contacts = contacts;
            // update status to denied
            if (activationObj.status !== "APPROVED" && activationObj.status !== "DENIED") {
               activationObj.status = Status.Denied;
            }
         } else if (fieldChanged === "pdf") {
            //PDF is not a field
         } else {
            activationObj[fieldChanged] = value as string;
         }

         let responseData;
         const setType = () => {
            if (activationObj.status === "APPROVED") return SetType.Approved;
            if (activationObj.status === "DENIED") return SetType.Denied;
            return SetType.Blank;
         };

         if (resendCustomerActivation) {
            activationObj.addDate = new Date();
            responseData = await sendInviteApi(activationObj);
         } else {
            responseData = await setCustomerActivation(activationObj, setType());
            activationUpdated = true;
         }

         if (responseData.success) {
            openAlertMessage("Successfully updated the customer activation.", "success");
         } else {
            openAlertMessage(responseData.message, "warning");
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
      }
      // change state
      closeCustomerDialog();
      setButtonClicked(false);
      setResendClicked(false);
      setSaveModal(false);
      setIsSaving(false);
      if (fromApproveOrDenial && activationUpdated) {
         history.push("/activations");
      }
   }

   async function handleAppLinkClick() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to copy the customer activation link is denied!", "warning");
            return;
         }

         setLinkClicked(true);

         let theme = "";

         if (selectedCustomerActivation.division_id) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id.toString() === selectedCustomerActivation.division_id?.toString(),
            );

            theme =
               divisionAssigned[0].code === "BRI"
                  ? "Bristol"
                  : divisionAssigned[0].code === "MBT"
                  ? "MiddleBay"
                  : "CPG";
         } else {
            const companyAssigned = _.filter(
               companyState.Companies,
               (x) => x._id.toString() === selectedCustomerActivation.company_id.toString(),
            );

            theme = companyAssigned[0].name;
         }

         navigator.clipboard.writeText(
            window.location.href.replace(`activations/details/${selectedCustomerActivation._id}`, "") +
               "Activation/" +
               theme +
               "/" +
               selectedCustomerActivation.email +
               "/" +
               selectedCustomerActivation._id,
         );

         setTimeout(function () {
            setLinkClicked(false);
         }, 1000);
      } catch (err) {
         openAlertMessage(
            "Error copying customer activation link. Please contact your administrator for assistance!",
            "error",
         );
      }
   }

   async function handleEmailClick() {
      try {
         setEmailing(true);

         window.open(
            `mailto:?subject=${selectedCustomerActivation.companyName.replace(
               "&",
               "AND",
            )} Customer Activation Needs Further Review&body=Customer Activation Details:%20${
               window.location.origin
            }/activations/details/${selectedCustomerActivation._id}`,
         );

         setEmailing(false);
      } catch (err) {
         openAlertMessage("Error loading email. Please contact your administrator for assistance!", "error");
         setEmailing(false);
      }
   }

   async function handlePrintClick() {
      try {
         setIsWorking("pdf");
         setPrinting(true);

         const responseData = await getCustomerActivationPdf(selectedCustomerActivation._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob), "PRINT", "height=500,width=700");
            });
         } else {
            openAlertMessage(
               "Error loading PDF customer activation. Please contact your administrator for assistance!",
               "error",
            );
         }

         setPrinting(false);
      } catch (err) {
         openAlertMessage(
            "Error loading PDF customer activation. Please contact your administrator for assistance!",
            "error",
         );
         setPrinting(false);
      }
      setIsWorking(undefined);
   }

   async function handleDeleteClick() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to delete a Customer Activation invite is denied!", "warning");
            return;
         }

         setSelectedApplicationId(selectedCustomerActivation._id);

         setDeleteText(
            `You are about to delete the customer activation for ${
               selectedCustomerActivation.companyName
                  ? selectedCustomerActivation.companyName
                  : selectedCustomerActivation.email
            }`,
         );

         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteCustomerActivation() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to delete a Customer Activation invite is denied!", "warning");
            return;
         }

         const responseData = await deleteCustomerActivationApi(selectedApplicationId);
         setDeleteModal(false);

         if (responseData.success) {
            openAlertMessage("Customer Activation invite has been deleted", "success");
            setLoading(true);
            window.location.replace("/");
         } else {
            openAlertMessage(
               "Error deleting Customer Activation. Please contact your administrator for assistance!",
               "error",
            );
         }
      } catch (err) {
         handleError(err);
      }
   }

   function openNotesDialog() {
      setNotesModal(true);
   }
   async function closeNotesModal() {
      try {
         setNotesModal(false);
         getApplication();
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
      }
   }

   async function handleDeleteClose() {
      try {
         setDeleteModal(false);
         getApplication();
      } catch (err) {
         handleError(err);
      }
   }

   function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
      if (event.target.files != null) {
         setFileUpload(event.target.files[0]);
         setFileUploadModal(true);
      }
   }

   async function closeCustomerDialog() {
      try {
         setOpenCustomerDialog(false);
         getApplication();
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
      }
   }

   function handleHeaderButton(from: ActivationDetailsButtonType) {
      switch (from) {
         case "notes":
            setNotesModal(true);
            break;
         case "approvedAmount":
            setApprovalModal(true);
            break;
         case "companyCode":
            setOpenCustomerDialog(true);
            break;
         case "denialCode":
            setDenialModal(true);
            break;
         case "email":
            setEmailModal(true);
            break;
         case "pdf":
            handlePrintClick();
            break;
         default:
            break;
      }
   }

   const showAlert = () => {
      setAlert({ isOpen: true, message: "Successfully updated the customer activation", variant: "success" });
   };

   function handleCloseDialog(from: ActivationDetailsButtonType) {
      if (from === "approvedAmount") {
         setApprovalModal(false);
      }
      if (from === "notes") {
         setNotesModal(false);
      }
   }

   return (
      <>
         <div className={styles.activationDetails}>
            <div className={styles.activationDetailsRoot}>
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className={styles.activationDetailsHeader}>
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Header mobileScreen={mobileScreen} showAlert={showAlert} />
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <SubHeader
                           selectedCustomerActivation={selectedCustomerActivation}
                           contactList={contactList}
                           selectedDivision={selectedDivision}
                           alertMessage={alertMessage}
                           alertType={alertType}
                           isWorking={isWorking}
                           setAlertMessage={setAlertMessage}
                           saveCustomerActivation={saveCustomerActivation}
                           openDialog={handleHeaderButton}
                        />
                        <div className={styles.activationDetailsBody}>
                           <Body
                              selectedCustomerActivation={selectedCustomerActivation}
                              selectedDivision={selectedDivision}
                           />
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         {selectedCustomerActivation._id ? (
            <>
               <CustomerCodeDialog
                  open={openCustomerDialog}
                  companyCode={companyCode ?? selectedCustomerActivation.companyCode}
                  handleClose={() => {
                     setOpenCustomerDialog(false);
                     setCompanyCode(undefined);
                  }}
                  callback={handleSaveCompanyCode}
                  setValue={(code: string) => setCompanyCode(code)}
               />
               <ActivationNotesDialog
                  open={notesModal}
                  value={notesValue ?? selectedCustomerActivation.notes}
                  handleClose={() => {
                     setNotesModal(false);
                     setNotesValue(undefined);
                  }}
                  callback={handleSaveNotes}
                  setValue={(notes: string) => setNotesValue(notes)}
               />
               <ActivationApproveDialogDetails
                  open={approvalModal}
                  divisionId={selectedCustomerActivation.division_id as string}
                  companyId={selectedCustomerActivation.company_id}
                  contacts={selectedCustomerActivation.contacts}
                  paymentTerms={selectedCustomerActivation.paymentTerms}
                  approvedAmount={selectedCustomerActivation.approvedAmount}
                  customerCode={selectedCustomerActivation.companyCode}
                  handleClose={() => setApprovalModal(false)}
                  callback={handleSaveApproval}
               />
               <ActivationDenialDialogDetails
                  open={denialModal}
                  divisionId={selectedCustomerActivation.division_id as string}
                  companyId={selectedCustomerActivation.company_id}
                  contacts={selectedCustomerActivation.contacts}
                  denialNotes={selectedCustomerActivation.notes}
                  denialCode={selectedCustomerActivation.denialCode}
                  denialCodes={denialCodesList}
                  handleClose={() => setDenialModal(false)}
                  callback={handleSaveDenial}
               />
               <ActivationEmailDialog
                  open={emailModal}
                  email={selectedCustomerActivation.email}
                  handleClose={() => setEmailModal(false)}
                  callback={handleSaveEmail}
               />
               <DeleteDialog
                  title={"Delete Customer Activation"}
                  text={deleteText}
                  text2={"Are you sure you want to continue?"}
                  open={deleteModal}
                  handleDelete={() => deleteCustomerActivation()}
                  handleClose={() => handleDeleteClose()}
               />
            </>
         ) : null}
         <ActivationSnackbar
            open={alert.isOpen}
            message={alert.message}
            variant={alert.variant}
            handleClose={() => setAlert({ isOpen: false, message: "", variant: "success" })}
         />
      </>
   );
};

export default ActivationDetails;
