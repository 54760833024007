/* eslint-disable react/react-in-jsx-scope */
import { Divider, Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import Grid from "@material-ui/core/Grid";
/* eslint-disable prettier/prettier */
import { Card } from "@material-ui/core";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { useBodyStyles } from "./Styles";

type Props = {
   selectedCustomerActivation: CustomerActivation;
};

function BillingInformation({ selectedCustomerActivation }: Props) {
   const classes = useBodyStyles();

   return (
      <>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px", marginLeft: "2px", marginRight: "2px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginTop: "16px",
                     marginLeft: "36px",
                     marginRight: "36px",
                     marginBottom: "36px",
                  }}
               >
                  <Typography variant="h6" color="primary" style={{ fontWeight: 700 }}>
                     {"Billing Information"}
                  </Typography>
                  <Divider className={classes.dividerBody} />
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Accounts Payable Contact Name
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Accounts Payable Contact Email
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Preferred Method for Receiving Invoices
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Preferred Method
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.accountsPayableName}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.accountsPayableEmail}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.method.code}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.method.value}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={12}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Documents Required with Invoice
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={12}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.documents.join(", ")}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { BillingInformation };
