import React from "react";
import { Route, Switch } from "react-router-dom";
import DivisionDetails from "./DivisionDetails";
import DivisionView from "./DivisionView";

export const Division: React.FC<any> = (): JSX.Element => {
   return (
      <>
         <Switch>
            <Route path="/divisions" exact component={DivisionView} />
            <Route path="/divisions/details/:id?" exact component={DivisionDetails} />
         </Switch>
      </>
   );
};

export default Division;
