import { Box, IconButton, Menu, Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { DateFormatter } from "../../utilities/DateFormatter";
import { CardColumn, CardColumnTextContent, CardColumnTitle } from "../Activation/ActivationPage/Activation.styled";
import { StyledDivider, StyledMenuItem } from "./OptionsMenuBurger.styled";

interface Props {
   applicationNumber: string;
   dateReceived: Date;
   companyOrDivisionName: string;
   clientName: string;
   pointOfcontact: string;
   handlePrintViewPDFActionClick: () => Promise<void>;
   handleDownloadPDFActionClick: () => Promise<void>;
   handleEmailActionClick: () => void;
   handleNotesDialogOpen: () => void;
   handleApprovalDialogOpen: () => void;
   handleDenialDialogOpen: () => void;
   handleGoToDetails: () => void;
}

export const NewActivationRow: React.FC<Props> = ({
   applicationNumber,
   dateReceived,
   companyOrDivisionName,
   clientName,
   pointOfcontact,
   handlePrintViewPDFActionClick,
   handleDownloadPDFActionClick,
   handleEmailActionClick,
   handleNotesDialogOpen,
   handleApprovalDialogOpen,
   handleDenialDialogOpen,
   handleGoToDetails,
}) => {
   const [isPrintingViewingPDF, setIsPrintingViewingPDF] = useState(false);
   const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleGo = () => {
      setAnchorEl(null);
      handleGoToDetails();
   };

   const handleNotes = () => {
      handleNotesDialogOpen();
      handleClose();
   };

   const handlePrintViewPDF = async () => {
      setIsPrintingViewingPDF(true);
      await handlePrintViewPDFActionClick();
      setIsPrintingViewingPDF(false);
      handleClose();
   };

   const handleDownloadPDF = async () => {
      setIsDownloadingPDF(true);
      await handleDownloadPDFActionClick();
      setIsDownloadingPDF(false);
      handleClose();
   };

   const handleEmail = () => {
      handleEmailActionClick();
      handleClose();
   };

   const handleApprove = () => {
      handleApprovalDialogOpen();
      handleClose();
   };

   const handleDenial = () => {
      handleDenialDialogOpen();
      handleClose();
   };

   return (
      <Box
         sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "0.5fr 0.8fr 0.5fr 0.5fr 0.5fr 0.2fr",
         }}
      >
         <CardColumn>
            <CardColumnTitle>C.A.F #</CardColumnTitle>
            <CardColumnTextContent>{applicationNumber}</CardColumnTextContent>
         </CardColumn>
         <CardColumn>
            <CardColumnTitle>Company | Division</CardColumnTitle>
            <CardColumnTextContent>{companyOrDivisionName}</CardColumnTextContent>
         </CardColumn>
         <CardColumn>
            <CardColumnTitle>Client</CardColumnTitle>
            <CardColumnTextContent>{clientName}</CardColumnTextContent>
         </CardColumn>
         <CardColumn>
            <CardColumnTitle>Date Received</CardColumnTitle>
            <CardColumnTextContent>{DateFormatter(dateReceived)}</CardColumnTextContent>
         </CardColumn>
         <CardColumn>
            <CardColumnTitle>Point of Contact</CardColumnTitle>
            <CardColumnTextContent>{pointOfcontact === "" ? "N/A" : pointOfcontact}</CardColumnTextContent>
         </CardColumn>
         <CardColumn>
            {/* Options Menu */}
            <div>
               <IconButton aria-label="more" aria-controls="options-menu" aria-haspopup="true" onClick={handleClick}>
                  <MoreVertIcon />
               </IconButton>
               <Menu
                  id="options-menu"
                  anchorEl={anchorEl}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
               >
                  <StyledMenuItem icon={VisibilityIcon} handleClick={handleGo}>
                     Details
                  </StyledMenuItem>
                  <StyledMenuItem icon={SpeakerNotesIcon} handleClick={handleNotes}>
                     Notes
                  </StyledMenuItem>
                  <StyledDivider />
                  <StyledMenuItem
                     icon={PictureAsPdfIcon}
                     loading={isPrintingViewingPDF}
                     handleClick={handlePrintViewPDF}
                  >
                     PDF Print/View
                  </StyledMenuItem>
                  <StyledMenuItem icon={GetAppIcon} loading={isDownloadingPDF} handleClick={handleDownloadPDF}>
                     Download PDF
                  </StyledMenuItem>
                  <StyledDivider />
                  <StyledMenuItem icon={ThumbUpAltIcon} iconColor="#187B22" handleClick={handleApprove}>
                     <Typography component="span" style={{ color: "#187B22" }}>
                        Approve
                     </Typography>
                  </StyledMenuItem>
                  <StyledMenuItem icon={ThumbDownAltIcon} iconColor="#CD0E0E" handleClick={handleDenial}>
                     <Typography component="span" style={{ color: "#CD0E0E" }}>
                        Deny
                     </Typography>
                  </StyledMenuItem>
                  <StyledDivider />
                  <StyledMenuItem icon={EmailIcon} handleClick={handleEmail}>
                     Email
                  </StyledMenuItem>
               </Menu>
            </div>
         </CardColumn>
      </Box>
   );
};
