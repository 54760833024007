import { Divisions } from "../types/Divisions";
import { ApiRequest } from "../types/Requests/ApiRequest";
import { ApiResponse } from "../types/Responses/ApiResponse";
import { HandleError, HandleResponse } from "./ApiUtilities";

export const getDivisionList = async (getApplicationCount = false): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/getDivisionList`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         getApplicationCount: getApplicationCount,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getDivisionById = async (id: string, getApplicationCount = false): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/getDivisionById`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
         getApplicationCount: getApplicationCount,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getDivision = async (division: Divisions): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/getDivision`,
      method: "POST",
      body: JSON.stringify(division),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setDivision = async (division: Divisions): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/setDivision`,
      method: "POST",
      body: JSON.stringify(division),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const removeDivision = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/removeDivision/`,
      method: "DELETE",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setDocument = async (document: FormData | null | undefined): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/setOnboardingPacket`,
      method: "POST",
      body: document,
      headers: {},
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getPacketLastModifiedDate = async (DivisionId: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Division/getPacketLastModifiedDate`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         DivisionId: DivisionId,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};
