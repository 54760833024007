import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Redirect, Route } from "react-router-dom";
import * as CookieTools from "./CookieTools";

export const SecureRoute: React.FC<any> = ({ component: Component, ...props }: any): JSX.Element => {
   const handleError = useErrorHandler();

   try {
      //check user id via cookies first
      if (CookieTools.checkCookie("ca-user-id")) {
         const userId = CookieTools.getCookie("ca-user-id");

         if (!userId) {
            //if token is blank return to login
            return <Route {...props} render={() => <Redirect to="/login" />} />;
         }
      } else {
         //token not found, return to login
         return <Route {...props} render={() => <Redirect to="/login" />} />;
      }
   } catch (err) {
      handleError(err);
   }

   //if above token validation is good, direct to route
   return <Route path={props.path} render={(props) => <Component {...props} />} />;
};

export default SecureRoute;
