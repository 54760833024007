import { Box, Button, CircularProgress, Collapse, IconButton, Slide, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TransitionProps } from "@material-ui/core/transitions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
import { UploadSvgIcon } from "../../../components/Icons/UploadSvgIcon";
import { TradeRefInfo } from "../../../types/tradeRefInfo";
import { useApplyStyles } from "../Styles";

export type TypeParams = keyof TradeRefInfo | "tradeReferencesSkipped" | "tradeReferencesFileName";

type TradeReferenceOptionsProps = {
   tradeReferencesSkipped: boolean;
   tradeReferencesFileName?: string;
   fillForm: boolean;
   loading: boolean;
   saving: boolean;
   setFillFormIn: React.Dispatch<React.SetStateAction<boolean>>;
   setLoading: React.Dispatch<React.SetStateAction<boolean>>;
   applicationID: string;
   handleChange: (value: string | boolean, type: TypeParams) => void;
};

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export default function TradeReferenceOptions({
   tradeReferencesSkipped,
   tradeReferencesFileName,
   saving,
   loading,
   setLoading,
   fillForm,
   applicationID,
   setFillFormIn,
   handleChange,
}: TradeReferenceOptionsProps) {
   const theme = useTheme();
   const [open, setOpen] = React.useState(false);
   const [uploading, setUploading] = React.useState(false);

   const inputRef = React.useRef<HTMLInputElement>(null);

   const [alertMessage, setAlertMessage] = React.useState<string>("");
   const [alertType, setAlertType] = React.useState<"error" | "info" | "success" | "warning">("warning");

   const classes = useApplyStyles({ activeStep: 0 });

   const openDialog = () => {
      setOpen(true);
   };

   const handleCloseDialog = (skipStep: boolean) => {
      setOpen(false);
      if (skipStep) {
         handleChange(skipStep, "tradeReferencesSkipped");
      }
   };

   const handleUploadFile = () => {
      if (inputRef.current) {
         inputRef.current.click();
      }
   };

   const handleSubmitFile = () => {
      if (!inputRef.current?.files) {
         return;
      }

      const file = inputRef.current.files[0];
      const formData = new FormData();
      formData.append("TradeReferenceDocument", file);
      formData.append("FileName", file.name);
      formData.append("Application_id", applicationID);
      setFillFormIn(false);
      setUploading(true);
      setLoading(true);

      fetch(`/api/application/setInviteTradeReferences`, {
         method: "POST",
         body: formData,
      })
         .then(() => {
            handleChange(buildFileName(file.name, applicationID), "tradeReferencesFileName");
         })
         .catch((error) => {
            openAlertMessage("Error uploadin file. Please contact your administrator for assistance!", "error");
         })
         .finally(() => {
            setUploading(false);
            setLoading(false);
         });
   };

   const buildFileName = (fileName: string, applicationID: string) => {
      const fileNameSplited = fileName.split(".");
      const ext = fileNameSplited[fileNameSplited.length - 1];
      fileNameSplited.pop();
      const name = fileNameSplited.join(".");
      return `${name}_${applicationID}.${ext}`;
   };

   const handleEditTradeReference = () => {
      setFillFormIn(true);
   };

   function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <div>
         <Collapse in={!!alertMessage}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <Box
            style={{
               display: "flex",
               justifyContent: "space-between",
               flexWrap: "wrap",
               padding: "0px 80px",
               gap: "15px",
            }}
         >
            <Button
               variant="contained"
               color="primary"
               onClick={() => handleEditTradeReference()}
               disabled={saving || loading}
               className={clsx(fillForm ? classes.backButton : classes.nextButton, classes.commonButton)}
               endIcon={<EditIcon />}
            >
               ENTER TRADE REFERENCES
            </Button>
            <Button
               variant="contained"
               color="primary"
               className={clsx(tradeReferencesFileName ? classes.backButton : classes.nextButton, classes.commonButton)}
               onClick={handleUploadFile}
               disabled={saving || loading}
               endIcon={
                  uploading ? (
                     <CircularProgress size={20} className={classes.buttonProgress} />
                  ) : (
                     <UploadSvgIcon
                        color={
                           tradeReferencesFileName ? theme.palette.primary.main : theme.palette.primary.contrastText
                        }
                     />
                  )
               }
            >
               UPLOAD TRADE REFERENCES
            </Button>
            <Button
               variant="contained"
               color="primary"
               className={clsx(tradeReferencesSkipped ? classes.backButton : classes.nextButton, classes.commonButton)}
               disabled={saving || loading}
               onClick={openDialog}
               endIcon={<ArrowForwardIcon />}
            >
               PROVIDE TRADE REFERENCES LATER
            </Button>
         </Box>
         <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleCloseDialog(false)}
            aria-labelledby="warning dialog"
            aria-describedby="Please be aware that you must provide trade references separately from this application in order for your credit request to be approved"
            className={classes.dialogWarning}
         >
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
               <DialogContentText className={classes.contectTextDialog}>
                  Please be aware that you must provide trade references separately from this application in order for
                  your credit request to be approved
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Box style={{ display: "flex", gap: "20px" }}>
                  <Button
                     onClick={() => handleCloseDialog(false)}
                     startIcon={<ArrowBackIcon />}
                     className={clsx(classes.backButton, classes.commonButton)}
                  >
                     GO BACK AND PROVIDE THE REFERENCES
                  </Button>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => handleCloseDialog(true)}
                     endIcon={<ArrowForwardIcon />}
                     className={clsx(classes.nextButton, classes.commonButton)}
                  >
                     GO TO E-SIGNATURE
                  </Button>
               </Box>
            </DialogActions>
         </Dialog>
         <form>
            <input
               type="file"
               ref={inputRef}
               style={{ display: "none" }}
               accept=".pdf, .docx, .doc"
               onChange={handleSubmitFile}
            />
         </form>
      </div>
   );
}
