import { Applications } from "../types/Applications";
import { ApiRequest } from "../types/Requests/ApiRequest";
import { ApiResponse } from "../types/Responses/ApiResponse";
import { HandleError, HandleResponse } from "./ApiUtilities";

export const sendInvite = async (application: Applications): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/sendInvite`,
      method: "POST",
      body: JSON.stringify(application),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getInviteApplication = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getInviteApplication`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setInviteApplication = async (application: Applications): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/setInviteApplication`,
      method: "POST",
      body: JSON.stringify(application),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setApplicationReferral = async (application: Applications): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/setApplicationReferral`,
      method: "POST",
      body: JSON.stringify(application),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getInviteApplicationPdf = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getInviteApplicationPdf`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/pdf",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getApplicationList = async (
   filter: string,
   status: string,
   applicationStatus: string,
   applicationQuery: {
      company: string;
      startDate: Date | null;
      endDate: Date | null;
   },
   selectedCompanies: string,
   selectedDivisions: string,
   page: number,
): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getApplicationList`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         filter: filter,
         status: status,
         applicationStatus: applicationStatus,
         company: applicationQuery.company,
         startDate: applicationQuery.startDate ? applicationQuery.startDate.toISOString() : "",
         endDate: applicationQuery.endDate ? applicationQuery.endDate.toISOString() : "",
         page: page,
         selectedCompanies: selectedCompanies,
         selectedDivisions: selectedDivisions,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getApplicationById = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getApplicationById`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getApplication = async (application: Applications): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getApplication`,
      method: "POST",
      body: JSON.stringify(application),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setApplication = async (application: Applications, setType = ""): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/setApplication`,
      method: "POST",
      body: JSON.stringify(application),
      headers: {
         "Content-Type": "application/json",
         setType: setType,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setApplicationCopy = async (
   id: string,
   companyId: string,
   divisionId: string,
   email: string,
   contacts: string[],
): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/setApplicationCopy`,
      method: "POST",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
         companyId: companyId,
         divisionId: divisionId,
         email: email,
         contacts: contacts,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setTradeReferencesDocument = async (document: FormData | null | undefined): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/setTradeReferences`,
      method: "POST",
      body: document,
      headers: {},
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getApplicationPdf = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getApplicationPdf`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/pdf",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getTradeReferencesPdf = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getTradeReferencesFile`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/octet-stream",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
      })
      .catch(async (response) => {
         resp = await HandleError(response);
      });

   return resp;
};

export const getDocuSignPdf = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getDocuSignPdf`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/pdf",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const completeDocuSignPdf = async (envelopeId: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/completeDocuSignPdf`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/pdf",
         envelopeId: envelopeId,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const deleteApplication = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/deleteApplication`,
      method: "DELETE",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getTradeReferencesLastModifiedDate = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/Application/getTradeReferencesLastModifiedDate`,
      method: "GET",
      body: null,
      headers: { "Content-Type": "application/json", id },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};
