import React from "react";
import "./CheckVoidAnimation.css";

export const CheckVoidAnimation: React.FC<any> = (): JSX.Element => {
   return (
      <div className="checkvoid-wrapper">
         <div className="checkvoid-wrapper-fullscreen">
            <div className="checkvoid">
               <div className="checkvoid-container"></div>
               <div className="line1-write">~~/~~/~~~~</div>
               <div className="line1"></div>
               <div className="line2-write">~~~/~~//~~~~~~~~~//` /~~~~~~~~``//~~~</div>
               <div className="line2"></div>
               <div className="line3-write">~~//~~~~./~/~</div>
               <div className="line3"></div>
               <div className="line4-write">/~~~~~`/~~~~~~///~~/~~~//``~~~~/~~/~/~~~~~~``/``~~~/~`/</div>
               <div className="line4"></div>
               <div className="line5-write">~~///~```///~~~~~~~~/`?``?``~~?</div>
               <div className="line5"></div>
               <div className="line6-write">~~??.`/~~~~~/?~~/?`?``~~~~~/</div>
               <div className="line6"></div>
               <div className="void">VOID</div>
            </div>
         </div>
      </div>
   );
};

export default CheckVoidAnimation;
