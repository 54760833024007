/* eslint-disable react/react-in-jsx-scope */
import { Button, IconButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SendIcon from "@material-ui/icons/Send";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ActivationInvite } from "../../Activation/ActivationInvite/ActivationInvite";
import { useHeaderStyles } from "./Styles";

type Props = {
   mobileScreen: boolean;
   showAlert: () => void;
};

function Header({ mobileScreen, showAlert }: Props) {
   const [open, setOpen] = useState(false);

   const history = useHistory();
   const classes = useHeaderStyles();

   async function onClickBack() {
      history.push("/activations");
   }

   const handleClose = (saved?: boolean) => {
      if (saved) {
         showAlert();
      }
      setOpen(false);
   };

   return (
      <>
         <Grid xs={12} container direction="row" justifyContent="space-between" alignItems="center">
            <Grid
               container
               direction="column"
               item
               xs={mobileScreen ? 7 : 9}
               justifyContent="center"
               alignItems="flex-start"
            >
               <div>
                  <Typography variant="h4" style={{ fontWeight: 700 }}>
                     <IconButton color="primary" onClick={() => onClickBack()}>
                        <NavigateBeforeIcon style={{ fontSize: "32px" }} />
                     </IconButton>
                     {" Customer Activations"}
                  </Typography>
               </div>
            </Grid>
            <Grid
               container
               direction="column"
               item
               xs={mobileScreen ? 5 : 3}
               justifyContent="center"
               alignItems="flex-end"
            >
               <Button
                  className={classes.inviteButton}
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={() => setOpen(true)}
               >
                  Activation Invite
               </Button>
            </Grid>
         </Grid>
         <ActivationInvite open={open} handleClose={handleClose} />
      </>
   );
}

export { Header };
