import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import InputMask from "react-input-mask";
import { BankInfo } from "../../../types/BankInfo";
import "../ApplyUWLWDS.css";

type BankingInfoProps = {
   bankInfo: BankInfo;
   handleChange: (value: string, type: keyof BankInfo) => void;
   errorHighlight: BankInfoError;
};

type BankInfoError = {
   duns: { error: boolean; message: string };
   bankName: { error: boolean; message: string };
   principalName: { error: boolean; message: string };
   address: { error: boolean; message: string };
   city: { error: boolean; message: string };
   state: { error: boolean; message: string };
   zip: { error: boolean; message: string };
   country: { error: boolean; message: string };
   contact: { error: boolean; message: string };
   phone: { error: boolean; message: string };
   accountNumber: { error: boolean; message: string };
   federalTaxNumber: { error: boolean; message: string };
   paymentMethod: { error: boolean; message: string };
};

export const BankingInfo: React.FC<any> = ({
   handleChange,
   bankInfo,
   errorHighlight,
}: BankingInfoProps): JSX.Element => {
   const useStyles = makeStyles((theme: Theme) =>
      createStyles({
         formControl: {
            width: "-webkit-fill-available",
         },
         selectEmpty: {
            marginTop: theme.spacing(2),
         },
         outerPaper: {
            padding: "1%",
         },
      }),
   );
   const classes = useStyles();

   return (
      <>
         <form noValidate autoComplete="off">
            {/* federalTaxNumber */}
            <Grid item xs={12}>
               <InputMask
                  mask="99-9999999"
                  value={bankInfo.federalTaxNumber}
                  onChange={(event) => handleChange(event.target.value, "federalTaxNumber")}
               >
                  {() => (
                     <TextField
                        id="bank-federalTaxNumber"
                        label="Federal Tax ID Number"
                        variant="outlined"
                        error={!!errorHighlight.federalTaxNumber.error}
                        inputProps={{
                           autocomplete: "federalTaxNumber",
                           form: {
                              autocomplete: "off",
                           },
                        }}
                     />
                  )}
               </InputMask>
               <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                  {errorHighlight.federalTaxNumber.error ? errorHighlight.federalTaxNumber.message : null}
               </FormHelperText>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
               {/* duns */}
               <Grid item xs={6}>
                  <InputMask
                     mask="99-999-9999"
                     value={bankInfo.duns}
                     onChange={(event) => handleChange(event.target.value, "duns")}
                  >
                     {() => (
                        <TextField
                           id="bank-duns"
                           label="D&B D-U-N-S Number (optional)"
                           variant="outlined"
                           error={!!errorHighlight.duns.error}
                           inputProps={{
                              autocomplete: "duns",
                              form: {
                                 autocomplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {errorHighlight.duns.error
                        ? errorHighlight.duns.message
                        : errorHighlight.bankName.error
                        ? " "
                        : null}
                  </FormHelperText>
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id="bank-bankName"
                     label="Bank Name"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "bankName")}
                     value={bankInfo.bankName}
                     helperText={errorHighlight.bankName.error ? errorHighlight.bankName.message : null}
                     error={errorHighlight.bankName.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
               <Grid item xs={6}>
                  <TextField
                     id="bank-accountNumber"
                     label="Account Number"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "accountNumber")}
                     value={bankInfo.accountNumber}
                     helperText={errorHighlight.accountNumber.error ? errorHighlight.accountNumber.message : null}
                     error={errorHighlight.accountNumber.error ? true : false}
                     inputProps={{ maxLength: 30 }}
                  />
               </Grid>
               <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth={true}>
                     <InputLabel>Payment Method</InputLabel>
                     <Select
                        id="select-paymentMethod"
                        label="Payment Method"
                        defaultValue=""
                        MenuProps={{
                           anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                           },
                           getContentAnchorEl: null,
                        }}
                        value={bankInfo.paymentMethod}
                        onChange={(event) => handleChange(event.target.value as string, "paymentMethod")}
                     >
                        <MenuItem key={"ACH"} value={"ACH"}>
                           {"ACH"}
                        </MenuItem>
                        <MenuItem key={"Wire"} value={"Wire"}>
                           {"Wire"}
                        </MenuItem>
                     </Select>
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {errorHighlight.paymentMethod.error
                           ? errorHighlight.paymentMethod.message
                           : errorHighlight.accountNumber.error
                           ? " "
                           : null}
                     </FormHelperText>
                  </FormControl>
               </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" justify="space-between">
               <Grid item xs={6}>
                  <TextField
                     id="bank-address"
                     label="Address"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "address1")}
                     value={bankInfo.address1}
                     helperText={
                        errorHighlight.address.error
                           ? errorHighlight.address.message
                           : errorHighlight.city.error || errorHighlight.state.error
                           ? " "
                           : null
                     }
                     error={errorHighlight.address.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               <Grid item xs={3}>
                  <TextField
                     id="bank-city"
                     label="City"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "city")}
                     value={bankInfo.city}
                     helperText={
                        errorHighlight.city.error
                           ? errorHighlight.city.message
                           : errorHighlight.address.error || errorHighlight.state.error
                           ? " "
                           : null
                     }
                     error={errorHighlight.city.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               <Grid item xs={3}>
                  <TextField
                     id="bank-state"
                     label="State"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "state")}
                     value={bankInfo.state}
                     helperText={
                        errorHighlight.state.error
                           ? errorHighlight.state.message
                           : errorHighlight.address.error || errorHighlight.city.error
                           ? " "
                           : null
                     }
                     error={errorHighlight.state.error ? true : false}
                     inputProps={{ maxLength: 2 }}
                  />
               </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
               <Grid item xs={6}>
                  <TextField
                     id="bank-zip"
                     label="Zip Code"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "zip")}
                     value={bankInfo.zip}
                     helperText={
                        errorHighlight.zip.error
                           ? errorHighlight.zip.message
                           : errorHighlight.contact.error
                           ? " "
                           : null
                     }
                     error={errorHighlight.zip.error ? true : false}
                     inputProps={{ maxLength: 10 }}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id="bank-contact"
                     label="Contact (optional)"
                     variant="outlined"
                     onChange={(event) => handleChange(event.target.value, "contact")}
                     value={bankInfo.contact}
                     helperText={
                        errorHighlight.contact.error
                           ? errorHighlight.contact.message
                           : errorHighlight.zip.error
                           ? " "
                           : null
                     }
                     error={errorHighlight.contact.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
            </Grid>
         </form>
      </>
   );
};

export default BankingInfo;
