import React from "react";

import { FormControl } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InputMask from "react-input-mask";

import { ActivationBankingInformationDTO } from "../../../types/Activation/ActivationDTO";
import { countryList } from "../../../utilities/CountryList";
import { useActivationStyles } from "../styles";
import { bankingInformationCpgReducer, initialBankingValues } from "./reducer";

type Props = {
   wasSubmit: boolean;
   changeFormState: (from: "banking", isValid: boolean) => void;
   updateActivationObject: (obj: ActivationBankingInformationDTO) => void;
};

function ActivationBankingInformation({ wasSubmit, changeFormState, updateActivationObject }: Props) {
   const [state, dispatch] = React.useReducer(bankingInformationCpgReducer, initialBankingValues);
   const classes = useActivationStyles();

   const refFormState = React.useRef(false);

   /**
    * Validate fields, return true if the form is valid
    */
   const validateForm = (): boolean => {
      if (state.BankName?.error) return false;
      if (state.PrincipalName?.error) return false;
      if (state.AccountNumber?.error) return false;
      if (state.Contacts?.error) return false;
      if (state.Phone?.error) return false;
      if (state.Address?.error) return false;
      if (state.City?.error) return false;
      if (state.State?.error) return false;
      if (state.Zip?.error) return false;
      if (state.Country?.error) return false;
      return true;
   };

   React.useEffect(() => {
      // validate fields
      if (!wasSubmit) return;

      dispatch({ type: "forceValidation", payload: state });
   }, [wasSubmit]);

   React.useEffect(() => {
      const isValid = validateForm();

      // Create object
      const bankingInformation: ActivationBankingInformationDTO = {
         BankInfo: {
            BankName: state.BankName?.value as string,
            PrincipalName: state.PrincipalName?.value as string,
            AccountNumber: state.AccountNumber?.value as string,
            Contact: state.Contact?.value as string,
            Phone: state.Phone?.value as string,
            Address: state.Address?.value as string,
            City: state.City?.value as string,
            State: state.State?.value as string,
            Zip: state.Zip?.value as string,
            Country: state.Country?.value as string,
         },
      };
      // update parent object
      updateActivationObject(bankingInformation);

      // sent to the parent component when the form change its state between valid and invalid
      if (isValid !== refFormState.current) {
         refFormState.current = isValid;
         changeFormState("banking", isValid);
      }
   }, [state]);

   return (
      <section className={classes.container}>
         <h1 className={classes.title}>
            Banking Information <span>(Optional)</span>
         </h1>
         <hr />
         <div>
            {/* Brokers information */}
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
               {/* Bank Name */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="bank-name"
                     label="Bank Name"
                     name="ActivationBankingBankName"
                     autoComplete="ActivationBankingBankName"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "BankName", payload: event.target.value })}
                     value={state.BankName?.value}
                     helperText={state.BankName?.error && state.BankName?.message}
                     error={!!state.BankName?.error}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               {/* Name of principal */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="name-principal"
                     label="Name of Principal"
                     name="ActivationBankingPrincipalName"
                     autoComplete="ActivationBankingPrincipalName"
                     placeholder="Name of Principal"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "PrincipalName", payload: event.target.value })}
                     value={state.PrincipalName?.value}
                     helperText={state.PrincipalName?.error && state.PrincipalName?.message}
                     error={!!state.PrincipalName?.error}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Account Number  */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="account-number"
                     label="Account Number"
                     autoComplete="ActivationBankingAccountNumber"
                     name="ActivationBankingAccountNumber"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "AccountNumber", payload: event.target.value })}
                     value={state.AccountNumber?.value}
                     helperText={state.AccountNumber?.error ? state.AccountNumber?.message : null}
                     error={!!state.AccountNumber?.error}
                  />
               </Grid>

               {/* Contact */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="contact"
                     label="Contact"
                     autoComplete="ActivationBankingContact"
                     name="ActivationBankingContact"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Contact", payload: event.target.value })}
                     value={state.Contact?.value}
                     helperText={state.Contact?.error ? state.Contact?.message : null}
                     error={!!state.Contact?.error}
                  />
               </Grid>

               {/* Phone number */}
               <Grid item lg={6} md={6} xs={12}>
                  <InputMask
                     mask="999-999-9999"
                     value={state.CompanyPhone?.value as string}
                     onChange={(event) => dispatch({ type: "Phone", payload: event.target.value })}
                  >
                     {() => (
                        <TextField
                           id={`phone`}
                           label="Phone"
                           variant="outlined"
                           error={!!state.Phone?.error}
                           inputProps={{
                              autoComplete: "phone",
                              form: {
                                 autoComplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {state.Phone?.error ? state.Phone?.message : null}
                  </FormHelperText>
               </Grid>

               {/* Address */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="address"
                     label="Address"
                     name="ActivationBankingAddress"
                     autoComplete="ActivationBankingAddress"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Address", payload: event.target.value })}
                     value={state.Address?.value}
                     helperText={state.Address?.error ? state.Address?.message : null}
                     error={!!state.Address?.error}
                  />
               </Grid>

               {/* City */}
               <Grid item lg={3} md={3} xs={12}>
                  <TextField
                     id="banking-city"
                     label="City"
                     variant="outlined"
                     name="ActivationBankingCity"
                     autoComplete="ActivationBankingCity"
                     InputProps={{
                        endAdornment: <LocationOnIcon style={{ color: "grey " }} />,
                     }}
                     onChange={(event) => dispatch({ type: "City", payload: event.target.value })}
                     value={state.City?.value}
                     helperText={state.City?.error ? state.City?.message : null}
                     error={state.City?.error}
                  />
               </Grid>

               {/* State */}
               <Grid item lg={3} md={3} xs={12}>
                  <TextField
                     id="banking-state"
                     label="State"
                     name="ActivationBankingState"
                     autoComplete="ActivationBankingState"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "State", payload: event.target.value })}
                     value={state.State?.value}
                     helperText={state.State?.error ? state.State.message : null}
                     error={!!state.State?.error}
                     inputProps={{ maxLength: 2 }}
                  />
               </Grid>

               {/* Zip Code */}
               <Grid item lg={3} md={3} xs={12}>
                  <TextField
                     id="banking-zip"
                     label="Zip Code"
                     name="ActivationBankingZip"
                     autoComplete="ActivationBankingZip"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Zip", payload: event.target.value })}
                     value={state.Zip?.value}
                     helperText={state.Zip?.error ? state?.Zip.message : null}
                     error={!!state.Zip?.error}
                     InputProps={{
                        endAdornment: <LocationOnIcon style={{ color: "grey " }} />,
                     }}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Country */}
               <Grid item lg={3} md={3} xs={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                     <InputLabel id="bank-country" style={{ color: state.Country?.error ? "red" : "inherit" }}>
                        Country
                     </InputLabel>
                     <Select
                        labelId="banking-country"
                        id="banking-country"
                        name="ActivationBankingCountry"
                        autoComplete="ActivationBankingCountry"
                        variant="outlined"
                        defaultValue={""}
                        style={{ height: "56px" }}
                        onChange={(event) => dispatch({ type: "Country", payload: event.target.value })}
                        value={state.Country?.value}
                        label="Country"
                        error={!!state.Country?.error}
                     >
                        {countryList.map((country, index) => {
                           return (
                              <MenuItem key={index} value={country.name}>
                                 {country.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {state.Country?.error ? state?.Country.message : null}
                     </FormHelperText>
                  </FormControl>
               </Grid>
            </Grid>
         </div>
      </section>
   );
}

export { ActivationBankingInformation };
