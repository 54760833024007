import React from "react";
import { Route, Switch } from "react-router-dom";
import ApplicationDetails from "./ApplicationDetails";
import ApplicationView from "./ApplicationView";

export const Application: React.FC<any> = (): JSX.Element => {
   return (
      <>
         <Switch>
            <Route path="/applications" exact component={ApplicationView} />
            <Route path="/applications/details/:id?" exact component={ApplicationDetails} />
            {/* THIS ONE MUST BE AT THE BOTTOM!!! */}
            <Route path="/" exact component={ApplicationView} />
         </Switch>
      </>
   );
};

export default Application;
