import _ from "lodash";
import React, { useRef, useState } from "react";

import { Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { CompanyContext } from "../../../../stores/Companies/Context";
import { DivisionContext } from "../../../../stores/Divisions/Context";
import { Contacts } from "../../../../types/Contacts";
import { DenialCodes } from "../../../../types/DenialCodes";
import { ActivationBaseDialogHOC } from "../ActivationBaseDialogHOC";

export type DenialParams = {
   denialCode: string;
   notes: string;
   contacts: string[];
};

interface Props {
   open: boolean; //TODO: replace this any type
   divisionId: string;
   companyId: string;

   denialCode: string;
   denialNotes: string;
   contacts: string[];
   denialCodes: DenialCodes[];

   handleClose: () => void;
   callback: (approval: DenialParams) => Promise<void>;
}

export const ActivationDenialDialogDetails: React.FC<Props> = ({
   open,
   denialCode,
   denialCodes,
   denialNotes,
   contacts,
   divisionId,
   companyId,
   handleClose,
   callback,
}) => {
   const [isSaving, setIsSaving] = useState(false);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);
   // Context
   const { state: companyState } = React.useContext(CompanyContext);
   const { state: divisionState } = React.useContext(DivisionContext);
   // form fields
   const [denialCodeValue, setDenialCodeValue] = useState<string>(() => denialCode);
   const [denialNotesValue, setDenialNotesValue] = useState(denialNotes);

   const [contactList, setContactList] = useState<Contacts[]>([]);
   const contactRef = useRef<Contacts[]>([]);
   // Errors
   const [errorDenialCodeValue, setErrorApprovedAmountValue] = useState("");
   const [errorContactsValue, setErrorContactsValue] = useState("");

   const validateDenialCodeValue = (value?: string | number) => {
      if (!value && errorDenialCodeValue) return;
      if (value && !errorDenialCodeValue) return;

      if (!value) {
         setErrorApprovedAmountValue("Denial code is required");
      } else {
         setErrorApprovedAmountValue("");
      }
   };

   const validateContactsValue = (value?: Contacts[]) => {
      if (!value?.length && errorContactsValue) return;
      if (value?.length && !errorContactsValue) return;

      if (!value?.length) {
         setErrorContactsValue("Contact is required");
      } else {
         setErrorContactsValue("");
      }
   };

   const handleSave = async () => {
      // validate errors
      if (!denialCodeValue || !selectedContactsList.length) {
         validateDenialCodeValue(denialCodeValue);
         validateContactsValue(selectedContactsList);
         return;
      }

      setIsSaving(true);
      await callback({
         denialCode: denialCodeValue,
         notes: denialNotesValue || "",
         contacts: selectedContactsList.map((x) => x._id),
      });
      setIsSaving(false);
      handleClose();
   };

   function handleChipDelete(_id: string) {
      // add to contact dropdown list
      const contactToAdd = contactRef.current.find((x) => x._id === _id) as Contacts;
      setContactList(_.orderBy([...contactList, contactToAdd], [(contact) => contact.name.toLowerCase()], ["asc"]));

      // remove from contact selected
      const newList = selectedContactsList.filter((x) => x._id !== _id);
      setSelectedContactsList(newList);
      validateContactsValue(newList);
   }

   function handleSelectContactChange(contactIds: (string | Contacts)[]) {
      // Add to contacts selected
      const contactsAux: Contacts[] = [];
      contactIds.forEach((id) => {
         if (typeof id === "string") {
            const contact = contactRef.current.find((x) => x._id === id);
            contact && contactsAux.push(contact);
         } else {
            const contact = contactRef.current.find((x) => x._id === id._id);
            contact && contactsAux.push(contact);
         }
      });

      setSelectedContactsList(contactsAux);

      // remove the contact selected from contactList
      const contactListAux = contactList.filter((x) => !contactsAux.includes(x));
      setContactList(contactListAux);
      validateContactsValue(contactsAux);
   }

   function handleLocalClose() {
      handleClose();
      setDenialNotesValue(denialNotes);
      setDenialCodeValue(denialCode);
      setErrorApprovedAmountValue("");
      setErrorContactsValue("");
   }

   //Loading Use Effect.
   React.useEffect(() => {
      if (!open) return;

      if (divisionId) {
         const divisionAssigned = _.filter(divisionState.Divisions, (x) => x._id.toString() === divisionId?.toString());

         if (divisionAssigned.length > 0) {
            contactRef.current = _.orderBy(
               _.filter(divisionAssigned[0].contacts, (x) => x.active === true),
               [(contact) => contact.name.toLowerCase()],
               ["asc"],
            );
         }
      } else {
         const companyAssigned = _.filter(companyState.Companies, (x) => x._id.toString() === companyId?.toString());

         if (companyAssigned.length > 0) {
            contactRef.current = _.orderBy(
               _.filter(companyAssigned[0].contacts, (x) => x.active === true),
               [(contact) => contact.name.toLowerCase()],
               ["asc"],
            );
         }
      }

      // Select contact by default
      const contactsSelected = contactRef.current.filter((x) => contacts.includes(x._id));
      const contactListAux = contactRef.current.filter((x) => !contacts.includes(x._id));

      setContactList(contactListAux);
      setSelectedContactsList(contactsSelected);
   }, [open]);

   React.useEffect(() => {
      setDenialNotesValue(denialNotes);
   }, [denialNotes]);

   const error = !!(errorContactsValue || errorDenialCodeValue);

   return (
      <ActivationBaseDialogHOC
         open={open}
         title="Activation Denial"
         primaryButtonTitle="SAVE CHANGES"
         secondaryButtonTitle="DISCARD CHANGES"
         isSaving={isSaving}
         disabled={error}
         handleClose={handleLocalClose}
         handleAction={handleSave}
      >
         <form>
            <FormControl variant="outlined" style={{ width: "100%" }} error={Boolean(errorDenialCodeValue)}>
               <InputLabel id="demo-simple-select-outlined-label">Denial Code</InputLabel>
               <Select
                  id="select-denial-codes"
                  label="Denial Code"
                  defaultValue=""
                  value={denialCodeValue}
                  onChange={(event) => {
                     setDenialCodeValue(event.target.value as string);
                     validateDenialCodeValue(event.target.value as string);
                  }}
               >
                  {denialCodes?.map((d) => {
                     return (
                        <MenuItem key={d._id} value={d.code}>
                           {d.code}
                        </MenuItem>
                     );
                  })}
               </Select>
               <FormHelperText>{errorDenialCodeValue}</FormHelperText>
            </FormControl>
            <TextField
               id="denial-notes"
               label="Notes"
               multiline
               minRows={7}
               maxRows={7}
               name="denialNotes"
               onChange={(event) => {
                  setDenialNotesValue(event.target.value);
               }}
               value={denialNotesValue}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
            />
            <FormControl
               variant="outlined"
               style={{ marginTop: "0.75rem", width: "100%" }}
               error={Boolean(errorContactsValue)}
            >
               <InputLabel>Contact</InputLabel>
               <Select
                  id="select-contact"
                  label="Contact"
                  multiple
                  MenuProps={{
                     getContentAnchorEl: null,
                     transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                     },
                  }}
                  value={selectedContactsList}
                  onChange={(event) => handleSelectContactChange(event.target.value as string[])}
                  disabled={contactList.length <= 0}
               >
                  {contactList.map((contact) => {
                     return (
                        <MenuItem key={contact._id} value={contact._id}>
                           {contact.name}
                        </MenuItem>
                     );
                  })}
               </Select>
               <FormHelperText>{errorContactsValue}</FormHelperText>
            </FormControl>
            {selectedContactsList.map((contact) => {
               return (
                  <Chip
                     key={contact._id}
                     label={contact.name}
                     variant="outlined"
                     color="primary"
                     onDelete={() => handleChipDelete(contact._id)}
                     style={{ marginTop: ".5rem", marginRight: ".5rem", fontSize: "0.75rem" }}
                  />
               );
            })}
         </form>
      </ActivationBaseDialogHOC>
   );
};
