type ActionMap<M extends { [index: string]: any }> = {
   [Key in keyof M]: M[Key] extends undefined
      ? {
           type: Key;
        }
      : {
           type: Key;
           payload: M[Key];
        };
};

export enum Types {
   Open = "OPEN_DRAWER",
   Close = "CLOSE_DRAWER",
}

type DrawerPayload = {
   [Types.Open]: {
      DrawerOpen: boolean;
   };
   [Types.Close]: {
      DrawerOpen: boolean;
   };
};

export type DrawerActions = ActionMap<DrawerPayload>[keyof ActionMap<DrawerPayload>];

export const drawerReducer = (state: boolean, action: DrawerActions) => {
   switch (action.type) {
      case Types.Open:
         return true;
      case Types.Close:
         return false;
      default:
         return state;
   }
};
