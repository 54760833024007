import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { green, red } from "@material-ui/core/colors";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { Applications } from "../../../types/Applications";
import "./AdoptSign.css";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
   createStyles({
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      buttonProgress: {
         color: green[500],
      },
      checkIcon: {
         color: red[500],
      },
      backButton: {
         "&.MuiButtonBase-root": {
            color: "#043F60",
            backgroundColor: "#F8F9FD",
            "&:hover": {
               backgroundColor: "#dfe1e8",
            },
         },
      },
   }),
);

export const AdoptSign: React.FC<any> = (props: {
   open: boolean;
   handleClose: () => void;
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   division: string;
}): JSX.Element => {
   const classes = useStyles();
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [signature, setSignature] = useState<string>("");
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   async function adoptAndSign() {
      try {
         if (signature === "") {
            openAlertMessage("You must enter in your signature.", "warning");
            return;
         }

         const newApplication = { ...props.application };

         newApplication.signature = signature;

         props.setApplication(newApplication);
         props.handleClose();
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent>
            <Card
               style={{
                  backgroundColor: "#EEEEEE",
                  width: "100%",
                  justifyContent: "center",
                  minHeight: "100px",
               }}
               elevation={0}
            >
               <Grid container direction="row" alignItems="center">
                  <Grid item xs={12}>
                     <Typography style={{ fontSize: "11px", textAlign: "center", color: "#A0A0A0" }}>
                        {"Preview"}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container direction="row" alignItems="center">
                  <Grid item xs={12}>
                     <Typography style={{ fontFamily: '"Brush Script MT"', fontSize: "36px", textAlign: "center" }}>
                        {signature ? signature : ""}
                     </Typography>
                  </Grid>
               </Grid>
            </Card>
            <Grid container direction={"row"} item xs={12} alignItems="center">
               <Grid item xs={12}>
                  <TextField
                     id="ci-signature"
                     autoComplete="off"
                     value={signature}
                     onChange={(event) => setSignature(event.target.value)}
                     label="Full Name"
                     name="SignatureFieldName"
                     InputProps={{
                        autoComplete: "SignatureFieldName",
                     }}
                     variant="outlined"
                     fullWidth
                     style={{ width: !mobileScreen ? "100%" : "30rem" }}
                  />
               </Grid>
            </Grid>
            <Grid container direction={"row"} item xs={12}>
               <Grid item xs={12}>
                  <p>
                     By clicking adopt and sign, I agree that the signature and initials will be an electronic
                     representation of my signature and initials for all purposes when used on documents. I agree it is
                     legal and binding; just the same as a pen-and-paper signature or initial. I attest all information
                     completed is accurate and honest to the best of my knowledge.
                  </p>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button
               onClick={props.handleClose}
               variant="contained"
               style={{ marginRight: "1rem" }}
               className={clsx(classes.backButton)}
               startIcon={<ArrowBackIcon />}
            >
               Back
            </Button>
            <Button onClick={adoptAndSign} variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>
               Adopt And Sign
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default AdoptSign;
