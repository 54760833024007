import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import App from "./App";
import "./index.css";
import { ActivationPage } from "./pages/Activation/ActivationPage";
import Apply from "./pages/Apply/Apply";
import DocuSignComplete from "./pages/Apply/DocuSign/DocuSignComplete";
import ApplyUWLWDS from "./pages/ApplyUWLWDS/ApplyUWLWDS";
import DocuSignCompleteUWL from "./pages/ApplyUWLWDS/DocuSign/DocuSignCompleteUWL";
import DocuSignCompleteWDS from "./pages/ApplyUWLWDS/DocuSign/DocuSignCompleteWDS";
import ErrorHandler from "./pages/ErrorHandler/ErrorHandler";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Password/ForgotPassword";
import PasswordReset from "./pages/Password/PasswordReset";
import Register from "./pages/Register/Register";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./stores/AppContextProvider";
import { theme, themeActivationUWL_WDS, themeBristol, themeMiddleBay, themeUWL_WDS } from "./styles/themes";

const chooseThemes = (siteUrl: string) => {
   // For Apply page
   if (siteUrl.toUpperCase().includes("/APPLY/BRISTOL")) return themeBristol;
   if (siteUrl.toUpperCase().includes("/APPLY/MIDDLEBAY")) return themeMiddleBay;
   if (siteUrl.toUpperCase().includes("/APPLY/UWL")) return themeUWL_WDS;
   if (siteUrl.toUpperCase().includes("/APPLY/WDS")) return themeUWL_WDS;
   // For Activation page
   if (siteUrl.toUpperCase().includes("/ACTIVATION/BRISTOL")) return themeBristol;
   if (siteUrl.toUpperCase().includes("/ACTIVATION/MIDDLEBAY")) return themeMiddleBay;
   if (siteUrl.toUpperCase().includes("/ACTIVATION/UWL")) return themeActivationUWL_WDS;
   if (siteUrl.toUpperCase().includes("/ACTIVATION/WDS")) return themeActivationUWL_WDS;
   return theme;
};

const siteUrl = window.location.href;

const Routes = () => {
   React.useRef();
   return (
      <Router>
         <Switch>
            <Route path="/Login/:loggedOut?" exact component={Login} />
            <Route path="/DocuSignComplete/UWL/:envelopeID" exact component={DocuSignCompleteUWL} />
            <Route path="/DocuSignComplete/WDS/:envelopeID" exact component={DocuSignCompleteWDS} />
            <Route path="/DocuSignComplete/:division/:envelopeID" exact component={DocuSignComplete} />
            <Route path="/Apply/UWL/:email/:applicationID" exact component={ApplyUWLWDS} />
            <Route path="/Apply/WDS/:email/:applicationID" exact component={ApplyUWLWDS} />
            <Route path="/Apply/:division/:email/:applicationID" exact component={Apply} />
            <Route path="/Activation/:division/:email/:applicationID" exact component={ActivationPage} />
            <Route path="/Register/:_id/:token" exact component={Register} />
            <Route path="/ForgotPassword" exact component={ForgotPassword} />
            <Route path="/PasswordReset/:_id?/:invite?" exact component={PasswordReset} />
            <Route path="/" component={App} />
         </Switch>
      </Router>
   );
};

ReactDOM.render(
   <ErrorBoundary FallbackComponent={ErrorHandler}>
      <AppContextProvider>
         <ThemeProvider theme={chooseThemes(siteUrl.toUpperCase())}>
            <CssBaseline />
            <Routes />
         </ThemeProvider>
      </AppContextProvider>
   </ErrorBoundary>,

   document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
