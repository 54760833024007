import React from "react";

import {
   Box,
   Button,
   Card,
   Checkbox,
   CircularProgress,
   FormControl,
   FormControlLabel,
   FormHelperText,
   Grid,
   InputAdornment,
   TextField,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CreateIcon from "@material-ui/icons/Create";

import AdoptSign from "../../../pages/ApplyUWLWDS/ESign/AdoptSign";
import { ActivationSignAndCompleteFormDTO } from "../../../types/Activation/ActivationDTO";
import { DateFormatter } from "../../../utilities/DateFormatter";
import { headerSubTitle, termsAndConditions } from "../const";
import { useActivationStyles } from "../styles";
import { initialSignAndCompleteValues, signAndCompleteReducer } from "./reducer";

type Props = {
   handleSubmit: () => void;
   disabled: boolean;
   wasSubmit: boolean;
   changeFormState: (from: "billing" | "companyInformation" | "signAndComplete", isValid: boolean) => void;
   updateActivationObject: (obj: ActivationSignAndCompleteFormDTO) => void;
   division: keyof typeof headerSubTitle;
   saving: boolean;
};

function ActivationSignAndComplete({
   handleSubmit,
   disabled,
   wasSubmit,
   changeFormState,
   updateActivationObject,
   division,
   saving,
}: Props) {
   const [adoptAndSignModal, setAdoptAndSignModal] = React.useState(false);
   const [state, dispatch] = React.useReducer(signAndCompleteReducer, initialSignAndCompleteValues);
   const classes = useActivationStyles();

   const refFormState = React.useRef(false);

   /**
    * Handles the click event for the button.
    */
   const handleClick = () => {
      handleSubmit();
   };

   /**
    * Validate fields, return true if the form is valid
    */
   const validateForm = (): boolean => {
      if (state.Signature?.error) return false;
      if (state.SignatureDate?.error) return false;
      if (state.SignatureTitle?.error) return false;
      if (state.TermsAndConditions?.error) return false;
      return true;
   };

   React.useEffect(() => {
      // validate fields
      if (!wasSubmit) return;

      dispatch({ type: "forceValidation", payload: state });
   }, [wasSubmit]);

   React.useEffect(() => {
      const isValid = validateForm();

      // Create object
      const signAndCompleteInformation: ActivationSignAndCompleteFormDTO = {
         Signature: state.Signature?.value as string,
         SignatureDate: state.SignatureDate?.value as Date,
         SignatureTitle: state.SignatureTitle?.value as string,
         TermsAndConditions: state.TermsAndConditions?.value as boolean,
      };
      // update parent object
      updateActivationObject(signAndCompleteInformation);

      // sent to the parent component when the form change its state between valid and invalid
      if (isValid !== refFormState.current) {
         refFormState.current = isValid;
         changeFormState("signAndComplete", isValid);
      }
   }, [state]);

   return (
      <section className={classes.container}>
         <Box component={"h1"} className={classes.title}>
            Sign and Complete
         </Box>
         <hr />
         <p className={classes.labelText}>
            Upon signing and submitting this Customer Activation Form you agree to the <b>Terms & Conditions</b>{" "}
            provided below and understand the information provided may be used to evaluate credit and will be reviewed
            by one our customer onboarding personnel who will promptly contact you upon completion of the review.
         </p>
         <Card className={classes.note} elevation={0}>
            *** Customer Activation Form must be signed by a member of Company Management ***
         </Card>
         {/* Form section */}
         <section>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
               <Grid item md={8} xs={12}>
                  <TextField
                     id="activation-signature"
                     label="Signature"
                     variant="outlined"
                     name="SignatureFieldName"
                     onChange={(event) => event.preventDefault()}
                     value={state.Signature?.value || ""}
                     InputProps={{
                        style: { fontFamily: '"Brush Script MT"', fontSize: "22px" },
                        endAdornment: (
                           <InputAdornment position="end">
                              <Button
                                 style={{
                                    backgroundColor: "primary",
                                    marginRight: "-.90rem",
                                    height: "3.5rem",
                                    fontSize: "15px",
                                 }}
                                 onClick={() => setAdoptAndSignModal(true)}
                                 variant="outlined"
                                 color="primary"
                              >
                                 {"Adopt Signature"}
                                 <CreateIcon style={{ marginLeft: "15px" }} />
                              </Button>
                           </InputAdornment>
                        ),
                        autoComplete: "off",
                     }}
                     helperText={state.Signature?.error && state.Signature?.message ? state.Signature.message : null}
                     error={!!(state.Signature?.error && state.Signature?.message)}
                  />
               </Grid>
               <Grid item md={4} xs={12}>
                  <TextField
                     required={true}
                     disabled={true}
                     id="signatureDate"
                     name="signatureDate"
                     label="Date"
                     variant="outlined"
                     value={state.SignatureDate ? DateFormatter(state.SignatureDate.value) : DateFormatter(new Date())}
                     helperText={state.Signature?.error && state.Signature?.message ? " " : null}
                  />
               </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
               <Grid item md={6} xs={12}>
                  <TextField
                     disabled
                     required
                     id="printName"
                     name="printName"
                     label="Printed Name"
                     variant="outlined"
                     value={state.Signature?.value || ""}
                     helperText={state.SignatureTitle?.error && state.SignatureTitle?.message ? " " : null}
                  />
               </Grid>
               {/* Signature title */}
               <Grid item md={6} xs={12}>
                  <TextField
                     required={true}
                     id="signature-title"
                     label="Title"
                     name="signatureTitle"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "SignatureTitle", payload: event.target.value })}
                     value={state.SignatureTitle?.value}
                     helperText={
                        state.SignatureTitle?.error && state.SignatureTitle?.message
                           ? state.SignatureTitle.message
                           : null
                     }
                     error={!!(state.SignatureTitle?.error && state.SignatureTitle?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
            </Grid>
            <AdoptSign
               open={adoptAndSignModal}
               handleClose={() => setAdoptAndSignModal(false)}
               application={{}}
               setApplication={({ signature }: { signature: string }) => {
                  dispatch({ type: "Signature", payload: signature });
               }}
            />
         </section>
         <p className={classes.labelText}>Please look for follow up in the Contact Email inbox provided.</p>
         <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            pb={"16px"}
            gridRowGap={"14px"}
         >
            <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"} className={classes.labelText}>
               Please read and acknowledge{" "}
               <span style={{ fontWeight: 600, marginLeft: "4px" }}> {headerSubTitle[division]} </span>
               <span style={{ marginRight: "16px", marginLeft: "4px" }}>Terms & Conditions below. </span>
               <FormControl style={{ top: "12px" }} required error={!!state.TermsAndConditions?.error}>
                  <FormControlLabel
                     color="primary"
                     className={classes.colorTheme}
                     control={
                        <Checkbox
                           color="primary"
                           checked={!!state.TermsAndConditions?.value}
                           onChange={(_: unknown, checked: boolean) =>
                              dispatch({ type: "TermsAndConditions", payload: checked })
                           }
                           name="termsAndContidions"
                        />
                     }
                     label="Acknowledge Terms & Conditions"
                  />
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem", marginTop: "0px" }}>
                     {state.TermsAndConditions?.error ? state?.TermsAndConditions.message : " "}
                  </FormHelperText>
               </FormControl>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
               <Button
                  variant="contained"
                  color="primary"
                  disabled={disabled || saving}
                  style={{
                     fontSize: "14px",
                     fontWeight: 500,
                  }}
                  size="large"
                  onClick={handleClick}
                  endIcon={
                     saving ? <CircularProgress size={20} className={classes.buttonProgress} /> : <ArrowForwardIcon />
                  }
               >
                  SUBMIT ACTIVATION FORM
               </Button>
               {disabled ? (
                  <div
                     style={{
                        color: "#F54D41",
                        marginLeft: ".75rem",
                        fontSize: "16px",
                        position: "relative",
                        top: "11px",
                     }}
                  >
                     Please fill out all required fields to continue
                  </div>
               ) : null}
            </Box>
         </Box>
         <hr />
         <p style={{ fontSize: "14px" }}>
            Terms & Conditions of{" "}
            <Box component={"span"} color={"primary"}>
               {" "}
               {headerSubTitle[division]}
            </Box>{" "}
            <a href={termsAndConditions[division]} target="_blank" rel="noreferrer">
               {termsAndConditions[division]}
            </a>
         </p>
      </section>
   );
}

export { ActivationSignAndComplete };
