import { createStyles, makeStyles } from "@material-ui/core";

export const useActivationSubmittedStyles = makeStyles((theme) =>
   createStyles({
      container: {
         borderRadius: "8px",
         padding: "36px",
         color: "#414141",
      },
      imgContainer: {
         display: "flex",
         justifyContent: "center",
      },
      titleText: {
         fontSize: "32px",
         display: "flex",
         justifyContent: "center",
      },
      subTitleText: {
         fontSize: "24px",
         display: "flex",
         justifyContent: "center",
      },
      paragrapText: {
         fontSize: "16px",
      },
      sectionButton: {
         marginTop: "30px",
         display: "flex",
         justifyContent: "end",
         "& .MuiButton-contained": {
            borderRadius: "100px",
         },
      },
   }),
);
