import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
// styles
import { Collapse, Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { getInviteCustomerActivation, setInviteCustomerActivation } from "../../api/CustomerActivationApi";
import { ActivationBankingInformation } from "../../components/Activation/BankingInformation/ActivationBankingInformation";
import { ActivationBillingInformation } from "../../components/Activation/BillingInformation/ActivationBillingInformation";
import { ActivationCpgBillingInformation } from "../../components/Activation/BillingInformation/CpgBriMbt/ActivationCpgBillingInformation";
import { ActivationCompanyInformation } from "../../components/Activation/CompanyInformation/ActivationCompanyInformation";
import ActivationExpired from "../../components/Activation/Expiration/Expiration";
import { ActivationHeader } from "../../components/Activation/Header/ActivationHeader";
import { ActivationSignAndComplete } from "../../components/Activation/SignAndComplete/SignAndComplete";
import { ActivationSignAndCompleteUWL_WDS } from "../../components/Activation/SignAndComplete/UWL_WDS/SignAndCompleteUWL_WDS";
import ConfirmSubmit from "../../components/Activation/Submit/ConfirmSubmit";
import { CPG_Bristol_MiddleBay, WDS_UWL, emailReadIcon, headerSubTitle } from "../../components/Activation/const";
import CheckAnimation from "../../styles/CheckAnimation";
import { ActivationModel } from "../../types/Activation/Activation";
import {
   ActivationBankingInformationDTO,
   ActivationBillingInformationFormDTO,
   ActivationCompanyInformationFormDTO,
   ActivationCpgBillingInformationFormDTO,
   ActivationModelDTO,
   ActivationSignAndCompleteFormDTO,
} from "../../types/Activation/ActivationDTO";
import { CustomerActivation } from "../../types/CustomerActivation";
import styles from "./ActivationPage.module.css";

// create a react function component
function ActivationPage(props: RouteComponentProps<{ division: string; email: string; applicationID: string }>) {
   const { division, applicationID } = props.match.params;
   const [wasSubmit, setWasSubmit] = React.useState(false);
   const [isValid, setIsValid] = React.useState(false);
   const [isSaving, setIsSaving] = React.useState(false);
   const [applicationNumber, setApplicationNumber] = useState("");
   const [alertMessage, setAlertMessage] = useState("");
   const [tokenExpired, setTokenExpired] = useState(false);
   const [loading, setLoading] = useState(false);

   const refForm = React.useRef<Partial<ActivationModelDTO>>({});
   const refActivationObj = React.useRef<Partial<ActivationModel>>({});

   const refIsValidCompanyInformation = React.useRef(false);
   const refIsValidBillingInformation = React.useRef(false);
   const refIsValidBillingCPGInformation = React.useRef(false);
   const refIsValidBakingCPGInformation = React.useRef(true); // this section is optional
   const refIsValidSignAndComplete = React.useRef(false);

   const handleSubmit = async () => {
      setWasSubmit(true);

      if (!isValid || !Object.keys(refForm.current).length) return;
      setIsSaving(true);
      const res = await setInviteCustomerActivation(refForm.current as CustomerActivation);
      if (res.success) {
         setApplicationNumber(res.data?.applicationNumber);
      } else {
         setAlertMessage(res.message);
      }
      setIsSaving(false);
   };

   /**
    * Set if the form is valid or invalid
    * @param from
    * @param isValid
    */
   const changeFormState = (
      from: "billing" | "companyInformation" | "signAndComplete" | "billingCpg" | "banking",
      isValid: boolean,
   ) => {
      let areValid = false;

      if (from === "billing") {
         refIsValidBillingInformation.current = isValid;
      }
      if (from === "companyInformation") {
         refIsValidCompanyInformation.current = isValid;
      }
      if (from === "signAndComplete") {
         refIsValidSignAndComplete.current = isValid;
      }

      if (from === "billingCpg") {
         refIsValidBillingCPGInformation.current = isValid;
      }
      if (from === "banking") {
         refIsValidBakingCPGInformation.current = isValid;
      }

      // validate form type
      if (CPG_Bristol_MiddleBay.includes(division)) {
         areValid =
            refIsValidBillingCPGInformation.current &&
            refIsValidCompanyInformation.current &&
            refIsValidSignAndComplete.current &&
            refIsValidBakingCPGInformation.current;
      }

      if (WDS_UWL.includes(division)) {
         areValid =
            refIsValidBillingInformation.current &&
            refIsValidCompanyInformation.current &&
            refIsValidSignAndComplete.current;
      }

      setIsValid(areValid);
   };

   const handleUpdateActivationObject = (
      obj:
         | ActivationCompanyInformationFormDTO
         | ActivationBillingInformationFormDTO
         | ActivationSignAndCompleteFormDTO
         | ActivationCpgBillingInformationFormDTO
         | ActivationBankingInformationDTO,
   ) => {
      let form: Partial<ActivationModelDTO> = {};
      if (refForm.current) {
         form = { ...refForm.current };
      }

      // Fill company information ids in
      if (obj instanceof Object && "DUNS" in obj) {
         obj.Address._id = refActivationObj.current.address?._id;
      }

      // Fill billing information ids in
      if (obj instanceof Object && "Chb" in obj) {
         obj.Method._id = refActivationObj.current.method?._id;
         obj.Chb._id = refActivationObj.current.chb?._id;
      }

      // Fill banking information ids in
      if (obj instanceof Object && "BankInfo" in obj) {
         obj.BankInfo._id = refActivationObj.current.bankInfo?._id;
      }

      // Fill billingCPG information ids in
      if (obj instanceof Object && "Documents" in obj) {
         obj.Method._id = refActivationObj.current.method?._id;
      }

      const activationForm: Partial<ActivationModelDTO> = {
         ...form,
         ...obj,
         _id: applicationID,
         Email: refActivationObj.current.email,
         AddDate: refActivationObj.current?.addDate,
         Company_id: refActivationObj.current?.company_id,
         Contacts: refActivationObj.current?.contacts,
         Status: "NEW",
         SignatureDate: new Date(),
         PaymentTerms: "",
         Division_id: refActivationObj.current.division_id,
         DocuSign: {
            _id: refActivationObj.current?.docuSign?._id,
            envelope_id: refActivationObj.current?.docuSign?.envelope_id as string,
            completed: refActivationObj.current?.docuSign?.completed as boolean,
         },
      };

      refForm.current = activationForm;
   };

   const getActivation = async () => {
      setLoading(true);
      const resp = await getInviteCustomerActivation(applicationID);
      if (resp.success) {
         refActivationObj.current = resp.data;
         setApplicationNumber(resp.data.applicationNumber);
      } else {
         setTokenExpired(true);
      }
      setLoading(false);
   };

   React.useEffect(() => {
      getActivation();
   }, []);

   if (loading) {
      return <CheckAnimation />;
   }

   if (tokenExpired) {
      return <ActivationExpired division={division as keyof typeof headerSubTitle} />;
   }

   return (
      <>
         <Collapse in={!!alertMessage}>
            <Alert
               variant="filled"
               severity={"error"}
               style={{ fontSize: "16px" }}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <div className={styles.container}>
            <p className={styles.browserText}>For the best experience use Chrome, Edge, or Brave browsers</p>
            <div className={styles.section}>
               <ActivationHeader division={division as keyof typeof headerSubTitle} />
            </div>
            <div className={styles.section}>
               <ActivationCompanyInformation
                  isCPG_Bristol_MiddleBay={CPG_Bristol_MiddleBay.includes(division as keyof typeof headerSubTitle)}
                  wasSubmit={wasSubmit}
                  changeFormState={changeFormState}
                  updateActivationObject={handleUpdateActivationObject}
               />
            </div>
            <div className={styles.section}>
               {CPG_Bristol_MiddleBay.includes(division) ? (
                  <ActivationCpgBillingInformation
                     wasSubmit={wasSubmit}
                     changeFormState={changeFormState}
                     updateActivationObject={handleUpdateActivationObject}
                  />
               ) : (
                  <ActivationBillingInformation
                     wasSubmit={wasSubmit}
                     changeFormState={changeFormState}
                     updateActivationObject={handleUpdateActivationObject}
                  />
               )}
            </div>
            {CPG_Bristol_MiddleBay.includes(division) ? (
               <div className={styles.section}>
                  <ActivationBankingInformation
                     wasSubmit={wasSubmit}
                     changeFormState={changeFormState}
                     updateActivationObject={handleUpdateActivationObject}
                  />
               </div>
            ) : null}
            <div className={styles.section}>
               {WDS_UWL.concat(["MiddleBay"]).includes(division) ? (
                  <ActivationSignAndCompleteUWL_WDS
                     division={division as keyof typeof headerSubTitle}
                     wasSubmit={wasSubmit}
                     disabled={!isValid && wasSubmit}
                     handleSubmit={handleSubmit}
                     changeFormState={changeFormState}
                     updateActivationObject={handleUpdateActivationObject}
                     saving={isSaving}
                  />
               ) : (
                  <ActivationSignAndComplete
                     division={division as keyof typeof headerSubTitle}
                     wasSubmit={wasSubmit}
                     disabled={!isValid && wasSubmit}
                     handleSubmit={handleSubmit}
                     changeFormState={changeFormState}
                     updateActivationObject={handleUpdateActivationObject}
                     saving={isSaving}
                  />
               )}
            </div>
            <Dialog open={!!applicationNumber}>
               <ConfirmSubmit applicationNumber={applicationNumber} division={division as keyof typeof emailReadIcon} />
            </Dialog>
         </div>
      </>
   );
}

export { ActivationPage };
