import {
   Box,
   Button,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton,
   Typography,
   useMediaQuery,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { theme } from "../../../styles/themes";
import { useActivationDialogStyles } from "./styles";

interface Props {
   open: boolean;
   title: string;
   primaryButtonTitle: string;
   secondaryButtonTitle: string;
   isSaving: boolean;
   disabled?: boolean;
   handleClose: () => void;
   handleAction: () => void;
   children: React.ReactNode;
}

export const ActivationBaseDialogHOC: React.FC<Props> = ({
   open,
   title,
   primaryButtonTitle,
   secondaryButtonTitle,
   isSaving,
   disabled = false,
   handleClose,
   handleAction,
   children,
}) => {
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const classes = useActivationDialogStyles();

   const handleSave = async () => {
      handleAction();
   };

   const handleCloseDialog = (_: unknown, reason: string) => {
      if (reason === "backdropClick") return;

      handleClose();
   };

   return (
      <Dialog
         open={open}
         onClose={handleCloseDialog}
         maxWidth={"sm"}
         fullScreen={mobileScreen}
         className={classes.container}
      >
         <Box>
            <DialogTitle style={{ padding: "30px 30px 0px 30px" }}>
               <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" color="primary">
                     {title}
                  </Typography>
                  <IconButton style={{ marginLeft: "35px" }} aria-label="close" color="primary" onClick={handleClose}>
                     <CloseIcon />
                  </IconButton>
               </div>
            </DialogTitle>

            <DialogContent style={{ padding: "0px 30px", marginTop: "16px" }}>
               <Box style={{ marginTop: "16px" }}>{children}</Box>
            </DialogContent>

            <DialogActions
               style={{
                  padding: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "16px",
               }}
            >
               <Button
                  onClick={handleClose}
                  color="primary"
                  variant="text"
                  startIcon={<CloseIcon />}
                  style={{ borderRadius: "100px", fontWeight: 600 }}
               >
                  {secondaryButtonTitle}
               </Button>
               <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={isSaving || disabled}
                  startIcon={
                     isSaving ? (
                        <CircularProgress style={{ color: "#FFF", width: "20px", height: "20px" }} />
                     ) : (
                        <CheckIcon />
                     )
                  }
                  style={{ borderRadius: "100px", fontWeight: 600 }}
               >
                  {primaryButtonTitle}
               </Button>
            </DialogActions>
         </Box>
      </Dialog>
   );
};
