export interface CustomerActivation {
   _id: string;
   applicationNumber: string;
   status: Status;
   companyCode: string;
   email: string;
   contacts: string[];
   companyName: string;
   companyContact: string;
   companyEstablishedDate: Date | null;
   companyEmail: string;
   companyPhone: string;
   companyWebsite: string;
   duns: string;
   fedTaxId: string;
   ctpatNumber: string;
   address: Address;
   accountsPayableName: string;
   accountsPayableEmail: string;
   method: Method;
   documents: string[];
   paymentMethod: string;
   chb: Chb;
   bankInfo: BankInfo;
   amountReq: number;
   approvedAmount: number;
   paymentTerms: string;
   approvedBy_id: null | string;
   company_id: string;
   division_id: null | string;
   notifiedBy_id: null | string;
   notifiedDate: Date | null;
   docuSign: DocuSign;
   approvedOrDeniedDate: Date | null;
   denialCode: string;
   applicationOpened: boolean;
   signature: string;
   signatureDate: Date | null;
   signatureTitle: string;
   notes: string;
   addDate: Date;
   deleteDate: Date | null;
}

export interface Address {
   _id: string;
   address1: string;
   address2: string;
   city: string;
   state: string;
   zip: string;
   country: string;
}

export interface BankInfo {
   _id: string;
   bankName: string;
   principalName: string;
   accountNumber: string;
   contact: string;
   phone: string;
   address: string;
   city: string;
   state: string;
   zip: string;
   country: string;
}

export interface Chb {
   _id: string;
   uwlHandleChb: boolean | null;
   achImporter: boolean;
   companyName: string;
   contact: string;
   address1: string;
   address2: string;
   city: string;
   state: string;
   zip: string;
   country: string;
   email: string;
   phone: string;
}

export interface DocuSign {
   _id: string;
   envelope_id: string;
   completed: boolean;
}

export interface Method {
   _id: string;
   code: string;
   value: string;
}

export enum Status {
   Sent = "SENT",
   New = "NEW",
   Pending = "PENDING",
   Approved = "APPROVED",
   Denied = "DENIED",
   Blank = "",
}
