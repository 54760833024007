export function DateFormatter(value: any) {
   try {
      const dateString = value;
      const newDate = new Date(dateString);
      const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
      const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
      const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
      return month + "/" + day + "/" + year;
   } catch {
      return "00/00/0000";
   }
}
