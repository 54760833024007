import {
   ActivationFormFieldError,
   ActivationFormProperties,
   ActivationModelForm,
   ActivationSignAndCompleteFormDTO,
   ActivationSignAndCompleteType,
} from "../../../types/Activation/ActivationDTO";
import { ValidationBuilder } from "../../../utilities/ValidationTools";

type FormProperties = ActivationFormProperties<ActivationSignAndCompleteType>;

export function signAndCompleteReducer(
   state: Partial<ActivationModelForm>,
   action: { type: ActivationSignAndCompleteType | "forceValidation"; payload: unknown },
): Partial<ActivationModelForm> {
   const Validation = new ValidationBuilder();
   switch (action.type) {
      case "Signature":
         const signatureErrorMessage = Validation.validateNotEmptyString(
            "Please enter a signature",
            action.payload as string,
         ).getMessageError();

         return {
            ...state,
            Signature: {
               value: action.payload as string,
               error: !!signatureErrorMessage,
               message: signatureErrorMessage,
            },
         };
      case "SignatureDate":
         const dateErrorMessage = Validation.validateDateFormat(action.payload as Date).getMessageError();
         return {
            ...state,
            SignatureDate: {
               value: action.payload as string,
               error: !!dateErrorMessage,
               message: dateErrorMessage,
            },
         };

      case "SignatureTitle":
         const titleErrorMessage = Validation.validateNotEmptyString(
            "Please enter a signature title",
            action.payload as string,
         ).getMessageError();
         return {
            ...state,
            SignatureTitle: {
               value: action.payload as string,
               error: !!titleErrorMessage,
               message: titleErrorMessage,
            },
         };

      case "TermsAndConditions": {
         let termsAndConditionsErrorMessage = "";
         if (!action.payload) {
            termsAndConditionsErrorMessage = "Please accept the terms and conditions";
         }
         return {
            ...state,
            TermsAndConditions: {
               value: action.payload as boolean,
               error: !!termsAndConditionsErrorMessage,
               message: termsAndConditionsErrorMessage,
            },
         };
      }

      case "forceValidation":
         const properties: FormProperties = { ...defaultValues };

         // filling the properties in.
         for (const field of Object.keys(
            action.payload as ActivationSignAndCompleteFormDTO,
         ) as (keyof FormProperties)[]) {
            properties[field] = (action.payload as FormProperties)[field]?.value
               ? (action.payload as FormProperties)[field]
               : defaultValues[field];
         }

         return {
            ...properties,
         };
      default:
         throw "Invalid action";
   }
}

const defaultValues: Record<ActivationSignAndCompleteType, ActivationFormFieldError<unknown>> = {
   Signature: {
      value: "",
      error: true,
      message: "Please enter a signature",
   },
   SignatureTitle: {
      value: "",
      error: true,
      message: "Please enter a signature title",
   },
   SignatureDate: {
      value: new Date(),
      error: false,
   },
   TermsAndConditions: {
      value: false,
      error: true,
      message: "Please accept the terms and conditions",
   },
};

export const initialSignAndCompleteValues: Record<ActivationSignAndCompleteType, ActivationFormFieldError<unknown>> = {
   Signature: {
      value: "",
      error: true,
   },
   SignatureTitle: {
      value: "",
      error: true,
   },
   SignatureDate: {
      value: new Date(),
      error: false,
   },
   TermsAndConditions: {
      value: false,
      error: true,
   },
};
