import { Status } from "../pages/Activation/ActivationView";
import { CustomerActivation } from "../types/CustomerActivation";
import { ApiRequest } from "../types/Requests/ApiRequest";
import { ApiResponse } from "../types/Responses/ApiResponse";
import * as CookieTools from "../utilities/CookieTools";
import { HandleError, HandleResponse } from "./ApiUtilities";

interface getCustomerActivationListArgs {
   filter: string;
   status: Status;
   page: number;
   company: string;
   startDate: Date | null;
   endDate: Date | null;
}

export enum SetType {
   Denied = "DENIED",
   Approved = "APPROVED",
   Blank = "",
}

export const sendInvite = async (customerActivation: CustomerActivation): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/sendInvite`,
      method: "POST",
      body: JSON.stringify(customerActivation),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getInviteCustomerActivation = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/getInvite`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
         "cache-control": "no-cache",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setInviteCustomerActivation = async (customerActivation: CustomerActivation): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/setInvite`,
      method: "POST",
      body: JSON.stringify(customerActivation),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getInviteCustomerActivationPdf = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/getInvitePdf`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/pdf",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getCustomerActivationList = async ({
   filter = "",
   status = "SENT",
   page = 1,
   company = "",
   startDate = null,
   endDate = null,
}: getCustomerActivationListArgs): Promise<ApiResponse> => {
   // initialize response
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   // get selected companies and divisions from cookies
   const selectedCompany = CookieTools.getCookie("selected-company");
   const selectedDivision = CookieTools.getCookie("selected-division");

   const request: ApiRequest = {
      address: `/api/CustomerActivation/getList`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         filter: filter,
         status: status,
         page: page,
         company: company,
         startDate: startDate ? startDate.toISOString() : "",
         endDate: endDate ? endDate.toISOString() : "",
         selectedCompanies: selectedCompany,
         selectedDivisions: selectedDivision,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getCustomerActivationById = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/getById`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getCustomerActivation = async (customerActivation: CustomerActivation): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/Get`,
      method: "POST",
      body: JSON.stringify(customerActivation),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setCustomerActivation = async (
   customerActivation: CustomerActivation,
   setType: SetType,
): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/set`,
      method: "POST",
      body: JSON.stringify(customerActivation),
      headers: {
         "Content-Type": "application/json",
         setType: setType,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getCustomerActivationPdf = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/getPdf`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/pdf",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const deleteCustomerActivation = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/delete`,
      method: "DELETE",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setActivationCopy = async (
   id: string,
   companyId: string,
   divisionId: string,
   email: string,
   contacts: string[],
): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/CustomerActivation/setActivationCopy`,
      method: "POST",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
         companyId: companyId,
         divisionId: divisionId,
         email: email,
         contacts: contacts,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};
