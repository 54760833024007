import { CircularProgress, List, ListItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { blue, green, grey, purple, red } from "@material-ui/core/colors";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { inviteUser as inviteUserApi } from "../../api/UserApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { UserContext } from "../../stores/Users/Context";
import { Companies } from "../../types/Companies";
import { Divisions } from "../../types/Divisions";
import { Users } from "../../types/Users";
import { validateEmailAddress as validateEmailAddressFunction } from "../../utilities/validateEmailAddress";
import "./UserNew.css";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
   createStyles({
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      buttonProgress: {
         color: green[500],
      },
      checkIcon: {
         color: red[500],
      },
      readOnlyButton: {
         color: "white",
         backgroundColor: grey[900],
         "&:hover": {
            backgroundColor: grey[800],
         },
      },
      adminButton: {
         color: "white",
         backgroundColor: blue[500],
         "&:hover": {
            backgroundColor: blue[600],
         },
      },
      superAdminButton: {
         color: "white",
         backgroundColor: purple[200],
         "&:hover": {
            backgroundColor: purple[300],
         },
      },
   }),
);

export const UserNew: React.FC<any> = (props: {
   open: boolean;
   handleClose: () => void;
   openAlertMessage: (alertMessage: string, alertType: "error" | "info" | "success" | "warning") => Promise<void>;
}): JSX.Element => {
   const classes = useStyles();
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [emailAddress, setEmailAddress] = useState<string>("");
   const [invalidEmail, setInvalidEmail] = useState<string>("Enter an Email");
   const [security, setSecurity] = useState<"Read Only" | "Admin" | "Super Admin">("Read Only");
   const [maximumApprovalAmount, setMaximumApprovalAmount] = useState<number>(0);
   const [department, setDepartment] = useState<string>("");
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [checked, setChecked] = React.useState<{ companies: string[]; divisions: string[] }>({
      companies: [],
      divisions: [],
   });
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);

   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   async function inviteUser() {
      try {
         if (invalidEmail !== "") {
            openAlertMessage(invalidEmail, "warning");
            return;
         }

         if (checked.companies.length <= 0) {
            openAlertMessage("You must select at least one company or division!", "warning");
            return;
         }

         if (security.toUpperCase() === "ADMIN" && checked.companies.length > 1) {
            openAlertMessage("Admins can only be assigned to one company!", "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to invite a user is denied!", "warning");
            return;
         }

         if (maximumApprovalAmount === 0 && security.toUpperCase() !== "READ ONLY") {
            openAlertMessage("You must enter a maximum approval amount!", "warning");
            return;
         }

         setButtonClicked(true);

         const newUser: Users = {
            _id: "",
            email: emailAddress,
            password: "",
            firstName: "",
            lastName: "",
            active: false,
            companies: checked.companies,
            divisions: checked.divisions,
            security: security,
            invite: "",
            maximumApprovalAmount: maximumApprovalAmount,
            department: department,
            addDate: new Date(),
            deleteDate: null,
         };

         const responseData = await inviteUserApi(newUser);

         if (responseData.success) {
            props.openAlertMessage("Invite has been sent out", "success");
            setButtonClicked(false);
            props.handleClose();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         handleError(err);
      }
   }

   //Loading Use Effect.
   useEffect(() => {
      setAlertMessage("");
      setAlertType("warning");
      setEmailAddress("");
      setMaximumApprovalAmount(0);
      setInvalidEmail("Enter an Email");
      setChecked({
         companies: [],
         divisions: [],
      });
      setButtonClicked(false);
   }, [props.open]);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         let newCompanyList: Divisions[] = [];

         companyState.Companies.forEach(function (company: Companies) {
            const divisionsAssigned = _.filter(divisionState.Divisions, (x) => x.company_id === company._id);

            if (divisionsAssigned.length > 0) {
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: company.denialCodes,
                     contacts: company.contacts,
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         newCompanyList = _.uniqBy(newCompanyList, "code");
         newCompanyList = _.orderBy(newCompanyList, ["code"], ["asc"]);

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState]);

   async function validateEmailAddress(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      try {
         const email = event.target.value ? event.target.value : "";

         const results = await validateEmailAddressFunction(email);

         setInvalidEmail(results);
         setEmailAddress(email);
      } catch (err) {
         handleError(err);
      }
   }

   function handleToggle(_id: string, company_id: string) {
      try {
         if (_id) {
            const currentIndex = checked.divisions.indexOf(_id);
            const newChecked = { ...checked };

            if (currentIndex === -1) {
               newChecked.divisions.push(_id);
            } else {
               newChecked.divisions.splice(currentIndex, 1);
            }

            const currentCompanyIndex = checked.companies.indexOf(company_id);

            if (currentCompanyIndex === -1) {
               newChecked.companies.push(company_id);
            }

            setChecked(newChecked);
         } else {
            const currentIndex = checked.companies.indexOf(company_id);
            const newChecked = { ...checked };

            if (currentIndex === -1) {
               newChecked.companies.push(company_id);
            } else {
               newChecked.companies.splice(currentIndex, 1);
            }

            setChecked(newChecked);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function handleMaximumApprovalAmount(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const onlyNums = event.target.value.replace(/[^0-9]/g, "");
      setMaximumApprovalAmount(onlyNums ? +onlyNums : 0);
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <DialogTitle className="UserNew-title">
            <Grid container direction={"row"} item xs={12} alignItems={"center"} justify={"center"}>
               <PersonAddIcon style={{ marginRight: "1rem" }} />
               {"Invite New User"}
            </Grid>
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent>
            <Grid
               container
               direction={"row"}
               item
               xs={12}
               style={{ placeContent: "space-evenly", margin: "auto", marginRight: "1.5rem" }}
            >
               <TextField
                  id="ci-email"
                  value={emailAddress}
                  onChange={(event) => validateEmailAddress(event)}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
                  InputProps={
                     invalidEmail
                        ? {
                             startAdornment: (
                                <Tooltip title={invalidEmail} arrow>
                                   <InputAdornment position="start">
                                      <CloseIcon className={classes.checkIcon} />
                                   </InputAdornment>
                                </Tooltip>
                             ),
                          }
                        : {
                             startAdornment: (
                                <Tooltip title={"Valid Email"} arrow>
                                   <InputAdornment position="start">
                                      <CheckIcon className={classes.buttonProgress} />
                                   </InputAdornment>
                                </Tooltip>
                             ),
                          }
                  }
               />
            </Grid>
            <Grid
               container
               direction={"row"}
               item
               xs={12}
               style={{ placeContent: "space-evenly", margin: "auto", marginRight: "1.5rem" }}
            >
               <TextField
                  id="ci-maximumApprovalAmount"
                  value={maximumApprovalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  onChange={(event) => handleMaximumApprovalAmount(event)}
                  label="Maximum Approval Amount"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
                  inputProps={{ maxLength: 9 }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachMoneyIcon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Grid>
            <Grid
               container
               direction={"row"}
               item
               xs={12}
               style={{ placeContent: "space-evenly", margin: "auto", marginRight: "1.5rem" }}
            >
               <TextField
                  id="ci-department"
                  value={department}
                  onChange={(event) => setDepartment(event.target.value)}
                  label="Department"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
               />
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Card className={"UserNew-cards"}>
                  <CardHeader
                     className={"UserNew-card-header2"}
                     style={{ textAlign: "center" }}
                     title={<Typography>Available Companies | Divisions</Typography>}
                  />
                  <List>
                     {companyList.map((company) => {
                        const labelId = `checkbox-list-label-${company._id}`;

                        return (
                           <ListItem
                              key={company._id}
                              dense
                              button
                              onClick={() => handleToggle(company._id, company.company_id)}
                           >
                              <ListItemIcon>
                                 <Checkbox
                                    edge="start"
                                    checked={
                                       checked.divisions.indexOf(company._id) !== -1 ||
                                       (checked.companies.indexOf(company.company_id) !== -1 && !company._id)
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    color="primary"
                                 />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={company.code + " | " + company.name} />
                           </ListItem>
                        );
                     })}
                  </List>
               </Card>
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Card className={"UserNew-cards"}>
                  <CardHeader
                     className={"UserNew-card-header2"}
                     style={{ textAlign: "center" }}
                     title={<Typography>Security</Typography>}
                  />
                  <Grid container direction="row" alignItems="center" justify="center" alignContent="space-around">
                     <ButtonGroup orientation="vertical" fullWidth>
                        <Button
                           className={security.toUpperCase() === "READ ONLY" ? classes.readOnlyButton : undefined}
                           variant="contained"
                           onClick={() => setSecurity("Read Only")}
                           style={{ borderRadius: "0em" }}
                        >
                           Read Only
                        </Button>
                        <Button
                           className={security.toUpperCase() === "ADMIN" ? classes.adminButton : undefined}
                           variant="contained"
                           onClick={() => setSecurity("Admin")}
                           style={{ borderRadius: "0em" }}
                        >
                           Admin
                        </Button>
                        <Button
                           className={security.toUpperCase() === "SUPER ADMIN" ? classes.superAdminButton : undefined}
                           variant="contained"
                           onClick={() => setSecurity("Super Admin")}
                           style={{ borderRadius: "0em" }}
                        >
                           Super Admin
                        </Button>
                     </ButtonGroup>
                  </Grid>
               </Card>
            </Grid>
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={inviteUser}
                  variant="contained"
                  color="primary"
                  classes={{
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <EmailIcon />}
                  Send Invite
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default UserNew;
