import { createStyles, makeStyles } from "@material-ui/core";

export const useBodyStyles = makeStyles((theme) =>
   createStyles({
      divider: {
         backgroundColor: "#BBBDC0",
         height: "1px",
         width: "100%",
      },
      primaryButton: {
         borderRadius: "100px",
         fontSize: "15px",
         fontStyle: "normal",
         fontWeight: 500,
         lineHeight: "normal",
         textTransform: "capitalize",
         color: "#5357a4",
      },
      saveButton: {
         borderRadius: "100px",
         fontSize: "15px",
         fontStyle: "normal",
         fontWeight: 500,
         lineHeight: "normal",
         textTransform: "capitalize",
         color: "#187B22",
         borderColor: "#187B22",
      },
      notesButton: {
         borderRadius: "100px",
         fontSize: "15px",
         fontStyle: "normal",
         fontWeight: 500,
         lineHeight: "normal",
         textTransform: "capitalize",
         color: "#FF7000",
         borderColor: "#FF7000",
      },
      deleteButton: {
         borderRadius: "100px",
         fontSize: "15px",
         fontStyle: "normal",
         fontWeight: 500,
         lineHeight: "normal",
         textTransform: "capitalize",
         color: "#CD0E0E",
         borderColor: "#CD0E0E",
      },
      chipContainer: {
         height: "100%",
         display: "flex",
         alignItems: "center",
         justifyContent: "left",
      },
      chipPending: {
         color: "#FF7000",
         borderColor: "#FF7000",
      },
      chipRed: {
         color: "#CD0E0E",
         borderColor: "#CD0E0E",
      },
      chipGreen: {
         color: "#187B22",
         borderColor: "#187B22",
      },
      dividerBody: {
         marginTop: "8px",
         backgroundColor: "#5357a4",
         height: "3px",
         width: "100%",
      },
   }),
);
