import DateFnsUtils from "@date-io/date-fns";
import React, { useMemo } from "react";
import InputMask from "react-input-mask";

import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { FormControl } from "@material-ui/core";
import { ActivationCompanyInformationFormDTO } from "../../../types/Activation/ActivationDTO";
import { countryList } from "../../../utilities/CountryList";
import { companyInformationReducer, initialCompanyValues } from "./reducer";
import { useValidationCompanyInformationStyles } from "./styles";

type Props = {
   changeFormState: (
      from: "billing" | "companyInformation",
      isValid: boolean,
      formType: "CPG-MBT-BRI" | "WDS-UWL",
   ) => void;
   updateActivationObject: (obj: ActivationCompanyInformationFormDTO) => void;
   wasSubmit: boolean;
   isCPG_Bristol_MiddleBay: boolean;
};

function ActivationCompanyInformation({
   changeFormState,
   wasSubmit,
   updateActivationObject,
   isCPG_Bristol_MiddleBay,
}: Props) {
   const initialCompanyValuesAux = useMemo(
      () => initialCompanyValues(isCPG_Bristol_MiddleBay),
      [isCPG_Bristol_MiddleBay],
   );
   const [state, dispatch] = React.useReducer(companyInformationReducer, initialCompanyValuesAux);
   const classes = useValidationCompanyInformationStyles();
   const refFormState = React.useRef(false);

   /**
    * Validate fields, return true if the form is valid
    */
   const validateForm = (): boolean => {
      if (state.CompanyName?.error) return false;
      if (state.CompanyContact?.error) return false;
      if (state.CompanyEstablishedDate?.error) return false;
      if (state.AccountsPayableEmail?.error) return false;
      if (state.CompanyWebsite?.error) return false;
      if (state.CompanyPhone?.error) return false;
      if (state.CtpatNumber?.error) return false;
      if (state.Address1?.error) return false;
      if (state.Address2?.error) return false;
      if (state.City?.error) return false;
      if (state.State?.error) return false;
      if (state.Zip?.error) return false;
      if (state.Country?.error) return false;
      if (state.FedTaxId?.error) return false;

      return true;
   };

   React.useEffect(() => {
      // validate fields
      if (!wasSubmit) return;

      dispatch({ type: "forceValidation", payload: state, UWL_WDS: !isCPG_Bristol_MiddleBay });
   }, [wasSubmit]);

   React.useEffect(() => {
      const isValid = validateForm();

      // Create object
      const companyInformation: ActivationCompanyInformationFormDTO = {
         CompanyName: state.CompanyName?.value as string,
         CompanyContact: state.CompanyContact?.value as string,
         CompanyEstablishedDate: state.CompanyEstablishedDate?.value as Date,
         CompanyPhone: state.CompanyPhone?.value as string,
         CompanyEmail: state.Email?.value as string,
         DUNS: state.DUNS?.value as string,
         FedTaxId: state.FedTaxId?.value as string,
         CompanyWebsite: state.CompanyWebsite?.value as string,
         CtpatNumber: state.CtpatNumber?.value as string,
         Address: {
            Address1: state.Address1?.value as string,
            Address2: state.Address2?.value as string,
            City: state.City?.value as string,
            State: state.State?.value as string,
            Zip: state.Zip?.value as string,
            Country: state.Country?.value as string,
         },
      };
      // update parent object
      updateActivationObject(companyInformation);

      // sent to the parent component when the form change its state between valid and invalid
      if (isValid !== refFormState.current) {
         refFormState.current = isValid;
         changeFormState("companyInformation", isValid, "WDS-UWL");
      }
   }, [state]);

   return (
      <section className={classes.container}>
         <h1 className={classes.title}>Company Information</h1>
         <hr />
         <form className={classes.section2}>
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
               {/* Company Name */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="company-name"
                     label="Company Name"
                     name="companyActivationName"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({
                           type: "CompanyName",
                           payload: event.target.value,
                           UWL_WDS: !isCPG_Bristol_MiddleBay,
                        })
                     }
                     value={state.CompanyName?.value}
                     helperText={state.CompanyName?.error && state.CompanyName?.message}
                     error={!!(state.CompanyName?.error && state.CompanyName?.message)}
                     inputProps={{ maxLength: 100 }}
                     required
                  />
               </Grid>

               {/* Company Contact */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="company-contact-name"
                     name="companyActivationContact"
                     label="Company Contact Name"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({
                           type: "CompanyContact",
                           payload: event.target.value,
                           UWL_WDS: !isCPG_Bristol_MiddleBay,
                        })
                     }
                     value={state.CompanyContact?.value}
                     helperText={
                        state.CompanyContact?.error && state.CompanyContact?.message
                           ? state.CompanyContact?.message
                           : null
                     }
                     error={!!(state.CompanyContact?.error && state.CompanyContact?.message)}
                  />
               </Grid>

               {/* Company Established Date */}
               <Grid item lg={3} md={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <KeyboardDatePicker
                        required
                        disableToolbar
                        autoComplete="companyEstablishedDate"
                        name="companyEstablishedDate"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        id="companyEstablishedDate"
                        label="Company Established Date"
                        onChange={(event) =>
                           dispatch({
                              type: "CompanyEstablishedDate",
                              payload: event,
                              UWL_WDS: !isCPG_Bristol_MiddleBay,
                           })
                        }
                        value={state.CompanyEstablishedDate?.value as Date}
                        helperText={
                           state.CompanyEstablishedDate?.error && state.CompanyEstablishedDate?.message
                              ? state.CompanyEstablishedDate?.message
                              : null
                        }
                        error={!!(state.CompanyEstablishedDate?.error && state.CompanyEstablishedDate?.message)}
                        KeyboardButtonProps={{
                           "aria-label": "change date",
                        }}
                     />
                  </MuiPickersUtilsProvider>
               </Grid>

               {/* Phone number */}
               <Grid item lg={3} md={6} xs={12}>
                  <InputMask
                     mask="999-999-9999"
                     value={state.CompanyPhone?.value as string}
                     onChange={(event) =>
                        dispatch({
                           type: "CompanyPhone",
                           payload: event.target.value,
                           UWL_WDS: !isCPG_Bristol_MiddleBay,
                        })
                     }
                  >
                     {() => (
                        <TextField
                           required
                           id={`company-phone`}
                           label="Phone Number"
                           name="companyPhone"
                           variant="outlined"
                           error={!!(state.CompanyPhone?.error && state.CompanyPhone?.message)}
                           inputProps={{
                              autoComplete: "phone",
                              form: {
                                 autoComplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {state.CompanyPhone?.error && state.CompanyPhone?.message ? state.CompanyPhone?.message : null}
                  </FormHelperText>
               </Grid>

               {/* Contact Email */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required
                     id="contact-email"
                     name="contactEmail"
                     label="Contact Email"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({ type: "Email", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.Email?.value}
                     helperText={state.Email?.error && state.Email?.message ? state.Email?.message : null}
                     error={!!(state.Email?.error && state.Email?.message)}
                  />
               </Grid>

               {/* Duns */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     id="duns"
                     name="duns"
                     label="D&B D-U-N-S"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({ type: "DUNS", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.DUNS?.value}
                     inputProps={{
                        autoComplete: "duns",
                        form: {
                           autoComplete: "off",
                        },
                     }}
                     helperText={state.DUNS?.error && state.DUNS?.message ? state.DUNS?.message : null}
                     error={!!(state.DUNS?.error && state.DUNS?.message)}
                  />
               </Grid>

               {/* Federal Tax ID */}
               <Grid item lg={isCPG_Bristol_MiddleBay ? 12 : 3} md={isCPG_Bristol_MiddleBay ? 12 : 3} xs={12}>
                  <InputMask
                     mask="99-9999999"
                     value={state.FedTaxId?.value as string}
                     onChange={(event) =>
                        dispatch({ type: "FedTaxId", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                  >
                     {() => (
                        <TextField
                           required={!isCPG_Bristol_MiddleBay}
                           id="federalID"
                           name="FedTaxId"
                           label="EIN/Federal Tax ID"
                           variant="outlined"
                           error={!!(state.FedTaxId?.error && state.FedTaxId?.message)}
                           inputProps={{
                              autoComplete: "federalTaxNumber",
                              form: {
                                 autoComplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {state.FedTaxId?.error && state.FedTaxId?.message ? state.FedTaxId.message : null}
                  </FormHelperText>
               </Grid>

               {/* C-TPAT */}
               {isCPG_Bristol_MiddleBay ? null : (
                  <Grid item lg={3} md={3} xs={12}>
                     <TextField
                        id="a-tpat-number"
                        name="C-TPAT"
                        label="C-TPAT Number"
                        variant="outlined"
                        onChange={(event) =>
                           dispatch({
                              type: "CtpatNumber",
                              payload: event.target.value,
                              UWL_WDS: !isCPG_Bristol_MiddleBay,
                           })
                        }
                        error={!!(state.CtpatNumber?.error && state.CtpatNumber?.message)}
                        inputProps={{
                           autoComplete: "CtpatNumber",
                           form: {
                              autoComplete: "off",
                           },
                        }}
                     />
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {state.CtpatNumber?.error && state.CtpatNumber?.message ? state.CtpatNumber.message : null}
                     </FormHelperText>
                  </Grid>
               )}

               {/* Website */}
               {isCPG_Bristol_MiddleBay ? null : (
                  <Grid item lg={6} md={6} xs={12}>
                     <TextField
                        id="website"
                        name="companyWebsite"
                        label="Website"
                        variant="outlined"
                        onChange={(event) =>
                           dispatch({
                              type: "CompanyWebsite",
                              payload: event.target.value,
                              UWL_WDS: !isCPG_Bristol_MiddleBay,
                           })
                        }
                        value={state.CompanyWebsite?.value}
                        helperText={state.CompanyWebsite?.error ? state.CompanyWebsite.message : null}
                        error={!!state.CompanyWebsite?.error}
                        inputProps={{ maxLength: 100 }}
                     />
                  </Grid>
               )}

               {/* Mailing Address 1 */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="mailingAddress"
                     name="address1"
                     label="Mailing Address"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({ type: "Address1", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.Address1?.value}
                     helperText={state.Address1?.error && state.Address1?.message ? state.Address1?.message : null}
                     error={!!(state.Address1?.error && state.Address1?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Mailing Address 2 */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="mailingAddress2"
                     name="address2"
                     label="Mailing Address 2"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({ type: "Address2", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.Address2?.value}
                     helperText={state.Address2?.error ? state.Address2?.message : null}
                     error={state.Address2?.error}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* City */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required
                     id="city"
                     name="city"
                     label="City"
                     variant="outlined"
                     InputProps={{
                        endAdornment: <LocationOnIcon style={{ color: "grey " }} />,
                     }}
                     onChange={(event) =>
                        dispatch({ type: "City", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.City?.value}
                     helperText={state.City?.error && state.City?.message ? state.City?.message : null}
                     error={!!(state.City?.error && state.City?.message)}
                  />
               </Grid>

               {/* State */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required
                     id="state"
                     name="state"
                     label="State"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({ type: "State", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.State?.value}
                     helperText={state.State?.error && state.State?.message ? state.State.message : null}
                     error={!!(state.State?.error && state.State?.message)}
                     inputProps={{ maxLength: 2 }}
                  />
               </Grid>

               {/* Zip Code */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required
                     id="zip"
                     name="zip"
                     label="Zip Code"
                     variant="outlined"
                     onChange={(event) =>
                        dispatch({ type: "Zip", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                     }
                     value={state.Zip?.value}
                     helperText={state.Zip?.error && state.Zip?.message ? state?.Zip.message : null}
                     error={!!(state.Zip?.error && state.Zip?.message)}
                     InputProps={{
                        endAdornment: <LocationOnIcon style={{ color: "grey " }} />,
                     }}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Country */}
               <Grid item lg={3} md={6} xs={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                     <InputLabel
                        id="lbl-country"
                        style={{ color: state.Country?.error && state.Country?.message ? "red" : "inherit" }}
                     >
                        Country *
                     </InputLabel>
                     <Select
                        required
                        labelId="test-country"
                        id="country"
                        name="country"
                        autoComplete="activationCompanyCountry"
                        className="country"
                        variant="outlined"
                        onChange={(event) =>
                           dispatch({ type: "Country", payload: event.target.value, UWL_WDS: !isCPG_Bristol_MiddleBay })
                        }
                        style={{ height: "56px" }}
                        value={state.Country?.value}
                        label="Country"
                        error={!!(state.Country?.error && state.Country?.message)}
                     >
                        {countryList.map((country, index) => {
                           return (
                              <MenuItem key={index} value={country.name}>
                                 {country.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {state.Country?.error && state.Country?.message ? state?.Country.message : null}
                     </FormHelperText>
                  </FormControl>
               </Grid>
            </Grid>
         </form>
      </section>
   );
}

export { ActivationCompanyInformation };
