import { ApiRequest } from "../types/Requests/ApiRequest";
import { ApiResponse } from "../types/Responses/ApiResponse";
import { TokenResponse } from "../types/Responses/TokenResponse";
import { Users } from "../types/Users";
import * as CookieTools from "../utilities/CookieTools";
import { HandleError, HandleErrorToken, HandleResponse, HandleResponseToken } from "./ApiUtilities";

export const getUserList = async (): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/getUserList`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getUserById = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/getUserById`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const validateInvite = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/validateInvite`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getUserByEmail = async (_email: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/getUserByEmail`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         _email: _email,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const getUser = async (user: Users): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/getUser`,
      method: "POST",
      body: JSON.stringify(user),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const setUser = async (user: Users): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/setUser`,
      method: "POST",
      body: JSON.stringify(user),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const removeUser = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/removeUser`,
      method: "DELETE",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const inviteUser = async (user: Users): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/inviteUser`,
      method: "POST",
      body: JSON.stringify(user),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const resendInvite = async (id: string): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/resendInvite`,
      method: "GET",
      body: null,
      headers: {
         "Content-Type": "application/json",
         id: id,
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const registerUser = async (user: Users): Promise<TokenResponse> => {
   let resp: TokenResponse = { token: "", refreshToken: "", success: false, message: "" };

   const request: ApiRequest = {
      address: `/api/User/registerUser`,
      method: "POST",
      body: JSON.stringify(user),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponseToken(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleErrorToken(response);
         return resp;
      });

   return resp;
};

export const passwordRequest = async (user: Users): Promise<TokenResponse> => {
   let resp: TokenResponse = { token: "", refreshToken: "", success: false, message: "" };

   const request: ApiRequest = {
      address: `/api/User/passwordRequest`,
      method: "POST",
      body: JSON.stringify(user),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponseToken(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleErrorToken(response);
         return resp;
      });

   return resp;
};

export const resetPassword = async (user: Users): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   const request: ApiRequest = {
      address: `/api/User/resetPassword`,
      method: "POST",
      body: JSON.stringify(user),
      headers: {
         "Content-Type": "application/json",
      },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const login = async (user: Users): Promise<TokenResponse> => {
   let resp: TokenResponse = { token: "", refreshToken: "", success: false, message: "" };
   const request: ApiRequest = {
      address: "/api/User/login",
      method: "POST",
      body: JSON.stringify(user),
      headers: { "Content-Type": "application/json" },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         resp = await HandleResponseToken(request, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleErrorToken(response);
         return resp;
      });

   return resp;
};

export const refreshToken = async (): Promise<TokenResponse> => {
   let resp: TokenResponse = { token: "", refreshToken: "", success: false, message: "" };
   const request: ApiRequest = {
      address: "/api/User/refreshToken",
      method: "POST",
      body: null,
      headers: { "Content-Type": "application/json" },
   };

   await fetch(request.address, {
      method: request.method,
      body: request.body,
      headers: request.headers,
   })
      .then(async (response) => {
         CookieTools.setCookie("ca-refreshing", "false");
         resp = await HandleResponseToken(request, response);
         return resp;
      })
      .catch(async (response) => {
         CookieTools.setCookie("ca-refreshing", "false");
         resp = await HandleErrorToken(response);
         return resp;
      });

   return resp;
};
