import { InputBase, TextField, withStyles } from "@material-ui/core";

export const BootstrapSelect = withStyles((theme) => ({
   root: {
      "label + &": {
         marginTop: theme.spacing(3),
      },
   },
   input: {
      width: "255px",
      textAlign: "center",
      borderRadius: 100,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),

      "&:focus": {
         borderRadius: 100,
      },
   },
}))(InputBase);

export const BootstrapInput = withStyles({
   root: {
      "& .MuiOutlinedInput-root": {
         height: "38px",
         width: "258px",
         borderRadius: "100px",
         border: "1px solid #C4C4C4",

         "&:hover fieldset": {
            borderColor: "transparent",
         },
         "&.Mui-focused fieldset": {
            borderColor: "transparent",
         },
      },
      "& label.Mui-focused": {
         color: "transparent",
      },
      "& .MuiInput-underline:after": {
         borderBottomColor: "transparent",
      },
   },
})(TextField);
