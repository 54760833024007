export function PhoneFormatter(value: string) {
   try {
      if (value.length < 10) {
         return value;
      }

      const areaCode = value.substring(0, 3);
      const subscriberPrefix = value.substring(3, 6);
      const subscriber = value.substring(6, 10);

      return areaCode + "-" + subscriberPrefix + "-" + subscriber;
   } catch {
      return "";
   }
}
