import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DescriptionIcon from "@material-ui/icons/Description";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./DocuSignComplete.css";

export const DocuSignComplete: React.FC<any> = ({
   match,
}: RouteComponentProps<{ division: string; envelopeID: string }>): JSX.Element => {
   return (
      <div
         className={
            match.params.division.toUpperCase() === "MIDDLEBAY"
               ? "grid-item-border-middlebay-docusign"
               : match.params.division.toUpperCase() === "BRISTOL"
               ? "grid-item-border-bristol-docusign"
               : "grid-item-border-containerport-docusign"
         }
      >
         <div className="grid-item-spacer-docusign" />
         <Paper style={{ height: "calc(100vh - 80px)", width: "100vw" }}>
            <Grid container>
               <Grid item xs={12} style={{ textAlignLast: "center", paddingTop: "5rem" }}>
                  <img
                     src={
                        match.params.division.toUpperCase() === "MIDDLEBAY"
                           ? "https://worldgroup.blob.core.windows.net/cpglogos/Middlebay_Full_Size.png"
                           : match.params.division.toUpperCase() === "BRISTOL"
                           ? "https://worldgroup.blob.core.windows.net/cpglogos/Bristol_Full_Size.png"
                           : "https://worldgroup.blob.core.windows.net/cpglogos/ContainerPort_Logo_White_Full_Size.png"
                     }
                     width="300em"
                     alt={
                        match.params.division.toUpperCase() === "MIDDLEBAY"
                           ? "Middle Bay Transportation"
                           : match.params.division.toUpperCase() === "BRISTOL"
                           ? "Bristol Transportation"
                           : "ContainerPort Group"
                     }
                  />
               </Grid>
               <Grid item xs={12} style={{ textAlignLast: "center" }} className="grid-item-title-docusign">
                  <div style={{ display: "inline-block" }}>
                     <h3 style={{ display: "flex", alignItems: "center" }}>
                        <div>
                           <DescriptionIcon
                              className={
                                 match.params.division.toUpperCase() === "MIDDLEBAY"
                                    ? "icon-middlebay-docusign"
                                    : match.params.division.toUpperCase() === "BRISTOL"
                                    ? "icon-bristol-docusign"
                                    : "icon-containerport-docusign"
                              }
                           ></DescriptionIcon>
                        </div>
                        {"DocuSign Completed!"}
                     </h3>
                  </div>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={3}></Grid>
               <Grid item xs={6} className="grid-item-message-docusign">
                  <p>Thank you for completing the DocuSign document, we look forward to working with you!</p>
                  <p>You may close this page.</p>
               </Grid>
               <Grid item xs={3}></Grid>
            </Grid>
            <div className="grid-item-spacer-docusign" />
         </Paper>
      </div>
   );
};

export default DocuSignComplete;
