import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DescriptionIcon from "@material-ui/icons/Description";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./DocuSignCompleteUWL.css";

export const DocuSignCompleteUWL: React.FC<any> = ({
   match,
}: RouteComponentProps<{ division: string; envelopeID: string }>): JSX.Element => {
   return (
      //REPLACE WITH UWL THEME IN CLASSNAME.
      <div className={"grid-item-border-uwl-docusign"}>
         <div className="grid-item-spacer-docusign" />
         <Paper style={{ height: "calc(100vh - 80px)", width: "100vw" }}>
            <Grid container>
               <Grid item xs={12} style={{ textAlignLast: "center", paddingTop: "5rem" }}>
                  <img
                     //REPLACE WITH UWL LOGO IN SRC.
                     src={"https://worldgroup.blob.core.windows.net/uwllogos/UWL-Logo-Full.jpg"}
                     width="300em"
                     alt={"UWL, Inc."}
                  />
               </Grid>
               <Grid item xs={12} style={{ textAlignLast: "center" }} className="grid-item-title-docusign">
                  <div style={{ display: "inline-block" }}>
                     <h3 style={{ display: "flex", alignItems: "center" }}>
                        <div>
                           {/* REPLACE WITH UWL THEME IN CLASSNAME. */}
                           <DescriptionIcon className={"icon-uwl-docusign"}></DescriptionIcon>
                        </div>
                        {"DocuSign Completed!"}
                     </h3>
                  </div>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={3} />
               <Grid item xs={6} className="grid-item-message-docusign">
                  <p>Thank you for completing the DocuSign document, we look forward to working with you!</p>
                  <p>You may close this page.</p>
               </Grid>
               <Grid item xs={3} />
            </Grid>
            <div className="grid-item-spacer-docusign" />
         </Paper>
      </div>
   );
};

export default DocuSignCompleteUWL;
