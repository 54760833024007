/* eslint-disable react/react-in-jsx-scope */
import { Divider, Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import Grid from "@material-ui/core/Grid";
/* eslint-disable prettier/prettier */
import { Card } from "@material-ui/core";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { PhoneFormatter } from "../../../utilities/PhoneFormatter";
import { useBodyStyles } from "./Styles";

type Props = {
   selectedCustomerActivation: CustomerActivation;
};

function CHBInformation({ selectedCustomerActivation }: Props) {
   const classes = useBodyStyles();

   return (
      <>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px", marginLeft: "2px", marginRight: "2px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginTop: "16px",
                     marginLeft: "36px",
                     marginRight: "36px",
                     marginBottom: "36px",
                  }}
               >
                  <Typography variant="h6" color="primary" style={{ fontWeight: 700 }}>
                     {"CHB Information"}
                  </Typography>
                  <Divider className={classes.dividerBody} />
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        UWL Handling CHB
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Importer ACH with U.S. Customs
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company Name
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Contact
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.uwlHandleChb ? "YES" : "NO"}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.achImporter ? "YES" : "NO"}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.companyName}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.contact}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Address 1
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Address 2
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        City
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        State
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.address1}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.address2}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.city}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.state}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Zip Code
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Country
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Email
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Phone
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.zip}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.country}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.chb.email}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {PhoneFormatter(selectedCustomerActivation.chb.phone)}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { CHBInformation };
