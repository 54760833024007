import {
   Button,
   ButtonGroup,
   Chip,
   Collapse,
   FormControl,
   IconButton,
   InputAdornment,
   InputLabel,
   makeStyles,
   MenuItem,
   Select,
   TextField,
   Tooltip,
   // eslint-disable-next-line prettier/prettier
   useTheme
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, red } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { setApplicationCopy } from "../../api/ApplicationApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { UserContext } from "../../stores/Users/Context";
import { Applications } from "../../types/Applications";
import { Companies } from "../../types/Companies";
import { Contacts } from "../../types/Contacts";
import { Divisions } from "../../types/Divisions";
import { validateEmailAddress as validateEmailAddressFunction } from "../../utilities/validateEmailAddress";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
   checkIcon: {
      color: red[500],
   },
}));

export const CopyDialog: React.FC<any> = (props: {
   open: boolean;
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   handleClose: () => void;
   onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "companyCode" | "notes") => void;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const handleError = useErrorHandler();

   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);
   const [selectedCompany, setSelectedCompany] = useState<string>("");
   const [emailAddress, setEmailAddress] = useState<string>("");
   const [invalidEmail, setInvalidEmail] = useState<string>("");

   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         let newCompanyList: Divisions[] = [];

         companyState.Companies.forEach(function (company: Companies) {
            if (company.active === false || company.deleteDate !== null) {
               return;
            }

            const divisionsAssigned = _.filter(
               divisionState.Divisions,
               (x) => x.company_id === company._id && x.active === true && x.deleteDate === null,
            );

            if (divisionsAssigned.length > 0) {
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: [],
                     contacts: [],
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         newCompanyList = _.uniqBy(newCompanyList, "code");
         newCompanyList = _.orderBy(newCompanyList, ["code"], ["asc"]);

         setCompanyList(newCompanyList);
      }

      setContactList([]);
      setSelectedContacts([]);
      setSelectedContactsList([]);
      setSelectedCompany("");
      setEmailAddress(props.application.email);
   }, [props.open, companyState, divisionState]);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function handleSelectChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const value = event.target.value ? (event.target.value as string) : "";
         setSelectedCompany(value);

         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === (event.target.value ? (event.target.value as string) : ""),
         );

         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === (event.target.value ? (event.target.value as string) : ""),
         );

         if (divisionAssigned.length > 0) {
            setContactList(
               _.orderBy(
                  _.filter(divisionAssigned[0].contacts, (x) => x.active === true),
                  [(contact) => contact.name.toLowerCase()],
                  ["asc"],
               ),
            );
         } else if (companyAssigned.length > 0) {
            setContactList(
               _.orderBy(
                  _.filter(companyAssigned[0].contacts, (x) => x.active === true),
                  [(contact) => contact.name.toLowerCase()],
                  ["asc"],
               ),
            );
         }

         setSelectedContacts([]);
         setSelectedContactsList([]);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleSelectContactChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const contacts = event.target.value ? (event.target.value as string[]) : [];

         setSelectedContacts(contacts);

         const contactAssigned = _.filter(contactList, (x) => contacts.includes(contacts ? x._id : contacts));

         setSelectedContactsList(contactAssigned);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChipDelete(_id: string) {
      try {
         setSelectedContactsList(selectedContactsList.filter((x) => x._id !== _id));
         setSelectedContacts(selectedContacts.filter((x) => x !== _id));
      } catch (err) {
         handleError(err);
      }
   }

   async function saveApplication() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to copy the application is denied!", "warning");
            return;
         }

         if (invalidEmail !== "") {
            openAlertMessage(invalidEmail, "warning");
            return;
         }

         if (!selectedCompany) {
            openAlertMessage("You must select at least one company or division!", "warning");
            return;
         }

         if (selectedContacts.length === 0) {
            openAlertMessage("You must select at least one contact!", "warning");
            return;
         }

         const divisionAssigned = _.filter(
            divisionState.Divisions,
            (x) => x._id.toString() === selectedCompany.toString(),
         );

         const companyAssigned = _.filter(
            companyState.Companies,
            (x) => x._id.toString() === selectedCompany.toString(),
         );

         if (divisionAssigned.length > 0) {
            if (!divisionAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!divisionAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !divisionAssigned[0].invitationBody ||
               !divisionAssigned[0].invitationTopNote ||
               !divisionAssigned[0].receiptFirstParagraph ||
               !divisionAssigned[0].receiptSecondParagraph ||
               !divisionAssigned[0].deniedFirstParagraph ||
               !divisionAssigned[0].deniedSecondParagraph ||
               !divisionAssigned[0].approvedFirstParagraph ||
               !divisionAssigned[0].approvedSecondParagraph ||
               !divisionAssigned[0].approvedBoldParagraph ||
               !divisionAssigned[0].contactApprovedBody ||
               !divisionAssigned[0].contactDeniedBody ||
               !divisionAssigned[0].duplicationNotification ||
               !divisionAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         } else {
            if (!companyAssigned[0].emailTemplate) {
               openAlertMessage("The Email Template must be setup to send applications!", "warning");
               return;
            }

            if (!companyAssigned[0].docuSignTemplateId) {
               openAlertMessage("The DocuSign Template Id must be setup to send applications!", "warning");
               return;
            }

            if (
               !companyAssigned[0].invitationBody ||
               !companyAssigned[0].invitationTopNote ||
               !companyAssigned[0].receiptFirstParagraph ||
               !companyAssigned[0].receiptSecondParagraph ||
               !companyAssigned[0].deniedFirstParagraph ||
               !companyAssigned[0].deniedSecondParagraph ||
               !companyAssigned[0].approvedFirstParagraph ||
               !companyAssigned[0].approvedSecondParagraph ||
               !companyAssigned[0].approvedBoldParagraph ||
               !companyAssigned[0].contactApprovedBody ||
               !companyAssigned[0].contactDeniedBody ||
               !companyAssigned[0].duplicationNotification ||
               !companyAssigned[0].contactDuplicationNotification
            ) {
               openAlertMessage("All Email Verbiage must be setup to send applications!", "warning");
               return;
            }
         }

         setButtonClicked(true);

         const responseData = await setApplicationCopy(
            props.application._id,
            divisionAssigned.length > 0 ? divisionAssigned[0].company_id : selectedCompany.toString(),
            divisionAssigned.length > 0 ? divisionAssigned[0]._id : "",
            emailAddress,
            selectedContacts,
         );

         if (responseData.success) {
            openAlertMessage("Successfully copied application. ", "success");
            setButtonClicked(false);
            props.handleClose();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
         setButtonClicked(false);
      }
   }

   async function validateEmailAddress(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      try {
         const email = event.target.value ? event.target.value : "";

         const results = await validateEmailAddressFunction(email);

         setInvalidEmail(results);
         setEmailAddress(email);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         fullScreen={mobileScreen}
         fullWidth
         maxWidth={"sm"}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            {`Copy Application ${props.application.applicationNumber}`}
         </DialogTitle>
         <DialogContent>
            <Collapse in={alertMessage ? true : false}>
               <Alert
                  variant="filled"
                  severity={alertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setAlertMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <InputLabel style={{ color: "#000000", marginBottom: "1rem" }}>
               Which company|division would you like to copy this credit application to?
            </InputLabel>
            <FormControl variant="outlined" style={{ width: "100%" }}>
               <Select
                  id="select-companies"
                  defaultValue=""
                  value={selectedCompany}
                  onChange={(event) => handleSelectChange(event)}
               >
                  {companyList.map((company) => {
                     return (
                        <MenuItem
                           key={company._id ? company._id : company.company_id}
                           value={company._id ? company._id : company.company_id}
                        >
                           {company.code + " | " + company.name}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
            <TextField
               id="ci-email"
               value={emailAddress}
               onChange={(event) => validateEmailAddress(event)}
               label="Email"
               variant="outlined"
               fullWidth
               style={{ marginTop: "1rem", width: "100%" }}
               InputProps={
                  invalidEmail
                     ? {
                          startAdornment: (
                             <Tooltip title={invalidEmail} arrow>
                                <InputAdornment position="start">
                                   <CloseIcon className={classes.checkIcon} />
                                </InputAdornment>
                             </Tooltip>
                          ),
                       }
                     : {
                          startAdornment: (
                             <Tooltip title={"Valid Email"} arrow>
                                <InputAdornment position="start">
                                   <CheckIcon className={classes.buttonProgress} />
                                </InputAdornment>
                             </Tooltip>
                          ),
                       }
               }
            />
            <FormControl variant="outlined" style={{ marginTop: "1rem", width: "100%" }}>
               <InputLabel>Contact</InputLabel>
               <Select
                  id="select-contact"
                  label="Contact"
                  defaultValue=""
                  multiple
                  MenuProps={{
                     getContentAnchorEl: null,
                     transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                     },
                  }}
                  value={selectedContacts}
                  onChange={(event) => handleSelectContactChange(event)}
                  disabled={contactList.length <= 0}
               >
                  {contactList.map((contact) => {
                     return (
                        <MenuItem key={contact._id} value={contact._id}>
                           {contact.name}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
            {selectedContactsList.map((contact) => {
               return (
                  <Chip
                     key={contact._id}
                     label={contact.name}
                     size="small"
                     color="primary"
                     onDelete={() => handleChipDelete(contact._id)}
                     style={{ marginTop: "1rem" }}
                  />
               );
            })}
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={saveApplication}
                  variant="contained"
                  color="primary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={buttonClicked || userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <FileCopyIcon />}
                  Copy
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default CopyDialog;
