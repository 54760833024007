import React from "react";
import "./CheckAnimation.css";

export const CheckAnimation: React.FC<any> = (): JSX.Element => {
   return (
      <div className="loader-wrapper">
         <div className="check-wrapper-fullscreen">
            <div className="check">
               <div className="check-container"></div>
               <div className="line1-write">~~/~~/~~~~</div>
               <div className="line1"></div>
               <div className="line2-write">~~~/~~//~~~~~~~~~//` /~~~~~~~~``//~~~</div>
               <div className="line2"></div>
               <div className="line3-write">~~//~~~~./~/~</div>
               <div className="line3"></div>
               <div className="line4-write">/~~~~~`/~~~~~~///~~/~~~//``~~~~/~~/~/~~~~~~``/``~~~/~`/</div>
               <div className="line4"></div>
               <div className="line5-write">~~///~```///~~~~~~~~/`?``?``~~?</div>
               <div className="line5"></div>
               <div className="line6-write">~~??.`/~~~~~/?~~/?`?``~~~~~/</div>
               <div className="line6"></div>
            </div>
         </div>
      </div>
   );
};

export default CheckAnimation;
