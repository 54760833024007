import { Box, Button, ButtonGroup, CircularProgress, Slide } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps } from "react-router-dom";
import { getInviteApplication, setInviteApplication } from "../../api/ApplicationApi";
import CheckAnimation from "../../styles/CheckAnimation";
import { Applications } from "../../types/Applications";
import { BankInfo } from "../../types/BankInfo";
import { BillingAddress } from "../../types/BillingAddress";
import { Chb } from "../../types/Chb";
import { TradeRefInfo } from "../../types/tradeRefInfo";
import { DateFormatter } from "../../utilities/DateFormatter";
import "./ApplyUWLWDS.css";
import BankingInfo from "./BankingInfo/BankingInfo";
import BillingInformation from "./BillingInformation/BillingInformation";
import ChbInfo from "./ChbInfo/ChbInfo";
import CompanyInformation from "./CompanyInformation/CompanyInformation";
import CreditRequest from "./CreditRequest/CreditRequest";
import SignComplete from "./ESign/SignComplete";
import ExpiredUWLWDS from "./Expired/ExpiredUWLWDS";
import LandingPage from "./LandingPage/LandingPage";
import Review, { CheckApplicationType } from "./Review/Review";
import { StepperUWLWDS } from "./Stepper/StepperUWLWDS";
import { useApplyStyles } from "./Styles";
import { TradeReference } from "./TradeReference/TradeReference";
import { TypeParams } from "./TradeReference/TradeReferenceOptions";
import {
   allowSomeSpecialChar,
   allowSomeSpecialCharNoAmpersand,
   allowSomeSpecialCharOnlyDash,
   allowSomeSpecialCharWithAtAmpersandPeriod,
   allowSomeSpecialCharWithHashtag,
   alphabetOnly,
   getCurrentStep,
   mockTradeRefInfo,
   numbersOnly,
   validEmail,
} from "./utils";

export const ApplyUWLWDS: React.FC<any> = ({
   match,
}: RouteComponentProps<{ division: string; email: string; applicationID: string }>): JSX.Element => {
   const handleError = useErrorHandler();

   const [application, setApplication] = useState<Applications>({
      _id: "",
      applicationNumber: "",
      email: "",
      companyName: "",
      companyEstablishedDate: null,
      accountsPayableEmail: "",
      companyEmail: "",
      companyContact: "",
      companyWebsite: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      billingAddress: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         email: "",
         phone: "",
         contact: "",
      },
      chb: {
         _id: "",
         uwlHandleChb: false,
         achImporter: false,
         companyName: "",
         contact: "",
         address: "",
         email: "",
         city: "",
         state: "",
         zip: "",
         phone: "",
      },
      companyPhone: "",
      status: "",
      amountReq: 0,
      numberEmployees: 0,
      annualRevenue: 0,
      method: {
         code: "",
         value: "",
      },
      ownership: "",
      documents: [],
      bankInfo: {
         duns: "",
         bankName: "",
         accountNumber: "",
         principalName: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         contact: "",
         phone: "",
         country: "",
         federalTaxNumber: "",
         paymentMethod: "",
      },
      tradeRefInfo: [
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
      ],
      tradeReferencesFileName: "",
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      code: "",
      approvedBy_id: "",
      approvedAmount: 0,
      netTerms: "",
      company_id: "",
      division_id: "",
      isCOD: null,
      notes: "",
      companyCode: "",
      fedTax_id: "",
      referral: [],
      notifiedBy_id: "",
      notifiedDate: null,
      docuSignEnvelope_id: "",
      docuSignCompleted: null,
      approvedOrDeniedDate: null,
      contacts: [],
      applicationOpened: null,
      addDate: new Date(),
      deleteDate: null,
      tradeReferencesSkipped: false,
   });

   const [errorHighlight, setErrorHighlight] = React.useState({
      companyName: { error: false, message: "" },
      companyEstablishedDate: { error: false, message: "" },
      accountsPayableEmail: { error: false, message: "" },
      companyWebsite: { error: false, message: "" },
      companyContact: { error: false, message: "" },
      address1: { error: false, message: "" },
      address2: { error: false, message: "" },
      city: { error: false, message: "" },
      state: { error: false, message: "" },
      zip: { error: false, message: "" },
      email: { error: false, message: "" },
      companyPhone: { error: false, message: "" },
      country: { error: false, message: "" },
      termsAndConditions: { error: false, message: "" },
      amountReq: { error: false, message: "" },
      numberEmployees: { error: false, message: "" },
      annualRevenue: { error: false, message: "" },
      method: { error: false, message: "" },
      ownership: { error: false, message: "" },
      documents: { error: false, message: "" },
      contact: { error: false, message: "" },
      fax: { error: false, message: "" },
      tradeRefInfo: [
         {
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
            website: { error: false, message: "" },
            ctpatNumber: { error: false, message: "" },
         },
         {
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
            website: { error: false, message: "" },
            ctpatNumber: { error: false, message: "" },
         },
         {
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
            website: { error: false, message: "" },
            ctpatNumber: { error: false, message: "" },
         },
      ],
      bankInfo: {
         bankName: { error: false, message: "" },
         address: { error: false, message: "" },
         city: { error: false, message: "" },
         state: { error: false, message: "" },
         zip: { error: false, message: "" },
         phone: { error: false, message: "" },
         country: { error: false, message: "" },
         principalName: { error: false, message: "" },
         duns: { error: false, message: "" },
         accountNumber: { error: false, message: "" },
         contact: { error: false, message: "" },
         federalTaxNumber: { error: false, message: "" },
         paymentMethod: { error: false, message: "" },
      },
      signature: { error: false, message: "" },
      signatureTitle: { error: false, message: "" },
      ctpatNumber: { error: false, message: "" },
      billingAddress: {
         address1: { error: false, message: "" },
         address2: { error: false, message: "" },
         city: { error: false, message: "" },
         state: { error: false, message: "" },
         zip: { error: false, message: "" },
         email: { error: false, message: "" },
         phone: { error: false, message: "" },
         contact: { error: false, message: "" },
      },
      chb: {
         companyName: { error: false, message: "" },
         contact: { error: false, message: "" },
         address: { error: false, message: "" },
         email: { error: false, message: "" },
         city: { error: false, message: "" },
         state: { error: false, message: "" },
         zip: { error: false, message: "" },
         phone: { error: false, message: "" },
      },
   });

   const [activeStep, setActiveStep] = React.useState(0);
   const [loading, setLoading] = React.useState(true);
   const [tokenExpired, setExpired] = React.useState(false);
   const [isUWL, setUWL] = React.useState(false);
   const [nextClicked, setNextClicked] = React.useState(false);
   const [saving, setSaving] = React.useState(false);
   const [showLoading, setShowLoading] = React.useState(false);
   const [termsAndConditions, setTermsAndConditions] = React.useState(false);
   const [showLandingPage, setShowLandingPage] = React.useState<boolean>(true);
   const [isCompanyAddressChecked, setIsCompanyAddressChecked] = React.useState(false);

   useEffect(() => {
      getApplication();
   }, []);

   useEffect(() => {
      //If loading, go through the fun of figuring out what page they were on.
      if (loading && application._id) {
         const step = getCurrentStep(application);

         setActiveStep(step);

         //This prevents undefined errors by appending the correct amount of in the tradeRefInfo error array.
         if (application.tradeRefInfo.length > 3) {
            const newErrorHighlight = { ...errorHighlight };

            while (application.tradeRefInfo.length > newErrorHighlight.tradeRefInfo.length) {
               newErrorHighlight.tradeRefInfo.push({
                  company: { error: false, message: "" },
                  address: { error: false, message: "" },
                  city: { error: false, message: "" },
                  state: { error: false, message: "" },
                  country: { error: false, message: "" },
                  phone: { error: false, message: "" },
                  email: { error: false, message: "" },
                  contact: { error: false, message: "" },
                  website: { error: false, message: "" },
                  ctpatNumber: { error: false, message: "" },
               });
            }

            setErrorHighlight(newErrorHighlight);
         } else if (application.tradeRefInfo.length < 1) {
            const mockArray = [];
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            application.tradeRefInfo = application.tradeRefInfo.concat(mockArray);
         } else if (application.tradeRefInfo.length < 2) {
            const mockArray = [];
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            application.tradeRefInfo = application.tradeRefInfo.concat(mockArray);
         } else if (application.tradeRefInfo.length < 3) {
            application.tradeRefInfo.push({ ...mockTradeRefInfo });
         }

         setLoading(false);
      }

      validatePage(false);
   }, [application]);

   async function getApplication() {
      try {
         setLoading(true);
         const responseData = await getInviteApplication(match.params.applicationID);

         // This is not the ideal why to check for UWL vs WDS, but it works until a better way is added
         if (window.location.pathname.slice(7, 10).toUpperCase() === "UWL") {
            setUWL(true);
         } else if (window.location.pathname.slice(7, 10).toUpperCase() === "WDS") {
            setUWL(false);
         } else {
            setUWL(true);
         }

         if (responseData.success) {
            //Default the country if not entered to United States.
            if (!responseData.data.address.country) {
               responseData.data.address.country = "United States";
            }

            //Default the country if not entered to United States.
            if (!responseData.data.bankInfo.country) {
               responseData.data.bankInfo.country = "United States";
            }

            //Default the method code to Email.
            if (!responseData.data.method.code) {
               responseData.data.method.code = "Email";
            }

            //Default the ownership code to Corporation.
            if (!responseData.data.ownership) {
               responseData.data.ownership = "Corporation";
            }

            //Default the payment method to ACH.
            if (!responseData.data.bankInfo.paymentMethod) {
               responseData.data.bankInfo.paymentMethod = "ACH";
            }

            responseData.data.tradeRefInfo.forEach((trade: TradeRefInfo, index: number) => {
               if (!trade.company) {
                  responseData.data.tradeRefInfo[index].country = "United States";
               }
            });

            setShowLandingPage(!responseData.data?.companyName);
            setApplication(responseData.data);
         } else if (!responseData.data) {
            setExpired(true);
            setLoading(false);
         } else if (responseData.data.status === "EXPIRED") {
            setExpired(true);
            setApplication(responseData.data);
         } else {
            setExpired(true);
            setApplication(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   // Handle Change Step 1
   async function handleChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value:
         | "companyName"
         | "accountsPayableEmail"
         | "companyContact"
         | "companyEmail"
         | "companyPhone"
         | "ownership"
         | "duns"
         | "bankName"
         | "principalName"
         | "accountNumber"
         | "contact"
         | "phone"
         | "companyWebsite",
   ) {
      try {
         const newApplication = { ...application };

         if (value === "companyName") {
            newApplication[value] = event.target.value ? event.target.value.toUpperCase() : "";
         } else if (value === "companyPhone") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.companyPhone = onlyNums ? onlyNums.toString() : "";
         } else if (value === "duns") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.bankInfo.duns = onlyNums ? onlyNums.toString() : "";
         } else if (value === "bankName") {
            newApplication.bankInfo[value] = event.target.value ? event.target.value : "";
         } else if (value === "principalName") {
            const onlyAlpha = event.target.value.replace(/[0-9]/g, "");
            newApplication.bankInfo[value] = onlyAlpha ? onlyAlpha.toString() : "";
         } else if (value === "accountNumber") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.bankInfo[value] = onlyNums ? onlyNums.toString() : "";
         } else if (value === "contact") {
            const onlyAlpha = event.target.value.replace(/[0-9]/g, "");
            newApplication.bankInfo[value] = onlyAlpha ? onlyAlpha.toString() : "";
         } else if (value === "phone") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.bankInfo[value] = onlyNums ? onlyNums.toString() : "";
         } else if (value === "companyWebsite") {
            newApplication[value] = event.target.value ? event.target.value : "";
         } else {
            newApplication[value] = event.target.value ? event.target.value : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   function copyCompanyAddress(clear: boolean) {
      try {
         const newApplication = { ...application };

         newApplication.billingAddress.address1 = clear ? "" : application.address.address1;
         newApplication.billingAddress.address2 = clear ? "" : application.address.address2;
         newApplication.billingAddress.city = clear ? "" : application.address.city;
         newApplication.billingAddress.state = clear ? "" : application.address.state;
         newApplication.billingAddress.zip = clear ? "" : application.address.zip;

         setIsCompanyAddressChecked(!clear);
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   // Handle Change Step 2
   function handleChangeBilling(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type: keyof BillingAddress,
   ) {
      try {
         const newApplication = { ...application };

         if (type === "address1" || type === "address2" || type === "city" || type === "zip") {
            newApplication.billingAddress[type] = event.target.value?.toString() || "";
         } else if (type === "state") {
            newApplication.billingAddress[type] = event.target.value?.toString()?.toUpperCase() || "";
         } else if (type === "phone") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.billingAddress[type] = onlyNums ? onlyNums.toString() : "";
         } else if (type === "contact") {
            const contact = event.target.value.replace(/[0-9]/g, "");
            newApplication.billingAddress[type] = contact ? contact.toString() : "";
         } else {
            newApplication.billingAddress[type] = event.target.value ? event.target.value : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   // Handle Change Step 3
   function handleChangeChb(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: keyof Chb) {
      try {
         const newApplication = { ...application };

         if (type === "address" || type === "city" || type === "zip") {
            newApplication.chb[type] = event.target.value?.toString() || "";
         } else if (type === "state") {
            newApplication.chb[type] = event.target.value?.toString()?.toUpperCase() || "";
         } else if (type === "phone") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.chb[type] = onlyNums ? onlyNums.toString() : "";
         } else if (type === "contact") {
            const contact = event.target.value.replace(/[0-9]/g, "");
            newApplication.chb[type] = contact ? contact.toString() : "";
         } else if (type === "uwlHandleChb" || type === "achImporter") {
         } else {
            newApplication.chb[type] = event.target.value ? event.target.value : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   // Handle Change Step 3
   function handleChangeChbCheck(event: React.ChangeEvent<HTMLInputElement>, type: keyof Chb) {
      try {
         const newApplication = { ...application };

         if (type === "uwlHandleChb" || type === "achImporter") {
            newApplication.chb[type] = !!event.target.checked;
         }

         if (type === "uwlHandleChb" && event.target.checked === false) {
            newApplication.chb.achImporter = false;
         }

         if (type === "uwlHandleChb" && event.target.checked === true) {
            newApplication.chb.companyName = "";
            newApplication.chb.contact = "";
            newApplication.chb.address = "";
            newApplication.chb.phone = "";
            newApplication.chb.email = "";
            newApplication.chb.city = "";
            newApplication.chb.state = "";
            newApplication.chb.zip = "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   // Handle Change Step 4
   function handleChangeCreditRequest(value: string, type: keyof CreditRequest) {
      try {
         const newApplication = { ...application };
         if (type === "amountReq") {
            const onlyNums = value.replace(/[^0-9]/g, "");
            newApplication.amountReq = onlyNums ? +onlyNums : 0;
         }
         if (type === "numberEmployees") {
            const onlyNums = value.replace(/[^0-9]/g, "");
            newApplication.numberEmployees = onlyNums ? +onlyNums : 0;
         }
         if (type === "annualRevenue") {
            const onlyNums = value.replace(/[^0-9]/g, "");
            newApplication.annualRevenue = onlyNums ? +onlyNums : 0;
         }
         if (type === "ownership") {
            newApplication.ownership = value || "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   // handle change step 5
   function handleChangeBankInfo(value: string, type: keyof BankInfo) {
      try {
         const newApplication = { ...application };
         if (type === "bankName") {
            newApplication.bankInfo[type] = value ? value : "";
         } else if (type === "address1" || type === "city" || type === "zip") {
            newApplication.bankInfo[type] = value?.toString() || "";
         } else if (type === "state") {
            newApplication.bankInfo[type] = value?.toString()?.toUpperCase() || "";
         } else if (type === "phone") {
            const onlyNums = value.replace(/[^0-9]/g, "");
            newApplication.bankInfo[type] = onlyNums ? onlyNums.toString() : "";
         } else if (type === "contact") {
            const contact = value.replace(/[0-9]/g, "");
            newApplication.bankInfo[type] = contact ? contact.toString() : "";
         } else {
            newApplication.bankInfo[type] = value ? (value as string) : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   // handle change step 6
   async function handleChangeTradeRefInfo(value: string | boolean, type: TypeParams, index: number) {
      try {
         const newApplication = { ...application };

         if (typeof value == "boolean" || type === "tradeReferencesSkipped") {
            // controll chache to show empty form when value == true and filling in the form with the cahche when value == false
            if (value) {
               const mockArray = [];
               mockArray.push({ ...mockTradeRefInfo });
               mockArray.push({ ...mockTradeRefInfo });
               mockArray.push({ ...mockTradeRefInfo });

               // clean other options (file and fill form in)
               newApplication.tradeRefInfo = mockArray;
               newApplication.tradeReferencesFileName = "";
            }

            newApplication.tradeReferencesSkipped = value as boolean;
            setApplication(newApplication);
            makeApplicationRequest(newApplication);
            return;
         }

         if (type === "tradeReferencesFileName") {
            const mockArray = [];
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            // clean other options (skip form and fill form in)
            newApplication.tradeRefInfo = mockArray;
            newApplication.tradeReferencesSkipped = false;

            newApplication.tradeReferencesFileName = value;

            // restore errors
            const errorHighlightAux = _.cloneDeep(errorHighlight);
            errorHighlightAux.tradeRefInfo = [
               {
                  company: { error: false, message: "" },
                  address: { error: false, message: "" },
                  city: { error: false, message: "" },
                  state: { error: false, message: "" },
                  country: { error: false, message: "" },
                  phone: { error: false, message: "" },
                  email: { error: false, message: "" },
                  contact: { error: false, message: "" },
                  website: { error: false, message: "" },
                  ctpatNumber: { error: false, message: "" },
               },
               {
                  company: { error: false, message: "" },
                  address: { error: false, message: "" },
                  city: { error: false, message: "" },
                  state: { error: false, message: "" },
                  country: { error: false, message: "" },
                  phone: { error: false, message: "" },
                  email: { error: false, message: "" },
                  contact: { error: false, message: "" },
                  website: { error: false, message: "" },
                  ctpatNumber: { error: false, message: "" },
               },
               {
                  company: { error: false, message: "" },
                  address: { error: false, message: "" },
                  city: { error: false, message: "" },
                  state: { error: false, message: "" },
                  country: { error: false, message: "" },
                  phone: { error: false, message: "" },
                  email: { error: false, message: "" },
                  contact: { error: false, message: "" },
                  website: { error: false, message: "" },
                  ctpatNumber: { error: false, message: "" },
               },
            ];
            setApplication(newApplication);
            makeApplicationRequest(newApplication);
            setErrorHighlight(errorHighlightAux);
            return;
         }

         if (newApplication.tradeRefInfo.length < 1) {
            const mockArray = [];
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            newApplication.tradeRefInfo = newApplication.tradeRefInfo.concat(mockArray);
         }

         if (newApplication.tradeRefInfo.length < 2) {
            const mockArray = [];
            mockArray.push({ ...mockTradeRefInfo });
            mockArray.push({ ...mockTradeRefInfo });
            newApplication.tradeRefInfo = newApplication.tradeRefInfo.concat(mockArray);
         }

         if (newApplication.tradeRefInfo.length < 3) {
            newApplication.tradeRefInfo.push({ ...mockTradeRefInfo });
         }

         if (type === "phone") {
            const onlyNums = (value as string).replace(/[^0-9]/g, "");
            newApplication.tradeRefInfo[index][type] = onlyNums ? onlyNums.toString() : "";
         } else if (type === "contact") {
            const onlyAlpha = value ? (value as string).replace(/[0-9]/g, "") : "";
            newApplication.tradeRefInfo[index][type] = onlyAlpha ? onlyAlpha.toString() : "";
         } else {
            newApplication.tradeRefInfo[index][type] = value ? value : "";
         }

         // clean other options (skip form and fill form in)
         newApplication.tradeReferencesFileName = "";
         newApplication.tradeReferencesSkipped = false;

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   function handleChangesignature(value: string, type: "signatureDate" | "signatureTitle") {
      try {
         const newApplication = { ...application };
         if (type === "signatureDate") {
            newApplication[type] = value ? new Date(value) : null;
         } else if (type === "signatureTitle") {
            newApplication[type] = value ? value.toLocaleUpperCase() : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   function handleChangeTermsAndConditionsCheck(event: React.ChangeEvent<HTMLInputElement>) {
      try {
         const newApplication = { ...application };
         setTermsAndConditions(!!event.target.checked);
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeDate(event: MaterialUiPickersDate) {
      try {
         const newApplication = { ...application };
         newApplication.companyEstablishedDate = event ? event : null;

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleCheckChange(value: string, type: CheckApplicationType) {
      try {
         const newApplication = { ...application };

         let arr = [];
         let ReferralArr = [];

         if (
            type === "Referral" ||
            type === "Advertisement" ||
            type === "Trade Show/Conference" ||
            type === "Social Media" ||
            type === "Website" ||
            type === "Salesperson"
         ) {
            ReferralArr = newApplication.referral;

            if (ReferralArr.indexOf(value) !== -1) {
               ReferralArr.splice(ReferralArr.indexOf(value), 1);
            } else {
               ReferralArr.push(value);
            }

            newApplication.referral = ReferralArr;
         } else {
            arr = newApplication.documents;

            if (arr.indexOf(value) !== -1) {
               arr.splice(arr.indexOf(value), 1);
            } else {
               arr.push(value);
            }

            newApplication.documents = arr;
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeAddress(
      event:
         | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value:
         | "address1"
         | "address2"
         | "city"
         | "state"
         | "zip"
         | "country"
         | "bankAddress"
         | "bankCity"
         | "bankState"
         | "bankZip"
         | "bankCountry",
   ) {
      try {
         const newApplication = { ...application };

         if (value === "address1" || value === "address2" || value === "city" || value === "state" || value === "zip") {
            newApplication.address[value] = event.target.value ? (event.target.value as string) : "";
            newApplication.address[value] = newApplication.address[value].toUpperCase();
         } else if (value === "bankAddress") {
            newApplication.bankInfo.address1 = event.target.value ? (event.target.value as string) : "";
         } else if (value === "bankCity") {
            newApplication.bankInfo.city = event.target.value ? (event.target.value as string) : "";
         } else if (value === "bankState") {
            newApplication.bankInfo.state = event.target.value ? (event.target.value as string) : "";
            newApplication.bankInfo.state = newApplication.bankInfo.state.toUpperCase();
         } else if (value === "bankZip") {
            newApplication.bankInfo.zip = event.target.value ? (event.target.value as string) : "";
            newApplication.bankInfo.zip = newApplication.bankInfo.zip.toUpperCase();
         } else if (value === "bankCountry") {
            newApplication.bankInfo.country = event.target.value ? (event.target.value as string) : "";
         } else {
            newApplication.address[value] = event.target.value ? (event.target.value as string) : "";
         }
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function validatePage(buttonEvent: boolean) {
      try {
         const newErrorHighlight = { ...errorHighlight };

         //Company Information
         if (activeStep >= 0) {
            if (application.companyName.length > 100) {
               newErrorHighlight.companyName.error = true;
               newErrorHighlight.companyName.message = "Cannot exceed 100 characters";
            } else if (application.companyName.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyName.error = true;
               newErrorHighlight.companyName.message = "Please enter the company name";
            } else if (application.companyName.match(allowSomeSpecialChar)) {
               newErrorHighlight.companyName.error = true;
               newErrorHighlight.companyName.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.companyName.error = false;
               newErrorHighlight.companyName.message = "";
            }

            if (application.companyWebsite.length > 200) {
               newErrorHighlight.companyWebsite.error = true;
               newErrorHighlight.companyWebsite.message = "Cannot exceed 200 characters";
            } else if (application.companyWebsite.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyWebsite.error = true;
               newErrorHighlight.companyWebsite.message = "Please enter the company website";
            } else {
               newErrorHighlight.companyWebsite.error = false;
               newErrorHighlight.companyWebsite.message = "";
            }

            if (!application.companyEstablishedDate && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyEstablishedDate.error = false;
               newErrorHighlight.companyEstablishedDate.message = "";
            } else if (
               DateFormatter(application.companyEstablishedDate) === "00/00/0000" &&
               (buttonEvent || nextClicked)
            ) {
               newErrorHighlight.companyEstablishedDate.error = true;
               newErrorHighlight.companyEstablishedDate.message = "Please enter a valid date";
            } else {
               newErrorHighlight.companyEstablishedDate.error = false;
               newErrorHighlight.companyEstablishedDate.message = "";
            }

            if (!application.accountsPayableEmail.match(validEmail) && application.accountsPayableEmail.length >= 1) {
               newErrorHighlight.accountsPayableEmail.error = true;
               newErrorHighlight.accountsPayableEmail.message = "Please enter a valid email";
            } else if (application.accountsPayableEmail.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.accountsPayableEmail.error = true;
               newErrorHighlight.accountsPayableEmail.message = "Please enter a valid email";
            } else {
               newErrorHighlight.accountsPayableEmail.error = false;
               newErrorHighlight.accountsPayableEmail.message = "";
            }

            if (application.companyContact.length > 100) {
               newErrorHighlight.companyContact.error = true;
               newErrorHighlight.companyContact.message = "Cannot exceed 100 characters";
            } else if (application.companyContact.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyContact.error = true;
               newErrorHighlight.companyContact.message = "Please enter the company contact";
            } else if (application.companyContact.match(allowSomeSpecialCharWithAtAmpersandPeriod)) {
               newErrorHighlight.companyContact.error = true;
               newErrorHighlight.companyContact.message = "Cannot contain (!, $, %, #, @, &)";
            } else {
               newErrorHighlight.companyContact.error = false;
               newErrorHighlight.companyContact.message = "";
            }

            if (application.address.address1.length > 100) {
               newErrorHighlight.address1.error = true;
               newErrorHighlight.address1.message = "Cannot exceed 100 characters";
            } else if (application.address.address1.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.address1.error = true;
               newErrorHighlight.address1.message = "Please enter the address";
            } else if (application.address.address1.match(allowSomeSpecialCharWithHashtag)) {
               newErrorHighlight.address1.error = true;
               newErrorHighlight.address1.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.address1.error = false;
               newErrorHighlight.address1.message = "";
            }

            if (application.address.address2.length > 100) {
               newErrorHighlight.address2.error = true;
               newErrorHighlight.address2.message = "Cannot exceed 100 characters";
            } else if (application.address.address2.match(allowSomeSpecialCharWithHashtag)) {
               newErrorHighlight.address2.error = true;
               newErrorHighlight.address2.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.address2.error = false;
               newErrorHighlight.address2.message = "";
            }

            if (application.address.city.length > 100) {
               newErrorHighlight.city.error = true;
               newErrorHighlight.city.message = "Cannot exceed 100 characters";
            } else if (application.address.city.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.city.error = true;
               newErrorHighlight.city.message = "Please enter the city";
            } else if (application.address.city.match(allowSomeSpecialChar)) {
               newErrorHighlight.city.error = true;
               newErrorHighlight.city.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.city.error = false;
               newErrorHighlight.city.message = "";
            }

            if (application.address.state.length > 2) {
               newErrorHighlight.state.error = true;
               newErrorHighlight.state.message = "Please enter a valid two-letter state code";
            } else if (application.address.state.length < 2 && application.address.state.length >= 1) {
               newErrorHighlight.state.error = true;
               newErrorHighlight.state.message = "Must have a minimum of 2 characters";
            } else if (application.address.state.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.state.error = true;
               newErrorHighlight.state.message = "Must have a minimum of 2 characters";
            } else {
               newErrorHighlight.state.error = false;
               newErrorHighlight.state.message = "";
            }

            if (application.address.zip.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.zip.error = true;
               newErrorHighlight.zip.message = "Please enter the zip code";
            } else if (
               application.address.zip.match(allowSomeSpecialCharOnlyDash) &&
               application.address.zip.length >= 1
            ) {
               newErrorHighlight.zip.error = true;
               newErrorHighlight.zip.message = "Cannot contain any special characters";
            } else {
               newErrorHighlight.zip.error = false;
               newErrorHighlight.zip.message = "";
            }

            if (!application.address.country && (buttonEvent || nextClicked)) {
               newErrorHighlight.country.error = true;
               newErrorHighlight.country.message = "Please select a valid country";
            } else if (application.address.country.length < 1 && buttonEvent) {
               newErrorHighlight.country.error = true;
               newErrorHighlight.country.message = "Please select a valid country";
            } else {
               newErrorHighlight.country.error = false;
               newErrorHighlight.country.message = "";
            }

            // phone validation
            if (!application.companyPhone.match(numbersOnly) && application.companyPhone.length >= 1) {
               newErrorHighlight.companyPhone.error = true;
               newErrorHighlight.companyPhone.message = "No alphabet or special characters allowed";
            } else if (application.companyPhone.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyPhone.error = true;
               newErrorHighlight.companyPhone.message = "Please enter the phone number";
            } else if (application.companyPhone.length != 10 && application.companyPhone.length >= 1) {
               newErrorHighlight.companyPhone.error = true;
               newErrorHighlight.companyPhone.message = "Please enter a valid phone number (10 number characters)";
            } else {
               newErrorHighlight.companyPhone.error = false;
               newErrorHighlight.companyPhone.message = "";
            }
         }

         // Billing Information
         if (activeStep >= 1) {
            if (activeStep === 1) {
               // phone validation
               if (
                  !application.billingAddress.phone.match(numbersOnly) &&
                  application.billingAddress.phone.length >= 1
               ) {
                  newErrorHighlight.billingAddress.phone.error = true;
                  newErrorHighlight.billingAddress.phone.message = "No alphabet or special characters allowed";
               } else if (application.billingAddress.phone.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.phone.error = true;
                  newErrorHighlight.billingAddress.phone.message = "Please enter the phone number";
               } else if (
                  application.billingAddress.phone.length != 10 &&
                  application.billingAddress.phone.length >= 1
               ) {
                  newErrorHighlight.billingAddress.phone.error = true;
                  newErrorHighlight.billingAddress.phone.message =
                     "Please enter a valid phone number (10 number characters)";
               } else {
                  newErrorHighlight.billingAddress.phone.error = false;
                  newErrorHighlight.billingAddress.phone.message = "";
               }

               // email validation
               if (
                  !application.billingAddress.email.match(validEmail) &&
                  application.billingAddress.email.length >= 1
               ) {
                  newErrorHighlight.billingAddress.email.error = true;
                  newErrorHighlight.billingAddress.email.message = "Please enter a valid email";
               } else if (application.billingAddress.email.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.email.error = true;
                  newErrorHighlight.billingAddress.email.message = "Please enter a valid email";
               } else {
                  newErrorHighlight.billingAddress.email.error = false;
                  newErrorHighlight.billingAddress.email.message = "";
               }

               // address 1 validation
               if (application.billingAddress.address1.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.address1.error = true;
                  newErrorHighlight.billingAddress.address1.message = "Please enter the address";
               } else if (application.billingAddress.address1.length > 100) {
                  newErrorHighlight.billingAddress.address1.error = true;
                  newErrorHighlight.billingAddress.address1.message = "Cannot exceed 100 characters";
               } else if (application.billingAddress.address1.match(allowSomeSpecialCharWithHashtag)) {
                  newErrorHighlight.billingAddress.address1.error = true;
                  newErrorHighlight.billingAddress.address1.message = "Cannot contain (!, $, %, @)";
               } else {
                  newErrorHighlight.billingAddress.address1.error = false;
                  newErrorHighlight.billingAddress.address1.message = "";
               }

               // address 2 validation
               if (application.billingAddress.address2.length > 100) {
                  newErrorHighlight.billingAddress.address2.error = true;
                  newErrorHighlight.billingAddress.address2.message = "Cannot exceed 100 characters";
               } else if (application.billingAddress.address2.match(allowSomeSpecialCharWithHashtag)) {
                  newErrorHighlight.billingAddress.address2.error = true;
                  newErrorHighlight.billingAddress.address2.message = "Cannot contain (!, $, %, @)";
               } else {
                  newErrorHighlight.billingAddress.address2.error = false;
                  newErrorHighlight.billingAddress.address2.message = "";
               }

               // city validation
               if (application.billingAddress.city.length > 100) {
                  newErrorHighlight.billingAddress.city.error = true;
                  newErrorHighlight.billingAddress.city.message = "Cannot exceed 100 characters";
               } else if (application.billingAddress.city.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.city.error = true;
                  newErrorHighlight.billingAddress.city.message = "Please enter the city";
               } else if (application.billingAddress.city.match(allowSomeSpecialChar)) {
                  newErrorHighlight.billingAddress.city.error = true;
                  newErrorHighlight.billingAddress.city.message = "Cannot contain (!, $, %, #, @)";
               } else {
                  newErrorHighlight.billingAddress.city.error = false;
                  newErrorHighlight.billingAddress.city.message = "";
               }

               // state validation
               if (application.billingAddress.state.length > 100) {
                  newErrorHighlight.billingAddress.state.error = true;
                  newErrorHighlight.billingAddress.state.message = "Cannot exceed 100 characters";
               } else if (application.billingAddress.state.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.state.error = true;
                  newErrorHighlight.billingAddress.state.message = "Please enter the state";
               } else if (application.billingAddress.state.match(allowSomeSpecialChar)) {
                  newErrorHighlight.billingAddress.state.error = true;
                  newErrorHighlight.billingAddress.state.message = "Cannot contain (!, $, %, #, @)";
               } else {
                  newErrorHighlight.billingAddress.state.error = false;
                  newErrorHighlight.billingAddress.state.message = "";
               }

               // zip validation
               if (application.billingAddress.zip.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.zip.error = true;
                  newErrorHighlight.billingAddress.zip.message = "Please enter the zip code";
               } else if (
                  application.billingAddress.zip.match(allowSomeSpecialCharOnlyDash) &&
                  application.billingAddress.zip.length >= 1
               ) {
                  newErrorHighlight.billingAddress.zip.error = true;
                  newErrorHighlight.billingAddress.zip.message = "Cannot contain any special characters";
               } else {
                  newErrorHighlight.billingAddress.zip.error = false;
                  newErrorHighlight.billingAddress.zip.message = "";
               }

               // contact validation
               if (application.billingAddress.contact.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.billingAddress.contact.error = true;
                  newErrorHighlight.billingAddress.contact.message = "Please enter the bank contact";
               } else if (application.billingAddress.contact.length > 100) {
                  newErrorHighlight.billingAddress.contact.error = true;
                  newErrorHighlight.billingAddress.contact.message = "Cannot exceed 100 characters";
               } else if (application.billingAddress.contact.match(allowSomeSpecialCharWithAtAmpersandPeriod)) {
                  newErrorHighlight.billingAddress.contact.error = true;
                  newErrorHighlight.billingAddress.contact.message = "Cannot contain (!, $, %, #, @, &)";
               } else if (!application.billingAddress.contact.match(alphabetOnly)) {
                  newErrorHighlight.billingAddress.contact.error = true;
                  newErrorHighlight.billingAddress.contact.message = "Cannot contain numbers";
               } else {
                  newErrorHighlight.billingAddress.contact.error = false;
                  newErrorHighlight.billingAddress.contact.message = "";
               }
            }
         } else {
            newErrorHighlight.billingAddress.phone.error = false;
            newErrorHighlight.billingAddress.phone.message = "";
            newErrorHighlight.billingAddress.email.error = false;
            newErrorHighlight.billingAddress.email.message = "";
            newErrorHighlight.billingAddress.address1.error = false;
            newErrorHighlight.billingAddress.address1.message = "";
            newErrorHighlight.billingAddress.address2.error = false;
            newErrorHighlight.billingAddress.address2.message = "";
            newErrorHighlight.billingAddress.city.error = false;
            newErrorHighlight.billingAddress.city.message = "";
            newErrorHighlight.billingAddress.state.error = false;
            newErrorHighlight.billingAddress.state.message = "";
            newErrorHighlight.billingAddress.zip.error = false;
            newErrorHighlight.billingAddress.zip.message = "";
            newErrorHighlight.billingAddress.contact.error = false;
            newErrorHighlight.billingAddress.contact.message = "";
         }

         // CHB Request
         if (activeStep >= 2) {
            if (activeStep === 2) {
               // company name
               if (!application.chb.uwlHandleChb) {
                  if (application.chb.companyName.length > 100) {
                     newErrorHighlight.chb.companyName.error = true;
                     newErrorHighlight.chb.companyName.message = "Cannot exceed 100 characters";
                  } else if (application.chb.companyName.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.companyName.error = true;
                     newErrorHighlight.chb.companyName.message = "Please enter the company name";
                  } else if (application.chb.companyName.match(allowSomeSpecialChar)) {
                     newErrorHighlight.chb.companyName.error = true;
                     newErrorHighlight.chb.companyName.message = "Cannot contain (!, $, %, #, @)";
                  } else {
                     newErrorHighlight.chb.companyName.error = false;
                     newErrorHighlight.chb.companyName.message = "";
                  }

                  // email address
                  if (!application.chb.email.match(validEmail) && application.chb.email.length >= 1) {
                     newErrorHighlight.chb.email.error = true;
                     newErrorHighlight.chb.email.message = "Please enter a valid email";
                  } else if (application.chb.email.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.email.error = true;
                     newErrorHighlight.chb.email.message = "Please enter a valid email";
                  } else {
                     newErrorHighlight.chb.email.error = false;
                     newErrorHighlight.chb.email.message = "";
                  }

                  // Phone
                  if (!application.chb.phone.match(numbersOnly) && application.chb.phone.length >= 1) {
                     newErrorHighlight.chb.phone.error = true;
                     newErrorHighlight.chb.phone.message = "No alphabet or special characters allowed";
                  } else if (application.chb.phone.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.phone.error = true;
                     newErrorHighlight.chb.phone.message = "Please enter the phone number";
                  } else if (application.chb.phone.length != 10 && application.chb.phone.length >= 1) {
                     newErrorHighlight.chb.phone.error = true;
                     newErrorHighlight.chb.phone.message = "Please enter a valid phone number (10 number characters)";
                  } else {
                     newErrorHighlight.chb.phone.error = false;
                     newErrorHighlight.chb.phone.message = "";
                  }

                  // City
                  if (application.chb.city.length > 100) {
                     newErrorHighlight.chb.city.error = true;
                     newErrorHighlight.chb.city.message = "Cannot exceed 100 characters";
                  } else if (application.chb.city.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.city.error = true;
                     newErrorHighlight.chb.city.message = "Please enter the city";
                  } else if (application.chb.city.match(allowSomeSpecialChar)) {
                     newErrorHighlight.chb.city.error = true;
                     newErrorHighlight.chb.city.message = "Cannot contain (!, $, %, #, @)";
                  } else {
                     newErrorHighlight.chb.city.error = false;
                     newErrorHighlight.chb.city.message = "";
                  }

                  // state validation
                  if (application.chb.state.length > 100) {
                     newErrorHighlight.chb.state.error = true;
                     newErrorHighlight.chb.state.message = "Cannot exceed 100 characters";
                  } else if (application.chb.state.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.state.error = true;
                     newErrorHighlight.chb.state.message = "Please enter the state";
                  } else if (application.chb.state.match(allowSomeSpecialChar)) {
                     newErrorHighlight.chb.state.error = true;
                     newErrorHighlight.chb.state.message = "Cannot contain (!, $, %, #, @)";
                  } else {
                     newErrorHighlight.chb.state.error = false;
                     newErrorHighlight.chb.state.message = "";
                  }

                  // zip validation
                  if (application.chb.zip.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.zip.error = true;
                     newErrorHighlight.chb.zip.message = "Please enter the zip code";
                  } else if (
                     application.chb.zip.match(allowSomeSpecialCharOnlyDash) &&
                     application.chb.zip.length >= 1
                  ) {
                     newErrorHighlight.chb.zip.error = true;
                     newErrorHighlight.chb.zip.message = "Cannot contain any special characters";
                  } else {
                     newErrorHighlight.chb.zip.error = false;
                     newErrorHighlight.chb.zip.message = "";
                  }

                  // contact validation
                  if (application.chb.contact.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.contact.error = true;
                     newErrorHighlight.chb.contact.message = "Please enter the contact";
                  } else if (application.chb.contact.length > 100) {
                     newErrorHighlight.chb.contact.error = true;
                     newErrorHighlight.chb.contact.message = "Cannot exceed 100 characters";
                  } else if (application.chb.contact.match(allowSomeSpecialCharWithAtAmpersandPeriod)) {
                     newErrorHighlight.chb.contact.error = true;
                     newErrorHighlight.chb.contact.message = "Cannot contain (!, $, %, #, @, &)";
                  } else if (!application.chb.contact.match(alphabetOnly)) {
                     newErrorHighlight.chb.contact.error = true;
                     newErrorHighlight.chb.contact.message = "Cannot contain numbers";
                  } else {
                     newErrorHighlight.chb.contact.error = false;
                     newErrorHighlight.chb.contact.message = "";
                  }

                  // Address
                  if (application.chb.address.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.chb.address.error = true;
                     newErrorHighlight.chb.address.message = "Please enter the address";
                  } else if (application.chb.address.length > 100) {
                     newErrorHighlight.chb.address.error = true;
                     newErrorHighlight.chb.address.message = "Cannot exceed 100 characters";
                  } else if (application.chb.address.match(allowSomeSpecialCharWithHashtag)) {
                     newErrorHighlight.chb.address.error = true;
                     newErrorHighlight.chb.address.message = "Cannot contain (!, $, %, @)";
                  } else {
                     newErrorHighlight.chb.address.error = false;
                     newErrorHighlight.chb.address.message = "";
                  }
               } else {
                  newErrorHighlight.chb.companyName.error = false;
                  newErrorHighlight.chb.companyName.message = "";
                  newErrorHighlight.chb.email.error = false;
                  newErrorHighlight.chb.email.message = "";
                  newErrorHighlight.chb.phone.error = false;
                  newErrorHighlight.chb.phone.message = "";
                  newErrorHighlight.chb.city.error = false;
                  newErrorHighlight.chb.city.message = "";
                  newErrorHighlight.chb.state.error = false;
                  newErrorHighlight.chb.state.message = "";
                  newErrorHighlight.chb.zip.error = false;
                  newErrorHighlight.chb.zip.message = "";
                  newErrorHighlight.chb.contact.error = false;
                  newErrorHighlight.chb.contact.message = "";
                  newErrorHighlight.chb.address.error = false;
                  newErrorHighlight.chb.address.message = "";
               }
            }
         } else {
            newErrorHighlight.chb.companyName.error = false;
            newErrorHighlight.chb.companyName.message = "";
            newErrorHighlight.chb.email.error = false;
            newErrorHighlight.chb.email.message = "";
            newErrorHighlight.chb.phone.error = false;
            newErrorHighlight.chb.phone.message = "";
            newErrorHighlight.chb.city.error = false;
            newErrorHighlight.chb.city.message = "";
            newErrorHighlight.chb.state.error = false;
            newErrorHighlight.chb.state.message = "";
            newErrorHighlight.chb.zip.error = false;
            newErrorHighlight.chb.zip.message = "";
            newErrorHighlight.chb.contact.error = false;
            newErrorHighlight.chb.contact.message = "";
            newErrorHighlight.chb.address.error = false;
            newErrorHighlight.chb.address.message = "";
         }

         // Credit Request
         if (activeStep >= 3) {
            if (activeStep === 3) {
               if (application.numberEmployees <= 0 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.numberEmployees.error = true;
                  newErrorHighlight.numberEmployees.message = "Please enter the number of employees.";
               } else if (application.numberEmployees > 9999999) {
                  newErrorHighlight.numberEmployees.error = true;
                  newErrorHighlight.numberEmployees.message = "Number of employes must be under 7 digits in length.";
               } else {
                  newErrorHighlight.numberEmployees.error = false;
                  newErrorHighlight.numberEmployees.message = "";
               }

               if (application.annualRevenue <= 0 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.annualRevenue.error = true;
                  newErrorHighlight.annualRevenue.message = "Please enter the annual revenue.";
               } else if (application.annualRevenue > 999999999999999) {
                  newErrorHighlight.annualRevenue.error = true;
                  newErrorHighlight.annualRevenue.message = "Annual revenue must be under 15 digits in length.";
               } else {
                  newErrorHighlight.annualRevenue.error = false;
                  newErrorHighlight.annualRevenue.message = "";
               }

               if (!application.ownership && (buttonEvent || nextClicked)) {
                  newErrorHighlight.ownership.error = true;
                  newErrorHighlight.ownership.message = "Please select the ownership.";
               } else {
                  newErrorHighlight.ownership.error = false;
                  newErrorHighlight.ownership.message = "";
               }
            }
         } else {
            newErrorHighlight.numberEmployees.error = false;
            newErrorHighlight.numberEmployees.message = "";
            newErrorHighlight.annualRevenue.error = false;
            newErrorHighlight.annualRevenue.message = "";
            newErrorHighlight.ownership.error = false;
            newErrorHighlight.ownership.message = "";
         }

         // Banking Info
         if (activeStep >= 4) {
            if (activeStep === 4) {
               // federalTaxNumber validation
               let federalTaxNumber = application.bankInfo?.federalTaxNumber || "";
               federalTaxNumber = federalTaxNumber.replaceAll("_", "") || "";
               federalTaxNumber = federalTaxNumber?.replaceAll("-", "") || "";

               if (application.bankInfo.federalTaxNumber.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.federalTaxNumber.error = true;
                  newErrorHighlight.bankInfo.federalTaxNumber.message = "Please enter the Federal Tax number.";
               } else if (federalTaxNumber.length >= 1 && federalTaxNumber.length !== 9) {
                  newErrorHighlight.bankInfo.federalTaxNumber.error = true;
                  newErrorHighlight.bankInfo.federalTaxNumber.message =
                     "Please enter a valid number (9 number characters)";
               } else if (application.bankInfo.federalTaxNumber.length > 100) {
                  newErrorHighlight.bankInfo.federalTaxNumber.error = true;
                  newErrorHighlight.bankInfo.federalTaxNumber.message = "Cannot exceed 100 characters";
               } else {
                  newErrorHighlight.bankInfo.federalTaxNumber.error = false;
                  newErrorHighlight.bankInfo.federalTaxNumber.message = "";
               }

               // duns Validation
               let duns = application.bankInfo?.duns || "";
               duns = duns?.replaceAll("_", "") || "";
               duns = duns?.replaceAll("-", "") || "";

               if (application.bankInfo.duns.length > 100) {
                  newErrorHighlight.bankInfo.duns.error = true;
                  newErrorHighlight.bankInfo.duns.message = "Cannot exceed 100 characters";
               } else if (duns.length >= 1 && duns.length !== 9) {
                  newErrorHighlight.bankInfo.duns.error = true;
                  newErrorHighlight.bankInfo.duns.message = "Please enter a valid number (9 number characters)";
               } else {
                  newErrorHighlight.bankInfo.duns.error = false;
                  newErrorHighlight.bankInfo.duns.message = "";
               }

               // Account Number validation
               if (application.bankInfo.accountNumber.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.accountNumber.error = true;
                  newErrorHighlight.bankInfo.accountNumber.message = "Please enter the bank account number.";
               } else if (application.bankInfo.duns.length > 30) {
                  newErrorHighlight.bankInfo.accountNumber.error = true;
                  newErrorHighlight.bankInfo.accountNumber.message = "Maximum length is 30 characters";
               } else {
                  newErrorHighlight.bankInfo.accountNumber.error = false;
                  newErrorHighlight.bankInfo.accountNumber.message = "";
               }

               // country validation
               if (!application.bankInfo.country && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.country.error = true;
                  newErrorHighlight.bankInfo.country.message = "Please select a valid country";
               } else if (application.bankInfo.country.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.country.error = true;
                  newErrorHighlight.bankInfo.country.message = "Please select a valid country";
               } else {
                  newErrorHighlight.bankInfo.country.error = false;
                  newErrorHighlight.bankInfo.country.message = "";
               }

               // zip validation
               if (
                  application.bankInfo.zip.match(allowSomeSpecialCharOnlyDash) &&
                  application.bankInfo.zip.length >= 1
               ) {
                  newErrorHighlight.bankInfo.zip.error = true;
                  newErrorHighlight.bankInfo.zip.message = "Cannot contain any special characters";
               } else if (application.bankInfo.zip.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.zip.error = true;
                  newErrorHighlight.bankInfo.zip.message = "Please enter the zip code";
               } else {
                  newErrorHighlight.bankInfo.zip.error = false;
                  newErrorHighlight.bankInfo.zip.message = "";
               }

               // state validation
               if (application.bankInfo.state.length > 2 && application.bankInfo.state.length >= 1) {
                  newErrorHighlight.bankInfo.state.error = true;
                  newErrorHighlight.bankInfo.state.message = "Please enter a valid two-letter state code";
               } else if (application.bankInfo.state.length < 2 && application.bankInfo.state.length >= 1) {
                  newErrorHighlight.bankInfo.state.error = true;
                  newErrorHighlight.bankInfo.state.message = "Must have a minimum of 2 characters";
               } else if (application.bankInfo.state.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.state.error = true;
                  newErrorHighlight.bankInfo.state.message = "Please enter a valid two-letter state code";
               } else {
                  newErrorHighlight.bankInfo.state.error = false;
                  newErrorHighlight.bankInfo.state.message = "";
               }

               // city validation
               if (application.bankInfo.city.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.city.error = true;
                  newErrorHighlight.bankInfo.city.message = "Please enter the bank city.";
               } else if (application.bankInfo.city.length > 100) {
                  newErrorHighlight.bankInfo.city.error = true;
                  newErrorHighlight.bankInfo.city.message = "Cannot exceed 100 characters";
               } else if (application.bankInfo.city.match(allowSomeSpecialCharNoAmpersand)) {
                  newErrorHighlight.bankInfo.city.error = true;
                  newErrorHighlight.bankInfo.city.message = "Cannot contain (!, $, %, #, @, &)";
               } else {
                  newErrorHighlight.bankInfo.city.error = false;
                  newErrorHighlight.bankInfo.city.message = "";
               }

               // address validation
               if (application.bankInfo.address1.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.address.error = true;
                  newErrorHighlight.bankInfo.address.message = "Please enter the bank address.";
               } else if (application.bankInfo.address1.length > 100) {
                  newErrorHighlight.bankInfo.address.error = true;
                  newErrorHighlight.bankInfo.address.message = "Cannot exceed 100 characters";
               } else if (application.bankInfo.address1.match(allowSomeSpecialCharWithHashtag)) {
                  newErrorHighlight.bankInfo.address.error = true;
                  newErrorHighlight.bankInfo.address.message = "Cannot contain (!, $, %, @)";
               } else {
                  newErrorHighlight.bankInfo.address.error = false;
                  newErrorHighlight.bankInfo.address.message = "";
               }

               // bankName validation
               if (application.bankInfo.bankName.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.bankName.error = true;
                  newErrorHighlight.bankInfo.bankName.message = "Please enter the bank name.";
               } else if (application.bankInfo.bankName.length > 100) {
                  newErrorHighlight.bankInfo.bankName.error = true;
                  newErrorHighlight.bankInfo.bankName.message = "Cannot exceed 100 characters";
               } else if (application.bankInfo.bankName.match(allowSomeSpecialChar)) {
                  newErrorHighlight.bankInfo.bankName.error = true;
                  newErrorHighlight.bankInfo.bankName.message = "Cannot contain (!, $, %, #, @)";
               } else {
                  newErrorHighlight.bankInfo.bankName.error = false;
                  newErrorHighlight.bankInfo.bankName.message = "";
               }

               // contact validation
               if (application.bankInfo.contact.length > 100) {
                  newErrorHighlight.bankInfo.contact.error = true;
                  newErrorHighlight.bankInfo.contact.message = "Cannot exceed 100 characters";
               } else if (application.bankInfo.contact.match(allowSomeSpecialCharWithAtAmpersandPeriod)) {
                  newErrorHighlight.bankInfo.contact.error = true;
                  newErrorHighlight.bankInfo.contact.message = "Cannot contain (!, $, %, #, @, &)";
               } else if (!application.bankInfo.contact.match(alphabetOnly)) {
                  newErrorHighlight.bankInfo.contact.error = true;
                  newErrorHighlight.bankInfo.contact.message = "Cannot contain numbers";
               } else {
                  newErrorHighlight.bankInfo.contact.error = false;
                  newErrorHighlight.bankInfo.contact.message = "";
               }

               // paymentMethod validation
               if (!application.bankInfo.paymentMethod && (buttonEvent || nextClicked)) {
                  newErrorHighlight.bankInfo.paymentMethod.error = true;
                  newErrorHighlight.bankInfo.paymentMethod.message = "Please select the payment method.";
               } else {
                  newErrorHighlight.bankInfo.paymentMethod.error = false;
                  newErrorHighlight.bankInfo.paymentMethod.message = "";
               }
            }
         } else {
            newErrorHighlight.bankInfo.federalTaxNumber.error = false;
            newErrorHighlight.bankInfo.federalTaxNumber.message = "";
            newErrorHighlight.bankInfo.duns.error = false;
            newErrorHighlight.bankInfo.duns.message = "";
            newErrorHighlight.bankInfo.accountNumber.error = false;
            newErrorHighlight.bankInfo.accountNumber.message = "";
            newErrorHighlight.bankInfo.country.error = false;
            newErrorHighlight.bankInfo.country.message = "";
            newErrorHighlight.bankInfo.zip.error = false;
            newErrorHighlight.bankInfo.zip.message = "";
            newErrorHighlight.bankInfo.state.error = false;
            newErrorHighlight.bankInfo.state.message = "";
            newErrorHighlight.bankInfo.city.error = false;
            newErrorHighlight.bankInfo.city.message = "";
            newErrorHighlight.bankInfo.address.error = false;
            newErrorHighlight.bankInfo.address.message = "";
            newErrorHighlight.bankInfo.bankName.error = false;
            newErrorHighlight.bankInfo.bankName.message = "";
            newErrorHighlight.bankInfo.contact.error = false;
            newErrorHighlight.bankInfo.contact.message = "";
         }

         // Trade References
         if (activeStep >= 5) {
            if (activeStep === 5 && !application.tradeReferencesFileName && !application.tradeReferencesSkipped) {
               const companyCheck = _.uniqBy(application.tradeRefInfo, "company");
               const phoneCheck = _.uniqBy(application.tradeRefInfo, "phone");
               const companyDuplicates: string[] = [];
               const phoneDuplicates: string[] = [];

               if (companyCheck.length !== application.tradeRefInfo.length) {
                  companyCheck.forEach((trade) => {
                     const companyDuplicate = _.filter(
                        application.tradeRefInfo,
                        (x) => x.company.toString() === trade.company.toString(),
                     );

                     if (companyDuplicate.length > 1) {
                        companyDuplicates.push(trade.company.toString());
                     }
                  });
               }

               if (phoneCheck.length !== application.tradeRefInfo.length) {
                  companyCheck.forEach((trade) => {
                     const phoneDuplicate = _.filter(
                        application.tradeRefInfo,
                        (x) => x.phone.toString() === trade.phone.toString(),
                     );

                     if (phoneDuplicate.length > 1) {
                        phoneDuplicates.push(trade.phone.toString());
                     }
                  });
               }

               application.tradeRefInfo.forEach((trade, index) => {
                  // company validation
                  if (!trade.company && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].company.error = true;
                     newErrorHighlight.tradeRefInfo[index].company.message = "Please enter the company";
                  } else if (companyDuplicates.includes(trade.company) && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].company.error = true;
                     newErrorHighlight.tradeRefInfo[index].company.message = "Company must be unique!";
                  } else if (trade.company.match(allowSomeSpecialCharNoAmpersand)) {
                     newErrorHighlight.tradeRefInfo[index].company.error = true;
                     newErrorHighlight.tradeRefInfo[index].company.message = "Cannot contain (!, $, %, #, @, &)";
                  } else if (trade.company.length > 100) {
                     newErrorHighlight.tradeRefInfo[index].company.error = true;
                     newErrorHighlight.tradeRefInfo[index].company.message = "Cannot exceed 100 characters";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].company.error = false;
                     newErrorHighlight.tradeRefInfo[index].company.message = "";
                  }

                  // city validations
                  if (!trade.city && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].city.error = true;
                     newErrorHighlight.tradeRefInfo[index].city.message = "Please enter the city";
                  } else if (trade.city.match(allowSomeSpecialChar)) {
                     newErrorHighlight.tradeRefInfo[index].city.error = true;
                     newErrorHighlight.tradeRefInfo[index].city.message = "Cannot contain (!, $, %, #, @)";
                  } else if (trade.city.length > 100) {
                     newErrorHighlight.tradeRefInfo[index].city.error = true;
                     newErrorHighlight.tradeRefInfo[index].city.message = "Cannot exceed 100 characters";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].city.error = false;
                     newErrorHighlight.tradeRefInfo[index].city.message = "";
                  }

                  // state validations
                  if (trade.state.length > 2 && trade.state.length >= 1) {
                     newErrorHighlight.tradeRefInfo[index].state.error = true;
                     newErrorHighlight.tradeRefInfo[index].state.message = "Please enter a valid two-letter state code";
                  } else if (trade.state.length < 2 && trade.state.length >= 1) {
                     newErrorHighlight.tradeRefInfo[index].state.error = true;
                     newErrorHighlight.tradeRefInfo[index].state.message = "Must have a minimum of 2 characters";
                  } else if (trade.state.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].state.error = true;
                     newErrorHighlight.tradeRefInfo[index].state.message = "Please enter a valid two-letter state code";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].state.error = false;
                     newErrorHighlight.tradeRefInfo[index].state.message = "";
                  }

                  // country validations
                  if (!trade.country && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].country.error = true;
                     newErrorHighlight.tradeRefInfo[index].country.message = "Please select a valid country";
                  } else if (trade.country.length < 1 && buttonEvent) {
                     newErrorHighlight.tradeRefInfo[index].country.error = true;
                     newErrorHighlight.tradeRefInfo[index].country.message = "Please select a valid country";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].country.error = false;
                     newErrorHighlight.tradeRefInfo[index].country.message = "";
                  }

                  // phone validations
                  if (!trade.phone.match(numbersOnly) && trade.phone.length >= 1) {
                     newErrorHighlight.tradeRefInfo[index].phone.error = true;
                     newErrorHighlight.tradeRefInfo[index].phone.message = "No alphabet or special characters allowed";
                  } else if (trade.phone.length != 10 && trade.phone.length >= 1) {
                     newErrorHighlight.tradeRefInfo[index].phone.error = true;
                     newErrorHighlight.tradeRefInfo[index].phone.message =
                        "Please enter a valid phone number (10 number characters)";
                  } else if (trade.phone.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].phone.error = true;
                     newErrorHighlight.tradeRefInfo[index].phone.message = "Please enter the phone number";
                  } else if (phoneDuplicates.includes(trade.phone) && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].phone.error = true;
                     newErrorHighlight.tradeRefInfo[index].phone.message = "Phone # must be unique!";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].phone.error = false;
                     newErrorHighlight.tradeRefInfo[index].phone.message = "";
                  }

                  // email validations
                  if (!trade.email && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].email.error = true;
                     newErrorHighlight.tradeRefInfo[index].email.message = "Please enter a valid email";
                  } else if (!trade.email.match(validEmail) && trade.email.length >= 1) {
                     newErrorHighlight.tradeRefInfo[index].email.error = true;
                     newErrorHighlight.tradeRefInfo[index].email.message = "Please enter a valid email";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].email.error = false;
                     newErrorHighlight.tradeRefInfo[index].email.message = "";
                  }

                  // address validation
                  if (trade.address.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].address.error = true;
                     newErrorHighlight.tradeRefInfo[index].address.message = "Please enter the address.";
                  } else if (trade.address.length > 100) {
                     newErrorHighlight.tradeRefInfo[index].address.error = true;
                     newErrorHighlight.tradeRefInfo[index].address.message = "Cannot exceed 100 characters";
                  } else if (trade.address.match(allowSomeSpecialCharWithHashtag)) {
                     newErrorHighlight.tradeRefInfo[index].address.error = true;
                     newErrorHighlight.tradeRefInfo[index].address.message = "Cannot contain (!, $, %, @)";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].address.error = false;
                     newErrorHighlight.tradeRefInfo[index].address.message = "";
                  }

                  if (trade.contact.length > 100) {
                     newErrorHighlight.tradeRefInfo[index].contact.error = true;
                     newErrorHighlight.tradeRefInfo[index].contact.message = "Cannot exceed 100 characters";
                  } else if (
                     trade.contact.match(allowSomeSpecialCharWithAtAmpersandPeriod) &&
                     trade.contact.length >= 1
                  ) {
                     newErrorHighlight.tradeRefInfo[index].contact.error = true;
                     newErrorHighlight.tradeRefInfo[index].contact.message = "Cannot contain (!, $, %, #, &)";
                  } else if (!trade.contact.match(alphabetOnly) && trade.contact.length >= 1) {
                     newErrorHighlight.tradeRefInfo[index].contact.error = true;
                     newErrorHighlight.tradeRefInfo[index].contact.message = "Cannot contain numbers";
                  } else if (trade.contact.length < 1 && (buttonEvent || nextClicked)) {
                     newErrorHighlight.tradeRefInfo[index].contact.error = true;
                     newErrorHighlight.tradeRefInfo[index].contact.message = "Please enter the contact";
                  } else {
                     newErrorHighlight.tradeRefInfo[index].contact.error = false;
                     newErrorHighlight.tradeRefInfo[index].contact.message = "";
                  }
               });
            }
         } else {
            newErrorHighlight.tradeRefInfo.forEach((error) => {
               error.city.error = false;
               error.city.message = "";
               error.company.error = false;
               error.company.message = "";
               error.contact.error = false;
               error.contact.message = "";
               error.email.error = false;
               error.email.message = "";
               error.phone.error = false;
               error.phone.message = "";
               error.state.error = false;
               error.state.message = "";
               error.country.error = false;
               error.country.message = "";
               error.address.error = false;
               error.address.message = "";
            });
         }

         // E-Signature
         if (activeStep === 6) {
            if (!application.signature && (buttonEvent || nextClicked)) {
               newErrorHighlight.signature.error = true;
               newErrorHighlight.signature.message = "We must have your signature to move forward";
            } else {
               newErrorHighlight.signature.error = false;
               newErrorHighlight.signature.message = "";
            }

            if (!application.signatureTitle && (buttonEvent || nextClicked)) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Please enter the title";
            } else if (application.signatureTitle.length > 100) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Cannot exceed 100 characters";
            } else if (application.signatureTitle.match(allowSomeSpecialCharNoAmpersand)) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Cannot contain (!, $, %, #, @, &)";
            } else {
               newErrorHighlight.signatureTitle.error = false;
               newErrorHighlight.signatureTitle.message = "";
            }

            if (!termsAndConditions) {
               newErrorHighlight.termsAndConditions.error = true;
               newErrorHighlight.termsAndConditions.message = "You must agree to the terms and conditions";
            } else {
               newErrorHighlight.termsAndConditions.error = false;
               newErrorHighlight.termsAndConditions.message = "";
            }
         }

         //signature
         if (activeStep === 7) {
            if (!application.signature && (buttonEvent || nextClicked)) {
               newErrorHighlight.signature.error = true;
               newErrorHighlight.signature.message = "We must have your signature to move forward";
            } else {
               newErrorHighlight.signature.error = false;
               newErrorHighlight.signature.message = "";
            }

            // signatureTitle validation
            if (!application.signatureTitle && (buttonEvent || nextClicked)) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Please enter the title";
            } else if (application.signatureTitle.length > 100) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Cannot exceed 100 characters";
            } else if (application.signatureTitle.match(allowSomeSpecialCharNoAmpersand)) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Cannot contain (!, $, %, #, @, &)";
            } else {
               newErrorHighlight.signatureTitle.error = false;
               newErrorHighlight.signatureTitle.message = "";
            }
         }

         setErrorHighlight(newErrorHighlight);
      } catch (err) {
         handleError(err);
      }
   }

   async function onNextClicked() {
      try {
         setNextClicked(true);
         await validatePage(true);

         if (
            _.filter(errorHighlight, { error: true }).length > 0 ||
            _.filter(errorHighlight.bankInfo, { error: true }).length > 0 ||
            _.filter(errorHighlight.chb, { error: true }).length > 0 ||
            _.filter(errorHighlight.billingAddress, { error: true }).length > 0 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.city.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.company.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.state.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.country.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.phone.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.website.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.email.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.contact.error) > -1
         ) {
            return;
         } else {
            setNextClicked(false);
            await makeApplicationRequest(application);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function makeApplicationRequest(application: Applications) {
      setSaving(true);

      const responseData = await setInviteApplication(application);

      setSaving(false);
      if (!responseData.success) {
         alert("Data was unable to save successfully. Please refresh the page and try again.");
         return;
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   }

   async function submitApplicationClicked() {
      try {
         setNextClicked(true);
         await validatePage(true);

         if (_.filter(errorHighlight, { error: true }).length > 0) {
            return;
         } else {
            setNextClicked(false);

            setSaving(true);

            const newApplication = { ...application };
            newApplication.signatureDate = new Date();
            newApplication.status = "NEW";

            const responseData = await setInviteApplication(newApplication);
            setSaving(false);

            if (!responseData.success) {
               alert("Data was unable to save successfully. Please refresh the page and try again.");
               return;
            } else {
               setApplication(responseData.data);
            }
         }

         setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (err) {
         handleError(err);
      }
   }

   async function onBackClicked() {
      try {
         setActiveStep((prevActiveStep) => prevActiveStep - 1);
         setNextClicked(false);
         setTermsAndConditions(false);
      } catch (err) {
         handleError(err);
      }
   }

   async function addTradeRefInfo() {
      try {
         const newApplication = { ...application };
         const newErrorHighlight = { ...errorHighlight };

         newErrorHighlight.tradeRefInfo.push({
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
            website: { error: false, message: "" },
            ctpatNumber: { error: false, message: "" },
         });

         newApplication.tradeRefInfo.push({ ...mockTradeRefInfo });

         setErrorHighlight(newErrorHighlight);
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function removeTradeRefInfo(index: number) {
      try {
         const newApplication = { ...application };
         const newErrorHighlight = { ...errorHighlight };

         newApplication.tradeRefInfo.splice(index, 1);
         newErrorHighlight.tradeRefInfo.splice(index, 1);

         setErrorHighlight(newErrorHighlight);
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   const classes = useApplyStyles({ activeStep });

   if (loading) {
      return <CheckAnimation />;
   }

   if (tokenExpired) {
      return <ExpiredUWLWDS isUWL={isUWL} />;
   }

   if (showLoading) {
      return (
         <div className={"divbodyUWL"}>
            <div className="bk-color">
               <LandingPage handleGoToSteps={() => setShowLoading(false)} />
            </div>
         </div>
      );
   }

   if (showLandingPage) {
      return (
         <div className={"divbodyUWL"}>
            <div className="bk-color">
               <LandingPage handleGoToSteps={() => setShowLandingPage(false)} />
            </div>
         </div>
      );
   }

   const isThereSomeError =
      (_.filter(errorHighlight, { error: true }).length > 0 ||
         _.filter(errorHighlight.bankInfo, { error: true }).length > 0 ||
         _.filter(errorHighlight.chb, { error: true }).length > 0 ||
         _.filter(errorHighlight.billingAddress, { error: true }).length > 0 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.city.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.company.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.state.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.country.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.phone.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.website.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.email.error) > -1 ||
         errorHighlight.tradeRefInfo.findIndex((x) => x.contact.error) > -1) &&
      nextClicked;

   return (
      <div className={"divbodyUWL"}>
         <div className="bk-color">
            <Box pt="40px">
               <StepperUWLWDS activeStep={activeStep} />
            </Box>
            <div style={{ marginTop: "24px", marginBottom: "30px", paddingLeft: "0px", paddingRight: "0px" }}>
               <Slide
                  direction={activeStep === 0 ? "left" : "right"}
                  in={activeStep === 0}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <CompanyInformation
                        application={application}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        handleChangeAddress={handleChangeAddress}
                        errorHighlight={errorHighlight}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 1 ? "left" : "right"}
                  in={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <BillingInformation
                        isChecked={isCompanyAddressChecked}
                        copyCompanyAddress={copyCompanyAddress}
                        billingAddress={application.billingAddress}
                        handleChange={handleChangeBilling}
                        errorHighlight={errorHighlight.billingAddress}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 2 ? "left" : "right"}
                  in={activeStep === 2}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <ChbInfo
                        chb={application.chb}
                        handleChange={handleChangeChb}
                        handleChangeCheck={handleChangeChbCheck}
                        errorHighlight={errorHighlight.chb}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 3 ? "left" : "right"}
                  in={activeStep === 3}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <CreditRequest
                        application={application}
                        handleChange={handleChangeCreditRequest}
                        errorHighlight={errorHighlight}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 4 ? "left" : "right"}
                  in={activeStep === 4}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <BankingInfo
                        bankInfo={application.bankInfo}
                        handleChange={handleChangeBankInfo}
                        errorHighlight={errorHighlight.bankInfo}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 5 ? "left" : "right"}
                  in={activeStep === 5}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <TradeReference
                        tradeRefInfo={application.tradeRefInfo}
                        tradeReferencesSkipped={application.tradeReferencesSkipped}
                        tradeReferencesFileName={application.tradeReferencesFileName}
                        handleChange={handleChangeTradeRefInfo}
                        errorHighlight={errorHighlight.tradeRefInfo}
                        addTradeRefInfo={addTradeRefInfo}
                        removeTradeRefInfo={removeTradeRefInfo}
                        applicationID={application._id}
                        saving={saving}
                        isThereSomeError={isThereSomeError || false}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 6 ? "left" : "right"}
                  in={activeStep === 6}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Paper elevation={0} className={classes.stepContainer}>
                     <SignComplete
                        application={application}
                        setApplication={setApplication}
                        handleChange={handleChangesignature}
                        handleCheckChange={handleChangeTermsAndConditionsCheck}
                        errorHighlight={errorHighlight}
                     />
                  </Paper>
               </Slide>
               <Slide
                  direction={activeStep === 7 ? "left" : "right"}
                  in={activeStep === 7}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 500, exit: 0 }}
               >
                  <Box>
                     <Review application={application} handleCheckChange={handleCheckChange} />
                  </Box>
               </Slide>
            </div>
            {/* Back, Next bottons */}
            <ButtonGroup className={classes.buttonContainer}>
               {/* Back button */}
               {activeStep === 0 || activeStep === 7 ? null : (
                  <Button
                     variant="contained"
                     onClick={() => onBackClicked()}
                     disabled={saving}
                     className={clsx(classes.backButton, classes.commonButton)}
                     startIcon={<ArrowBackIcon />}
                  >
                     BACK
                  </Button>
               )}
               {/* Next button */}
               {activeStep === 7 ? null : (
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => {
                        activeStep === 6 ? submitApplicationClicked() : onNextClicked();
                     }}
                     className={clsx(classes.nextButton, classes.commonButton)}
                     disabled={saving || isThereSomeError}
                     endIcon={
                        saving ? (
                           <CircularProgress size={20} className={classes.buttonProgress} />
                        ) : (
                           <ArrowForwardIcon />
                        )
                     }
                  >
                     {activeStep === 6 ? "SUBMIT APPLICATION" : "NEXT"}
                  </Button>
               )}
            </ButtonGroup>
         </div>
      </div>
   );
};

export default ApplyUWLWDS;
