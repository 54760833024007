import { Box, Step, StepConnector, StepLabel, Stepper, Typography, withStyles } from "@material-ui/core";
import { StepIconProps } from "@material-ui/core/StepIcon";
import DoneIcon from "@material-ui/icons/Done";
import clsx from "clsx";
import React from "react";

import { useStepperStyles } from "./Styles";

const STEPS = [
   "Company Information",
   "Billing Information",
   "CHB Information",
   "Credit Request",
   "Banking Information",
   "Trade References",
   "E-Sign",
   "Review",
] as const;

type StepType =
   | "Company Information"
   | "Billing Information"
   | "CHB Information"
   | "Credit Request"
   | "Banking Information"
   | "Trade References"
   | "E-Sign"
   | "Review";

const StepConnectorUWL_WDS = withStyles({
   alternativeLabel: {
      top: "40px",
      left: "calc(-50% + 60px)",
      right: "calc(50% + 60px)",
      position: "absolute",
      minWidth: "15px",
      maxWidth: "60px",
   },
   active: {
      "& $line": {
         borderColor: "#043F60",
      },
   },
   completed: {
      "& $line": {
         borderColor: "#043F60",
      },
   },
   line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 1,
      borderRadius: 1,
   },
})(StepConnector);

type Props = {
   activeStep: number;
};

function StepIcon(props: StepIconProps) {
   React.useRef();
   const classes = useStepperStyles();
   const { active, icon, completed } = props;

   const icons: { [key: number]: string } = {
      1: "/office-building.png",
      2: "/bill.png",
      3: "/quote-request.png",
      4: "/credit.png",
      5: "/piggy-bank.png",
      6: "/trade.png",
      7: "/global-network.png",
      8: "/review.png",
   };

   if (active) {
      const anyIcon = icon as number;

      return (
         <div className={classes.iconContainer}>
            <div className={classes.activeIcon}>
               <img src={icons[anyIcon]} alt="icon" />
            </div>
         </div>
      );
   }

   if (completed) {
      return (
         <div className={classes.iconContainer}>
            <div className={clsx(classes.completed)}>
               <DoneIcon />
            </div>
         </div>
      );
   }

   return (
      <div className={classes.iconContainer}>
         <div className={clsx(classes.circle)}>{icon}</div>
      </div>
   );
}

export function StepperUWLWDS({ activeStep }: Props) {
   const classes = useStepperStyles();

   return (
      <>
         <Box className={classes.headerContainer}>
            <Typography variant="h3" component={"h2"} className={classes.textColor}>
               {STEPS[activeStep]}
            </Typography>
            <Typography component="h2" className={clsx(classes.textColor, classes.smallText)}>
               For the best experience use Chrome, Edge, or Brave browsers
            </Typography>
         </Box>
         <Stepper
            alternativeLabel
            activeStep={activeStep}
            className={classes.stepper}
            connector={<StepConnectorUWL_WDS />}
         >
            {STEPS.map((label: StepType) => (
               <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon} className={classes.stepperLabel}>
                     {label}
                  </StepLabel>
               </Step>
            ))}
         </Stepper>
      </>
   );
}
