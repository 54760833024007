import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { ActivationBaseDialogHOC } from "../ActivationBaseDialogHOC";

interface Props {
   open: boolean;
   value: string;
   handleClose: () => void;
   callback: () => Promise<void>;
   setValue: (notes: string) => void;
}

export const ActivationNotesDialog: React.FC<Props> = ({ open, value, handleClose, callback, setValue }) => {
   const [isSaving, setIsSaving] = useState(false);

   const handleSave = async () => {
      setIsSaving(true);
      await callback();
      setIsSaving(false);
      handleClose();
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
   };

   return (
      <ActivationBaseDialogHOC
         open={open}
         title="Update Notes"
         primaryButtonTitle="SAVE CHANGES"
         secondaryButtonTitle="DISCARD CHANGES"
         isSaving={isSaving}
         handleClose={handleClose}
         handleAction={handleSave}
      >
         <TextField
            id="tf-applicationNotes"
            label="Activation Notes"
            name="activationNotes"
            multiline
            minRows={7}
            maxRows={7}
            variant="outlined"
            value={value}
            onChange={handleChange}
         />
      </ActivationBaseDialogHOC>
   );
};
