/* eslint-disable react/react-in-jsx-scope */
import { TextField } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { ActivationBaseDialogHOC } from "../ActivationBaseDialogHOC";

type Props = {
   companyCode: string;
   open: boolean;
   handleClose: () => void;
   callback: () => Promise<void>;
   setValue: (input: string) => void;
};

export function CustomerCodeDialog({ companyCode, open, handleClose, callback, setValue }: Props) {
   const [isSaving, setIsSaving] = useState(false);

   const handleSave = async () => {
      setIsSaving(true);
      await callback();
      setIsSaving(false);
      handleClose();
   };

   const handleEmailInputChange = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
   };

   return (
      <>
         <ActivationBaseDialogHOC
            open={open}
            title="Update Customer Code"
            primaryButtonTitle="SAVE CHANGES"
            secondaryButtonTitle="DISCARD CHANGES"
            isSaving={isSaving}
            handleClose={handleClose}
            handleAction={handleSave}
         >
            <TextField
               variant="outlined"
               placeholder="Customer Code"
               name="customerCode"
               size="small"
               label="Customer Code"
               fullWidth
               value={companyCode}
               onChange={handleEmailInputChange}
               inputProps={{ style: { height: "40px" } }}
            />
         </ActivationBaseDialogHOC>
      </>
   );
}
