import { Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { green } from "@material-ui/core/colors";
import { Theme, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Check from "@material-ui/icons/Check";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getInviteApplication, setInviteApplication } from "../../api/ApplicationApi";
import CheckAnimation from "../../styles/CheckAnimation";
import { Applications } from "../../types/Applications";
import { TradeRefInfo } from "../../types/tradeRefInfo";
import { DateFormatter } from "../../utilities/DateFormatter";
import ApplicationConfirmation from "./ApplicationConfirmation";
import "./Apply.css";
import BankingInfo from "./BankingInfo";
import CreditRequest from "./CreditRequest";
import CustomerInfo from "./CustomerInfo";
import ExpiredBristol from "./Expired/ExpiredBristol";
import ExpiredContainerPort from "./Expired/ExpiredContainerPort";
import ExpiredMiddleBay from "./Expired/ExpiredMiddleBay";
import SignComplete from "./SignComplete";
import TradeReference from "./TradeReference";

export const Apply: React.FC<any> = ({
   match,
}: RouteComponentProps<{ division: string; email: string; applicationID: string }>): JSX.Element => {
   const history = useHistory();
   const handleError = useErrorHandler();

   const [application, setApplication] = useState<Applications>({
      _id: "",
      applicationNumber: "",
      email: "",
      companyName: "",
      companyEstablishedDate: null,
      accountsPayableEmail: "",
      companyEmail: "",
      companyContact: "",
      companyWebsite: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      billingAddress: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         email: "",
         phone: "",
         contact: "",
      },
      chb: {
         _id: "",
         uwlHandleChb: false,
         achImporter: false,
         companyName: "",
         contact: "",
         address: "",
         email: "",
         city: "",
         state: "",
         zip: "",
         phone: "",
      },
      companyPhone: "",
      status: "",
      amountReq: 0,
      numberEmployees: 0,
      annualRevenue: 0,
      method: {
         code: "",
         value: "",
      },
      ownership: "",
      documents: [],
      bankInfo: {
         duns: "",
         bankName: "",
         accountNumber: "",
         principalName: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         contact: "",
         phone: "",
         country: "",
         federalTaxNumber: "",
         paymentMethod: "",
      },
      tradeRefInfo: [
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
      ],
      tradeReferencesFileName: "",
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      code: "",
      approvedBy_id: "",
      approvedAmount: 0,
      netTerms: "",
      company_id: "",
      division_id: "",
      isCOD: null,
      notes: "",
      companyCode: "",
      fedTax_id: "",
      referral: [],
      notifiedBy_id: "",
      notifiedDate: null,
      docuSignEnvelope_id: "",
      docuSignCompleted: null,
      approvedOrDeniedDate: null,
      contacts: [],
      applicationOpened: null,
      addDate: new Date(),
      deleteDate: null,
      tradeReferencesSkipped: false,
   });

   const [errorHighlight, setErrorHighlight] = React.useState({
      companyName: { error: false, message: "" },
      companyEstablishedDate: { error: false, message: "" },
      accountsPayableEmail: { error: false, message: "" },
      companyContact: { error: false, message: "" },
      address1: { error: false, message: "" },
      address2: { error: false, message: "" },
      city: { error: false, message: "" },
      state: { error: false, message: "" },
      zip: { error: false, message: "" },
      email: { error: false, message: "" },
      phoneNumber: { error: false, message: "" },
      country: { error: false, message: "" },
      perDiem: { error: false, message: "" },
      amountReq: { error: false, message: "" },
      numberEmployees: { error: false, message: "" },
      annualRevenue: { error: false, message: "" },
      method: { error: false, message: "" },
      ownership: { error: false, message: "" },
      documents: { error: false, message: "" },
      dunsNumber: { error: false, message: "" },
      bankName: { error: false, message: "" },
      accountNumber: { error: false, message: "" },
      principalName: { error: false, message: "" },
      contact: { error: false, message: "" },
      fax: { error: false, message: "" },
      bankAddress: { error: false, message: "" },
      bankCity: { error: false, message: "" },
      bankState: { error: false, message: "" },
      bankZip: { error: false, message: "" },
      bankPhoneNumber: { error: false, message: "" },
      bankCountry: { error: false, message: "" },
      tradeRefInfo: [
         {
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
         },
         {
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
         },
         {
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
         },
      ],
      signature: { error: false, message: "" },
      signatureTitle: { error: false, message: "" },
   });

   const [activeStep, setActiveStep] = React.useState(0);
   const [loading, setLoading] = React.useState(true);
   const [tokenExpired, setExpired] = React.useState(false);
   const [isBristol, setBristol] = React.useState(false);
   const [isMiddleBay, setMiddleBay] = React.useState(false);
   const [isContainerPort, setContainerPort] = React.useState(false);
   const [nextClicked, setNextClicked] = React.useState(false);
   const [saving, setSaving] = React.useState(false);
   const [bankAccountEntered, setBankAccountEntered] = React.useState(false);
   const [showDisclaimer, setShowDisclaimer] = React.useState(true);
   const [perDiem, setPerDiem] = React.useState(false);
   const steps = getSteps();

   useEffect(() => {
      getApplication();
   }, []);

   useEffect(() => {
      //If loading, go through the fun of figuring out what page they were on.
      if (loading && application._id) {
         if (
            application.companyName &&
            application.companyEstablishedDate &&
            application.accountsPayableEmail &&
            application.companyContact &&
            application.address.address1 &&
            application.address.city &&
            application.address.state &&
            application.address.country &&
            application.address.zip &&
            application.companyEmail &&
            application.companyPhone
         ) {
            setActiveStep(1);
         }

         if (
            application.amountReq > 0 &&
            application.numberEmployees > 0 &&
            application.annualRevenue > 0 &&
            application.method.code &&
            (application.method.code.toUpperCase() === "EMAIL" || application.method.code.toUpperCase() === "OTHER"
               ? application.method.value
               : true) &&
            application.ownership &&
            application.documents.length > 0
         ) {
            setPerDiem(true);
            setActiveStep(2);
         }

         if (
            application.bankInfo.duns &&
            application.bankInfo.bankName &&
            application.bankInfo.principalName &&
            application.bankInfo.accountNumber &&
            application.bankInfo.address1 &&
            application.bankInfo.city &&
            application.bankInfo.state &&
            application.bankInfo.zip &&
            application.bankInfo.country &&
            application.bankInfo.contact &&
            application.bankInfo.phone
         ) {
            setPerDiem(true);
            setActiveStep(3);
         }

         if (application.tradeRefInfo.length >= 3) {
            if (
               application.tradeRefInfo[0].company &&
               application.tradeRefInfo[0].city &&
               application.tradeRefInfo[0].state &&
               application.tradeRefInfo[0].country &&
               application.tradeRefInfo[0].contact &&
               application.tradeRefInfo[0].phone &&
               application.tradeRefInfo[1].company &&
               application.tradeRefInfo[1].city &&
               application.tradeRefInfo[1].state &&
               application.tradeRefInfo[0].country &&
               application.tradeRefInfo[1].contact &&
               application.tradeRefInfo[1].phone &&
               application.tradeRefInfo[2].company &&
               application.tradeRefInfo[2].city &&
               application.tradeRefInfo[2].state &&
               application.tradeRefInfo[0].country &&
               application.tradeRefInfo[2].contact &&
               application.tradeRefInfo[2].phone
            ) {
               setPerDiem(true);
               setActiveStep(4);
            }
         }

         //This prevents undefined errors by appending the correct amount of in the tradeRefInfo error array.
         if (application.tradeRefInfo.length > 3) {
            const newErrorHighlight = { ...errorHighlight };

            while (application.tradeRefInfo.length > newErrorHighlight.tradeRefInfo.length) {
               newErrorHighlight.tradeRefInfo.push({
                  company: { error: false, message: "" },
                  address: { error: false, message: "" },
                  city: { error: false, message: "" },
                  state: { error: false, message: "" },
                  country: { error: false, message: "" },
                  phone: { error: false, message: "" },
                  email: { error: false, message: "" },
                  contact: { error: false, message: "" },
               });
            }

            setErrorHighlight(newErrorHighlight);
         } else if (application.tradeRefInfo.length < 1) {
            application.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });

            application.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });

            application.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });
         } else if (application.tradeRefInfo.length < 2) {
            application.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });

            application.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });
         } else if (application.tradeRefInfo.length < 3) {
            application.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });
         }

         if (application.bankInfo.accountNumber) {
            setBankAccountEntered(true);
         }

         setLoading(false);
      }

      validatePage(false);
   }, [application, perDiem]);

   async function getApplication() {
      try {
         setLoading(true);
         const responseData = await getInviteApplication(match.params.applicationID);

         if (responseData.success) {
            //Default the country if not entered to United States.
            if (!responseData.data.address.country) {
               responseData.data.address.country = "United States";
            }

            //Default the country if not entered to United States.
            if (!responseData.data.bankInfo.country) {
               responseData.data.bankInfo.country = "United States";
            }

            //Default the method code to Email.
            if (!responseData.data.method.code) {
               responseData.data.method.code = "Email";
            }

            //Default the ownership code to Corporation.
            if (!responseData.data.ownership) {
               responseData.data.ownership = "Corporation";
            }

            responseData.data.tradeRefInfo.forEach((trade: TradeRefInfo, index: number) => {
               if (!trade.company) {
                  responseData.data.tradeRefInfo[index].country = "United States";
               }
            });

            if (match.params.division.toUpperCase() === "BRISTOL") {
               setBristol(true);
               setPerDiem(true);
            } else if (match.params.division.toUpperCase() === "MIDDLEBAY") {
               setMiddleBay(true);
               setPerDiem(true);
            } else {
               setContainerPort(true);
            }

            setApplication(responseData.data);
         } else if (!responseData.data) {
            setExpired(true);

            if (match.params.division.toUpperCase() === "BRISTOL") {
               setBristol(true);
               setPerDiem(true);
            } else if (match.params.division.toUpperCase() === "MIDDLEBAY") {
               setMiddleBay(true);
               setPerDiem(true);
            } else {
               setContainerPort(true);
            }

            setLoading(false);
         } else if (responseData.data.status === "EXPIRED") {
            setExpired(true);

            if (match.params.division.toUpperCase() === "BRISTOL") {
               setBristol(true);
               setPerDiem(true);
            } else if (match.params.division.toUpperCase() === "MIDDLEBAY") {
               setMiddleBay(true);
               setPerDiem(true);
            } else {
               setContainerPort(true);
            }

            setApplication(responseData.data);
         } else {
            setExpired(true);

            if (match.params.division.toUpperCase() === "BRISTOL") {
               setBristol(true);
               setPerDiem(true);
            } else if (match.params.division.toUpperCase() === "MIDDLEBAY") {
               setMiddleBay(true);
               setPerDiem(true);
            } else {
               setContainerPort(true);
            }

            setApplication(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   const QontoConnector = withStyles({
      alternativeLabel: {
         top: 10,
         left: "calc(-50% + 16px)",
         right: "calc(50% + 16px)",
      },
      active: {
         "& $line": {
            borderColor:
               match.params.division.toUpperCase() === "MIDDLEBAY"
                  ? "#8F9864"
                  : match.params.division.toUpperCase() === "BRISTOL"
                  ? "#1E376B"
                  : "#002968",
         },
      },
      completed: {
         "& $line": {
            borderColor:
               match.params.division.toUpperCase() === "MIDDLEBAY"
                  ? "#8F9864"
                  : match.params.division.toUpperCase() === "BRISTOL"
                  ? "#1E376B"
                  : "#002968",
         },
      },
      line: {
         borderColor: "#eaeaf0",
         borderTopWidth: 3,
         borderRadius: 1,
      },
   })(StepConnector);

   const useQontoStepIconStyles = makeStyles({
      root: {
         color: "#eaeaf0",
         display: "flex",
         height: 22,
         alignItems: "center",
      },
      active: {
         color:
            match.params.division.toUpperCase() === "MIDDLEBAY"
               ? "#8F9864"
               : match.params.division.toUpperCase() === "BRISTOL"
               ? "#1E376B"
               : "#002968",
      },
      circle: {
         width: 8,
         height: 8,
         borderRadius: "50%",
         backgroundColor: "currentColor",
      },
      completed: {
         color:
            match.params.division.toUpperCase() === "MIDDLEBAY"
               ? "#8F9864"
               : match.params.division.toUpperCase() === "BRISTOL"
               ? "#1E376B"
               : "#002968",
         zIndex: 1,
         fontSize: 18,
      },
   });

   function QontoStepIcon(props: StepIconProps) {
      const classes = useQontoStepIconStyles();
      const { active, completed } = props;

      return (
         <div
            className={clsx(classes.root, {
               [classes.active]: active,
            })}
         >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
         </div>
      );
   }

   function getSteps() {
      return ["Company Information", "Credit Request", "Banking Information", "Trade References", "E-Sign", "Review"];
   }

   const useStyles = makeStyles((theme: Theme) =>
      createStyles({
         formControl: {
            width: "-webkit-fill-available",
         },
         selectEmpty: {
            marginTop: theme.spacing(2),
         },
         outerPaper: {
            padding: "1%",
            height: "calc(100vh - 109px)",
            borderRadius: "0px",
         },
         label: {
            flexDirection: "column",
            fontSize: "11px",
         },
         buttonProgress: {
            color: green[500],
         },
         stepLabel: {
            fontSize: "16px",
         },
      }),
   );

   async function handleChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value:
         | "companyName"
         | "accountsPayableEmail"
         | "companyContact"
         | "companyEmail"
         | "companyPhone"
         | "amountReq"
         | "numberEmployees"
         | "annualRevenue"
         | "ownership"
         | "duns"
         | "bankName"
         | "principalName"
         | "accountNumber"
         | "contact"
         | "phone"
         | "signatureDate"
         | "signatureTitle",
   ) {
      try {
         const newApplication = { ...application };

         if (value === "companyName") {
            newApplication[value] = event.target.value ? event.target.value.toUpperCase() : "";
         } else if (value === "amountReq") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.amountReq = onlyNums ? +onlyNums : 0;
         } else if (value === "numberEmployees") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.numberEmployees = onlyNums ? +onlyNums : 0;
         } else if (value === "annualRevenue") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.annualRevenue = onlyNums ? +onlyNums : 0;
         } else if (value === "companyPhone") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.companyPhone = onlyNums ? onlyNums.toString() : "";
         } else if (value === "duns") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.bankInfo.duns = onlyNums ? onlyNums.toString() : "";
         } else if (value === "bankName") {
            newApplication.bankInfo[value] = event.target.value ? event.target.value : "";
         } else if (value === "principalName") {
            const onlyAlpha = event.target.value.replace(/[0-9]/g, "");
            newApplication.bankInfo[value] = onlyAlpha ? onlyAlpha.toString() : "";
         } else if (value === "accountNumber") {
            setBankAccountEntered(false);
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.bankInfo[value] = onlyNums ? onlyNums.toString() : "";
         } else if (value === "contact") {
            const onlyAlpha = event.target.value.replace(/[0-9]/g, "");
            newApplication.bankInfo[value] = onlyAlpha ? onlyAlpha.toString() : "";
         } else if (value === "phone") {
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            newApplication.bankInfo[value] = onlyNums ? onlyNums.toString() : "";
         } else if (value === "signatureDate") {
            newApplication[value] = event.target.value ? new Date(event.target.value) : null;
         } else {
            newApplication[value] = event.target.value ? event.target.value : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeDate(event: MaterialUiPickersDate) {
      try {
         const newApplication = { ...application };
         newApplication.companyEstablishedDate = event ? event : null;

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleCheckChange(
      event: React.ChangeEvent<HTMLInputElement>,
      value:
         | "Per Diem"
         | "Work Order"
         | "POD/BOL"
         | "Scale Ticket"
         | "Interchange In/Out"
         | "Accessorial Approval"
         | "Rate Confirmation"
         | "Customs Paperwork"
         | "Driver Detention"
         | "Referral"
         | "Advertisement"
         | "Trade Show/Conference"
         | "Social Media"
         | "Website"
         | "Salesperson",
   ) {
      try {
         const newApplication = { ...application };

         let arr = [];
         let ReferralArr = [];

         if (value === "Per Diem") {
            setPerDiem(!perDiem);
         } else if (
            value === "Referral" ||
            value === "Advertisement" ||
            value === "Trade Show/Conference" ||
            value === "Social Media" ||
            value === "Website" ||
            value === "Salesperson"
         ) {
            ReferralArr = newApplication.referral;

            if (ReferralArr.indexOf(event.target.value) !== -1) {
               ReferralArr.splice(ReferralArr.indexOf(event.target.value), 1);
            } else {
               ReferralArr.push(event.target.value);
            }

            newApplication.referral = ReferralArr;
         } else {
            arr = newApplication.documents;

            if (arr.indexOf(event.target.value) !== -1) {
               arr.splice(arr.indexOf(event.target.value), 1);
            } else {
               arr.push(event.target.value);
            }

            newApplication.documents = arr;
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeButton(
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      value: "Email" | "EDI" | "Other" | "Corporation" | "Partnership" | "Individual" | "OwnershipOther",
   ) {
      try {
         const newApplication = { ...application };

         if (
            value === "Corporation" ||
            value === "Partnership" ||
            value === "Individual" ||
            value === "OwnershipOther"
         ) {
            newApplication.ownership = value === "OwnershipOther" ? "Other" : value;
         }

         if (value === "Email" || value === "EDI" || value === "Other") {
            newApplication.method.code = value;
            newApplication.method.value = "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeAddress(
      event:
         | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value:
         | "address1"
         | "address2"
         | "city"
         | "state"
         | "zip"
         | "country"
         | "bankAddress"
         | "bankCity"
         | "bankState"
         | "bankZip"
         | "bankCountry",
   ) {
      try {
         const newApplication = { ...application };

         if (value === "address1" || value === "address2" || value === "city" || value === "state" || value === "zip") {
            newApplication.address[value] = event.target.value ? (event.target.value as string) : "";
            newApplication.address[value] = newApplication.address[value].toUpperCase();
         } else if (value === "bankAddress") {
            newApplication.bankInfo.address1 = event.target.value ? (event.target.value as string) : "";
         } else if (value === "bankCity") {
            newApplication.bankInfo.city = event.target.value ? (event.target.value as string) : "";
         } else if (value === "bankState") {
            newApplication.bankInfo.state = event.target.value ? (event.target.value as string) : "";
            newApplication.bankInfo.state = newApplication.bankInfo.state.toUpperCase();
         } else if (value === "bankZip") {
            newApplication.bankInfo.zip = event.target.value ? (event.target.value as string) : "";
            newApplication.bankInfo.zip = newApplication.bankInfo.zip.toUpperCase();
         } else if (value === "bankCountry") {
            newApplication.bankInfo.country = event.target.value ? (event.target.value as string) : "";
         } else {
            newApplication.address[value] = event.target.value ? (event.target.value as string) : "";
         }
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeMethod(
      event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      value: "value",
   ) {
      try {
         const newApplication = { ...application };
         newApplication.method[value] = event.target.value ? (event.target.value as string) : "";

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function handleChangeTradeRefInfo(
      event:
         | React.ChangeEvent<HTMLInputElement>
         | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
         | React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
           }>,
      value: "company" | "city" | "state" | "country" | "phone" | "email" | "contact",
      index: number,
   ) {
      try {
         const newApplication = { ...application };

         if (newApplication.tradeRefInfo.length < 1) {
            newApplication.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });

            newApplication.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });

            newApplication.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });
         }

         if (newApplication.tradeRefInfo.length < 2) {
            newApplication.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });

            newApplication.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });
         }

         if (newApplication.tradeRefInfo.length < 3) {
            newApplication.tradeRefInfo.push({
               _id: "",
               company: "",
               address: "",
               city: "",
               state: "",
               country: "United States",
               phone: "",
               email: "",
               contact: "",
            });
         }

         if (value === "phone") {
            const onlyNums = (event.target.value as string).replace(/[^0-9]/g, "");
            newApplication.tradeRefInfo[index][value] = onlyNums ? onlyNums.toString() : "";
         } else if (value === "contact") {
            const onlyAlpha = event.target.value ? (event.target.value as string).replace(/[0-9]/g, "") : "";
            newApplication.tradeRefInfo[index][value] = onlyAlpha ? onlyAlpha.toString() : "";
         } else {
            newApplication.tradeRefInfo[index][value] = event.target.value ? (event.target.value as string) : "";
         }

         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function validatePage(buttonEvent: boolean) {
      try {
         const newErrorHighlight = { ...errorHighlight };

         //allows no characters except for (-)
         const allowSomeSpecialCharOnlyDash = new RegExp(/[!@#?$%><:;"^/*){}&,|(+=._\\]/);

         //allows no characters except for (&,-, ,)
         const allowSomeSpecialChar = new RegExp(/[!@#?$%><:;"^/*){}|(+=._\\]/);

         //allows no characters except for (&, @, ., -)
         const allowSomeSpecialCharWithAtAmpersandPeriod = new RegExp(/[!#?$%><:;"^/*){}|(+=_\\]/);

         //allows no characters except for (&,-, ,, #)
         const allowSomeSpecialCharWithHashtag = new RegExp(/[!@?$%><:;"^/*){}|(+=._\\]/);

         //allows no characters except for (- and ,)
         const allowSomeSpecialCharNoAmpersand = new RegExp(/[&!@#?$%><:;"^/*){}|(+=._\\]/);

         //valid email address
         const validEmail = new RegExp(/^\S+@\S+\.\S+$/);

         //numbers only
         const numbersOnly = new RegExp(/^[0-9]/);

         //alphabet only
         const alphabetOnly = new RegExp(/^([^0-9]*)$/);

         //Company Information
         if (activeStep >= 0) {
            if (application.companyName.length > 100) {
               newErrorHighlight.companyName.error = true;
               newErrorHighlight.companyName.message = "Cannot exceed 100 characters";
            } else if (application.companyName.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyName.error = true;
               newErrorHighlight.companyName.message = "Must have a minimum of 1 character";
            } else if (application.companyName.match(allowSomeSpecialChar)) {
               newErrorHighlight.companyName.error = true;
               newErrorHighlight.companyName.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.companyName.error = false;
               newErrorHighlight.companyName.message = "";
            }

            if (!application.companyEstablishedDate && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyEstablishedDate.error = true;
               newErrorHighlight.companyEstablishedDate.message = "Please enter a valid date";
            } else if (
               DateFormatter(application.companyEstablishedDate) === "00/00/0000" &&
               (buttonEvent || nextClicked)
            ) {
               newErrorHighlight.companyEstablishedDate.error = true;
               newErrorHighlight.companyEstablishedDate.message = "Please enter a valid date";
            } else {
               newErrorHighlight.companyEstablishedDate.error = false;
               newErrorHighlight.companyEstablishedDate.message = "";
            }

            if (!application.accountsPayableEmail.match(validEmail) && application.accountsPayableEmail.length >= 1) {
               newErrorHighlight.accountsPayableEmail.error = true;
               newErrorHighlight.accountsPayableEmail.message = "Please enter a valid email";
            } else if (application.accountsPayableEmail.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.accountsPayableEmail.error = true;
               newErrorHighlight.accountsPayableEmail.message = "Please enter a valid email";
            } else {
               newErrorHighlight.accountsPayableEmail.error = false;
               newErrorHighlight.accountsPayableEmail.message = "";
            }

            if (application.companyContact.length > 100) {
               newErrorHighlight.companyContact.error = true;
               newErrorHighlight.companyContact.message = "Cannot exceed 100 characters";
            } else if (application.companyContact.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.companyContact.error = true;
               newErrorHighlight.companyContact.message = "Must have a minimum of 1 character";
            } else if (application.companyContact.match(allowSomeSpecialCharWithAtAmpersandPeriod)) {
               newErrorHighlight.companyContact.error = true;
               newErrorHighlight.companyContact.message = "Cannot contain (!, $, %, #, @, &)";
            } else {
               newErrorHighlight.companyContact.error = false;
               newErrorHighlight.companyContact.message = "";
            }

            if (application.address.address1.length > 100) {
               newErrorHighlight.address1.error = true;
               newErrorHighlight.address1.message = "Cannot exceed 100 characters";
            } else if (application.address.address1.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.address1.error = true;
               newErrorHighlight.address1.message = "Must have a minimum of 1 character";
            } else if (application.address.address1.match(allowSomeSpecialCharWithHashtag)) {
               newErrorHighlight.address1.error = true;
               newErrorHighlight.address1.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.address1.error = false;
               newErrorHighlight.address1.message = "";
            }

            if (application.address.address2.length > 100) {
               newErrorHighlight.address2.error = true;
               newErrorHighlight.address2.message = "Cannot exceed 100 characters";
            } else if (application.address.address2.match(allowSomeSpecialCharWithHashtag)) {
               newErrorHighlight.address2.error = true;
               newErrorHighlight.address2.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.address2.error = false;
               newErrorHighlight.address2.message = "";
            }

            if (application.address.city.length > 100) {
               newErrorHighlight.city.error = true;
               newErrorHighlight.city.message = "Cannot exceed 100 characters";
            } else if (application.address.city.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.city.error = true;
               newErrorHighlight.city.message = "Must have a minimum of 1 character";
            } else if (application.address.city.match(allowSomeSpecialChar)) {
               newErrorHighlight.city.error = true;
               newErrorHighlight.city.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.city.error = false;
               newErrorHighlight.city.message = "";
            }

            if (application.address.state.length > 2) {
               newErrorHighlight.state.error = true;
               newErrorHighlight.state.message = "Please enter a valid two-letter state code";
            } else if (application.address.state.length < 2 && application.address.state.length >= 1) {
               newErrorHighlight.state.error = true;
               newErrorHighlight.state.message = "Must have a minimum of 2 characters";
            } else if (application.address.state.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.state.error = true;
               newErrorHighlight.state.message = "Must have a minimum of 2 characters";
            } else {
               newErrorHighlight.state.error = false;
               newErrorHighlight.state.message = "";
            }

            if (application.address.zip.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.zip.error = true;
               newErrorHighlight.zip.message = "Must have a minimum of 1 character";
            } else if (
               application.address.zip.match(allowSomeSpecialCharOnlyDash) &&
               application.address.zip.length >= 1
            ) {
               newErrorHighlight.zip.error = true;
               newErrorHighlight.zip.message = "Cannot contain any special characters";
            } else {
               newErrorHighlight.zip.error = false;
               newErrorHighlight.zip.message = "";
            }

            if (!application.address.country && (buttonEvent || nextClicked)) {
               newErrorHighlight.country.error = true;
               newErrorHighlight.country.message = "Please select a valid country";
            } else if (application.address.country.length < 1 && buttonEvent) {
               newErrorHighlight.country.error = true;
               newErrorHighlight.country.message = "Please select a valid country";
            } else {
               newErrorHighlight.country.error = false;
               newErrorHighlight.country.message = "";
            }

            if (!application.companyEmail.match(validEmail) && application.companyEmail.length >= 1) {
               newErrorHighlight.email.error = true;
               newErrorHighlight.email.message = "Please enter a valid email";
            } else if (application.companyEmail.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.email.error = true;
               newErrorHighlight.email.message = "Please enter a valid email";
            } else {
               newErrorHighlight.email.error = false;
               newErrorHighlight.email.message = "";
            }

            if (!application.companyPhone.match(numbersOnly) && application.companyPhone.length >= 1) {
               newErrorHighlight.phoneNumber.error = true;
               newErrorHighlight.phoneNumber.message = "No alphabet or special characters allowed";
            } else if (application.companyPhone.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.phoneNumber.error = true;
               newErrorHighlight.phoneNumber.message = "Please enter a phone number";
            } else if (application.companyPhone.length != 10 && application.companyPhone.length >= 1) {
               newErrorHighlight.phoneNumber.error = true;
               newErrorHighlight.phoneNumber.message = "Please enter a valid phone number (10 number characters)";
            } else {
               newErrorHighlight.phoneNumber.error = false;
               newErrorHighlight.phoneNumber.message = "";
            }
         }

         //Credit Request
         if (activeStep >= 1) {
            if (activeStep == 1) {
               if (!perDiem && (buttonEvent || nextClicked)) {
                  newErrorHighlight.perDiem.error = true;
                  newErrorHighlight.perDiem.message =
                     "Please confirm agreement to the cash outlay terms before proceeding to the next page of the credit application.";
               } else {
                  newErrorHighlight.perDiem.error = false;
                  newErrorHighlight.perDiem.message = "";
               }
            }

            if (application.amountReq <= 0 && (buttonEvent || nextClicked)) {
               newErrorHighlight.amountReq.error = true;
               newErrorHighlight.amountReq.message = "Please enter a credit amount requested.";
            } else {
               newErrorHighlight.amountReq.error = false;
               newErrorHighlight.amountReq.message = "";
            }

            if (application.numberEmployees <= 0 && (buttonEvent || nextClicked)) {
               newErrorHighlight.numberEmployees.error = true;
               newErrorHighlight.numberEmployees.message = "Please enter the total number of employees.";
            } else if (application.numberEmployees > 9999999) {
               newErrorHighlight.numberEmployees.error = true;
               newErrorHighlight.numberEmployees.message = "Number of employess must be under 7 digits in length.";
            } else {
               newErrorHighlight.numberEmployees.error = false;
               newErrorHighlight.numberEmployees.message = "";
            }

            if (application.annualRevenue <= 0 && (buttonEvent || nextClicked)) {
               newErrorHighlight.annualRevenue.error = true;
               newErrorHighlight.annualRevenue.message = "Please enter the annual revenue.";
            } else if (application.annualRevenue > 999999999999999) {
               newErrorHighlight.numberEmployees.error = true;
               newErrorHighlight.numberEmployees.message = "Number of employess must be under 15 digits in length.";
            } else {
               newErrorHighlight.annualRevenue.error = false;
               newErrorHighlight.annualRevenue.message = "";
            }

            if (!application.method.code && (buttonEvent || nextClicked)) {
               newErrorHighlight.method.error = true;
               newErrorHighlight.method.message = "Please select a preferred method of receiving invoices.";
            } else if (application.method.code === "Email" && !application.method.value) {
               newErrorHighlight.method.error = true;
               newErrorHighlight.method.message = "Please enter the email address.";
            } else if (application.method.code === "Email" && !application.method.value.match(validEmail)) {
               newErrorHighlight.method.error = true;
               newErrorHighlight.method.message = "Please enter a valid email address.";
            } else if (application.method.code === "Other" && !application.method.value) {
               newErrorHighlight.method.error = true;
               newErrorHighlight.method.message =
                  "Please enter the other method of how you will be receiving invoices.";
            } else {
               newErrorHighlight.method.error = false;
               newErrorHighlight.method.message = "";
            }

            if (!application.ownership && (buttonEvent || nextClicked)) {
               newErrorHighlight.ownership.error = true;
               newErrorHighlight.ownership.message = "Please select the ownership.";
            } else {
               newErrorHighlight.ownership.error = false;
               newErrorHighlight.ownership.message = "";
            }

            if (application.documents.length === 0 && (buttonEvent || nextClicked)) {
               newErrorHighlight.documents.error = true;
               newErrorHighlight.documents.message = "Please select at least one document.";
            } else {
               newErrorHighlight.documents.error = false;
               newErrorHighlight.documents.message = "";
            }
         } else {
            newErrorHighlight.perDiem.error = false;
            newErrorHighlight.perDiem.message = "";
            newErrorHighlight.amountReq.error = false;
            newErrorHighlight.amountReq.message = "";
            newErrorHighlight.numberEmployees.error = false;
            newErrorHighlight.numberEmployees.message = "";
            newErrorHighlight.annualRevenue.error = false;
            newErrorHighlight.annualRevenue.message = "";
            newErrorHighlight.method.error = false;
            newErrorHighlight.method.message = "";
            newErrorHighlight.ownership.error = false;
            newErrorHighlight.ownership.message = "";
            newErrorHighlight.documents.error = false;
            newErrorHighlight.documents.message = "";
         }

         //Banking Info
         if (activeStep >= 2) {
            if (!application.bankInfo.duns.match(numbersOnly) && application.bankInfo.duns.length >= 1) {
               newErrorHighlight.dunsNumber.error = true;
               newErrorHighlight.dunsNumber.message = "No alphabet or special characters allowed";
            } else if (application.bankInfo.duns.length > 9) {
               newErrorHighlight.dunsNumber.error = true;
               newErrorHighlight.dunsNumber.message = "Maximum length is 9 characters";
            } else {
               newErrorHighlight.dunsNumber.error = false;
               newErrorHighlight.dunsNumber.message = "";
            }

            if (!bankAccountEntered) {
               if (application.bankInfo.accountNumber.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.accountNumber.error = true;
                  newErrorHighlight.accountNumber.message = "Please enter the bank account number.";
               } else if (
                  !application.bankInfo.accountNumber.match(numbersOnly) &&
                  application.bankInfo.accountNumber.length >= 1
               ) {
                  newErrorHighlight.accountNumber.error = true;
                  newErrorHighlight.accountNumber.message = "No alphabet or special characters allowed";
               } else if (application.bankInfo.duns.length > 30) {
                  newErrorHighlight.accountNumber.error = true;
                  newErrorHighlight.accountNumber.message = "Maximum length is 30 characters";
               } else {
                  newErrorHighlight.accountNumber.error = false;
                  newErrorHighlight.accountNumber.message = "";
               }
            }

            if (application.bankInfo.phone.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankPhoneNumber.error = true;
               newErrorHighlight.bankPhoneNumber.message = "Please enter the bank phone number";
            } else if (!application.bankInfo.phone.match(numbersOnly) && application.bankInfo.phone.length >= 1) {
               newErrorHighlight.bankPhoneNumber.error = true;
               newErrorHighlight.bankPhoneNumber.message = "No alphabet or special characters allowed";
            } else if (application.bankInfo.phone.length != 10 && application.bankInfo.phone.length >= 1) {
               newErrorHighlight.bankPhoneNumber.error = true;
               newErrorHighlight.bankPhoneNumber.message = "Please enter a valid phone number (10 number characters)";
            } else {
               newErrorHighlight.bankPhoneNumber.error = false;
               newErrorHighlight.bankPhoneNumber.message = "";
            }

            if (!application.bankInfo.country && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankCountry.error = true;
               newErrorHighlight.bankCountry.message = "Please select a valid country";
            } else if (application.bankInfo.country.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankCountry.error = true;
               newErrorHighlight.bankCountry.message = "Please select a valid country";
            } else {
               newErrorHighlight.bankCountry.error = false;
               newErrorHighlight.bankCountry.message = "";
            }

            if (application.bankInfo.zip.match(allowSomeSpecialCharOnlyDash) && application.bankInfo.zip.length >= 1) {
               newErrorHighlight.bankZip.error = true;
               newErrorHighlight.bankZip.message = "Cannot contain any special characters";
            } else if (application.bankInfo.zip.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankZip.error = true;
               newErrorHighlight.bankZip.message = "Please enter a valid zip code";
            } else {
               newErrorHighlight.bankZip.error = false;
               newErrorHighlight.bankZip.message = "";
            }

            if (application.bankInfo.state.length > 2 && application.bankInfo.state.length >= 1) {
               newErrorHighlight.bankState.error = true;
               newErrorHighlight.bankState.message = "Please enter a valid two-letter state code";
            } else if (application.bankInfo.state.length < 2 && application.bankInfo.state.length >= 1) {
               newErrorHighlight.bankState.error = true;
               newErrorHighlight.bankState.message = "Must have a minimum of 2 characters";
            } else if (application.bankInfo.state.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankState.error = true;
               newErrorHighlight.bankState.message = "Please enter a valid two-letter state code";
            } else {
               newErrorHighlight.bankState.error = false;
               newErrorHighlight.bankState.message = "";
            }

            if (application.bankInfo.city.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankCity.error = true;
               newErrorHighlight.bankCity.message = "Please enter the bank city.";
            } else if (application.bankInfo.city.length > 100) {
               newErrorHighlight.bankCity.error = true;
               newErrorHighlight.bankCity.message = "Cannot exceed 100 characters";
            } else if (application.bankInfo.city.match(allowSomeSpecialCharNoAmpersand)) {
               newErrorHighlight.bankCity.error = true;
               newErrorHighlight.bankCity.message = "Cannot contain (!, $, %, #, @, &)";
            } else {
               newErrorHighlight.bankCity.error = false;
               newErrorHighlight.bankCity.message = "";
            }

            if (application.bankInfo.address1.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankAddress.error = true;
               newErrorHighlight.bankAddress.message = "Please enter the bank address.";
            } else if (application.bankInfo.address1.length > 100) {
               newErrorHighlight.bankAddress.error = true;
               newErrorHighlight.bankAddress.message = "Cannot exceed 100 characters";
            } else if (application.bankInfo.address1.match(allowSomeSpecialCharWithHashtag)) {
               newErrorHighlight.bankAddress.error = true;
               newErrorHighlight.bankAddress.message = "Cannot contain (!, $, %, @)";
            } else {
               newErrorHighlight.bankAddress.error = false;
               newErrorHighlight.bankAddress.message = "";
            }

            if (application.bankInfo.bankName.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.bankName.error = true;
               newErrorHighlight.bankName.message = "Please enter the bank name.";
            } else if (application.bankInfo.bankName.length > 100) {
               newErrorHighlight.bankName.error = true;
               newErrorHighlight.bankName.message = "Cannot exceed 100 characters";
            } else if (application.bankInfo.bankName.match(allowSomeSpecialChar)) {
               newErrorHighlight.bankName.error = true;
               newErrorHighlight.bankName.message = "Cannot contain (!, $, %, #, @)";
            } else {
               newErrorHighlight.bankName.error = false;
               newErrorHighlight.bankName.message = "";
            }

            if (application.bankInfo.principalName.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.principalName.error = true;
               newErrorHighlight.principalName.message = "Please enter the principal name.";
            } else if (application.bankInfo.principalName.length > 100) {
               newErrorHighlight.principalName.error = true;
               newErrorHighlight.principalName.message = "Cannot exceed 100 characters";
            } else if (application.bankInfo.principalName.match(allowSomeSpecialChar)) {
               newErrorHighlight.principalName.error = true;
               newErrorHighlight.principalName.message = "Cannot contain (!, $, %, #, @)";
            } else if (!application.bankInfo.principalName.match(alphabetOnly)) {
               newErrorHighlight.principalName.error = true;
               newErrorHighlight.principalName.message = "Cannot contain numbers";
            } else {
               newErrorHighlight.principalName.error = false;
               newErrorHighlight.principalName.message = "";
            }

            if (application.bankInfo.contact.length < 1 && (buttonEvent || nextClicked)) {
               newErrorHighlight.contact.error = true;
               newErrorHighlight.contact.message = "Please enter the bank contact.";
            } else if (application.bankInfo.contact.length > 100) {
               newErrorHighlight.contact.error = true;
               newErrorHighlight.contact.message = "Cannot exceed 100 characters";
            } else if (application.bankInfo.contact.match(allowSomeSpecialCharWithAtAmpersandPeriod)) {
               newErrorHighlight.contact.error = true;
               newErrorHighlight.contact.message = "Cannot contain (!, $, %, #, @, &)";
            } else if (!application.bankInfo.contact.match(alphabetOnly)) {
               newErrorHighlight.contact.error = true;
               newErrorHighlight.contact.message = "Cannot contain numbers";
            } else {
               newErrorHighlight.contact.error = false;
               newErrorHighlight.contact.message = "";
            }
         } else {
            newErrorHighlight.dunsNumber.error = false;
            newErrorHighlight.dunsNumber.message = "";
            newErrorHighlight.accountNumber.error = false;
            newErrorHighlight.accountNumber.message = "";
            newErrorHighlight.bankPhoneNumber.error = false;
            newErrorHighlight.bankPhoneNumber.message = "";
            newErrorHighlight.fax.error = false;
            newErrorHighlight.fax.message = "";
            newErrorHighlight.bankCountry.error = false;
            newErrorHighlight.bankCountry.message = "";
            newErrorHighlight.bankZip.error = false;
            newErrorHighlight.bankZip.message = "";
            newErrorHighlight.bankState.error = false;
            newErrorHighlight.bankState.message = "";
            newErrorHighlight.bankCity.error = false;
            newErrorHighlight.bankCity.message = "";
            newErrorHighlight.bankAddress.error = false;
            newErrorHighlight.bankAddress.message = "";
            newErrorHighlight.bankName.error = false;
            newErrorHighlight.bankName.message = "";
            newErrorHighlight.principalName.error = false;
            newErrorHighlight.principalName.message = "";
            newErrorHighlight.contact.error = false;
            newErrorHighlight.contact.message = "";
         }

         //Trade References
         if (activeStep >= 3) {
            const companyCheck = _.uniqBy(application.tradeRefInfo, "company");
            const phoneCheck = _.uniqBy(application.tradeRefInfo, "phone");
            const companyDuplicates: string[] = [];
            const phoneDuplicates: string[] = [];

            if (companyCheck.length !== application.tradeRefInfo.length) {
               companyCheck.forEach((trade) => {
                  const companyDuplicate = _.filter(
                     application.tradeRefInfo,
                     (x) => x.company.toString() === trade.company.toString(),
                  );

                  if (companyDuplicate.length > 1) {
                     companyDuplicates.push(trade.company.toString());
                  }
               });
            }

            if (phoneCheck.length !== application.tradeRefInfo.length) {
               companyCheck.forEach((trade) => {
                  const phoneDuplicate = _.filter(
                     application.tradeRefInfo,
                     (x) => x.phone.toString() === trade.phone.toString(),
                  );

                  if (phoneDuplicate.length > 1) {
                     phoneDuplicates.push(trade.phone.toString());
                  }
               });
            }

            application.tradeRefInfo.forEach((trade, index) => {
               if (!trade.company && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].company.error = true;
                  newErrorHighlight.tradeRefInfo[index].company.message = "You must enter a company";
               } else if (companyDuplicates.includes(trade.company) && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].company.error = true;
                  newErrorHighlight.tradeRefInfo[index].company.message = "Company must be unique!";
               } else if (trade.company.match(allowSomeSpecialCharNoAmpersand)) {
                  newErrorHighlight.tradeRefInfo[index].company.error = true;
                  newErrorHighlight.tradeRefInfo[index].company.message = "Cannot contain (!, $, %, #, @, &)";
               } else if (trade.company.length > 100) {
                  newErrorHighlight.tradeRefInfo[index].company.error = true;
                  newErrorHighlight.tradeRefInfo[index].company.message = "Cannot exceed 100 characters";
               } else {
                  newErrorHighlight.tradeRefInfo[index].company.error = false;
                  newErrorHighlight.tradeRefInfo[index].company.message = "";
               }

               if (!trade.city && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].city.error = true;
                  newErrorHighlight.tradeRefInfo[index].city.message = "You must enter a city";
               } else if (trade.city.match(allowSomeSpecialChar)) {
                  newErrorHighlight.tradeRefInfo[index].city.error = true;
                  newErrorHighlight.tradeRefInfo[index].city.message = "Cannot contain (!, $, %, #, @)";
               } else if (trade.city.length > 100) {
                  newErrorHighlight.tradeRefInfo[index].city.error = true;
                  newErrorHighlight.tradeRefInfo[index].city.message = "Cannot exceed 100 characters";
               } else {
                  newErrorHighlight.tradeRefInfo[index].city.error = false;
                  newErrorHighlight.tradeRefInfo[index].city.message = "";
               }

               if (trade.state.length > 2 && trade.state.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].state.error = true;
                  newErrorHighlight.tradeRefInfo[index].state.message = "Please enter a valid two-letter state code";
               } else if (trade.state.length < 2 && trade.state.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].state.error = true;
                  newErrorHighlight.tradeRefInfo[index].state.message = "Must have a minimum of 2 characters";
               } else if (trade.state.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].state.error = true;
                  newErrorHighlight.tradeRefInfo[index].state.message = "Please enter a valid two-letter state code";
               } else {
                  newErrorHighlight.tradeRefInfo[index].state.error = false;
                  newErrorHighlight.tradeRefInfo[index].state.message = "";
               }

               if (!trade.country && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].country.error = true;
                  newErrorHighlight.tradeRefInfo[index].country.message = "Please select a valid country";
               } else if (trade.country.length < 1 && buttonEvent) {
                  newErrorHighlight.tradeRefInfo[index].country.error = true;
                  newErrorHighlight.tradeRefInfo[index].country.message = "Please select a valid country";
               } else {
                  newErrorHighlight.tradeRefInfo[index].country.error = false;
                  newErrorHighlight.tradeRefInfo[index].country.message = "";
               }

               if (!trade.phone.match(numbersOnly) && trade.phone.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].phone.error = true;
                  newErrorHighlight.tradeRefInfo[index].phone.message = "No alphabet or special characters allowed";
               } else if (trade.phone.length != 10 && trade.phone.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].phone.error = true;
                  newErrorHighlight.tradeRefInfo[index].phone.message =
                     "Please enter a valid phone number (10 number characters)";
               } else if (trade.phone.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].phone.error = true;
                  newErrorHighlight.tradeRefInfo[index].phone.message = "Please enter the reference phone number";
               } else if (phoneDuplicates.includes(trade.phone) && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].phone.error = true;
                  newErrorHighlight.tradeRefInfo[index].phone.message = "Phone # must be unique!";
               } else {
                  newErrorHighlight.tradeRefInfo[index].phone.error = false;
                  newErrorHighlight.tradeRefInfo[index].phone.message = "";
               }

               if (!trade.email.match(validEmail) && trade.email.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].email.error = true;
                  newErrorHighlight.tradeRefInfo[index].email.message = "Please enter a valid email";
               } else {
                  newErrorHighlight.tradeRefInfo[index].email.error = false;
                  newErrorHighlight.tradeRefInfo[index].email.message = "";
               }

               if (trade.contact.length > 100) {
                  newErrorHighlight.tradeRefInfo[index].contact.error = true;
                  newErrorHighlight.tradeRefInfo[index].contact.message = "Cannot exceed 100 characters";
               } else if (trade.contact.match(allowSomeSpecialCharWithAtAmpersandPeriod) && trade.contact.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].contact.error = true;
                  newErrorHighlight.tradeRefInfo[index].contact.message = "Cannot contain (!, $, %, #, &)";
               } else if (!trade.contact.match(alphabetOnly) && trade.contact.length >= 1) {
                  newErrorHighlight.tradeRefInfo[index].contact.error = true;
                  newErrorHighlight.tradeRefInfo[index].contact.message = "Cannot contain numbers";
               } else if (trade.contact.length < 1 && (buttonEvent || nextClicked)) {
                  newErrorHighlight.tradeRefInfo[index].contact.error = true;
                  newErrorHighlight.tradeRefInfo[index].contact.message = "Must have a minimum of 1 character";
               } else {
                  newErrorHighlight.tradeRefInfo[index].contact.error = false;
                  newErrorHighlight.tradeRefInfo[index].contact.message = "";
               }
            });
         } else {
            newErrorHighlight.tradeRefInfo.forEach((error) => {
               error.city.error = false;
               error.city.message = "";
               error.company.error = false;
               error.company.message = "";
               error.contact.error = false;
               error.contact.message = "";
               error.email.error = false;
               error.email.message = "";
               error.phone.error = false;
               error.phone.message = "";
               error.state.error = false;
               error.state.message = "";
               error.country.error = false;
               error.country.message = "";
            });
         }

         //Sign And Complete
         if (activeStep >= 4) {
            if (!application.signature && (buttonEvent || nextClicked)) {
               newErrorHighlight.signature.error = true;
               newErrorHighlight.signature.message = "Must have your signature to move forward";
            } else {
               newErrorHighlight.signature.error = false;
               newErrorHighlight.signature.message = "";
            }

            if (!application.signatureTitle && (buttonEvent || nextClicked)) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Must have a minimum of 1 character";
            } else if (application.signatureTitle.length > 100) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Cannot exceed 100 characters";
            } else if (application.signatureTitle.match(allowSomeSpecialCharNoAmpersand)) {
               newErrorHighlight.signatureTitle.error = true;
               newErrorHighlight.signatureTitle.message = "Cannot contain (!, $, %, #, @, &)";
            } else {
               newErrorHighlight.signatureTitle.error = false;
               newErrorHighlight.signatureTitle.message = "";
            }
         } else {
            newErrorHighlight.signature.error = false;
            newErrorHighlight.signature.message = "";
            newErrorHighlight.signatureTitle.error = false;
            newErrorHighlight.signatureTitle.message = "";
         }

         setErrorHighlight(newErrorHighlight);
      } catch (err) {
         handleError(err);
      }
   }

   async function onNextClicked() {
      try {
         setNextClicked(true);
         await validatePage(true);

         if (
            _.filter(errorHighlight, { error: true }).length > 0 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.city.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.company.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.state.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.country.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.phone.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.email.error) > -1 ||
            errorHighlight.tradeRefInfo.findIndex((x) => x.contact.error) > -1
         ) {
            return;
         } else {
            setNextClicked(false);

            setSaving(true);

            const responseData = await setInviteApplication(application);
            setSaving(false);

            if (!responseData.success) {
               alert("Data was unable to save successfully. Please refresh the page and try again.");
               return;
            }
         }

         setShowDisclaimer(false);
         setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (err) {
         handleError(err);
      }
   }

   async function submitApplicationClicked() {
      try {
         await validatePage(true);

         if (_.filter(errorHighlight, { error: true }).length > 0) {
            return;
         } else {
            setNextClicked(false);

            setSaving(true);

            const newApplication = { ...application };
            newApplication.signatureDate = new Date();
            newApplication.status = "NEW";

            const responseData = await setInviteApplication(newApplication);
            setSaving(false);

            if (!responseData.success) {
               alert("Data was unable to save successfully. Please refresh the page and try again.");
               return;
            } else {
               setApplication(responseData.data);
            }
         }

         setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (err) {
         handleError(err);
      }
   }

   async function onBackClicked() {
      try {
         setActiveStep((prevActiveStep) => prevActiveStep - 1);
         setShowDisclaimer(false);
         setNextClicked(false);
      } catch (err) {
         handleError(err);
      }
   }

   async function addTradeRefInfo() {
      try {
         const newApplication = { ...application };
         const newErrorHighlight = { ...errorHighlight };

         newErrorHighlight.tradeRefInfo.push({
            company: { error: false, message: "" },
            address: { error: false, message: "" },
            city: { error: false, message: "" },
            state: { error: false, message: "" },
            country: { error: false, message: "" },
            phone: { error: false, message: "" },
            email: { error: false, message: "" },
            contact: { error: false, message: "" },
         });

         newApplication.tradeRefInfo.push({
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "United States",
            phone: "",
            email: "",
            contact: "",
         });

         setErrorHighlight(newErrorHighlight);
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   async function removeTradeRefInfo(index: number) {
      try {
         const newApplication = { ...application };
         const newErrorHighlight = { ...errorHighlight };

         newApplication.tradeRefInfo.splice(index, 1);
         newErrorHighlight.tradeRefInfo.splice(index, 1);

         setErrorHighlight(newErrorHighlight);
         setApplication(newApplication);
      } catch (err) {
         handleError(err);
      }
   }

   const classes = useStyles();

   return (
      <>
         <div
            className={
               match.params.division.toUpperCase() == "MIDDLEBAY"
                  ? "divbody-middlebay"
                  : match.params.division.toUpperCase() == "BRISTOL"
                  ? "divbody-bristol"
                  : "divbody"
            }
         >
            {loading ? (
               <>
                  <CheckAnimation />
               </>
            ) : tokenExpired && isBristol ? (
               <>
                  <ExpiredBristol />
               </>
            ) : tokenExpired && isContainerPort ? (
               <>
                  <ExpiredContainerPort />
               </>
            ) : tokenExpired && isMiddleBay ? (
               <>
                  <ExpiredMiddleBay />
               </>
            ) : tokenExpired && !isMiddleBay && !isContainerPort && !isBristol ? (
               <>
                  <ExpiredContainerPort />
               </>
            ) : (
               <Container fixed style={{ height: "100vh" }}>
                  <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                     {steps.map((label) => (
                        <Step key={label}>
                           <StepLabel classes={{ label: classes.stepLabel }} StepIconComponent={QontoStepIcon}>
                              {label}
                           </StepLabel>
                        </Step>
                     ))}
                  </Stepper>
                  <Paper
                     elevation={0}
                     className={classes.outerPaper}
                     style={{ overflowY: "auto", overflowX: "hidden" }}
                  >
                     {showDisclaimer ? (
                        <Typography style={{ fontSize: "16px", marginLeft: ".75rem" }}>
                           <b>{`For the best experience use Chrome, Edge, or Brave browsers.`}</b>
                        </Typography>
                     ) : (
                        <></>
                     )}
                     <Slide
                        direction={activeStep === 0 ? "left" : "right"}
                        in={activeStep === 0 ? true : false}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                     >
                        <Paper elevation={0}>
                           <CustomerInfo
                              application={application}
                              handleChange={handleChange}
                              handleChangeDate={handleChangeDate}
                              handleChangeAddress={handleChangeAddress}
                              errorHighlight={errorHighlight}
                           />
                        </Paper>
                     </Slide>
                     <Slide
                        direction={activeStep === 1 ? "left" : "right"}
                        in={activeStep === 1 ? true : false}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                     >
                        <Paper elevation={0}>
                           <CreditRequest
                              application={application}
                              handleChange={handleChange}
                              handleChangeMethod={handleChangeMethod}
                              handleCheckChange={handleCheckChange}
                              handleChangeButton={handleChangeButton}
                              errorHighlight={errorHighlight}
                              isContainerPort={isContainerPort}
                              perDiem={perDiem}
                           />
                        </Paper>
                     </Slide>
                     <Slide
                        direction={activeStep === 2 ? "left" : "right"}
                        in={activeStep === 2 ? true : false}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                     >
                        <Paper elevation={0}>
                           <BankingInfo
                              application={application}
                              handleChange={handleChange}
                              handleChangeAddress={handleChangeAddress}
                              errorHighlight={errorHighlight}
                           />
                        </Paper>
                     </Slide>
                     <Slide
                        direction={activeStep === 3 ? "left" : "right"}
                        in={activeStep === 3 ? true : false}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                     >
                        <Paper elevation={0}>
                           <TradeReference
                              application={application}
                              handleChangeTradeRefInfo={handleChangeTradeRefInfo}
                              addTradeRefInfo={addTradeRefInfo}
                              removeTradeRefInfo={removeTradeRefInfo}
                              errorHighlight={errorHighlight}
                           />
                        </Paper>
                     </Slide>
                     <Slide
                        direction={activeStep === 4 ? "left" : "right"}
                        in={activeStep === 4 ? true : false}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                     >
                        <Paper elevation={0}>
                           <SignComplete
                              application={application}
                              setApplication={setApplication}
                              handleChange={handleChange}
                              errorHighlight={errorHighlight}
                              division={match.params.division}
                           />
                        </Paper>
                     </Slide>
                     <Slide
                        direction={activeStep === 5 ? "left" : "right"}
                        in={activeStep === 5 ? true : false}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                     >
                        <Paper elevation={0}>
                           <ApplicationConfirmation
                              application={application}
                              setApplication={setApplication}
                              handleCheckChange={handleCheckChange}
                              activeStep={activeStep}
                              division={match.params.division}
                           />
                        </Paper>
                     </Slide>
                     <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item xs={3} />
                        <Grid item xs={7} container style={{ marginRight: "-1rem" }}>
                           <div style={{ display: activeStep === 4 ? "block" : "none" }}>
                              {_.filter(errorHighlight, { error: true }).length > 0 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.city.error) > -1 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.company.error) > -1 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.state.error) > -1 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.country.error) > -1 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.phone.error) > -1 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.email.error) > -1 ||
                              errorHighlight.tradeRefInfo.findIndex((x) => x.contact.error) > -1 ? (
                                 <Button
                                    variant="contained"
                                    disabled={true}
                                    color="primary"
                                    classes={{
                                       label: classes.label,
                                    }}
                                 >
                                    <Check />
                                    Submit Application
                                 </Button>
                              ) : (
                                 <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => submitApplicationClicked()}
                                    classes={{
                                       label: classes.label,
                                    }}
                                    disabled={saving}
                                 >
                                    {saving ? (
                                       <CircularProgress size={20} className={classes.buttonProgress} />
                                    ) : (
                                       <Check />
                                    )}
                                    Submit Application
                                 </Button>
                              )}
                           </div>
                        </Grid>
                        <Grid
                           container
                           direction="row"
                           justify="flex-end"
                           alignItems="center"
                           style={{ padding: "1rem" }}
                        >
                           <div style={{ display: activeStep > 4 ? "none" : undefined }}>
                              <ButtonGroup>
                                 <Button
                                    variant="contained"
                                    onClick={() => onBackClicked()}
                                    classes={{
                                       label: classes.label,
                                    }}
                                    disabled={activeStep === 0 ? true : false}
                                 >
                                    <ArrowBackIcon />
                                    Back
                                 </Button>
                                 <div style={{ display: activeStep > 3 ? "none" : undefined }}>
                                    {(_.filter(errorHighlight, { error: true }).length > 0 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.city.error) > -1 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.company.error) > -1 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.state.error) > -1 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.country.error) > -1 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.phone.error) > -1 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.email.error) > -1 ||
                                       errorHighlight.tradeRefInfo.findIndex((x) => x.contact.error) > -1) &&
                                    nextClicked === true ? (
                                       <Button
                                          variant="contained"
                                          disabled={true}
                                          color="primary"
                                          classes={{
                                             label: classes.label,
                                          }}
                                       >
                                          <ArrowForwardIcon />
                                          Next
                                       </Button>
                                    ) : (
                                       <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => onNextClicked()}
                                          classes={{
                                             label: classes.label,
                                          }}
                                          disabled={saving}
                                       >
                                          {saving ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <ArrowForwardIcon />
                                          )}
                                          Next
                                       </Button>
                                    )}
                                 </div>
                              </ButtonGroup>
                           </div>
                        </Grid>
                     </Grid>
                  </Paper>
               </Container>
            )}
         </div>
      </>
   );
};

export default Apply;
