import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

interface Props {
   open: boolean;
   message: string;
   variant: "success" | "error" | "warning" | "info";
   handleClose: () => void;
}

export const ActivationSnackbar: React.FC<Props> = ({ open, message, variant, handleClose }) => {
   return (
      <Snackbar
         style={{ width: "400px" }}
         open={open}
         autoHideDuration={6000}
         onClose={handleClose}
         anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
         }}
      >
         <Alert style={{ width: "100%", fontSize: "14px" }} variant="filled" onClose={handleClose} severity={variant}>
            {message}
         </Alert>
      </Snackbar>
   );
};
