import { allowSomeSpecialCharOnlyDash, allowSomeSpecialCharWithHashtag } from "../pages/ApplyUWLWDS/utils";
import { DateFormatter } from "./DateFormatter";

const lowerCase = new RegExp(/[a-z]/);
const upperCase = new RegExp(/[A-Z]/);
const specialChar = new RegExp(/[!@#\$%\^\&*\)\(+=._-]/);
const minChar = new RegExp(/.{8,}/);
const minNum = new RegExp(/(?=.*?[0-9])/);
const emailValid = new RegExp(/^.+@(.+)[.](.+)$/);

export function validatePassword(password: string) {
   let validationMessage = "";

   if (!password.match(specialChar)) {
      validationMessage = "Password must contain at least one special character";
   }
   if (!password.match(minNum)) {
      validationMessage = "Password must contain at least one number";
   }
   if (!(password.match(lowerCase) && password.match(upperCase))) {
      validationMessage = "Password must contain a lowercase and an uppercase character";
   }
   if (!password.match(minChar)) {
      validationMessage = "Password must be at least 8 characters in length";
   }

   return validationMessage;
}

export function validateEmail(input: string) {
   if (!input) return "Must be a valid email address";

   let validationMessage = "";
   if (!input.match(emailValid)) {
      validationMessage = "Must be a valid email address";
   }

   return validationMessage;
}

export function validateNotEmptyString(input: string, message: string) {
   if (!input) return message;
   let messageAux = "";

   const textClean = input.trim();
   if (!textClean.length) {
      messageAux = message;
   }

   return messageAux;
}

export function maximumCharacters(input: string, max: number) {
   if (!input) return "";

   let messageAux = "";

   if (input?.length > max) {
      messageAux = `Maximum length is ${max} characters`;
   }

   return messageAux;
}

export class ValidationBuilder {
   private errorMessage = "";
   private variable!: Date | string;

   validateEmail(email?: string) {
      if (this.errorMessage) return this;

      if (email) this.variable = email;

      this.errorMessage = validateEmail(this.variable as string);
      return this;
   }

   validateNotEmptyString(message: string, input?: string) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      this.errorMessage = validateNotEmptyString(this.variable as string, message);
      return this;
   }

   validateExaclyStringLength(message: string, length: number, input?: string) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      if (typeof this.variable === "string" && this.variable.length != length) {
         this.errorMessage = message;
      }

      return this;
   }

   maximumLength(max: number, input?: string) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      this.errorMessage = maximumCharacters(this.variable as string, max);
      return this;
   }

   allowSomeSpecialCharWithHashtag(input?: string) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      const invalid = input?.match(allowSomeSpecialCharWithHashtag);
      if (invalid) this.errorMessage = "Cannot contain (!, $, %, #, @)";

      return this;
   }

   allowSomeSpecialCharOnlyDash(input?: string) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      const invalid = input?.match(allowSomeSpecialCharOnlyDash);
      if (invalid) this.errorMessage = "Cannot contain any special characters";

      return this;
   }

   validateDateFormat(input?: Date) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      if (DateFormatter(this.variable) === "00/00/0000") {
         this.errorMessage = "Please enter a valid date";
      }

      return this;
   }

   validateWebSite(input?: string) {
      if (this.errorMessage) return this;

      if (input) this.variable = input;

      const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/.*)*$/;
      if (!regex.test(this.variable as string)) {
         this.errorMessage = "Please enter a valid URL";
      }

      return this;
   }

   getMessageError() {
      return this.errorMessage;
   }
}
