import React from "react";
import { Route, Switch } from "react-router-dom";
import ActivationDetails from "./ActivationDetails";
import { ActivationView } from "./ActivationView";

const Activation: React.FC = () => {
   return (
      <>
         <Switch>
            <Route path="/activations/details/:id?" exact component={ActivationDetails} />
            <Route path="/" component={ActivationView} />
         </Switch>
      </>
   );
};

export default Activation;
