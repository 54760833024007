import {
   ActivationBillingInformationFormDTO,
   ActivationBillingInformationType,
   ActivationFormFieldError,
   ActivationFormProperties,
   ActivationModelForm,
} from "../../../types/Activation/ActivationDTO";
import { ValidationBuilder } from "../../../utilities/ValidationTools";

type FormProperties = ActivationFormProperties<ActivationBillingInformationType>;

export function billingInformationReducer(
   state: Partial<ActivationModelForm>,
   action: { type: ActivationBillingInformationType | "forceValidation"; payload: unknown },
): Partial<ActivationModelForm> {
   const Validation = new ValidationBuilder();
   switch (action.type) {
      case "AccountsPayableName":
         const companyContactErrorMessage = Validation.validateNotEmptyString(
            "Please enter the account payable name",
            action.payload as string,
         )
            .maximumLength(100)
            .getMessageError();
         return {
            ...state,
            AccountsPayableName: {
               value: action.payload as string,
               error: !!companyContactErrorMessage,
               message: companyContactErrorMessage,
            },
         };
      case "AccountsPayableEmail":
         const accountsPayableEmailErrorMessage = Validation.validateEmail(action.payload as string).getMessageError();
         return {
            ...state,
            AccountsPayableEmail: {
               value: action.payload as string,
               error: !!accountsPayableEmailErrorMessage,
               message: accountsPayableEmailErrorMessage,
            },
         };
      case "MethodAux":
         const methodAuxErrorMessage = Validation.validateNotEmptyString(
            "Please enter the method",
            action.payload as string,
         ).getMessageError();

         //Method value
         const MethodValidation = new ValidationBuilder();
         let methodErrorMessageAux = "";

         if (action.payload === "Email" && !methodErrorMessageAux) {
            methodErrorMessageAux = MethodValidation.validateNotEmptyString(
               "If email, enter the address for invoicing here",
               state.Method?.value as string,
            )
               .validateEmail(state.Method?.value as string)
               .getMessageError();
         }
         if (action.payload === "Website" && !methodErrorMessageAux) {
            methodErrorMessageAux = MethodValidation.validateWebSite(state.Method?.value as string).getMessageError();
         }

         if (action.payload === "Other" && !methodErrorMessageAux) {
            methodErrorMessageAux = MethodValidation.validateNotEmptyString(
               "Please enter the method",
               state.Method?.value as string,
            ).getMessageError();
         }

         return {
            ...state,
            MethodAux: {
               value: action.payload as string,
               error: !!methodAuxErrorMessage,
               message: methodAuxErrorMessage,
            },
            Method: {
               value: state.Method?.value as string,
               error: !!methodErrorMessageAux,
               message: methodErrorMessageAux,
            },
         };
      case "Method":
         let methodErrorMessage = "";

         if (state.MethodAux?.value === "Email" && !methodErrorMessage) {
            methodErrorMessage = Validation.validateNotEmptyString(
               "If email, enter the address for invoicing here",
               action.payload as string,
            )
               .validateEmail(action.payload as string)
               .getMessageError();
         }
         if (state.MethodAux?.value === "Website" && !methodErrorMessage) {
            methodErrorMessage = Validation.validateNotEmptyString("Please enter a valid URL", action.payload as string)
               .validateWebSite(action.payload as string)
               .getMessageError();
         }

         if (state.MethodAux?.value === "Other" && !methodErrorMessage) {
            methodErrorMessage = Validation.validateNotEmptyString(
               "Please enter the method",
               action.payload as string,
            ).getMessageError();
         }

         return {
            ...state,
            Method: {
               value: action.payload as string,
               error: !!methodErrorMessage,
               message: methodErrorMessage,
            },
         };
      case "PaymentMethod":
         const paymentMethodErrorMessage = Validation.validateNotEmptyString(
            "Please enter the company phone",
            action.payload as string,
         ).getMessageError();
         return {
            ...state,
            PaymentMethod: {
               value: action.payload as string,
               error: !!paymentMethodErrorMessage,
               message: paymentMethodErrorMessage,
            },
         };
      case "UwlHandleChb":
         const uwlHandleChbErrorMessage = Validation.validateNotEmptyString(
            "Please select an option",
            action.payload as string,
         ).getMessageError();

         // validate the custom broker information
         if (action.payload === "no") {
            const brokerValidation = validateBrokerSection(state);

            return {
               ...state,
               ...brokerValidation,
               UwlHandleChb: {
                  value: action.payload as string,
                  error: !!uwlHandleChbErrorMessage,
                  message: uwlHandleChbErrorMessage,
               },
            };
         } else {
            const brokerValidation = removeValidationForBrokerSection(state);
            return {
               ...state,
               ...brokerValidation,
               UwlHandleChb: {
                  value: action.payload as string,
                  error: !!uwlHandleChbErrorMessage,
                  message: uwlHandleChbErrorMessage,
               },
            };
         }
      case "AchImporter":
         const achImporterErrorMessage = Validation.validateNotEmptyString(
            "Please Select an option",
            action.payload as string,
         ).getMessageError();

         return {
            ...state,
            AchImporter: {
               value: action.payload as string,
               error: !!achImporterErrorMessage,
               message: achImporterErrorMessage,
            },
         };

      case "CompanyName":
         let companyNameErrorMessage = "";
         if (state.UwlHandleChb?.value == "no") {
            companyNameErrorMessage = Validation.validateNotEmptyString(
               "Please enter the company name",
               action.payload as string,
            )
               .maximumLength(200)
               .getMessageError();
         }
         return {
            ...state,
            CompanyName: {
               value: action.payload as string,
               error: !!companyNameErrorMessage,
               message: companyNameErrorMessage,
            },
         };
      case "CompanyContact":
         let contactErrorMessage = "";
         if (state.UwlHandleChb?.value == "no") {
            contactErrorMessage = Validation.validateNotEmptyString(
               "Please enter the company contact name",
               action.payload as string,
            )
               .maximumLength(200)
               .getMessageError();
         }
         return {
            ...state,
            CompanyContact: {
               value: action.payload as string,
               error: !!contactErrorMessage,
               message: contactErrorMessage,
            },
         };
      case "CompanyPhone":
         let phone = (action.payload as string).replaceAll("_", "") || "";
         phone = phone?.replaceAll("-", "") || "";

         let companyPhoneErrorMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value == "no") {
            companyPhoneErrorMessage = Validation.validateExaclyStringLength(
               "Please enter a valid number (10 number characters)",
               10,
               phone,
            )
               .validateNotEmptyString("Please enter the company phone")
               .getMessageError();
         }

         return {
            ...state,
            CompanyPhone: {
               value: phone,
               error: !!companyPhoneErrorMessage,
               message: companyPhoneErrorMessage,
            },
         };
      case "Address1":
         let address1ErrorMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value == "no") {
            address1ErrorMessage = Validation.validateNotEmptyString(
               "Please enter the address",
               action.payload as string,
            )
               .maximumLength(100)
               .allowSomeSpecialCharWithHashtag()
               .getMessageError();
         }

         return {
            ...state,
            Address1: {
               value: action.payload as string,
               error: !!address1ErrorMessage,
               message: address1ErrorMessage,
            },
         };
      case "Address2":
         return {
            ...state,
            Address2: {
               value: action.payload as string,
               error: false,
            },
         };
      case "City":
         let cityErrorMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value == "no") {
            cityErrorMessage = Validation.validateNotEmptyString("Please enter the city", action.payload as string)
               .allowSomeSpecialCharWithHashtag()
               .getMessageError();
         }

         return {
            ...state,
            City: {
               value: action.payload as string,
               error: !!cityErrorMessage,
               message: cityErrorMessage,
            },
         };
      case "State":
         let stateErrorMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value == "no") {
            stateErrorMessage = Validation.validateNotEmptyString("Please enter the state", action.payload as string)
               .allowSomeSpecialCharWithHashtag()
               .getMessageError();
         }

         return {
            ...state,
            State: {
               value: action.payload as string,
               error: !!stateErrorMessage,
               message: stateErrorMessage,
            },
         };
      case "Zip":
         let zipErrorMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value == "no") {
            zipErrorMessage = Validation.validateNotEmptyString("Please enter the zip code", action.payload as string)
               .allowSomeSpecialCharOnlyDash()
               .getMessageError();
         }

         return {
            ...state,
            Zip: {
               value: action.payload as string,
               error: !!zipErrorMessage,
               message: zipErrorMessage,
            },
         };
      case "Country":
         let countryErrorMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value == "no") {
            countryErrorMessage = Validation.validateNotEmptyString(
               "Please select a valid country",
               action.payload as string,
            ).getMessageError();
         }

         return {
            ...state,
            Country: {
               value: action.payload as string,
               error: !!countryErrorMessage,
               message: countryErrorMessage,
            },
         };
      case "Email":
         let validationMessage = "";

         // be require if UwlHandleChb == "no"
         if (state.UwlHandleChb?.value === "no" || (action.payload && state.UwlHandleChb?.value !== "no")) {
            validationMessage = Validation.validateEmail(action.payload as string).getMessageError();
         }

         return {
            ...state,
            Email: {
               value: action.payload as string,
               error: !!validationMessage,
               message: validationMessage,
            },
         };
      case "forceValidation":
         const defaultValues = getDefaultValues(state.UwlHandleChb?.value as "yes" | "no" | "n/a");
         const properties: FormProperties = { ...defaultValues };

         // filling the properties in.
         for (const field of Object.keys(
            action.payload as ActivationBillingInformationFormDTO,
         ) as (keyof FormProperties)[]) {
            properties[field] = (action.payload as FormProperties)[field]?.value
               ? (action.payload as FormProperties)[field]
               : defaultValues[field];
         }

         return {
            ...properties,
         };
      default:
         throw "Invalid action";
   }
}

const getDefaultValues = (
   uwlHandleChb?: "yes" | "no" | "n/a",
): Record<ActivationBillingInformationType, ActivationFormFieldError<string | number | Date>> => ({
   AccountsPayableName: {
      value: "",
      error: true,
      message: "Please enter the account payable name",
   },
   AccountsPayableEmail: {
      value: "",
      error: true,
      message: "Please enter the account payable email",
   },
   Method: {
      value: "",
      error: true,
      message: "Please enter the method",
   },
   MethodAux: {
      value: "",
      error: true,
      message: "Please select an method",
   },
   PaymentMethod: {
      value: "",
      error: true,
      message: "Please select a payment method",
   },
   UwlHandleChb: {
      value: "",
      error: true,
      message: "Please select an option",
   },
   AchImporter: {
      value: "",
      error: true,
      message: "Please select an option",
   },
   CompanyName: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the company name" : "",
   },
   CompanyContact: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the company contact" : "",
   },
   CompanyPhone: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the company phone" : "",
   },
   Email: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the email" : "",
   },
   Address1: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the address" : "",
   },
   Address2: {
      value: "",
      error: false,
   },
   City: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the city" : "",
   },
   State: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please enter the state" : "",
   },
   Zip: {
      value: "",
      error: uwlHandleChb == "no",
      message: "Please enter the zip code",
   },
   Country: {
      value: "",
      error: uwlHandleChb == "no",
      message: uwlHandleChb == "no" ? "Please select a valid country" : "",
   },
});

function validateBrokerSection(state: Partial<ActivationModelForm>) {
   const ValidationName = new ValidationBuilder();
   const ValidationContact = new ValidationBuilder();
   const ValidationPhone = new ValidationBuilder();
   const ValidationAddress1 = new ValidationBuilder();
   const ValidationCity = new ValidationBuilder();
   const ValidationState = new ValidationBuilder();
   const ValidationZip = new ValidationBuilder();
   const ValidationCountry = new ValidationBuilder();
   const ValidationEmail = new ValidationBuilder();

   // Company Name
   const companyNameErrorMessage = ValidationName.validateNotEmptyString(
      "Please enter the company name",
      state.CompanyName?.value as string,
   )
      .maximumLength(200)
      .getMessageError();

   // Company Contact
   const contactErrorMessage = ValidationContact.validateNotEmptyString(
      "Please enter the company contact name",
      state.CompanyContact?.value as string,
   )
      .maximumLength(200)
      .getMessageError();

   // Phone

   const companyPhoneErrorMessage = ValidationPhone.validateExaclyStringLength(
      "Please enter a valid number (10 number characters)",
      10,
      state.CompanyPhone?.value as string,
   )
      .validateNotEmptyString("Please enter the company phone")
      .getMessageError();

   // Address1
   const address1ErrorMessage = ValidationAddress1.validateNotEmptyString(
      "Please enter the address",
      state.Address1?.value as string,
   )
      .maximumLength(100)
      .allowSomeSpecialCharWithHashtag()
      .getMessageError();

   // Address2

   // City
   const cityErrorMessage = ValidationCity.validateNotEmptyString("Please enter the city", state.City?.value as string)
      .allowSomeSpecialCharWithHashtag()
      .getMessageError();

   // State
   const stateErrorMessage = ValidationState.validateNotEmptyString(
      "Please enter the state",
      state.State?.value as string,
   )
      .allowSomeSpecialCharWithHashtag()
      .getMessageError();

   // Zip
   const zipErrorMessage = ValidationZip.validateNotEmptyString("Please enter the zip code", state.Zip?.value as string)
      .allowSomeSpecialCharOnlyDash()
      .getMessageError();

   // Country
   const countryErrorMessage = ValidationCountry.validateNotEmptyString(
      "Please select a valid country",
      state.Country?.value as string,
   ).getMessageError();

   // Email
   const emailValidationMessage = ValidationEmail.validateEmail(state.Email?.value as string).getMessageError();

   return {
      CompanyName: {
         value: state.CompanyName?.value as string,
         error: !!companyNameErrorMessage,
         message: companyNameErrorMessage,
      },
      CompanyContact: {
         value: state.CompanyContact?.value as string,
         error: !!contactErrorMessage,
         message: contactErrorMessage,
      },
      CompanyPhone: {
         value: state.CompanyPhone?.value as string,
         error: !!companyPhoneErrorMessage,
         message: companyPhoneErrorMessage,
      },
      Address1: {
         value: state.Address1?.value as string,
         error: !!address1ErrorMessage,
         message: address1ErrorMessage,
      },

      Address2: {
         value: state.Address2?.value as string,
         error: false,
      },
      City: {
         value: state.City?.value as string,
         error: !!cityErrorMessage,
         message: cityErrorMessage,
      },
      State: {
         value: state.State?.value as string,
         error: !!stateErrorMessage,
         message: stateErrorMessage,
      },
      Zip: {
         value: state.Zip?.value as string,
         error: !!zipErrorMessage,
         message: zipErrorMessage,
      },
      Country: {
         value: state.Country?.value as string,
         error: !!countryErrorMessage,
         message: countryErrorMessage,
      },
      Email: {
         value: state.Email?.value as string,
         error: !!emailValidationMessage,
         message: emailValidationMessage,
      },
   };
}

function removeValidationForBrokerSection(state: Partial<ActivationModelForm>) {
   return {
      CompanyName: {
         value: state.CompanyName?.value,
         error: false,
      },
      CompanyContact: {
         value: state.CompanyContact?.value,
         error: false,
      },
      CompanyPhone: {
         value: state.CompanyPhone?.value,
         error: false,
      },
      Address1: {
         value: state.Address1?.value,
         error: false,
      },
      Address2: {
         value: state.Address2?.value,
         error: false,
      },
      City: {
         value: state.City?.value,
         error: false,
      },
      State: {
         value: state.State?.value,
         error: false,
      },
      Zip: {
         value: state.Zip?.value,
         error: false,
      },
      Country: {
         value: state.Country?.value,
         error: false,
      },
      Email: {
         value: state.Email?.value,
         error: false,
      },
   };
}

export const initialBillingValues: FormProperties = {
   AccountsPayableName: {
      value: "",
      error: true,
   },
   AccountsPayableEmail: {
      value: "",
      error: true,
   },
   Method: {
      value: "",
      error: true,
   },
   MethodAux: {
      value: "",
      error: true,
   },
   PaymentMethod: {
      value: "",
      error: true,
   },
   UwlHandleChb: {
      value: "",
      error: true,
   },
   AchImporter: {
      value: "",
      error: true,
   },
   CompanyName: {
      value: "",
      error: false,
   },
   CompanyContact: {
      value: "",
      error: false,
   },
   CompanyPhone: {
      value: "",
      error: false,
   },
   Email: {
      value: "",
      error: false,
   },
   Address1: {
      value: "",
      error: false,
   },
   Address2: {
      value: "",
      error: false,
   },
   City: {
      value: "",
      error: false,
   },
   State: {
      value: "",
      error: false,
   },
   Zip: {
      value: "",
      error: false,
   },
   Country: {
      value: "",
      error: false,
   },
};
