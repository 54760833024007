import React from "react";

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InputMask from "react-input-mask";

import { ActivationBillingInformationFormDTO } from "../../../types/Activation/ActivationDTO";
import { countryList } from "../../../utilities/CountryList";
import { useActivationStyles } from "../styles";
import { billingInformationReducer, initialBillingValues } from "./reducer";

const methodOptions = [
   {
      Code: "Email",
      Value: "Email",
   },
   {
      Code: "Website",
      Value: "Website/Web Portal",
   },
   {
      Code: "Other",
      Value: "Other",
   },
];

type Props = {
   wasSubmit: boolean;
   changeFormState: (from: "billing" | "companyInformation" | "signAndComplete", isValid: boolean) => void;
   updateActivationObject: (obj: ActivationBillingInformationFormDTO) => void;
};

function ActivationBillingInformation({ wasSubmit, changeFormState, updateActivationObject }: Props) {
   const [state, dispatch] = React.useReducer(billingInformationReducer, initialBillingValues);
   const classes = useActivationStyles();

   const refFormState = React.useRef(false);

   /**
    * Validate fields, return true if the form is valid
    */
   const validateForm = (): boolean => {
      if (state.AccountsPayableName?.error) return false;
      if (state.AccountsPayableEmail?.error) return false;
      if (state.Method?.error) return false;
      if (state.PaymentMethod?.error) return false;
      if (state.UwlHandleChb?.error) return false;
      if (state.UwlHandleChb?.value === "yes" && state.AchImporter?.error) return false;

      if (state.Address1?.error) return false;
      if (state.Address2?.error) return false;
      if (state.City?.error) return false;
      if (state.State?.error) return false;
      if (state.Zip?.error) return false;
      if (state.Country?.error) return false;
      return true;
   };

   const brokerDisabled = state.UwlHandleChb?.value == "yes" || state.UwlHandleChb?.value == "n/a";

   React.useEffect(() => {
      // validate fields
      if (!wasSubmit) return;

      dispatch({ type: "forceValidation", payload: state });
   }, [wasSubmit]);

   React.useEffect(() => {
      const isValid = validateForm();

      // Create object
      const billingInformation: ActivationBillingInformationFormDTO = {
         AccountsPayableName: state.AccountsPayableName?.value as string,
         AccountsPayableEmail: state.AccountsPayableEmail?.value as string,
         Method: {
            Code: state.MethodAux?.value as string,
            Value: state.Method?.value as string,
         },
         PaymentMethod: state.PaymentMethod?.value as string,
         Chb: {
            CompanyName: brokerDisabled ? "" : (state.CompanyName?.value as string),
            Contact: brokerDisabled ? "" : (state.CompanyContact?.value as string),
            Phone: brokerDisabled ? "" : (state.CompanyPhone?.value as string),
            Email: brokerDisabled ? "" : (state.Email?.value as string),
            Address1: brokerDisabled ? "" : (state.Address1?.value as string),
            Address2: brokerDisabled ? "" : (state.Address2?.value as string),
            City: brokerDisabled ? "" : (state.City?.value as string),
            State: brokerDisabled ? "" : (state.State?.value as string),
            Zip: brokerDisabled ? "" : (state.Zip?.value as string),
            Country: brokerDisabled ? "" : (state.Country?.value as string),

            AchImporter: state.AchImporter?.value === "yes",
            UwlHandleChb: state.UwlHandleChb?.value === "n/a" ? undefined : state.UwlHandleChb?.value == "yes",
         },
      };
      // update parent object
      updateActivationObject(billingInformation);

      // sent to the parent component when the form change its state between valid and invalid
      if (isValid !== refFormState.current) {
         refFormState.current = isValid;
         changeFormState("billing", isValid);
      }
   }, [state]);

   return (
      <section className={classes.container}>
         <h1 className={classes.title}>
            Billing Information <span>(AP Contact)</span>
         </h1>
         <hr />
         <div>
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
               {/* Accounts Payable Contact */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="payable-contact-name"
                     label="Accounts Payable Contact Name"
                     name="activationBillingAccountsPayableName"
                     placeholder="Accounts Payable Contact Name"
                     autoComplete="activationBillingAccountsPayableName"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "AccountsPayableName", payload: event.target.value })}
                     value={state.AccountsPayableName?.value}
                     helperText={state.AccountsPayableName?.error && state.AccountsPayableName?.message}
                     error={!!(state.AccountsPayableName?.error && state.AccountsPayableName?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
               {/* Accounts Payable Email */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="payable-contact-email"
                     label="Accounts Payable Contact Email"
                     name="activationBillingAccountsPayableEmail"
                     autoComplete="activationBillingAccountsPayableEmail"
                     placeholder="Accounts Payable Contact Email"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "AccountsPayableEmail", payload: event.target.value })}
                     value={state.AccountsPayableEmail?.value}
                     helperText={state.AccountsPayableEmail?.error && state.AccountsPayableEmail?.message}
                     error={!!(state.AccountsPayableEmail?.error && state.AccountsPayableEmail?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Preferred Method */}
               <Grid item lg={6} md={6} xs={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                     <InputLabel
                        id="type-method"
                        style={{ color: state.MethodAux?.error && state.MethodAux?.message ? "#f44336" : "inherit" }}
                     >
                        Preferred Method for Receiving Invoices
                     </InputLabel>
                     <Select
                        required
                        labelId="methodAux"
                        autoComplete="activationBillingPreferredMethod"
                        id="methodAux"
                        name="methodAux"
                        variant="outlined"
                        defaultValue={""}
                        onChange={(event) => dispatch({ type: "MethodAux", payload: event.target.value })}
                        value={state.MethodAux?.value || ""}
                        label="Preferred Method for Receiving Invoices"
                        error={!!(state.MethodAux?.error && state.MethodAux.message)}
                     >
                        {methodOptions.map((method, i) => {
                           return (
                              <MenuItem key={method.Code} value={method.Code}>
                                 {method.Value}
                              </MenuItem>
                           );
                        })}
                     </Select>
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {state.MethodAux?.error && state.MethodAux?.message ? state?.MethodAux.message : null}
                     </FormHelperText>
                  </FormControl>
               </Grid>

               {/* Preferred Method Email or URL */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required
                     id="preferred-method-email-or-url"
                     label="Enter Preferred Method: Email/URL/Other"
                     name="activationBillingPreferredMethod"
                     autoComplete="activationBillingPreferredMethod"
                     placeholder="Enter Preferred Method: Email/URL/Other"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Method", payload: event.target.value })}
                     value={state.Method?.value}
                     helperText={state.Method?.error && state.Method?.message}
                     error={!!(state.Method?.error && state.Method?.message)}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>
            </Grid>

            {/* Payment methods */}
            <FormControl
               component={"fieldset"}
               style={{ padding: "0px 0px 0px 11px", display: "flex", flexDirection: "row", alignItems: "center" }}
               required
            >
               <FormLabel className={classes.labelText} style={{ color: "#000", marginRight: "1rem" }}>
                  Payment Method:
               </FormLabel>
               <RadioGroup
                  aria-label="payment-method"
                  name="paymentMethod"
                  value={state.PaymentMethod?.value}
                  onChange={(event) => dispatch({ type: "PaymentMethod", payload: event.target.value })}
                  style={{ display: "flex", flexDirection: "row" }}
                  row
               >
                  <FormControlLabel
                     className={classes.controlLabel}
                     value="ACH"
                     control={<Radio color="primary" />}
                     label="ACH"
                  />
                  <FormControlLabel
                     className={classes.controlLabel}
                     value="Wire"
                     control={<Radio color="primary" />}
                     label="Wire"
                  />
               </RadioGroup>
            </FormControl>
            <FormHelperText
               style={{
                  color: state.PaymentMethod?.error && state.PaymentMethod?.message ? "#f44336" : "inherit",
                  padding: "0px 0px 0px 21px",
               }}
            >
               {state.PaymentMethod?.error && state.PaymentMethod?.message ? state.PaymentMethod.message : null}
            </FormHelperText>

            <h3 className={classes.section2Title}>U.S. Customs Brokerage</h3>

            {/* ACH importer */}
            <article style={{ padding: "0px 0px 0px 11px" }}>
               <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <FormLabel className={classes.labelText} style={{ color: "#000", marginRight: "1rem" }} required>
                     Will UWL handle your CHB?:
                  </FormLabel>
                  <RadioGroup
                     aria-label="uwl-handle"
                     name="uwlHandleChb"
                     value={state.UwlHandleChb?.value}
                     onChange={(event) => dispatch({ type: "UwlHandleChb", payload: event.target.value })}
                     style={{ display: "flex", flexDirection: "row" }}
                  >
                     <FormControlLabel
                        className={classes.controlLabel}
                        value={"yes"}
                        control={<Radio color="primary" />}
                        label="Yes"
                     />
                     <FormControlLabel
                        className={classes.controlLabel}
                        value={"no"}
                        control={<Radio color="primary" />}
                        label="No"
                     />
                     <FormControlLabel
                        className={classes.controlLabel}
                        value={"n/a"}
                        control={<Radio color="primary" />}
                        label="N/A (CHB Does not apply)"
                     />
                  </RadioGroup>
               </div>
               <FormHelperText
                  style={{
                     color: state.UwlHandleChb?.error && state.UwlHandleChb?.message ? "#f44336" : "inherit",
                     padding: "0px 0px 0px 10px",
                  }}
               >
                  {state.UwlHandleChb?.error && state.UwlHandleChb?.message ? state.UwlHandleChb.message : null}
               </FormHelperText>

               <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <FormLabel className={classes.labelText} style={{ color: "#000", marginRight: "1rem" }}>
                     If Yes, are you on Importer ACH with U.S. Customs?:
                  </FormLabel>
                  <RadioGroup
                     aria-label="are-you-ach-importer"
                     name="achImporter"
                     value={state.AchImporter?.value}
                     onChange={(event) => dispatch({ type: "AchImporter", payload: event.target.value })}
                     style={{ display: "flex", flexDirection: "row" }}
                  >
                     <FormControlLabel
                        className={classes.controlLabel}
                        value={"yes"}
                        control={<Radio color="primary" />}
                        label="Yes"
                        disabled={state.UwlHandleChb?.value === "no" || state.UwlHandleChb?.value === "n/a"}
                     />
                     <FormControlLabel
                        className={classes.controlLabel}
                        value={"no"}
                        control={<Radio color="primary" />}
                        label="No"
                        disabled={state.UwlHandleChb?.value === "no" || state.UwlHandleChb?.value === "n/a"}
                     />
                  </RadioGroup>
               </div>
               <FormHelperText
                  style={{
                     color: state.AchImporter?.error && state.AchImporter?.message ? "#f44336" : "inherit",
                     padding: "0px 0px 0px 10px",
                  }}
               >
                  {state.UwlHandleChb?.value === "yes" && state.AchImporter?.error && state.AchImporter?.message
                     ? state.AchImporter.message
                     : null}
               </FormHelperText>

               <p className={classes.labelText}>
                  If UWL will not handle your CHB, please provide your Customs Brokers information.
               </p>
            </article>

            {/* Brokers information */}
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
               {/* Company Name */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-company-name"
                     disabled={brokerDisabled}
                     label="Company Name"
                     autoComplete="activationBillingCompanyName"
                     name="activationBillingCompanyName"
                     placeholder="Name of Company Requesting Credit"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "CompanyName", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.CompanyName?.value}
                     helperText={state.CompanyName?.error && state.CompanyName?.message}
                     error={!!state.CompanyName?.error}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Company Contact */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-company-contact"
                     disabled={brokerDisabled}
                     autoComplete="activationBillingCompanyContact"
                     label="Company Contact Name"
                     name="activationBillingCompanyContact"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "CompanyContact", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.CompanyContact?.value}
                     helperText={state.CompanyContact?.error ? state.CompanyContact?.message : null}
                     error={!!state.CompanyContact?.error}
                  />
               </Grid>

               {/* Phone number */}
               <Grid item lg={6} md={6} xs={12}>
                  <InputMask
                     mask="999-999-9999"
                     disabled={brokerDisabled}
                     value={brokerDisabled ? "" : (state.CompanyPhone?.value as string)}
                     onChange={(event) => dispatch({ type: "CompanyPhone", payload: event.target.value })}
                  >
                     {() => (
                        <TextField
                           required={state.UwlHandleChb?.value == "no"}
                           disabled={brokerDisabled}
                           id={`billing-phone-number`}
                           label="Phone"
                           variant="outlined"
                           error={!!state.CompanyPhone?.error}
                           inputProps={{
                              autoComplete: "phone",
                              form: {
                                 autoComplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
                  <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                     {state.CompanyPhone?.error ? state.CompanyPhone?.message : null}
                  </FormHelperText>
               </Grid>

               {/* Contact Email */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-contact-email"
                     name="activationBillingContactEmail"
                     autoComplete="activationBillingContactEmail"
                     disabled={brokerDisabled}
                     label="Contact Email"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Email", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.Email?.value}
                     helperText={state.Email?.error ? state.Email?.message : null}
                     error={!!state.Email?.error}
                  />
               </Grid>

               {/* Mailing Address 1 */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-mailing-address1"
                     disabled={brokerDisabled}
                     label="Mailing Address"
                     autoComplete="activationBillingAddress1"
                     name="activationBillingAddress1"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Address1", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.Address1?.value}
                     helperText={state.Address1?.error ? state.Address1?.message : null}
                     error={!!state.Address1?.error}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Mailing Address 2 */}
               <Grid item lg={6} md={6} xs={12}>
                  <TextField
                     id="billing-mailing-address2"
                     label="Mailing Address 2"
                     name="activationBillingAddress2"
                     autoComplete="activationBillingAddress2"
                     disabled={brokerDisabled}
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Address2", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.Address2?.value}
                     helperText={state.Address2?.error ? state.Address2?.message : null}
                     error={!!state.Address2?.error}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* City */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-city"
                     disabled={brokerDisabled}
                     label="City"
                     autoComplete="activationBillingCity"
                     name="activationBillingCity"
                     variant="outlined"
                     InputProps={{
                        endAdornment: <LocationOnIcon style={{ color: "grey " }} />,
                     }}
                     onChange={(event) => dispatch({ type: "City", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.City?.value}
                     helperText={state.City?.error ? state.City?.message : null}
                     error={state.City?.error}
                  />
               </Grid>

               {/* State */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-state"
                     disabled={brokerDisabled}
                     label="State"
                     autoComplete="activationBillingState"
                     name="activationBillingState"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "State", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.State?.value}
                     helperText={state.State?.error ? state.State.message : null}
                     error={!!state.State?.error}
                     inputProps={{ maxLength: 2 }}
                  />
               </Grid>

               {/* Zip Code */}
               <Grid item lg={3} md={6} xs={12}>
                  <TextField
                     required={state.UwlHandleChb?.value == "no"}
                     id="billing-zip"
                     disabled={brokerDisabled}
                     label="Zip Code"
                     autoComplete="activationBillingZip"
                     name="activationBillingZip"
                     variant="outlined"
                     onChange={(event) => dispatch({ type: "Zip", payload: event.target.value })}
                     value={brokerDisabled ? "" : state.Zip?.value}
                     helperText={state.Zip?.error ? state?.Zip.message : null}
                     error={!!state.Zip?.error}
                     InputProps={{
                        endAdornment: <LocationOnIcon style={{ color: "grey " }} />,
                     }}
                     inputProps={{ maxLength: 100 }}
                  />
               </Grid>

               {/* Country */}
               <Grid item lg={3} md={6} xs={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                     <InputLabel id="billing-country" style={{ color: state.Country?.error ? "#f44336" : "inherit" }}>
                        Country
                     </InputLabel>
                     <Select
                        required={state.UwlHandleChb?.value == "no"}
                        labelId="test-billing-country"
                        autoComplete="activationBillingCountry"
                        disabled={brokerDisabled}
                        id="billing-country"
                        name="activationBillingCountry"
                        variant="outlined"
                        defaultValue={""}
                        style={{ height: "56px" }}
                        onChange={(event) => dispatch({ type: "Country", payload: event.target.value })}
                        value={brokerDisabled ? "" : state.Country?.value}
                        label="Country"
                        error={!!state.Country?.error}
                     >
                        {countryList.map((country, index) => {
                           return (
                              <MenuItem key={index} value={country.name}>
                                 {country.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                     <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
                        {state.Country?.error ? state?.Country.message : null}
                     </FormHelperText>
                  </FormControl>
               </Grid>
            </Grid>
         </div>
      </section>
   );
}

export { ActivationBillingInformation };
