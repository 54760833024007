import { Card, CardHeader, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useEffect } from "react";
import "./TransferList.css";

function not(a: { _id: string; value: string }[], b: { _id: string; value: string }[]) {
   return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: { _id: string; value: string }[], b: { _id: string; value: string }[]) {
   return a.filter((value) => b.indexOf(value) !== -1);
}

export const TransferList: React.FC<any> = (props: {
   leftTitle: string;
   rightTitle: string;
   leftItems: { _id: string; value: string }[];
   rightItems: { _id: string; value: string }[];
   setLeftItems: React.Dispatch<
      React.SetStateAction<
         {
            _id: string;
            value: string;
         }[]
      >
   >;
   setRightItems: React.Dispatch<
      React.SetStateAction<
         {
            _id: string;
            value: string;
         }[]
      >
   >;
   disabled: boolean;
   readOnly: boolean;
}): JSX.Element => {
   const [checked, setChecked] = React.useState<{ _id: string; value: string }[]>([]);

   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const leftChecked = intersection(checked, props.leftItems);
   const rightChecked = intersection(checked, props.rightItems);

   const handleToggle = (value: { _id: string; value: string }) => () => {
      if (props.disabled) {
         return;
      }

      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
   };

   const handleAllRight = () => {
      props.setRightItems(props.rightItems.concat(props.leftItems));
      props.setLeftItems([]);
   };

   const handleCheckedRight = () => {
      props.setRightItems(props.rightItems.concat(leftChecked));
      props.setLeftItems(not(props.leftItems, leftChecked));
      setChecked(not(checked, leftChecked));
   };

   const handleCheckedLeft = () => {
      props.setLeftItems(props.leftItems.concat(rightChecked));
      props.setRightItems(not(props.rightItems, rightChecked));
      setChecked(not(checked, rightChecked));
   };

   const handleAllLeft = () => {
      props.setLeftItems(props.leftItems.concat(props.rightItems));
      props.setRightItems([]);
   };

   useEffect(() => {
      props.setLeftItems(props.leftItems);
      props.setRightItems(props.rightItems);
   }, [props.leftItems, props.rightItems]);

   return (
      <Grid container direction="row" xs={12} justify="center" alignItems="center">
         <Grid container item direction="column" alignItems="center" xs={5}>
            <Card className={"TransferList-cards"}>
               <CardHeader
                  className={"TransferList-card-header1"}
                  style={{ textAlign: "center" }}
                  title={<Typography>{props.leftTitle}</Typography>}
               />
               <List>
                  {props.leftItems.map((left: { _id: string; value: string }) => {
                     const labelId = `transfer-list-item-left-${left._id}-label`;

                     return (
                        <ListItem key={left._id} role="listitem" button onClick={handleToggle(left)}>
                           {props.readOnly ? (
                              <></>
                           ) : (
                              <ListItemIcon>
                                 <Checkbox
                                    checked={checked.indexOf(left) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    color="primary"
                                    disabled={props.disabled}
                                 />
                              </ListItemIcon>
                           )}
                           <ListItemText id={labelId} primary={left.value} />
                        </ListItem>
                     );
                  })}
               </List>
            </Card>
         </Grid>
         <Grid container direction="column" alignItems="center" xs={2}>
            {props.readOnly ? (
               <></>
            ) : (
               <ButtonGroup orientation={"vertical"}>
                  <Button
                     size={mobileScreen ? "medium" : "large"}
                     onClick={handleAllRight}
                     disabled={props.leftItems.length === 0 || props.disabled}
                     aria-label="move all right"
                     style={{
                        backgroundColor: props.leftItems.length === 0 || props.disabled ? "#F2F2F2" : "#010440",
                        color: props.leftItems.length === 0 || props.disabled ? "grey" : "white",
                     }}
                  >
                     ≫
                  </Button>
                  <Button
                     size={mobileScreen ? "medium" : "large"}
                     onClick={handleCheckedRight}
                     disabled={leftChecked.length === 0 || props.disabled}
                     aria-label="move selected right"
                     style={{
                        backgroundColor: leftChecked.length === 0 || props.disabled ? "#F2F2F2" : "#010440",
                        color: leftChecked.length === 0 || props.disabled ? "grey" : "white",
                     }}
                  >
                     &gt;
                  </Button>
                  <Button
                     size={mobileScreen ? "medium" : "large"}
                     onClick={handleCheckedLeft}
                     disabled={rightChecked.length === 0 || props.disabled}
                     aria-label="move selected left"
                     style={{
                        backgroundColor: rightChecked.length === 0 || props.disabled ? "#F2F2F2" : "#010440",
                        color: rightChecked.length === 0 || props.disabled ? "grey" : "white",
                     }}
                  >
                     &lt;
                  </Button>
                  <Button
                     size={mobileScreen ? "medium" : "large"}
                     onClick={handleAllLeft}
                     disabled={props.rightItems.length === 0 || props.disabled}
                     aria-label="move all left"
                     style={{
                        backgroundColor: props.rightItems.length === 0 || props.disabled ? "#F2F2F2" : "#010440",
                        color: props.rightItems.length === 0 || props.disabled ? "grey" : "white",
                     }}
                  >
                     ≪
                  </Button>
               </ButtonGroup>
            )}
         </Grid>
         <Grid item container direction="column" alignItems="center" xs={5}>
            <Card className={"TransferList-cards"}>
               <CardHeader
                  className={"TransferList-card-header1"}
                  style={{ textAlign: "center" }}
                  title={<Typography>{props.rightTitle}</Typography>}
               />
               <List>
                  {props.rightItems.map((right: { _id: string; value: string }) => {
                     const labelId = `transfer-list-item-right-${right._id}-label`;

                     return (
                        <ListItem key={right._id} role="listitem" button onClick={handleToggle(right)}>
                           {props.readOnly ? (
                              <></>
                           ) : (
                              <ListItemIcon>
                                 <Checkbox
                                    checked={checked.indexOf(right) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    color="primary"
                                    disabled={props.disabled}
                                 />
                              </ListItemIcon>
                           )}
                           <ListItemText id={labelId} primary={right.value} />
                        </ListItem>
                     );
                  })}
               </List>
            </Card>
         </Grid>
      </Grid>
   );
};

export default TransferList;
