import { createStyles, makeStyles } from "@material-ui/core";

export const useStepperStyles = makeStyles(() =>
   createStyles({
      headerContainer: {
         display: "flex",
         justifyContent: "space-between",
         alignItems: "baseline",
         paddingBottom: "21px",
      },
      textColor: {
         color: "#FFF",
      },
      smallText: {
         fontSize: "0.85rem",
      },
      circle: {
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         padding: "0px 7px",
         width: "24px",
         height: "24px",
         background: "rgba(0, 0, 0, 0.38)",
         color: "#FFF",
         borderRadius: "64px",
      },
      stepper: {
         display: "flex",
         flexWrap: "wrap",
         borderRadius: "8px",
         padding: "16px 30px",
         "& .MuiStep-horizontal": {
            minWidth: "110px",
         },
      },
      stepperLabel: {
         "& span.MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
            fontSize: "0.95rem",
            marginTop: "0px",
         },
         "& .MuiStepLabel-active": {
            fontWeight: "500",
         },
      },
      iconContainer: {
         minHeight: "80px",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
      },
      activeIcon: {
         width: "80px",
         height: "80px",
         background: "#EAF9FF",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         borderRadius: "50%",
      },
      completed: {
         color: "#ffffff",
         zIndex: 1,
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         padding: "0px 7px",
         width: "24px",
         height: "24px",
         background: "#043F60",
         borderRadius: "64px",
      },
   }),
);
