import { Users } from "../../types/Users";

type ActionMap<M extends { [index: string]: any }> = {
   [Key in keyof M]: M[Key] extends undefined
      ? {
           type: Key;
        }
      : {
           type: Key;
           payload: M[Key];
        };
};

export enum Types {
   Create = "CREATE_USER",
   Delete = "DELETE_USER",
}

type UserPayload = {
   [Types.Create]: {
      CurrentUser: Users;
   };
   [Types.Delete]: {
      CurrentUser: Users;
   };
};

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const userReducer = (state: Users, action: UserActions) => {
   switch (action.type) {
      case Types.Create:
         return {
            ...state,
            _id: action.payload.CurrentUser._id,
            email: action.payload.CurrentUser.email,
            password: action.payload.CurrentUser.password,
            firstName: action.payload.CurrentUser.firstName,
            lastName: action.payload.CurrentUser.lastName,
            active: action.payload.CurrentUser.active,
            companies: action.payload.CurrentUser.companies,
            divisions: action.payload.CurrentUser.divisions,
            security: action.payload.CurrentUser.security,
            invite: action.payload.CurrentUser.invite,
            maximumApprovalAmount: action.payload.CurrentUser.maximumApprovalAmount,
            department: action.payload.CurrentUser.department,
            addDate: action.payload.CurrentUser.addDate,
            deleteDate: action.payload.CurrentUser.deleteDate,
         };
      case Types.Delete:
         return {
            ...state,
            _id: "",
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            active: null,
            companies: null,
            divisions: null,
            security: "",
            invite: "",
            maximumApprovalAmount: 0,
            department: "",
            addDate: null,
            deleteDate: null,
         };
      default:
         return state;
   }
};
