import { AppBar, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import React from "react";
import CheckVoidAnimation from "../../styles/CheckVoidAnimation";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      containerStyle: {
         backgroundColor: "#002D72",
         width: "100%",
         height: "100%",
      },
      title: {
         flexGrow: 2,
         textAlign: "center",
         color: "white",
      },
   }),
);

export const ErrorHandler: React.FC<any> = (props: any): JSX.Element => {
   const classes = useStyles();

   return (
      <>
         <div>
            <AppBar className={classes.containerStyle}>
               <Grid container direction="row" alignItems="center">
                  <Grid item xs={12}>
                     <Typography variant="h2" className={classes.title}>
                        {`:'(`}
                     </Typography>
                  </Grid>
                  <CheckVoidAnimation />
                  <Grid item xs={12} style={{ marginTop: "6rem" }}>
                     <Typography variant="h2" className={classes.title}>
                        Looks Like A Bad Check Came In!
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography className={classes.title}>
                        Please contact your administrator regarding the below error.
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography className={classes.title}>{props.error.message}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography className={classes.title}>Go ahead and refresh the site and try again:</Typography>
                  </Grid>
                  <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                     <Tooltip title="Refresh Page" arrow>
                        <IconButton id="btn-refresh" className={classes.title} onClick={props.resetErrorBoundary}>
                           <RefreshRoundedIcon style={{ fontSize: "2rem" }}></RefreshRoundedIcon>
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
            </AppBar>
         </div>
      </>
   );
};

export default ErrorHandler;
