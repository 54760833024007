/* eslint-disable react/react-in-jsx-scope */
import { CircularProgress, Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSubHeaderStyles } from "./Styles";
/* eslint-disable prettier/prettier */
import { Card, Chip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import EmailIcon from "@material-ui/icons/Email";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Contacts } from "../../../types/Contacts";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { DateTimeFormatterShortYear } from "../../../utilities/DateTimeFormatterShortYear";

export type ActivationDetailsButtonType = "companyCode" | "notes" | "approvedAmount" | "email" | "denialCode" | "pdf";

type Props = {
   selectedCustomerActivation: CustomerActivation;
   contactList: Contacts[];
   selectedDivision: string;
   openDialog: (type: ActivationDetailsButtonType) => void;
   isWorking?: ActivationDetailsButtonType;
};

function NewSubHeader({ selectedCustomerActivation, contactList, selectedDivision, isWorking, openDialog }: Props) {
   const classes = useSubHeaderStyles();

   return (
      <>
         <Grid container direction={"row"}>
            <Grid direction={"column"} item xs={12}>
               <Typography variant="h5" color="primary">
                  {selectedCustomerActivation.companyName + " - " + selectedCustomerActivation.applicationNumber}
               </Typography>
            </Grid>
         </Grid>
         <Grid container direction={"row"}>
            <Grid direction={"column"} item md={6}>
               <Button
                  variant="outlined"
                  color="primary"
                  className={classes.primaryButton}
                  endIcon={<CreateIcon />}
                  style={{ marginRight: "12px" }}
                  onClick={() => openDialog("companyCode")}
               >
                  Update Customer Code
               </Button>
            </Grid>
            <Grid direction={"column"} item md={6} justifyContent="flex-end">
               <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ rowGap: "8px" }}
               >
                  <Button
                     variant="outlined"
                     className={classes.saveButton}
                     endIcon={<ThumbUpIcon />}
                     onClick={() => openDialog("approvedAmount")}
                     style={{ marginRight: "12px" }}
                  >
                     Approve
                  </Button>
                  <Button
                     variant="outlined"
                     className={classes.deleteButton}
                     endIcon={<ThumbDownIcon />}
                     onClick={() => openDialog("denialCode")}
                     style={{ marginRight: "12px" }}
                  >
                     Deny
                  </Button>
                  <Button
                     variant="outlined"
                     color="primary"
                     className={classes.primaryButton}
                     endIcon={<EmailIcon />}
                     onClick={() => openDialog("email")}
                     style={{ marginRight: "12px" }}
                  >
                     Email
                  </Button>
                  <Button
                     variant="outlined"
                     color="primary"
                     disabled={isWorking === "pdf"}
                     className={classes.primaryButton}
                     endIcon={
                        isWorking === "pdf" ? (
                           <CircularProgress style={{ opacity: 0.7, width: "20px", height: "20px" }} />
                        ) : (
                           <PictureAsPdfIcon />
                        )
                     }
                     onClick={() => openDialog("pdf")}
                     style={{ marginRight: "12px" }}
                  >
                     View PDF
                  </Button>
                  <Button
                     variant="outlined"
                     className={classes.notesButton}
                     endIcon={<SpeakerNotesIcon />}
                     onClick={() => openDialog("notes")}
                     style={{ marginRight: "12px" }}
                  >
                     Notes
                  </Button>
               </Grid>
            </Grid>
         </Grid>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     padding: "16px 36px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Date
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company | Division
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Customer Code
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Point of Contact
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Status
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  justifyContent="space-between"
                  style={{
                     minHeight: "50px",
                     padding: "16px 36px",
                  }}
               >
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {DateTimeFormatterShortYear(selectedCustomerActivation.addDate)}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedDivision}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedCustomerActivation.companyCode}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {contactList.map((contact, index) => {
                           return (
                              <div key={contact._id}>
                                 {contact.name}
                                 {index < contactList.length - 1 ? ", " : ""}
                              </div>
                           );
                        })}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <div className={classes.chipContainer}>
                        {selectedCustomerActivation.status.toLowerCase() === "pending" ? (
                           <Chip
                              key={selectedCustomerActivation._id}
                              className={classes.chipPending}
                              label="Pending"
                              size="small"
                              clickable={false}
                              variant="outlined"
                           />
                        ) : (
                           <Chip
                              key={selectedCustomerActivation._id}
                              label="New"
                              size="small"
                              clickable={false}
                              color="primary"
                              variant="outlined"
                           />
                        )}
                     </div>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { NewSubHeader };
