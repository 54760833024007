import { Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Select } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import TuneIcon from "@material-ui/icons/Tune";
import _ from "lodash";
import React, { ChangeEvent, MouseEvent, useContext, useEffect, useState } from "react";
import { Status } from "../../../pages/Activation/ActivationView";
import { CompanyContext } from "../../../stores/Companies/Context";
import { DivisionContext } from "../../../stores/Divisions/Context";
import { Companies } from "../../../types/Companies";
import { Divisions } from "../../../types/Divisions";
import * as CookieTools from "../../../utilities/CookieTools";
import { DateFilterProps, fetchDataWithFiltersProps } from "../ActivationViewFilters/ActivationViewFilters";
import styles from "./FiltersMenu.module.css";
import { BootstrapDatePicker, MenuSubtitle, MenuTitle } from "./FiltersMenu.styled";

interface Props {
   status: Status;
   companyFilter: string | null;
   dateFilter: DateFilterProps;
   statusSelected: Status | null;
   setStatusSelected: React.Dispatch<React.SetStateAction<Status | null>>;
   setCompanyFilter: React.Dispatch<React.SetStateAction<string>>;
   setDateFilter: React.Dispatch<React.SetStateAction<{ from: Date | null; to: Date | null }>>;
   setShowChips: React.Dispatch<React.SetStateAction<boolean>>;
   fetchDataWithFilters: ({ reset }: fetchDataWithFiltersProps) => void;
}

type SelectChangeEvent = ChangeEvent<{
   name?: string | undefined;
   value: unknown;
}>;

export const FiltersMenu: React.FC<Props> = ({
   status,

   companyFilter,
   dateFilter,
   statusSelected,
   setStatusSelected,
   setCompanyFilter,
   setDateFilter,
   setShowChips,
   fetchDataWithFilters,
}) => {
   // context
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   // states
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const [companyList, setCompanyList] = useState<Divisions[]>([]);

   const open = Boolean(anchorEl);

   const handleAllFiltersClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleAllFiltersClose = () => {
      setAnchorEl(null);
   };

   const handleSelectChange = (event: SelectChangeEvent) => setCompanyFilter(event.target.value as string);

   const handleDateFilterChange =
      (filter: "from" | "to") => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
         const date = new Date(event.target.value);
         // add 12 hours to date to display the correct date in the chip
         date.setHours(date.getHours() + 12);

         setDateFilter((prev) => ({ ...prev, [filter]: date ?? null }));
      };

   const clearFilters = () => {
      // clear filters
      setCompanyFilter("");
      setStatusSelected(status);
      setDateFilter({ from: null, to: null });
      fetchDataWithFilters({ reset: true });
      // hide chips
      setShowChips(false);
      // close menu
      handleAllFiltersClose();
   };

   const handleApplyFilters = () => {
      // show chips on apply filters click
      setShowChips(true);
      // fetch data with filters
      fetchDataWithFilters({ reset: false });
      // close menu
      handleAllFiltersClose();
   };

   const formatDate = (date: Date) => {
      // this function takes a date and returns a string with the format: "yyyy-mm-dd"
      // so that the date can be used in the native date picker
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
   };

   const [dateFromValue, dateToValue] = [
      dateFilter.from ? formatDate(new Date(dateFilter.from)) : "",
      dateFilter.to ? formatDate(new Date(dateFilter.to)) : "",
   ];

   // set companies and divisions for select
   useEffect(() => {
      // merge these for a clean list.
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0 && companyList.length === 0) {
         let newCompanyList: Divisions[] = [];
         const selectedCompanies = CookieTools.getCookie("selected-company");
         const selectedDivisions = CookieTools.getCookie("selected-division");

         // first, setup an all option.
         const newCompanyAll: Divisions[] = [
            {
               _id: "",
               company_id: "ALL",
               code: "ALL",
               name: "All Companies | Divisions",
               managers: [],
               active: true,
               prefix: "",
               counter: 0,
               addDate: null,
               deleteDate: null,
               invitationBody: "",
               invitationTopNote: "",
               emailTemplate: "",
               docuSignTemplateId: "",
               receiptFirstParagraph: "",
               receiptSecondParagraph: "",
               deniedFirstParagraph: "",
               deniedSecondParagraph: "",
               approvedFirstParagraph: "",
               approvedSecondParagraph: "",
               approvedBoldParagraph: "",
               contactApprovedBody: "",
               contactDeniedBody: "",
               duplicationNotification: "",
               contactDuplicationNotification: "",
               denialCodes: [],
               contacts: [],
               quickCreditAmount: 0,
            },
         ];

         newCompanyList = [...newCompanyList, ...newCompanyAll];

         companyState.Companies.forEach((company: Companies) => {
            if (!selectedCompanies.includes(company._id)) return;

            const divisionsAssigned = _.orderBy(
               _.filter(
                  divisionState.Divisions,
                  (x) =>
                     x.company_id === company._id &&
                     selectedDivisions.includes(selectedDivisions ? x._id : selectedDivisions),
               ),
               ["name"],
               ["asc"],
            );

            if (divisionsAssigned.length > 0) {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: company.denialCodes,
                     contacts: company.contacts,
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
               newCompanyList = [...newCompanyList, ...divisionsAssigned];
            } else {
               const newCompany: Divisions[] = [
                  {
                     _id: "",
                     company_id: company._id,
                     code: company.code,
                     name: company.name,
                     managers: company.managers,
                     active: company.active,
                     prefix: company.prefix,
                     counter: company.counter,
                     addDate: company.addDate,
                     deleteDate: company.deleteDate,
                     invitationBody: company.invitationBody,
                     invitationTopNote: company.invitationTopNote,
                     emailTemplate: company.emailTemplate,
                     docuSignTemplateId: company.docuSignTemplateId,
                     receiptFirstParagraph: company.receiptFirstParagraph,
                     receiptSecondParagraph: company.receiptSecondParagraph,
                     deniedFirstParagraph: company.deniedFirstParagraph,
                     deniedSecondParagraph: company.deniedSecondParagraph,
                     approvedFirstParagraph: company.approvedFirstParagraph,
                     approvedSecondParagraph: company.approvedSecondParagraph,
                     approvedBoldParagraph: company.approvedBoldParagraph,
                     contactApprovedBody: company.contactApprovedBody,
                     contactDeniedBody: company.contactDeniedBody,
                     duplicationNotification: company.duplicationNotification,
                     contactDuplicationNotification: company.contactDuplicationNotification,
                     denialCodes: company.denialCodes,
                     contacts: company.contacts,
                     quickCreditAmount: company.quickCreditAmount,
                  },
               ];

               newCompanyList = [...newCompanyList, ...newCompany];
            }
         });

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState]);

   return (
      <div className={styles.filters_menu}>
         <Button className={styles.button} variant="outlined" endIcon={<TuneIcon />} onClick={handleAllFiltersClick}>
            All Filters
         </Button>
         <Menu
            PaperProps={{
               style: {
                  width: "460px",
                  marginTop: "60px",
                  padding: "36px",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.25)",
                  backgroundColor: "#FFFFFF",
               },
            }}
            id="options-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleAllFiltersClose}
         >
            <header className={styles.menu_header}>
               <MenuTitle>Filters</MenuTitle>
               <IconButton onClick={handleAllFiltersClose}>
                  <ClearIcon style={{ color: "#010440", fontSize: "24px" }} />
               </IconButton>
            </header>
            <div className={styles.menu_filter_option}>
               <MenuSubtitle>Date Range</MenuSubtitle>
               {/* Date Filter */}
               <div className={styles.date_pickers_container}>
                  <BootstrapDatePicker
                     id="start-date"
                     label="Start Date"
                     type="date"
                     variant="outlined"
                     InputLabelProps={{ shrink: true }}
                     value={dateFromValue}
                     onChange={handleDateFilterChange("from")}
                  />
                  <BootstrapDatePicker
                     id="end-date"
                     label="End Date"
                     type="date"
                     variant="outlined"
                     InputLabelProps={{ shrink: true }}
                     value={dateToValue}
                     onChange={handleDateFilterChange("to")}
                  />
               </div>
            </div>
            {/* Company - division filter */}
            <div className={styles.menu_filter_option}>
               <MenuSubtitle>Company / Division</MenuSubtitle>
               {/* Companies | Divisions Filter */}
               <FormControl style={{ width: "100%", margin: "16px 0" }} variant="outlined" size="small">
                  <InputLabel htmlFor="company-division">Company / Division</InputLabel>
                  <Select
                     label="Company / Division"
                     labelId="company-division"
                     id="demo-simple-select"
                     value={companyFilter}
                     onChange={handleSelectChange}
                  >
                     {companyList.map((company) => (
                        <MenuItem
                           style={{
                              fontWeight: company._id ? 500 : 700,
                              marginLeft: company._id ? "1rem" : "0rem",
                              color: company._id ? "#000000" : "#002D72",
                           }}
                           key={company._id ? company._id : company.company_id}
                           value={company._id ? company._id : company.company_id}
                        >
                           {(company._id ? "  " : "") + company.code + " | " + company.name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </div>
            {/* Status, only for review Page */}
            {status === "REVIEWED" ? (
               <div className={styles.menu_filter_option}>
                  <FormControl style={{ width: "100%", margin: "16px 0" }} variant="outlined" size="small">
                     <InputLabel htmlFor="status-reviewed">Status</InputLabel>
                     <Select
                        label="Status"
                        labelId="status-reviewed"
                        id="status-reviewed-select"
                        value={statusSelected}
                        onChange={(event) => setStatusSelected(event?.target.value as Status)}
                     >
                        {["APPROVED", "DENIED"].map((status) => (
                           <MenuItem key={status} value={status}>
                              {status}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
            ) : null}
            <footer className={styles.menu_footer}>
               <Button variant="text" startIcon={<ClearIcon />} onClick={clearFilters}>
                  Clear
               </Button>
               <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleApplyFilters}>
                  Apply Filter
               </Button>
            </footer>
         </Menu>
      </div>
   );
};
