import _ from "lodash";
import React, { useRef, useState } from "react";

import {
   Chip,
   FormControl,
   FormHelperText,
   InputAdornment,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { CompanyContext } from "../../../../stores/Companies/Context";
import { DivisionContext } from "../../../../stores/Divisions/Context";
import { Contacts } from "../../../../types/Contacts";
import { ApprovalParams } from "../ActivarionApproveDialog/ActivationApproveDialog";
import { ActivationBaseDialogHOC } from "../ActivationBaseDialogHOC";

interface Props {
   open: boolean; //TODO: replace this any type
   divisionId: string;
   companyId: string;

   customerCode: string;
   approvedAmount: number;
   contacts: string[];
   paymentTerms?: string;

   handleClose: () => void;
   callback: (approval: ApprovalParams) => Promise<void>;
}

export const ActivationApproveDialogDetails: React.FC<Props> = ({
   open,
   customerCode,
   approvedAmount,
   contacts,
   divisionId,
   companyId,
   paymentTerms,
   handleClose,
   callback,
}) => {
   const [isSaving, setIsSaving] = useState(false);
   const [selectedContactsList, setSelectedContactsList] = useState<Contacts[]>([]);
   // Context
   const { state: companyState } = React.useContext(CompanyContext);
   const { state: divisionState } = React.useContext(DivisionContext);
   // form fields
   const [customValue, setCustomValue] = useState<string>(customerCode);
   const [approvedAmountValue, setApprovedAmountValue] = useState<string | number>(approvedAmount);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [paymentApprovalTerms, setPaymentApprovalTerms] = useState<string | undefined>(paymentTerms);

   const contactRef = useRef<Contacts[]>([]);
   // Errors
   const [errorCustomValue, setErrorCustomValue] = useState("");
   const [errorContactsValue, setErrorContactsValue] = useState("");

   const validateCustomValue = (value?: string) => {
      if (!value && errorCustomValue) return;
      if (value && !errorCustomValue) return;

      if (!value) {
         setErrorCustomValue("Customer code is required");
      } else {
         setErrorCustomValue("");
      }
   };

   const validateContactsValue = (value?: Contacts[]) => {
      if (!value?.length && errorContactsValue) return;
      if (value?.length && !errorContactsValue) return;

      if (!value?.length) {
         setErrorContactsValue("Contact is required");
      } else {
         setErrorContactsValue("");
      }
   };

   const handleSave = async () => {
      // validate errors
      if (!customValue || !selectedContactsList.length) {
         validateCustomValue(customValue);
         validateContactsValue(selectedContactsList);
         return;
      }

      setIsSaving(true);
      await callback({
         customerCode: customValue,
         approvedAmount: approvedAmountValue + "",
         contacts: selectedContactsList.map((x) => x._id),
         paymentTerms: paymentApprovalTerms,
      });
      setIsSaving(false);
      handleClose();
   };

   function handleChipDelete(_id: string) {
      // add to contact dropdown list
      const contactToAdd = contactRef.current.find((x) => x._id === _id) as Contacts;
      setContactList(_.orderBy([...contactList, contactToAdd], [(contact) => contact.name.toLowerCase()], ["asc"]));

      // remove from contact selected
      const newList = selectedContactsList.filter((x) => x._id !== _id);
      validateContactsValue(newList);
      setSelectedContactsList(newList);
   }

   function handleSelectContactChange(contactIds: (string | Contacts)[]) {
      // Add to contacts selected
      const contactsAux: Contacts[] = [];
      contactIds.forEach((id) => {
         if (typeof id === "string") {
            const contact = contactRef.current.find((x) => x._id === id);
            contact && contactsAux.push(contact);
         } else {
            const contact = contactRef.current.find((x) => x._id === id._id);
            contact && contactsAux.push(contact);
         }
      });

      setSelectedContactsList(contactsAux);
      validateContactsValue(contactsAux);

      // remove the contact selected from contactList
      const contactListAux = contactList.filter((x) => !contactsAux.includes(x));
      setContactList(contactListAux);
   }

   function handleLocalClose() {
      handleClose();
      setCustomValue(customerCode);
      setApprovedAmountValue(approvedAmount);
      setErrorCustomValue("");
      setErrorContactsValue("");
   }

   function handleLocalChange(text: string) {
      const onlyNums = text.replace(/[^0-9]/g, "");
      const value = onlyNums ? +onlyNums : 0;
      setApprovedAmountValue(value);
   }

   //Loading Use Effect.
   React.useEffect(() => {
      if (!open) return;

      if (divisionId) {
         const divisionAssigned = _.filter(divisionState.Divisions, (x) => x._id.toString() === divisionId?.toString());

         if (divisionAssigned.length > 0) {
            contactRef.current = _.orderBy(
               _.filter(divisionAssigned[0].contacts, (x) => x.active === true),
               [(contact) => contact.name.toLowerCase()],
               ["asc"],
            );
         }
      } else {
         const companyAssigned = _.filter(companyState.Companies, (x) => x._id.toString() === companyId?.toString());

         if (companyAssigned.length > 0) {
            contactRef.current = _.orderBy(
               _.filter(companyAssigned[0].contacts, (x) => x.active === true),
               [(contact) => contact.name.toLowerCase()],
               ["asc"],
            );
         }
      }

      // Select contact by default
      const contactsSelected = contactRef.current.filter((x) => contacts.includes(x._id));
      const contactListAux = contactRef.current.filter((x) => !contacts.includes(x._id));

      setContactList(contactListAux);
      setSelectedContactsList(contactsSelected);
   }, [open]);

   const error = !!(errorCustomValue || errorContactsValue);

   return (
      <ActivationBaseDialogHOC
         open={open}
         title="Activation Approval"
         primaryButtonTitle="SAVE CHANGES"
         secondaryButtonTitle="DISCARD CHANGES"
         isSaving={isSaving}
         disabled={error}
         handleClose={handleLocalClose}
         handleAction={handleSave}
      >
         <form>
            <TextField
               id="amountApproved"
               label="Amount Approved (optional)"
               name="amountApproved"
               onChange={(event) => {
                  handleLocalChange(event.target.value);
               }}
               value={approvedAmountValue.toLocaleString("en-US", { maximumFractionDigits: 2 })}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position="start">
                        <AttachMoneyIcon />
                     </InputAdornment>
                  ),
               }}
            />
            <TextField
               id="custom-code"
               label="Customer Code"
               name="customerCode"
               multiline
               onChange={(event) => {
                  validateCustomValue(event.target.value);
                  setCustomValue(event.target.value);
               }}
               value={customValue}
               error={Boolean(errorCustomValue)}
               helperText={errorCustomValue}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
            />
            <FormControl
               variant="outlined"
               style={{ marginTop: "0.75rem", width: "100%" }}
               error={Boolean(errorContactsValue)}
            >
               <InputLabel>Contact</InputLabel>
               <Select
                  id="select-contact"
                  label="Contact"
                  multiple
                  MenuProps={{
                     getContentAnchorEl: null,
                     transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                     },
                  }}
                  value={selectedContactsList}
                  onChange={(event) => handleSelectContactChange(event.target.value as string[])}
                  disabled={contactList.length <= 0}
               >
                  {contactList.map((contact) => {
                     return (
                        <MenuItem key={contact._id} value={contact._id}>
                           {contact.name}
                        </MenuItem>
                     );
                  })}
               </Select>
               <FormHelperText>{errorContactsValue}</FormHelperText>
            </FormControl>

            <TextField
               id="payment-terms"
               label="Payment Terms (optional)"
               name="paymentTerms"
               multiline
               onChange={(event) => setPaymentApprovalTerms(event.target.value)}
               value={paymentApprovalTerms}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
            />
            {selectedContactsList.map((contact) => {
               return (
                  <Chip
                     key={contact._id}
                     label={contact.name}
                     variant="outlined"
                     color="primary"
                     onDelete={() => handleChipDelete(contact._id)}
                     style={{ marginTop: ".5rem", marginRight: ".5rem", fontSize: "0.75rem" }}
                  />
               );
            })}
         </form>
      </ActivationBaseDialogHOC>
   );
};
