import { Button, ButtonGroup, CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
}));

export const SaveDialog: React.FC<any> = (props: {
   title: string;
   text: string;
   text2: string;
   open: boolean;
   saveClicked: boolean;
   handleSave: () => void;
   handleClose: () => void;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const saveTitle = props.title || "Save Item?";
   const saveText = props.text || "Are you sure you want to save this item?";
   const saveText2 = props.text2 || "";

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            {saveTitle}
         </DialogTitle>
         <DialogContent>
            <DialogContentText>{saveText}</DialogContentText>
            {saveText2 ? <DialogContentText>{saveText2}</DialogContentText> : <></>}
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={props.handleSave}
                  variant="contained"
                  color="secondary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={props.saveClicked}
               >
                  {props.saveClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SaveIcon />}
                  Save
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default SaveDialog;
