import { Card, Switch, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import InputMask from "react-input-mask";
import { Chb } from "../../../types/Chb";
import "../ApplyUWLWDS.css";

type ChbError = {
   companyName: { error: boolean; message: string };
   contact: { error: boolean; message: string };
   address: { error: boolean; message: string };
   email: { error: boolean; message: string };
   city: { error: boolean; message: string };
   state: { error: boolean; message: string };
   zip: { error: boolean; message: string };
   phone: { error: boolean; message: string };
};

type CHBInfoProps = {
   chb: Chb;
   handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: keyof Chb) => void;
   handleChangeCheck: (event: React.ChangeEvent<HTMLInputElement>, value: keyof Chb) => void;
   errorHighlight: ChbError;
};

export const ChbInfo: React.FC<any> = ({ chb, handleChange, handleChangeCheck, errorHighlight }: CHBInfoProps) => {
   const useStyles = makeStyles((theme: Theme) =>
      createStyles({
         formControl: {
            width: "-webkit-fill-available",
         },
         selectEmpty: {
            marginTop: theme.spacing(2),
         },
         outerPaper: {
            padding: "1%",
         },
         descriptionText: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: "1%",
            fontSize: "16px",
         },
      }),
   );

   const classes = useStyles();

   return (
      <>
         <form noValidate autoComplete="off">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
               <Grid item xs={6}>
                  <Card
                     style={{
                        backgroundColor: "#EEEEEE",
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                     elevation={0}
                  >
                     <Typography style={{ fontWeight: 600, marginLeft: "15px" }}>Will UWL handle your CHB?</Typography>
                     <Switch
                        color="primary"
                        style={{ justifyContent: "flex-end" }}
                        checked={chb.uwlHandleChb ? true : false}
                        onChange={(event) => handleChangeCheck(event, "uwlHandleChb")}
                     />
                  </Card>
               </Grid>
               <Grid item xs={6}>
                  <Card
                     style={{
                        backgroundColor: "#EEEEEE",
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                     elevation={0}
                  >
                     <Typography style={{ fontWeight: 600, marginLeft: "15px" }}>
                        Are you on importer ACH with U.S. Customs?
                     </Typography>
                     <Switch
                        color="primary"
                        style={{ justifyContent: "flex-end" }}
                        checked={chb.achImporter ? true : false}
                        onChange={(event) => handleChangeCheck(event, "achImporter")}
                        disabled={!chb.uwlHandleChb}
                     />
                  </Card>
               </Grid>
            </Grid>
            <Typography className={classes.descriptionText}>
               If UWL will not handle your CHB, please provide your Custom Brokers info…
            </Typography>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
               <Grid item xs={12}>
                  <TextField
                     id="chb-companyName"
                     label="Company Name"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "companyName")}
                     value={chb.companyName}
                     helperText={errorHighlight.companyName.error ? errorHighlight.companyName.message : null}
                     error={!!errorHighlight.companyName.error}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     id="chb-contact"
                     label="Contact Info"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "contact")}
                     value={chb.contact}
                     helperText={errorHighlight.contact.error ? errorHighlight.contact.message : null}
                     error={!!errorHighlight.contact.error}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     id="chb-address"
                     label="Address"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "address")}
                     value={chb.address}
                     helperText={errorHighlight.address.error ? errorHighlight.address.message : null}
                     error={errorHighlight.address.error ? true : false}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
               <Grid item xs={12}>
                  <InputMask
                     mask="999-999-9999"
                     value={chb.phone}
                     onChange={(event) => handleChange(event, "phone")}
                     disabled={chb.uwlHandleChb}
                  >
                     {() => (
                        <TextField
                           id="chb-phone"
                           label="Phone #"
                           variant="outlined"
                           error={errorHighlight.phone.error ? true : false}
                           helperText={errorHighlight.phone.error ? errorHighlight.phone.message : null}
                           inputProps={{
                              autoComplete: "phone",
                              form: {
                                 autoComplete: "off",
                              },
                           }}
                        />
                     )}
                  </InputMask>
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     id="chb-email"
                     label="Email"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "email")}
                     value={chb.email}
                     helperText={errorHighlight.email.error ? errorHighlight.email.message : null}
                     error={!!errorHighlight.email.error}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
               <Grid item xs={6}>
                  <TextField
                     id="chb-city"
                     label="City"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "city")}
                     value={chb.city}
                     helperText={errorHighlight.city.error ? errorHighlight.city.message : " "}
                     error={errorHighlight.city.error ? true : false}
                     inputProps={{ maxLength: 100 }}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
               <Grid item xs={3}>
                  <TextField
                     id="chb-state"
                     label="State"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "state")}
                     value={chb.state}
                     helperText={errorHighlight.state.error ? errorHighlight.state.message : " "}
                     error={!!errorHighlight.state.error}
                     inputProps={{ maxLength: 2 }}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
               <Grid item xs={3}>
                  <TextField
                     id="chb-zip"
                     label="Zip Code"
                     variant="outlined"
                     onChange={(event) => handleChange(event, "zip")}
                     value={chb.zip}
                     helperText={errorHighlight.zip.error ? errorHighlight.zip.message : " "}
                     error={!!errorHighlight.zip.error}
                     inputProps={{ maxLength: 10 }}
                     disabled={chb.uwlHandleChb}
                  />
               </Grid>
            </Grid>
         </form>
      </>
   );
};

export default ChbInfo;
