import React, { createContext, Dispatch, useReducer } from "react";
import { DrawerActions, drawerReducer } from "./Reducers";

type InitialStateType = {
   DrawerOpen: boolean;
};

const initialState = {
   DrawerOpen: false,
};

const DrawerContext = createContext<{
   state: InitialStateType;
   dispatch: Dispatch<DrawerActions>;
}>({
   state: initialState,
   dispatch: () => null,
});

const mainReducer = ({ DrawerOpen }: InitialStateType, action: DrawerActions) => ({
   DrawerOpen: drawerReducer(DrawerOpen, action),
});

// eslint-disable-next-line react/prop-types
const DrawerProvider: React.FC = ({ children }) => {
   const [state, dispatch] = useReducer(mainReducer, initialState);
   return <DrawerContext.Provider value={{ state, dispatch }}>{children}</DrawerContext.Provider>;
};

export { DrawerProvider, DrawerContext };
//BUG IN prettier.
