import { CircularProgress, Divider, ListItemIcon, MenuItem, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React, { MouseEvent } from "react";

interface MenuItemProps {
   icon: OverridableComponent<SvgIconTypeMap>;
   iconColor?: string;
   handleClick: (event: MouseEvent) => void;
   loading?: boolean;
}

const BASE_COLOR = "#010440";

export const StyledMenuItem: React.FC<MenuItemProps> = ({
   icon,
   loading = false,
   handleClick,
   iconColor,
   children,
}) => {
   const Icon = icon;

   return (
      <MenuItem onClick={handleClick} style={{ color: BASE_COLOR }}>
         <ListItemIcon>
            {loading ? (
               <CircularProgress style={{ color: "#010440", width: "20px", height: "20px" }} />
            ) : (
               <Icon style={{ color: iconColor ?? BASE_COLOR }} />
            )}
         </ListItemIcon>
         {children}
      </MenuItem>
   );
};

export const StyledDivider = () => <Divider style={{ backgroundColor: BASE_COLOR, margin: "7px 0" }} />;
