// validate if I can change it any

import { Applications } from "../../types/Applications";

/**
 * Return the current step according to application data
 * @param application
 * @returns
 */
export function getCurrentStep(application: Applications): number {
   if (
      application.companyName &&
      application.accountsPayableEmail &&
      application.companyContact &&
      application.address.address1 &&
      application.address.city &&
      application.address.state &&
      application.address.country &&
      application.address.zip &&
      // step 2 is not completed yet
      !application.billingAddress.address1
   ) {
      return 1;
   }

   // Step 2
   if (
      application.billingAddress &&
      application.billingAddress.address1 &&
      application.billingAddress.city &&
      application.billingAddress.state &&
      application.billingAddress.zip &&
      application.billingAddress.email &&
      application.billingAddress.phone &&
      application.billingAddress.contact &&
      // step 3 is not completed yet
      !(application.chb.companyName || application.chb.uwlHandleChb)
   ) {
      return 2;
   }

   // Step 3
   if (
      (application.chb.uwlHandleChb ||
         (application.chb.companyName &&
            application.chb.contact &&
            application.chb.address &&
            application.chb.phone &&
            application.chb.email &&
            application.chb.city &&
            application.chb.state &&
            application.chb.zip)) &&
      // step 4 is not completed yet
      application.annualRevenue <= 0
   ) {
      return 3;
   }

   if (
      application.numberEmployees > 0 &&
      application.annualRevenue > 0 &&
      application.ownership &&
      // step 5 is not completed yet
      !application.bankInfo.bankName
   )
      return 4;

   if (
      application.bankInfo.bankName &&
      application.bankInfo.accountNumber &&
      application.bankInfo.address1 &&
      application.bankInfo.city &&
      application.bankInfo.state &&
      application.bankInfo.zip &&
      application.bankInfo.federalTaxNumber &&
      // step 6 is not completed yet
      !application.tradeRefInfo[0]?.company &&
      !application.tradeReferencesSkipped &&
      !application.tradeReferencesFileName
   )
      return 5;

   if (
      // step 6 is not completed yet
      !application.signature &&
      (application.tradeReferencesSkipped ||
         application.tradeReferencesFileName ||
         (application.tradeRefInfo.length >= 3 &&
            application.tradeRefInfo[0].company &&
            application.tradeRefInfo[0].city &&
            application.tradeRefInfo[0].state &&
            application.tradeRefInfo[0].country &&
            application.tradeRefInfo[0].email &&
            application.tradeRefInfo[0].phone &&
            application.tradeRefInfo[1].company &&
            application.tradeRefInfo[1].city &&
            application.tradeRefInfo[1].state &&
            application.tradeRefInfo[0].country &&
            application.tradeRefInfo[1].email &&
            application.tradeRefInfo[1].phone &&
            application.tradeRefInfo[2].company &&
            application.tradeRefInfo[2].city &&
            application.tradeRefInfo[2].state &&
            application.tradeRefInfo[0].country &&
            application.tradeRefInfo[2].email &&
            application.tradeRefInfo[2].phone))
   )
      return 6;

   if (application.signature && application.signatureTitle) return 6;

   return 0;
}

export const mockTradeRefInfo = {
   _id: "",
   company: "",
   address: "",
   city: "",
   state: "",
   country: "United States",
   phone: "",
   email: "",
   contact: "",
   website: "",
   ctpatNumber: "",
};

// Regex pattern

//valid email address
export const validEmail = new RegExp(/^\S+@\S+\.\S+$/);

//allows no characters except for (-)
export const allowSomeSpecialCharOnlyDash = new RegExp(/[!@#?$%><:;"^/*){}&,|(+=._\\]/);

//allows no characters except for (&,-, ,)
export const allowSomeSpecialChar = new RegExp(/[!@#?$%><:;"^/*){}|(+=._\\]/);

//allows no characters except for (&, @, ., -)
export const allowSomeSpecialCharWithAtAmpersandPeriod = new RegExp(/[!#?$%><:;"^/*){}|(+=_\\]/);

//allows no characters except for (&,-, ,, #)
export const allowSomeSpecialCharWithHashtag = new RegExp(/[!@?$%><:;"^/*){}|(+=._\\]/);

//allows no characters except for (- and ,)
export const allowSomeSpecialCharNoAmpersand = new RegExp(/[&!@#?$%><:;"^/*){}|(+=._\\]/);

//numbers only
export const numbersOnly = new RegExp(/^[0-9]/);

//alphabet only
export const alphabetOnly = new RegExp(/^([^0-9]*)$/);
