import {
   ActivationBankingInformationDTO,
   ActivationBankingInformationType,
   ActivationFormFieldError,
   ActivationFormProperties,
   ActivationModelForm,
} from "../../../types/Activation/ActivationDTO";
import { ValidationBuilder } from "../../../utilities/ValidationTools";

type FormProperties = ActivationFormProperties<ActivationBankingInformationType>;

export function bankingInformationCpgReducer(
   state: Partial<ActivationModelForm>,
   action: { type: ActivationBankingInformationType | "forceValidation"; payload: unknown },
): Partial<ActivationModelForm> {
   const Validation = new ValidationBuilder();
   switch (action.type) {
      case "BankName":
         return {
            ...state,
            BankName: {
               value: action.payload as string,
               error: false,
            },
         };
      case "PrincipalName":
         return {
            ...state,
            PrincipalName: {
               value: action.payload as string,
               error: false,
            },
         };
      case "AccountNumber":
         return {
            ...state,
            AccountNumber: {
               value: action.payload as string,
               error: false,
            },
         };
      case "Contact":
         return {
            ...state,
            Contact: {
               value: action.payload as string,
               error: false,
            },
         };
      case "Phone":
         let phone = (action.payload as string).replaceAll("_", "") || "";
         phone = phone?.replaceAll("-", "") || "";

         const phoneErrorMessage = Validation.validateExaclyStringLength(
            "Please enter a valid number (10 number characters)",
            10,
            phone,
         ).getMessageError();

         return {
            ...state,
            Phone: {
               value: phone,
               error: !!phoneErrorMessage,
               message: phoneErrorMessage,
            },
         };
      case "Address":
         let addressErrorMessage = "";
         addressErrorMessage = Validation.maximumLength(100, action.payload as string)
            .allowSomeSpecialCharWithHashtag()
            .getMessageError();

         return {
            ...state,
            Address: {
               value: action.payload as string,
               error: !!addressErrorMessage,
               message: addressErrorMessage,
            },
         };
      case "City":
         const cityErrorMessage = Validation.allowSomeSpecialCharWithHashtag(
            action.payload as string,
         ).getMessageError();

         return {
            ...state,
            City: {
               value: action.payload as string,
               error: !!cityErrorMessage,
               message: cityErrorMessage,
            },
         };
      case "State":
         const stateErrorMessage = Validation.allowSomeSpecialCharWithHashtag(
            action.payload as string,
         ).getMessageError();

         return {
            ...state,
            State: {
               value: action.payload as string,
               error: !!stateErrorMessage,
               message: stateErrorMessage,
            },
         };
      case "Zip":
         const zipErrorMessage = Validation.allowSomeSpecialCharOnlyDash(action.payload as string).getMessageError();

         return {
            ...state,
            Zip: {
               value: action.payload as string,
               error: !!zipErrorMessage,
               message: zipErrorMessage,
            },
         };
      case "Country":
         return {
            ...state,
            Country: {
               value: action.payload as string,
               error: false,
            },
         };
      case "forceValidation":
         const properties: FormProperties = { ...initialBankingValues };

         // filling the properties in.
         for (const field of Object.keys(
            action.payload as ActivationBankingInformationDTO,
         ) as (keyof FormProperties)[]) {
            properties[field] = (action.payload as FormProperties)[field]?.value
               ? (action.payload as FormProperties)[field]
               : initialBankingValues[field];
         }

         return {
            ...properties,
         };
      default:
         throw "Invalid action";
   }
}

export const initialBankingValues: Record<ActivationBankingInformationType, ActivationFormFieldError<string>> = {
   BankName: {
      value: "",
      error: false,
   },
   PrincipalName: {
      value: "",
      error: false,
   },
   AccountNumber: {
      value: "",
      error: false,
   },
   Contact: {
      value: "",
      error: false,
   },
   Phone: {
      value: "",
      error: false,
   },
   Address: {
      value: "",
      error: false,
   },
   City: {
      value: "",
      error: false,
   },
   State: {
      value: "",
      error: false,
   },
   Zip: {
      value: "",
      error: false,
   },
   Country: {
      value: "",
      error: false,
   },
};
