import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useLandingPageStyles = makeStyles((theme: Theme) =>
   createStyles({
      page: {
         padding: "40px 0px 30px 0px",
         display: "flex",
         flexDirection: "column",
      },
      // text header
      whiteText: {
         color: theme.palette.common.white,
         fontWeight: "bold",
      },
      welcomeText: {
         fontSize: "2rem",
      },
      creditText: {
         fontSize: "4rem",
         lineHeight: "1",
      },
      stepContainer: {
         padding: "40px",
         borderRadius: "8px",
      },
      buttonContainer: {
         padding: "40px 0px 10px 0px",
         display: "flex",
         justifyContent: "space-between",
         alignItems: "center",
         flexWrap: "wrap",
         gap: "20px",
      },
      iconsContainer: {
         display: "flex",
         justifyContent: "space-between",
         flexWrap: "wrap",
         alignItems: "center",
         gap: "5px",
         padding: "45px 50px",
      },
      line: {
         width: "120px",
         "@media (max-width: 1430px)": {
            width: "80px",
         },
         "@media (max-width: 1350px)": {
            width: "20px",
         },
         height: "1px",
         background: "grey",
      },

      step: {
         width: "80px",
         height: "80px",
         background: "#EAF9FF",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         borderRadius: "50%",
      },
      commonButton: {
         "&.MuiButton-root": {
            padding: "8px 24px",
            fontWeight: 500,
            fontSize: "14px",
            border: "1px solid #043F60",
         },
      },

      // footer
      footerContainer: {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         gap: "10px",
         marginTop: "100px",
      },
      footerText: {
         color: theme.palette.common.white,
         fontWeight: "bold",
      },
      footerLink: {
         color: theme.palette.common.white,
         fontWeight: "bold",
         textDecoration: "none",
      },
   }),
);
