import { makeStyles, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { getCompanyList, setCompany } from "../../api/CompanyApi";
import { getDivisionList, setDivision } from "../../api/DivisionApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { CompanyTypes } from "../../stores/Companies/Reducers";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DivisionTypes } from "../../stores/Divisions/Reducers";
import { UserContext } from "../../stores/Users/Context";
import { Companies } from "../../types/Companies";
import { Divisions } from "../../types/Divisions";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
}));

export const ContactNewDialog: React.FC<any> = (props: {
   open: boolean;
   divisionOrCompany: Divisions;
   setDivisionOrCompany: (value: React.SetStateAction<Divisions>) => void;
   component: "division" | "company";
   contactId: string;
   handleClose: () => void;
   openAlertMessage: (alertMessage: string, alertType: "error" | "info" | "success" | "warning") => Promise<void>;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const { state: userState } = useContext(UserContext);
   const [contact, setContact] = useState({
      _id: "",
      name: "",
      email: "",
      active: true,
      addDate: new Date(),
   });
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const { dispatch: companyDispatch } = useContext(CompanyContext);
   const { dispatch: divisionDispatch } = useContext(DivisionContext);

   useEffect(() => {
      if (props.open && props.contactId) {
         const selectedContact = _.filter(props.divisionOrCompany.contacts, (x) => x._id === props.contactId);

         if (selectedContact.length > -1) {
            const newContact = {
               _id: selectedContact[0]._id,
               name: selectedContact[0].name,
               email: selectedContact[0].email,
               active: selectedContact[0].active,
               addDate: selectedContact[0].addDate ? selectedContact[0].addDate : new Date(),
            };

            setContact(newContact);
         }
      }
   }, [props.open, props.contactId]);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function saveContact() {
      try {
         if (!contact.name) {
            openAlertMessage("You must enter a Contact Name!", "warning");
            return;
         }

         const contactNameCheck = _.filter(
            props.divisionOrCompany.contacts,
            (x) => x.name === contact.name && x._id !== contact._id,
         );

         if (contactNameCheck.length > 0) {
            openAlertMessage("Contact Name must be unique", "warning");
            return;
         }

         if (!contact.email) {
            openAlertMessage("You must enter a Email!", "warning");
            return;
         }

         if ((contact.email.match(new RegExp("@", "g")) || []).length === 0) {
            openAlertMessage("Invalid Email. @ Character is Required", "warning");
            return;
         }

         if ((contact.email.match(new RegExp("@", "g")) || []).length > 1) {
            openAlertMessage("Invalid Email. Only One @ Character Allowed", "warning");
            return;
         }

         if ((contact.email.match(new RegExp("[.]", "g")) || []).length === 0) {
            openAlertMessage("Invalid Email. '.' Character is Required", "warning");
            return;
         }

         if ((contact.email.match(new RegExp(`["(),:;<>]`, "g")) || []).length > 0) {
            openAlertMessage(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`, "warning");
            return;
         }

         if ((contact.email.match(new RegExp(`[[]`, "g")) || []).length > 0) {
            openAlertMessage(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`, "warning");
            return;
         }

         if ((contact.email.match(new RegExp(`]`, "g")) || []).length > 0) {
            openAlertMessage(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`, "warning");
            return;
         }

         if (contact.email.indexOf(`\\`) >= 0) {
            openAlertMessage(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`, "warning");
            return;
         }

         if (contact.email.substring(0, 1) === "@") {
            openAlertMessage(`Invalid Email. Email Cannot Begin With @ Character`, "warning");
            return;
         }

         if ((contact.email.substring(contact.email.indexOf("@")).match(new RegExp("[.]", "g")) || []).length === 0) {
            openAlertMessage(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`, "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN") {
            openAlertMessage("Access to add a new contact is denied!", "warning");
            return;
         }

         setButtonClicked(true);

         const divisionOrCompanyObj = { ...props.divisionOrCompany };

         if (props.contactId) {
            const index = _.findIndex(divisionOrCompanyObj.contacts, (x) => x._id === props.contactId);

            divisionOrCompanyObj.contacts[index] = contact;
         } else {
            divisionOrCompanyObj.contacts.push(contact);
         }

         if (props.component === "division") {
            const responseData = await setDivision(divisionOrCompanyObj);

            if (responseData.success) {
               setButtonClicked(false);
               props.setDivisionOrCompany(responseData.data);
               props.openAlertMessage("Successfully added the Contact.", "success");
               setContact({
                  _id: "",
                  name: "",
                  email: "",
                  active: true,
                  addDate: new Date(),
               });

               const responseDataDivision = await getDivisionList();

               if (responseDataDivision.success && responseDataDivision.data) {
                  const divisions: Divisions[] = responseDataDivision.data;

                  divisionDispatch({
                     type: DivisionTypes.Create,
                     payload: { Divisions: divisions },
                  });
               } else {
                  console.error("Unable to refresh the division state.");
               }

               props.handleClose();
            } else {
               openAlertMessage(responseData.message, "warning");
               setButtonClicked(false);
               return;
            }
         } else {
            const responseData = await setCompany(divisionOrCompanyObj);

            if (responseData.success) {
               setButtonClicked(false);
               props.setDivisionOrCompany(responseData.data);
               props.openAlertMessage("Successfully added the Contact.", "success");
               setContact({
                  _id: "",
                  name: "",
                  email: "",
                  active: true,
                  addDate: new Date(),
               });

               const responseDataCompany = await getCompanyList();

               if (responseDataCompany.success && responseDataCompany.data) {
                  const companies: Companies[] = responseDataCompany.data;

                  companyDispatch({
                     type: CompanyTypes.Create,
                     payload: { Companies: companies },
                  });
               } else {
                  console.error("Unable to refresh the company state.");
               }

               props.handleClose();
            } else {
               openAlertMessage(responseData.message, "warning");
               setButtonClicked(false);
               return;
            }
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
         setButtonClicked(false);
      }
   }

   async function onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "name" | "email") {
      try {
         const newContact = { ...contact };

         newContact[value] = event.target.value ? event.target.value.toUpperCase() : "";

         setContact(newContact);
      } catch (err) {
         handleError(err);
      }
   }

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         fullScreen={mobileScreen}
         fullWidth
         maxWidth={"xs"}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}> Contact</DialogTitle>
         <DialogContent>
            <Collapse in={alertMessage ? true : false}>
               <Alert
                  variant="filled"
                  severity={alertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setAlertMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <TextField
               id="tf-contact"
               label="Name"
               name="name"
               onChange={(event) => onChange(event, "name")}
               value={contact.name}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               inputProps={{ maxLength: 100 }}
               autoComplete="new-password"
            />
            <TextField
               id="tf-email"
               label="Email"
               name="email"
               onChange={(event) => onChange(event, "email")}
               value={contact.email}
               variant="outlined"
               style={{ marginTop: ".5rem" }}
               inputProps={{ maxLength: 250 }}
               autoComplete="new-password"
            />
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={() => {
                     setContact({
                        _id: "",
                        name: "",
                        email: "",
                        active: true,
                        addDate: new Date(),
                     });
                     props.handleClose();
                  }}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={saveContact}
                  variant="contained"
                  color="primary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SaveIcon />}
                  Save
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default ContactNewDialog;
