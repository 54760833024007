import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useTradeinfoStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         width: "-webkit-fill-available",
      },
      divider: {
         backgroundColor: "#000000",
         height: "2px",
         marginBottom: "1rem",
      },
      buttonRemove: {
         width: "100%",
      },
      labelRemove: {
         flexDirection: "column",
         fontSize: "11px",
      },
      icon: {
         fontSize: "24px",
      },
      sectionTitle: {
         fontSize: "16px",
         fontWeight: 500,
      },
      sectionTitleContainer: {
         padding: "8px 16px",
         backgroundColor: "#EEEEEE",
         borderRadius: "8px",
      },
      referencesContainer: {
         marginTop: "50px",
      },
      itemContainer: {
         marginTop: "0.5rem",
         marginBottom: "1rem",
      },
      commonButton: {
         "&.MuiButton-root": {
            maxHeight: "2.5rem",
            padding: "8px 24px",
            fontWeight: 500,
            fontSize: "14px",
            border: "1px solid #043F60",
         },
      },
   }),
);
