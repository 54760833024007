import { Box, IconButton, Menu } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { Status } from "../../types/CustomerActivation";
import { DateFormatter } from "../../utilities/DateFormatter";
import {
   CardColumn,
   CardColumnTextContent,
   CardColumnTextContentEllipsis,
   CardColumnTitle,
} from "../Activation/ActivationPage/Activation.styled";
import { StyledDivider, StyledMenuItem } from "./OptionsMenuBurger.styled";

interface Props {
   applicationNumber: string;
   dateReceived: Date;
   dateApproved: Date | null;
   dateDenied: Date | null;
   denialCode: string | null;
   status: Status;
   notes: string;
   approvedAmount: number;
   companyOrDivisionName: string;
   pointOfcontact: string;
   clientName: string;
   handlePrintViewPDFActionClick: () => Promise<void>;
   handleDownloadPDFActionClick: () => Promise<void>;
   handleEmailActionClick: () => void;
   handleNotesDialogOpen: () => void;
   handleGoToDetails: () => void;
   handleCopyActivation: () => void;
}

export const ReviewActivationRow: React.FC<Props> = ({
   applicationNumber,
   dateReceived,
   dateApproved,
   dateDenied,
   denialCode,
   status,
   notes,
   approvedAmount,
   companyOrDivisionName,
   pointOfcontact,
   clientName,
   handlePrintViewPDFActionClick,
   handleDownloadPDFActionClick,
   handleEmailActionClick,
   handleNotesDialogOpen,
   handleGoToDetails,
   handleCopyActivation,
}) => {
   const [isPrintingViewingPDF, setIsPrintingViewingPDF] = useState(false);
   const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleGo = () => {
      setAnchorEl(null);
      handleGoToDetails();
   };

   const handleCopy = () => {
      setAnchorEl(null);
      handleCopyActivation();
   };

   const handleNotes = () => {
      handleNotesDialogOpen();
      handleClose();
   };

   const handlePrintViewPDF = async () => {
      setIsPrintingViewingPDF(true);
      await handlePrintViewPDFActionClick();
      setIsPrintingViewingPDF(false);
      handleClose();
   };

   const handleDownloadPDF = async () => {
      setIsDownloadingPDF(true);
      await handleDownloadPDFActionClick();
      setIsDownloadingPDF(false);
      handleClose();
   };

   const handleEmail = () => {
      handleEmailActionClick();
      handleClose();
   };

   return (
      <Box
         sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "0.9fr 0.1fr",
         }}
      >
         <Box
            sx={{
               display: "grid",
               gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
               gridRowGap: "30px",
               gridColumnGap: "60px",
            }}
         >
            <Box>
               <CardColumnTitle>C.A.F #</CardColumnTitle>
               <CardColumnTextContent>{applicationNumber}</CardColumnTextContent>
            </Box>
            <Box>
               <CardColumnTitle>Company Name</CardColumnTitle>
               <CardColumnTextContent>{companyOrDivisionName}</CardColumnTextContent>
            </Box>
            <Box>
               <CardColumnTitle>Client</CardColumnTitle>
               <CardColumnTextContent>{clientName}</CardColumnTextContent>
            </Box>
            <Box>
               <CardColumnTitle>Date Received</CardColumnTitle>
               <CardColumnTextContent>{DateFormatter(dateReceived)}</CardColumnTextContent>
            </Box>
            {status === "APPROVED" ? (
               <Box>
                  <CardColumnTitle>Date Approved</CardColumnTitle>
                  <CardColumnTextContent>{DateFormatter(dateApproved)}</CardColumnTextContent>
               </Box>
            ) : null}
            {status === "DENIED" ? (
               <Box>
                  <CardColumnTitle>Date Denied</CardColumnTitle>
                  <CardColumnTextContent>{dateDenied ? DateFormatter(dateDenied) : ""}</CardColumnTextContent>
               </Box>
            ) : null}
            {status === "DENIED" ? (
               <Box>
                  <CardColumnTitle>Denial Code</CardColumnTitle>
                  <CardColumnTextContent>{denialCode || ""}</CardColumnTextContent>
               </Box>
            ) : null}
            <Box>
               <CardColumnTitle>Amount Approved</CardColumnTitle>
               <CardColumnTextContent>{approvedAmount || ""}</CardColumnTextContent>
            </Box>
            <Box>
               <CardColumnTitle>Notes</CardColumnTitle>
               <CardColumnTextContentEllipsis>{notes}</CardColumnTextContentEllipsis>
            </Box>
            <Box>
               <CardColumnTitle>Point of Contact</CardColumnTitle>
               <CardColumnTextContent>{pointOfcontact === "" ? "N/A" : pointOfcontact}</CardColumnTextContent>
            </Box>
            <Box>
               <CardColumnTitle>Status</CardColumnTitle>
               <CardColumnTextContent>
                  {status === "APPROVED" ? (
                     <ThumbUpIcon
                        style={{
                           color: green[500],
                        }}
                     />
                  ) : (
                     <ThumbDownIcon
                        style={{
                           color: red[500],
                        }}
                     />
                  )}
               </CardColumnTextContent>
            </Box>
         </Box>
         <CardColumn>
            {/* Options Menu */}
            <div>
               <IconButton aria-label="more" aria-controls="options-menu" aria-haspopup="true" onClick={handleClick}>
                  <MoreVertIcon />
               </IconButton>
               <Menu
                  id="options-menu"
                  anchorEl={anchorEl}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
               >
                  <StyledMenuItem icon={VisibilityIcon} handleClick={handleGo}>
                     Details
                  </StyledMenuItem>
                  <StyledMenuItem icon={FileCopyIcon} handleClick={handleCopy}>
                     Copy
                  </StyledMenuItem>
                  <StyledDivider />
                  <StyledMenuItem
                     icon={PictureAsPdfIcon}
                     loading={isPrintingViewingPDF}
                     handleClick={handlePrintViewPDF}
                  >
                     PDF Print/View
                  </StyledMenuItem>
                  <StyledMenuItem icon={GetAppIcon} loading={isDownloadingPDF} handleClick={handleDownloadPDF}>
                     Download PDF
                  </StyledMenuItem>
                  <StyledDivider />
                  <StyledMenuItem icon={SpeakerNotesIcon} handleClick={handleNotes}>
                     Notes
                  </StyledMenuItem>
                  <StyledMenuItem icon={EmailIcon} handleClick={handleEmail}>
                     Email
                  </StyledMenuItem>
               </Menu>
            </div>
         </CardColumn>
      </Box>
   );
};
