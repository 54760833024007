import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { VpnKey } from "@material-ui/icons";
import React from "react";
import "./Expired.css";

export const ExpiredMiddleBay: React.FC<any> = (): JSX.Element => {
   return (
      <div>
         <div className="grid-item-border-middlebay-expired"></div>
         <Paper style={{ height: "calc(100vh - 80px)", width: "100vw" }}>
            <Grid container>
               <Grid item xs={12} style={{ textAlignLast: "center", paddingTop: "5rem" }}>
                  <img
                     src="https://worldgroup.blob.core.windows.net/cpglogos/Middlebay_Full_Size.png"
                     width="300em"
                     alt="middlebay"
                  />
               </Grid>
               <Grid item xs={12} style={{ textAlignLast: "center" }} className="grid-item-title-expired">
                  <div style={{ display: "inline-block" }}>
                     <h3 style={{ display: "flex", alignItems: "center" }}>
                        <div>
                           <VpnKey className="key-icon-middlebay-expired"></VpnKey>
                        </div>
                        Authentication Invalid
                     </h3>
                  </div>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={3}></Grid>
               <Grid item xs={6} className="grid-item-message-Expired">
                  <p>
                     {`You are seeing this page because the link in your email has expired. Please reach out to `}
                     <a href="mailto:customeronboarding@middlebaytransportation.com">
                        customeronboarding@middlebaytransportation.com
                     </a>
                     {` to request a new credit application.`}
                  </p>
               </Grid>
               <Grid item xs={3}></Grid>
            </Grid>
            <div className="grid-item-spacer-expired"></div>
         </Paper>
      </div>
   );
};

export default ExpiredMiddleBay;
