export function DUNSFormatter(value: any) {
   try {
      const dunsString = value;

      if (dunsString >= 9) {
         return dunsString.substring(0, 2) + "-" + dunsString.substring(2, 5) + "-" + dunsString.substring(5, 9);
      } else {
         return value;
      }
   } catch {
      return value;
   }
}
