import { Box, CircularProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import React, { useState } from "react";

interface Props {
   open: boolean;
   item: string;
   handleClose: () => void;
   callback: () => Promise<void>;
}

export const ActivationDeleteDialog: React.FC<Props> = ({ open, item, handleClose, callback }) => {
   const [isDeleting, setIsDeleting] = useState(false);

   const handleDelete = async () => {
      setIsDeleting(true);
      await callback();
      setIsDeleting(false);
      handleClose();
   };

   return (
      <Dialog
         open={open}
         onClose={handleClose}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
      >
         <Box style={{ padding: "36px", width: "458px" }}>
            {/* Dialog Title */}
            <Box
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  marginBottom: "24px",
               }}
            >
               <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                  <mask id="mask0_688_3613" maskUnits="userSpaceOnUse" x="0" y="0" width="81" height="80">
                     <rect x="0.5" width="80" height="80" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_688_3613)">
                     <path
                        d="M31.833 55L40.4997 46.3333L49.1663 55L53.833 50.3333L45.1663 41.6667L53.833 33L49.1663 28.3333L40.4997 37L31.833 28.3333L27.1663 33L35.833 41.6667L27.1663 50.3333L31.833 55ZM23.833 70C21.9997 70 20.4302 69.3472 19.1247 68.0417C17.8191 66.7361 17.1663 65.1667 17.1663 63.3333V20H13.833V13.3333H30.4997V10H50.4997V13.3333H67.1663V20H63.833V63.3333C63.833 65.1667 63.1802 66.7361 61.8747 68.0417C60.5691 69.3472 58.9997 70 57.1663 70H23.833Z"
                        fill="#010440"
                     />
                  </g>
               </svg>
               <Typography style={{ color: "#010440", fontSize: "24px", fontWeight: "700", lineHeight: "34px" }}>
                  Delete Activation Form
               </Typography>
            </Box>
            {/* Dialog Content */}
            <Box style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px" }}>
               <Typography style={{ fontSize: "16px", lineHeight: "28px" }}>
                  You are about to delete the activation form for <strong>{item}</strong>
               </Typography>
               <Typography style={{ fontSize: "16px", lineHeight: "28px" }}>
                  Are you sure you want to continue?
               </Typography>
            </Box>
            {/* Dialog Actions */}
            <Box
               style={{
                  marginTop: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "16px",
               }}
            >
               <Button
                  onClick={handleClose}
                  color="primary"
                  variant="text"
                  startIcon={<CloseIcon />}
                  style={{ borderRadius: "100px", fontWeight: 600 }}
               >
                  Close
               </Button>
               <Button
                  onClick={handleDelete}
                  variant="contained"
                  color="secondary"
                  disabled={isDeleting}
                  startIcon={
                     isDeleting ? (
                        <CircularProgress style={{ color: "#FFF", width: "20px", height: "20px" }} />
                     ) : (
                        <DeleteOutlineIcon />
                     )
                  }
                  style={{
                     borderRadius: "100px",
                     fontWeight: 600,
                     background: isDeleting ? "#F8AFAF" : "#CD0E0E",
                     color: "#FFF",
                  }}
               >
                  Delete Form
               </Button>
            </Box>
         </Box>
      </Dialog>
   );
};
