import { ApiRequest } from "../types/Requests/ApiRequest";
import { ApiResponse } from "../types/Responses/ApiResponse";
import { TokenResponse } from "../types/Responses/TokenResponse";
import * as CookieTools from "../utilities/CookieTools";
import { refreshToken } from "./UserApi";

enum StatusCode {
   BadRequest = 400,
   Unauthorized = 401,
   Forbidden = 403,
   TooManyRequests = 429,
   InternalServerError = 500,
}

export const HandleResponse = async (apiRequest: ApiRequest, response: Response): Promise<ApiResponse> => {
   const resp: ApiResponse = {
      message: "An error has occurred. Please reach out to administration for assistance.",
      success: false,
      data: null,
      id: "",
      applicationCount: "",
   };

   //If ok, get and return reponse.
   if (response.ok) {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
         const responseData = await response.json();

         if (responseData) {
            resp.success = responseData.success;
            resp.message = responseData.message;
            resp.data = responseData.data;
            resp.id = responseData.id;
            resp.applicationCount = responseData.applicationCount;

            return resp;
         } else {
            return resp;
         }
      } else if (contentType && contentType.indexOf("application/octet-stream") == 0) {
         const blob = await response.blob();
         resp.success = true;
         resp.message = "";
         resp.data = blob;
         resp.id = "";
         resp.applicationCount = "";
         return resp;
      } else if (contentType && contentType.indexOf("application/pdf") !== -1) {
         resp.success = true;
         resp.message = "";
         resp.data = response;
         resp.id = "";
         resp.applicationCount = "";
      } else {
         const responseData = await response.text();

         resp.success = true;
         resp.message = responseData;
         resp.data = response;
         resp.id = "";
         resp.applicationCount = "";
         return resp;
      }
   } else {
      //Response not ok, handle by return code.
      switch (response.status) {
         //400: Bad Request: Return the data as-is. Most likely it is a missing value.
         case StatusCode.BadRequest: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.data = responseData.data;
                  resp.id = responseData.id;
                  resp.applicationCount = responseData.applicationCount;

                  console.log(resp);
                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               const responseData = await response.text();
               console.log(responseData);
               resp.message = responseData;
               return resp;
            }
         }
         //401: Unauthorized: This is the tricky one. See if refresh token is refreshing.
         //if token is not refreshing, try to refresh the token. if token received back, use new token and retry request. If token was not successful, revoke and put them back into login.
         //if token is refreshing, check every 100ms to see if the refresh token task has completed. if not completed, wait another 100ms. If completed, retry request.
         case StatusCode.Unauthorized: {
            if (CookieTools.checkCookie("ca-refreshing")) {
               if (CookieTools.getCookie("ca-refreshing") !== "true") {
                  CookieTools.setCookie("ca-refreshing", "true");

                  const refreshData = await refreshToken();

                  if (refreshData.success) {
                     const retryData = await Retry(apiRequest);

                     resp.success = retryData.success;
                     resp.message = retryData.message;
                     resp.data = retryData.data;
                     resp.id = retryData.id;
                     resp.applicationCount = retryData.applicationCount;

                     return resp;
                  } else {
                     CookieTools.deleteCookie("ca-user-id");
                     window.location.replace("/login"); //Need to pass a loggedout variable at some point.
                  }
               } else {
                  await new Promise<ApiResponse>((resolve, reject) => {
                     const intervalId = setInterval(async () => {
                        if (CookieTools.getCookie("ca-refreshing") !== "true") {
                           clearInterval(intervalId);
                           const retryData = await Retry(apiRequest);

                           resp.success = retryData.success;
                           resp.message = retryData.message;
                           resp.data = retryData.data;
                           resp.id = retryData.id;
                           resp.applicationCount = retryData.applicationCount;

                           resolve(resp);
                        }
                     }, 100);
                  })
                     .then(async (response) => {
                        return response;
                     })
                     .catch(async (response) => {
                        return response;
                     });
               }
            } else {
               CookieTools.setCookie("ca-refreshing", "true");

               const refreshData = await refreshToken();

               if (refreshData.success) {
                  const retryData = await Retry(apiRequest);

                  resp.success = retryData.success;
                  resp.message = retryData.message;
                  resp.data = retryData.data;
                  resp.id = retryData.id;
                  resp.applicationCount = retryData.applicationCount;

                  return resp;
               } else {
                  CookieTools.deleteCookie("ca-user-id");
                  window.location.replace("/login"); //Need to pass a loggedout variable at some point.
               }
            }
            break;
         }
         //500: Internal Server Error: Something went wrong with the API. Return data as-is.
         case StatusCode.InternalServerError: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.data = responseData.data;
                  resp.id = responseData.id;
                  resp.applicationCount = responseData.applicationCount;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
         //403: Forbidden: Rejected from the API. Return response as-is.
         case StatusCode.Forbidden: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.data = responseData.data;
                  resp.id = responseData.id;
                  resp.applicationCount = responseData.applicationCount;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
         //429: Too Many Requests: Retry request in ten seconds. if still bad, fail it.
         case StatusCode.TooManyRequests: {
            return new Promise<ApiResponse>((resolve, reject) => {
               const intervalId = setInterval(async () => {
                  clearInterval(intervalId);
                  const retryData = await Retry(apiRequest);

                  resp.success = retryData.success;
                  resp.message = retryData.message;
                  resp.data = retryData.data;
                  resp.id = retryData.id;
                  resp.applicationCount = retryData.applicationCount;

                  resolve(resp);
               }, 10000);
            })
               .then(async (response) => {
                  return response;
               })
               .catch(async (response) => {
                  return response;
               });
         }
         //Default. Handle as-is.
         default: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.data = responseData.data;
                  resp.id = responseData.id;
                  resp.applicationCount = responseData.applicationCount;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
      }
   }

   return resp;
};

export const HandleResponseToken = async (apiRequest: ApiRequest, response: Response): Promise<TokenResponse> => {
   const resp: TokenResponse = {
      token: "",
      refreshToken: "",
      success: false,
      message: "An error has occurred. Please reach out to administration for assistance.",
   };

   //If ok, get and return reponse.
   if (response.ok) {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
         const responseData = await response.json();

         if (responseData) {
            resp.success = responseData.success;
            resp.message = responseData.message;
            resp.refreshToken = responseData.refreshToken;
            resp.token = responseData.token;

            return resp;
         } else {
            console.log(response);
            return resp;
         }
      } else {
         console.log(response);
         const responseData = await response.text();

         resp.message = responseData;
         return resp;
      }
   } else {
      //Response not ok, handle by return code.
      switch (response.status) {
         //400: Bad Request: Return the data as-is. Most likely it is a missing value.
         case StatusCode.BadRequest: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.refreshToken = responseData.refreshToken;
                  resp.token = responseData.token;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
         //401: Unauthorized: Return the data as-is. This one shouldn't ever happen.
         case StatusCode.Unauthorized: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.refreshToken = responseData.refreshToken;
                  resp.token = responseData.token;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
         //500: Internal Server Error: Something went wrong with the API. Return data as-is.
         case StatusCode.InternalServerError: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.refreshToken = responseData.refreshToken;
                  resp.token = responseData.token;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
         //403: Forbidden: Rejected from the API. Return response as-is.
         case StatusCode.Forbidden: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.refreshToken = responseData.refreshToken;
                  resp.token = responseData.token;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
         //429: Too Many Requests: Retry request in one second. if still bad, fail it.
         case StatusCode.TooManyRequests: {
            return new Promise<TokenResponse>((resolve, reject) => {
               const intervalId = setInterval(async () => {
                  clearInterval(intervalId);
                  const retryData = await RetryToken(apiRequest);

                  resp.token = retryData.token;
                  resp.refreshToken = retryData.refreshToken;
                  resp.success = retryData.success;
                  resp.message = retryData.message;

                  resolve(resp);
               }, 1000);
            })
               .then(async (response) => {
                  return response;
               })
               .catch(async (response) => {
                  return response;
               });
         }
         //Default. Handle as-is.
         default: {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
               const responseData = await response.json();

               if (responseData) {
                  resp.success = responseData.success;
                  resp.message = responseData.message;
                  resp.refreshToken = responseData.refreshToken;
                  resp.token = responseData.token;

                  return resp;
               } else {
                  console.log(response);
                  return resp;
               }
            } else {
               console.log(response);
               const responseData = await response.text();

               resp.message = responseData;
               return resp;
            }
         }
      }
   }
};

export const HandleError = async (response: Response): Promise<ApiResponse> => {
   const resp: ApiResponse = {
      message: "An error has occurred. Please reach out to administration for assistance.",
      success: false,
      data: null,
      id: "",
      applicationCount: "",
   };

   const contentType = response.headers.get("content-type");

   if (contentType && contentType.indexOf("application/json") !== -1) {
      const responseData = await response.json();

      if (responseData) {
         resp.success = responseData.success;
         resp.message = responseData.message;
         resp.data = responseData.data;
         resp.id = responseData.id;
         resp.applicationCount = responseData.applicationCount;

         return resp;
      } else {
         console.log(response);
         return resp;
      }
   } else {
      console.log(response);
      const responseData = await response.text();

      resp.message = responseData;
      return resp;
   }
};

export const HandleErrorToken = async (response: Response): Promise<TokenResponse> => {
   const resp: TokenResponse = {
      token: "An error has occurred. Please reach out to administration for assistance.",
      refreshToken: "",
      success: false,
      message: "",
   };

   const contentType = response.headers.get("content-type");

   if (contentType && contentType.indexOf("application/json") !== -1) {
      const responseData = await response.json();

      if (responseData) {
         resp.success = responseData.success;
         resp.message = responseData.message;
         resp.refreshToken = responseData.refreshToken;
         resp.token = responseData.token;

         return resp;
      } else {
         console.log(response);
         return resp;
      }
   } else {
      console.log(response);
      const responseData = await response.text();

      resp.message = responseData;
      return resp;
   }
};

export const Retry = async (apiRequest: ApiRequest): Promise<ApiResponse> => {
   let resp: ApiResponse = { success: false, message: "", data: null, id: "", applicationCount: "" };

   await fetch(apiRequest.address, {
      method: apiRequest.method,
      body: apiRequest.body,
      headers: apiRequest.headers,
   })
      .then(async (response) => {
         resp = await HandleResponse(apiRequest, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleError(response);
         return resp;
      });

   return resp;
};

export const RetryToken = async (apiRequest: ApiRequest): Promise<TokenResponse> => {
   let resp: TokenResponse = { token: "", refreshToken: "", success: false, message: "" };

   await fetch(apiRequest.address, {
      method: apiRequest.method,
      body: apiRequest.body,
      headers: apiRequest.headers,
   })
      .then(async (response) => {
         resp = await HandleResponseToken(apiRequest, response);
         return resp;
      })
      .catch(async (response) => {
         resp = await HandleErrorToken(response);
         return resp;
      });

   return resp;
};
