import { Box, Chip, FormControl, InputAdornment, MenuItem, Select, Typography, capitalize } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { FetchDataProps, Status, states } from "../../../pages/Activation/ActivationView";
import { DateFormatter } from "../../../utilities/DateFormatter";
import { FiltersMenu } from "../FiltersMenu/FiltersMenu";
import styles from "./ActivationViewFilters.module.css";
import { BootstrapInput, BootstrapSelect } from "./ActivationViewFilters.styled";

interface Props {
   loading: boolean;
   status: Status;
   setStatus: React.Dispatch<React.SetStateAction<Status>>;
   fetchData: (args: FetchDataProps) => void;
   getCompanyOrDivisionName: (companyId: string, divisionId: string) => string;
}

export type DateFilterProps = {
   from: Date | null;
   to: Date | null;
};

export type fetchDataWithFiltersProps = {
   reset?: boolean;
};

export const ActivationViewFilters: React.FC<Props> = ({
   status,
   loading,
   setStatus,
   fetchData,
   getCompanyOrDivisionName,
}) => {
   // states
   const [dateFilter, setDateFilter] = useState<DateFilterProps>({ from: null, to: null });
   const [companyOrDivisionFilter, setCompanyOrDivisionFilter] = useState<string>("");
   const [statusSelected, setStatusSelected] = useState<Status | null>(status);
   const [query, setQuery] = useState("");
   const [showChips, setShowChips] = useState(false);
   // debounce search query
   const { debouncedValue } = useDebounce(query, 300);

   const handleDeleteChip = (filter: "date" | "company" | "status") => {
      // reset filters on delete chip
      if (filter === "date") {
         setDateFilter({ from: null, to: null });
         // update data
         fetchData({
            company: companyOrDivisionFilter ?? "",
            startDate: null,
            endDate: null,
            filter: debouncedValue,
            status,
         });
      } else if (filter === "company") {
         setCompanyOrDivisionFilter("");
         // update data
         fetchData({
            company: "",
            startDate: dateFilter.from,
            endDate: dateFilter.to,
            filter: debouncedValue,
            status,
         });
      } else if (filter === "status") {
         setStatusSelected(null);
         fetchData({
            company: companyOrDivisionFilter ?? "",
            startDate: dateFilter.from,
            endDate: dateFilter.to,
            filter: debouncedValue,
            status,
         });
      }
   };

   const fetchDataWithFilters = ({ reset = false }: fetchDataWithFiltersProps = {}) => {
      // fetch data with default values
      if (reset) {
         fetchData({
            status,
         });
         return;
      }

      // fetch data with filters
      fetchData({
         company: companyOrDivisionFilter ?? "",
         startDate: dateFilter.from,
         endDate: dateFilter.to,
         filter: debouncedValue,
         status: statusSelected || status,
      });
   };

   const handleInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
   };

   const handleSelectStatus = (
      event: ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) => {
      setStatus(event.target.value as Status);
      setStatusSelected(null);
      // clear chips
      setShowChips(false);
      setDateFilter({ from: null, to: null });
      setCompanyOrDivisionFilter("");
      // data will be updated from the useEffect of the parent component
   };

   const statusesModified = states.filter((status) => {
      if (status === "APPROVED" || status === "DENIED") return false;
      return true;
   });

   // update data on debounced value change (after 300ms)
   useEffect(() => {
      // fetch only if we are not loading
      if (loading) return;

      fetchData({
         company: companyOrDivisionFilter ?? "",
         startDate: dateFilter.from,
         endDate: dateFilter.to,
         filter: debouncedValue,
         status,
      });
   }, [debouncedValue]);

   return (
      <Box className={styles.container}>
         <div className={styles.status_filter}>
            <Typography component="p">{capitalize(status.toLocaleLowerCase())} Activations</Typography>
            <FormControl variant="outlined">
               <Select
                  labelId="status-label"
                  id="demo-simple-select-outlined"
                  value={status}
                  onChange={handleSelectStatus}
                  input={<BootstrapSelect />}
               >
                  {statusesModified.map((state) => (
                     <MenuItem style={{ justifyContent: "center" }} key={state} value={state}>
                        {capitalize(state.toLocaleLowerCase())}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </div>
         <div className={styles.search_filter}>
            <BootstrapInput
               variant="outlined"
               placeholder="Search"
               value={query}
               onChange={handleInputSearch}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position="start">
                        <SearchIcon style={{ color: "#010440" }} />
                     </InputAdornment>
                  ),
               }}
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "10px" }}>
               <FiltersMenu
                  companyFilter={companyOrDivisionFilter}
                  dateFilter={dateFilter}
                  statusSelected={statusSelected}
                  status={status}
                  fetchDataWithFilters={fetchDataWithFilters}
                  setShowChips={setShowChips}
                  setDateFilter={setDateFilter}
                  setCompanyFilter={setCompanyOrDivisionFilter}
                  setStatusSelected={setStatusSelected}
               />
               {/* show chips only if apply filter button was clicked */}
               {showChips && (
                  <>
                     {(dateFilter.from || dateFilter.to) && (
                        <Chip
                           className={styles.filter_chip}
                           label={`${dateFilter.from ? `From: ${DateFormatter(dateFilter.from)}` : ""} ${
                              dateFilter.to ? `to: ${DateFormatter(dateFilter.to)}` : ""
                           }`}
                           variant="outlined"
                           onDelete={(e) => handleDeleteChip("date")}
                        />
                     )}
                     {companyOrDivisionFilter && (
                        <Chip
                           className={styles.filter_chip}
                           label={`Company: ${
                              companyOrDivisionFilter === "ALL"
                                 ? "ALL"
                                 : getCompanyOrDivisionName(companyOrDivisionFilter, companyOrDivisionFilter)
                           }`}
                           variant="outlined"
                           onDelete={(e) => handleDeleteChip("company")}
                        />
                     )}
                     {statusSelected && (
                        <Chip
                           className={styles.filter_chip}
                           label={`Status: ${capitalize(statusSelected.toLocaleLowerCase())}`}
                           variant="outlined"
                           onDelete={(e) => handleDeleteChip("status")}
                        />
                     )}
                  </>
               )}
            </div>
         </div>
      </Box>
   );
};
