import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { VpnKey } from "@material-ui/icons";
import React from "react";
import "./Expired.css";

type Props = {
   isUWL: boolean;
};
export const ExpiredContainerPort: React.FC<any> = (props: Props): JSX.Element => {
   const isUWL = props.isUWL;
   const logoURL = isUWL
      ? "https://worldgroup.blob.core.windows.net/uwllogos/UWL-Logo-Full.jpg"
      : "https://worldgroup.blob.core.windows.net/wdslogos/WDS-Logo-Digital-Use.jpg";
   const cssClassModifier = isUWL ? "UWL" : "WDS";
   const supportEmail = isUWL ? "customeronboarding@shipuwl.com" : "customeronboarding@worldds.net";

   return (
      <div>
         <div className={`grid-item-border-${cssClassModifier}-expired`}></div>
         <Paper style={{ height: "calc(100vh - 80px)", width: "100vw" }}>
            <Grid container>
               <Grid item xs={12} style={{ textAlignLast: "center", paddingTop: "5rem" }}>
                  <img src={logoURL} width="300em" alt="UWL" />
               </Grid>
               <Grid item xs={12} style={{ textAlignLast: "center" }} className="grid-item-title-expired">
                  <div style={{ display: "inline-block" }}>
                     <h3 style={{ display: "flex", alignItems: "center" }}>
                        <div>
                           <VpnKey className={`key-icon-${cssClassModifier}-expired`}></VpnKey>
                        </div>
                        Authentication Invalid
                     </h3>
                  </div>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={3}></Grid>
               <Grid item xs={6} className="grid-item-message-Expired">
                  <p>
                     {`You are seeing this page because the link in your email has expired. Please reach out to `}
                     <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                     {` to request a new credit application.`}
                  </p>
               </Grid>
               <Grid item xs={3}></Grid>
            </Grid>
            <div className="grid-item-spacer-expired"></div>
         </Paper>
         <div className={`grid-item-border-${cssClassModifier}-expired`}></div>
      </div>
   );
};

export default ExpiredContainerPort;
