import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// import { useMediaQuery } from "@material-ui/core/useMediaQuery";
import { Checkbox, FormHelperText, createStyles, makeStyles } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import InputMask from "react-input-mask";
import { BillingAddress } from "../../../types/BillingAddress";
import "../ApplyUWLWDS.css";

type BillingInformationError = {
   address1: { error: boolean; message: string };
   address2: { error: boolean; message: string };
   city: { error: boolean; message: string };
   state: { error: boolean; message: string };
   zip: { error: boolean; message: string };
   email: { error: boolean; message: string };
   phone: { error: boolean; message: string };
   contact: { error: boolean; message: string };
};

type BillingInformationProps = {
   isChecked: boolean;
   copyCompanyAddress: (clear: boolean) => void;
   billingAddress: BillingAddress;
   handleChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: keyof BillingAddress,
   ) => void;
   errorHighlight: BillingInformationError;
};

export const BillingInformation: React.FC<any> = ({
   isChecked,
   billingAddress,
   copyCompanyAddress,
   handleChange,
   errorHighlight,
}: BillingInformationProps): JSX.Element => {
   const billingInformationRef = React.useRef<HTMLFormElement | null>(null);

   const useStyles = makeStyles(() =>
      createStyles({
         descriptionText: {
            display: "inline",
            fontSize: "16px",
            marginTop: ".2rem",
         },
      }),
   );

   const classes = useStyles();

   const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
      copyCompanyAddress(!event.target.checked);
   };

   return (
      <form ref={billingInformationRef}>
         <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={isChecked} onChange={handleCheckbox} color="primary" />
            <label className={classes.descriptionText}>Use Company Address</label>
         </Grid>
         {/* Billing email */}
         <Grid item xs={12}>
            <TextField
               id="bi-email"
               label="Billing Email"
               variant="outlined"
               onChange={(event) => handleChange(event, "email")}
               value={billingAddress.email}
               helperText={errorHighlight.email.error ? errorHighlight.email.message : null}
               error={!!errorHighlight.email.error}
               inputProps={{ maxLength: 100 }}
            />
         </Grid>
         {/* Billing Phone */}
         <Grid item xs={12}>
            <InputMask
               mask="999-999-9999"
               value={billingAddress.phone}
               onChange={(event) => handleChange(event, "phone")}
            >
               {() => (
                  <TextField
                     id={`tf-billingPhone`}
                     label="Billing Phone"
                     variant="outlined"
                     error={errorHighlight.phone.error ? true : false}
                     inputProps={{
                        autoComplete: "phone",
                        form: {
                           autoComplete: "off",
                        },
                     }}
                  />
               )}
            </InputMask>
            <FormHelperText style={{ color: "#F54D41", marginLeft: ".75rem" }}>
               {errorHighlight.phone.error ? errorHighlight.phone.message : null}
            </FormHelperText>
         </Grid>

         {/* Billing Contract */}
         <Grid item xs={12}>
            <TextField
               id="bi-billingContact"
               label="Billing Contact"
               variant="outlined"
               onChange={(event) => handleChange(event, "contact")}
               value={billingAddress.contact}
               helperText={errorHighlight.contact?.error ? errorHighlight.contact.message : null}
               error={errorHighlight.contact?.error ? true : false}
               inputProps={{ maxLength: 100 }}
            />
         </Grid>

         {/* Billing Address */}
         <Grid item xs={12}>
            <TextField
               id="bi-billingAddress"
               label="Billing Address"
               variant="outlined"
               onChange={(event) => handleChange(event, "address1")}
               value={billingAddress.address1}
               helperText={errorHighlight.address1.error ? errorHighlight.address1.message : null}
               error={errorHighlight.address1.error ? true : false}
               inputProps={{ maxLength: 100 }}
               disabled={isChecked}
            />
         </Grid>

         {/* Billing Address 2 */}
         <Grid item xs={12}>
            <TextField
               id="bi-billingAddress2"
               label="Billing Address 2 (optional)"
               variant="outlined"
               onChange={(event) => handleChange(event, "address2")}
               value={billingAddress.address2}
               helperText={errorHighlight.address2.error ? errorHighlight.address2.message : null}
               error={errorHighlight.address2.error ? true : false}
               inputProps={{ maxLength: 100 }}
               disabled={isChecked}
            />
         </Grid>

         {/* City - state - zip code */}
         <Grid container direction="row" alignItems="center">
            {/* City */}
            <Grid item xs={6}>
               <TextField
                  id="bi-billingCity"
                  label="Billing City"
                  variant="outlined"
                  onChange={(event) => handleChange(event, "city")}
                  value={billingAddress.city}
                  helperText={
                     errorHighlight.city.error
                        ? errorHighlight.city.message
                        : errorHighlight.state.error || errorHighlight.zip.error
                        ? " "
                        : null
                  }
                  error={!!errorHighlight.city.error}
                  inputProps={{ maxLength: 100 }}
                  disabled={isChecked}
               />
            </Grid>
            {/* State */}
            <Grid item xs={3}>
               <TextField
                  id="bi-billingState"
                  label="Billing State"
                  variant="outlined"
                  onChange={(event) => handleChange(event, "state")}
                  value={billingAddress.state}
                  helperText={
                     errorHighlight.state.error
                        ? errorHighlight.state.message
                        : errorHighlight.city.error || errorHighlight.zip.error
                        ? " "
                        : null
                  }
                  error={errorHighlight.state.error ? true : false}
                  inputProps={{ maxLength: 2 }}
                  disabled={isChecked}
               />
            </Grid>
            {/* Zip Code */}
            <Grid item xs={3}>
               <TextField
                  id="bi-billingZip"
                  label="Billing Zip Code"
                  variant="outlined"
                  onChange={(event) => handleChange(event, "zip")}
                  value={billingAddress.zip}
                  helperText={
                     errorHighlight.zip.error
                        ? errorHighlight.zip.message
                        : errorHighlight.city.error || errorHighlight.state.error
                        ? " "
                        : null
                  }
                  error={errorHighlight.zip.error ? true : false}
                  inputProps={{
                     maxLength: 10,
                  }}
                  disabled={isChecked}
               />
            </Grid>
         </Grid>
      </form>
   );
};

export default BillingInformation;
