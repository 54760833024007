import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const useReviewStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         display: "flex",
         justifyContent: "space-between",
         alignItems: "center",
         padding: "0px 16px 0px 32px",
      },
      containerIcon: {
         display: "flex",
         gap: "3rem",
      },
      sectionTitle: {
         fontSize: "16px",
         fontWeight: 500,
      },
      sectionTitleContainer: {
         padding: "8px 16px",
         backgroundColor: "#EEEEEE",
         borderRadius: "8px",
         maxHeight: "2.5rem",
      },
      highText: {
         fontSize: "24px",
      },
      optionsContainer: {
         "& .MuiFormControlLabel-root .MuiTypography-root": {
            fontSize: "16px",
         },
      },
      buttonContainer: {
         display: "flex",
         flexDirection: "column",
         alignItems: "end",
      },
      commonButton: {
         "&.MuiButton-root": {
            maxHeight: "2.5rem",
            padding: "8px 24px",
            fontWeight: 500,
            fontSize: "14px",
            border: "1px solid #043F60",
         },
      },
      viewBotton: {
         marginBottom: "30px",
      },
      buttonProgress: {
         color: green[500],
      },
      activeIcon: {
         width: "148px",
         height: "148px",
         background: "#EAF9FF",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         borderRadius: "50%",
      },
      stepContainer: {
         padding: "24px",
         borderRadius: "8px",
      },
   }),
);
