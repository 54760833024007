import { Companies } from "../../types/Companies";

type ActionMap<M extends { [index: string]: any }> = {
   [Key in keyof M]: M[Key] extends undefined
      ? {
           type: Key;
        }
      : {
           type: Key;
           payload: M[Key];
        };
};

export enum CompanyTypes {
   Create = "GET_COMPANIES",
}

type CompanyPayload = {
   [CompanyTypes.Create]: {
      Companies: Companies[];
   };
};

export type CompanyActions = ActionMap<CompanyPayload>[keyof ActionMap<CompanyPayload>];

export const companyReducer = (state: Companies[], action: CompanyActions) => {
   switch (action.type) {
      case CompanyTypes.Create:
         return [...action.payload.Companies];
      default:
         return state;
   }
};
