import { InputBase, TextField, Typography, withStyles } from "@material-ui/core";
import React from "react";

export const MenuTitle: React.FC = ({ children }) => (
   <Typography
      style={{
         color: "#010440",
         fontSize: "24px",
         fontStyle: "normal",
         fontWeight: "700",
         lineHeight: "normal",
         textTransform: "capitalize",
      }}
   >
      {children}
   </Typography>
);

export const MenuSubtitle: React.FC = ({ children }) => (
   <Typography
      style={{
         color: "#010440",
         fontSize: "16px",
         fontStyle: "normal",
         fontWeight: "700",
         lineHeight: "23px",
         textTransform: "capitalize",
      }}
   >
      {children}
   </Typography>
);

export const BootstrapDatePicker = withStyles({
   root: {
      "& .MuiOutlinedInput-root": {
         height: "38px",
         width: "180px",
      },
   },
})(TextField);

export const BootstrapSelect = withStyles((theme) => ({
   root: {
      "label + &": {
         marginTop: theme.spacing(3),
      },
   },
   input: {
      width: "100%",
      position: "relative",
      border: "1px solid #ced4da",
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
   },
}))(InputBase);
