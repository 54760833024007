import { Box, IconButton, Menu } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LinkIcon from "@material-ui/icons/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { DateFormatter } from "../../utilities/DateFormatter";
import {
   CardColumn,
   CardColumnTextContent,
   CardColumnTitle,
   StatusChip,
} from "../Activation/ActivationPage/Activation.styled";
import { StyledDivider, StyledMenuItem } from "./OptionsMenuBurger.styled";

interface Props {
   date: Date;
   companyOrDivisionName: string;
   email: string;
   pointOfcontact: string;
   status: "SENT" | "OPENED" | "EXPIRED";
   clientName: string;
   handleLinkActionClick: () => void;
   handleResendActionClick: () => Promise<void>;
   handleDeleteDialogOpen: () => void;
   handleGoToDetails: () => void;
}

export const SentActivationRow: React.FC<Props> = ({
   date,
   companyOrDivisionName,
   email,
   pointOfcontact,
   status,
   clientName,
   handleLinkActionClick,
   handleResendActionClick,
   handleDeleteDialogOpen,
   handleGoToDetails,
}) => {
   const [anchorEl, setAnchorEl] = useState(null);
   const [isResending, setIsResending] = useState(false);

   const open = Boolean(anchorEl);

   const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleGo = () => {
      setAnchorEl(null);
      handleGoToDetails();
   };

   const handleLink = () => {
      handleLinkActionClick();
      handleClose();
   };

   const handleResend = async () => {
      setIsResending(true);
      await handleResendActionClick();
      setIsResending(false);
      handleClose();
   };

   const handleDelete = () => {
      handleDeleteDialogOpen();
      handleClose();
   };

   return (
      <>
         <Box
            sx={{
               width: "100%",
               display: "grid",
               gridTemplateColumns: "0.5fr 0.8fr 0.4fr 0.8fr 0.5fr 0.4fr 0.2fr",
            }}
         >
            <CardColumn>
               <CardColumnTitle>Date</CardColumnTitle>
               <CardColumnTextContent>{DateFormatter(date)}</CardColumnTextContent>
            </CardColumn>
            <CardColumn>
               <CardColumnTitle>Company | Division</CardColumnTitle>
               <CardColumnTextContent>{companyOrDivisionName}</CardColumnTextContent>
            </CardColumn>
            <CardColumn>
               <CardColumnTitle>Client</CardColumnTitle>
               <CardColumnTextContent>{clientName}</CardColumnTextContent>
            </CardColumn>
            <CardColumn>
               <CardColumnTitle>Email</CardColumnTitle>
               <CardColumnTextContent>{email}</CardColumnTextContent>
            </CardColumn>
            <CardColumn>
               <CardColumnTitle>Point of Contact</CardColumnTitle>
               <CardColumnTextContent>{pointOfcontact === "" ? "N/A" : pointOfcontact}</CardColumnTextContent>
            </CardColumn>
            <CardColumn>
               <CardColumnTitle>Status</CardColumnTitle>
               <StatusChip status={status}>{status}</StatusChip>
            </CardColumn>
            <CardColumn>
               {/* Options Menu */}
               <div>
                  <IconButton aria-label="more" aria-controls="options-menu" aria-haspopup="true" onClick={handleClick}>
                     <MoreVertIcon />
                  </IconButton>
                  <Menu
                     id="options-menu"
                     anchorEl={anchorEl}
                     transformOrigin={{ vertical: "top", horizontal: "left" }}
                     keepMounted
                     open={open}
                     onClose={handleClose}
                  >
                     <StyledMenuItem icon={VisibilityIcon} handleClick={handleGo}>
                        Details
                     </StyledMenuItem>
                     <StyledDivider />
                     <StyledMenuItem icon={LinkIcon} handleClick={handleLink}>
                        Link
                     </StyledMenuItem>
                     <StyledMenuItem icon={SendIcon} loading={isResending} handleClick={handleResend}>
                        Resend
                     </StyledMenuItem>
                     <StyledDivider />
                     <StyledMenuItem icon={DeleteForeverIcon} iconColor="#CF0000" handleClick={handleDelete}>
                        Delete
                     </StyledMenuItem>
                  </Menu>
               </div>
            </CardColumn>
         </Box>
      </>
   );
};
