/* eslint-disable react/react-in-jsx-scope */
import { Divider, Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import Grid from "@material-ui/core/Grid";
/* eslint-disable prettier/prettier */
import { Card } from "@material-ui/core";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { DateFormatter } from "../../../utilities/DateFormatter";
import { PhoneFormatter } from "../../../utilities/PhoneFormatter";
import { useBodyStyles } from "./Styles";

type Props = {
   selectedCustomerActivation: CustomerActivation;
};

function CompanyInformation({ selectedCustomerActivation }: Props) {
   const classes = useBodyStyles();

   return (
      <>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px", marginLeft: "2px", marginRight: "2px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginTop: "16px",
                     marginLeft: "36px",
                     marginRight: "36px",
                     marginBottom: "36px",
                  }}
               >
                  <Typography variant="h6" color="primary" style={{ fontWeight: 700 }}>
                     {"Company Information"}
                  </Typography>
                  <Divider className={classes.dividerBody} />
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company Name
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company Contact
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company Established
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Phone
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.companyName}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.companyContact}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {DateFormatter(selectedCustomerActivation.companyEstablishedDate)}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {PhoneFormatter(selectedCustomerActivation.companyPhone)}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Contact Email
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        D&B D-U-N-S
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        EIN/Federal Tax ID
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Mailing Address
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.companyEmail}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.duns}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.fedTaxId}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.address.address1}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Mailing Address 2
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        City
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        State
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Zipcode
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "45px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.address.address2}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.address.city}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.address.state}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.address.zip}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Country
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     marginLeft: "36px",
                     marginRight: "36px",
                  }}
               >
                  <Grid item xs={3}>
                     <Typography variant="h6" noWrap style={{ color: "#010440" }}>
                        {selectedCustomerActivation.address.country}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { CompanyInformation };
