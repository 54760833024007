import { InputAdornment, TableBody, Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { green, grey, red } from "@material-ui/core/colors";
import {
   Theme,
   createStyles,
   makeStyles,
   useTheme,
   // eslint-disable-next-line prettier/prettier
   withStyles,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DownloadIcon from "@material-ui/icons/GetApp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PublishIcon from "@material-ui/icons/Publish";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
   getDivisionById,
   getDivisionList,
   getPacketLastModifiedDate,
   setDivision,
   // eslint-disable-next-line prettier/prettier
   setDocument,
} from "../../api/DivisionApi";
import ContactDialog from "../../components/ContactDialog/ContactDialog";
import DeleteContactDialog from "../../components/DeleteDialog/DeleteContactDialog";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import DenialCodeDialog from "../../components/DenialCodeDialog/DenialCodeDialog";
import UploadDialog from "../../components/UploadDialog/UploadDialog";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DivisionTypes } from "../../stores/Divisions/Reducers";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Companies } from "../../types/Companies";
import { Contacts } from "../../types/Contacts";
import { DenialCodes } from "../../types/DenialCodes";
import { Divisions } from "../../types/Divisions";
import { DateFormatter } from "../../utilities/DateFormatter";
import "./DivisionDetails.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 240,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7),
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "100%",
      },
      activeChip: {
         backgroundColor: green[500],
         color: "white",
      },
      inactiveChip: {
         backgroundColor: red[500],
         color: "white",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      labelMobile: {
         flexDirection: "column",
         fontSize: "7px",
      },
      addNewButton: {
         color: "white",
         backgroundColor: green[500],
         "&:hover": {
            backgroundColor: green[600],
         },
      },
      buttonProgress: {
         color: green[500],
      },
   }),
);

const GreySwitch = withStyles({
   switchBase: {
      color: grey[300],
      "&$checked": {
         color: grey[500],
      },
      "&$checked + $track": {
         backgroundColor: grey[500],
      },
   },
   checked: {},
   track: {},
})(Switch);

const ContactSwitch = withStyles({
   switchBase: {
      color: grey[500],
      "&$checked": {
         color: "#002D72",
      },
      "&$checked + $track": {
         backgroundColor: "#002D72",
      },
   },
   checked: {},
   track: { backgroundColor: grey[400] },
})(Switch);

export const DivisionDetails: React.FC<any> = ({ match }: RouteComponentProps<{ id: string }>): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const handleError = useErrorHandler();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
   const largeScreen = useMediaQuery(theme.breakpoints.down("lg"));

   const { state: drawerState } = useContext(DrawerContext);
   const [selectedDivision, setSelectedDivision] = useState<Divisions>({
      _id: "",
      company_id: "",
      code: "",
      name: "",
      managers: [],
      active: false,
      prefix: "",
      counter: 0,
      addDate: new Date(),
      deleteDate: new Date(),
      invitationBody: "",
      invitationTopNote: "",
      emailTemplate: "",
      docuSignTemplateId: "",
      receiptFirstParagraph: "",
      receiptSecondParagraph: "",
      deniedFirstParagraph: "",
      deniedSecondParagraph: "",
      approvedFirstParagraph: "",
      approvedSecondParagraph: "",
      approvedBoldParagraph: "",
      contactApprovedBody: "",
      contactDeniedBody: "",
      duplicationNotification: "",
      contactDuplicationNotification: "",
      denialCodes: [],
      contacts: [],
      quickCreditAmount: 0,
   });
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState, dispatch: divisionDispatch } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);
   const [companyList, setCompanyList] = useState<Companies[]>([]);
   const [leftList, setLeftList] = React.useState<{ _id: string; value: string }[]>([]);
   const [rightList, setRightList] = React.useState<{ _id: string; value: string }[]>([]);
   const [applicationCounts, setApplicationCounts] = useState<{ item1: string; item2: number }[]>([]);
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [uploadClicked, setUploadClicked] = useState<boolean>(false);
   const [contactModal, setContactModal] = useState<boolean>(false);
   const [deleteContactModal, setDeleteContactModal] = useState<boolean>(false);
   const [denialCodeModal, setDenialCodeModal] = useState<boolean>(false);
   const [deleteModal, setDeleteModal] = useState<boolean>(false);
   const [denialCodeId, setDenialCodeId] = useState<string>("");
   const [contactId, setContactId] = useState<string>("");
   const [selectedDenialCode, setSelectedDenialCode] = useState<DenialCodes>({
      _id: "",
      code: "",
      description: "",
      addDate: null,
   });
   const [selectedContact, setSelectedContact] = useState<Contacts>({
      _id: "",
      name: "",
      email: "",
      active: false,
      addDate: null,
   });
   const [docuSignTemplateAssigned, setDocuSignTemplateAssigned] = useState<boolean>(false);
   const [loading, setLoading] = useState(true);
   const [expanded, setExpanded] = useState({
      panel1: true,
      panel2: true,
      panel3: true,
      panel4: true,
      panel5: true,
      panel6: true,
      panel7: true,
      panel8: true,
      "panel-customer-activation-email-1": true,
      "panel-customer-activation-email-2": true,
      "panel-customer-activation-email-3": true,
      "panel-customer-activation-email-4": true,
      "panel-customer-activation-email-5": true,
   });
   const [fileName, setFileName] = useState("");
   const [fileUpload, setFileUpload] = useState<File | null>();
   const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
   const [fileDate, setFileDate] = useState<Date | null>(null);

   function breadcrumbOnClick(path: string) {
      if (path) {
         history.push(path);
      }
   }

   async function getDivision() {
      try {
         const responseData = await getDivisionById(match.params.id, true);

         if (responseData.success) {
            setSelectedDivision(responseData.data);
            setApplicationCounts(responseData.applicationCount);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function getPacketDate() {
      try {
         const responseData = await getPacketLastModifiedDate(match.params.id);

         if (responseData.success) {
            setFileDate(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   useEffect(() => {
      getPacketDate();
      getDivision();
   }, []);

   useEffect(() => {
      if (selectedDivision._id && loading) {
         if (selectedDivision.docuSignTemplateId) {
            setDocuSignTemplateAssigned(true);
         }

         if (selectedDivision.emailTemplate) {
            switch (selectedDivision.emailTemplate) {
               case "CPG":
               case "IDS":
               default:
                  setFileName("New Customer Packet- Intermodal.pdf");
                  break;
               case "MIDDLEBAY":
                  setFileName("New Customer Packet - Middle Bay.pdf");
                  break;
               case "BRISTOL":
                  setFileName("New Customer Packet - Bristol.pdf");
                  break;
               case "UWL":
                  //COME BACK TO LATER!!!!!
                  setFileName("New Customer Packet - Bristol.pdf");
                  break;
               case "WDS":
                  //COME BACK TO LATER!!!!!
                  setFileName("New Customer Packet - Bristol.pdf");
                  break;
            }
         }

         setLoading(false);
      }
   }, [selectedDivision]);

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function onChangeActive(event: React.ChangeEvent<HTMLInputElement>) {
      try {
         const divisionObj = { ...selectedDivision };
         divisionObj.active = event.target.checked;
         setSelectedDivision(divisionObj);
      } catch (err) {
         handleError(err);
      }
   }

   // eslint-disable-next-line @typescript-eslint/ban-types
   async function accordionOnChange(
      value:
         | "panel1"
         | "panel2"
         | "panel3"
         | "panel4"
         | "panel5"
         | "panel6"
         | "panel7"
         | "panel8"
         | "panel-customer-activation-email-1"
         | "panel-customer-activation-email-2"
         | "panel-customer-activation-email-3"
         | "panel-customer-activation-email-4"
         | "panel-customer-activation-email-5",
   ) {
      const newExpanded = { ...expanded };

      newExpanded[value] = !newExpanded[value];

      setExpanded(newExpanded);
   }

   async function onChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value:
         | "name"
         | "code"
         | "prefix"
         | "docuSignTemplateId"
         | "invitationBody"
         | "invitationTopNote"
         | "receiptFirstParagraph"
         | "receiptSecondParagraph"
         | "deniedFirstParagraph"
         | "deniedSecondParagraph"
         | "approvedFirstParagraph"
         | "approvedSecondParagraph"
         | "approvedBoldParagraph"
         | "contactApprovedBody"
         | "contactDeniedBody"
         | "duplicationNotification"
         | "contactDuplicationNotification"
         | "quickCreditAmount",
   ) {
      try {
         const divisionObj = { ...selectedDivision };

         if (value === "quickCreditAmount") {
            divisionObj[value] = Number(event.target.value);
         } else {
            divisionObj[value] = event.target.value;
         }

         setSelectedDivision(divisionObj);
      } catch (err) {
         handleError(err);
      }
   }

   function handleCustomerActivationEmailTemplateChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value:
         | "invitationBody"
         | "invitationTopNote"
         | "receiptFirstParagraph"
         | "receiptSecondParagraph"
         | "approvedFirstParagraph"
         | "approvedSecondParagraph"
         | "statusParagraph",
   ) {
      try {
         const divisionClone = { ...selectedDivision };

         if (divisionClone.customerActivationEmailTemplate) {
            divisionClone.customerActivationEmailTemplate[value] = event.target.value;
            setSelectedDivision(divisionClone);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function onSelectChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const divisionObj = { ...selectedDivision };
         divisionObj.emailTemplate = event.target.value ? (event.target.value as string) : "";
         setSelectedDivision(divisionObj);
      } catch (err) {
         handleError(err);
      }
   }

   async function onSelectChangeCompany(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const divisionObj = { ...selectedDivision };
         divisionObj.company_id = event.target.value ? (event.target.value as string) : "";
         setSelectedDivision(divisionObj);
      } catch (err) {
         handleError(err);
      }
   }

   async function saveDivision() {
      try {
         const divisionCodeCheck = _.filter(
            divisionState.Divisions,
            (x) => x.code === selectedDivision.code && x._id !== selectedDivision._id,
         );

         if (divisionCodeCheck.length > 0) {
            openAlertMessage("Division Code must be unique!", "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN") {
            openAlertMessage("Access to save the Division is denied!", "warning");
            return;
         }

         if (!selectedDivision.company_id) {
            openAlertMessage("You must select a company", "warning");
            return;
         }

         if (selectedDivision.name === "" || selectedDivision.name === null) {
            openAlertMessage("Please enter a Company Name", "warning");
            return;
         }

         if (selectedDivision.code === "" || selectedDivision.name === null) {
            openAlertMessage("Please enter a Company Code", "warning");
            return;
         }

         if (selectedDivision.prefix === "" || selectedDivision.prefix === null) {
            openAlertMessage("Please enter a Prefix", "warning");
            return;
         }

         if (selectedDivision.emailTemplate === "" || selectedDivision.emailTemplate === null) {
            openAlertMessage("Please select a Email Template", "warning");
            return;
         }

         setButtonClicked(true);

         const divisionObj = { ...selectedDivision };

         const responseData = await setDivision(divisionObj);

         if (responseData.success) {
            const responseDataDivision = await getDivisionList();

            if (responseDataDivision.success && responseDataDivision.data) {
               const divisions: Divisions[] = responseDataDivision.data;

               divisionDispatch({
                  type: DivisionTypes.Create,
                  payload: { Divisions: divisions },
               });
            } else {
               console.error("Unable to refresh the division state.");
            }

            openAlertMessage("Successfully updated Division.", "success");
            setButtonClicked(false);
            getDivision();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         setButtonClicked(false);
         handleError(err);
      }
   }

   async function onChangeActiveContact(event: React.ChangeEvent<HTMLInputElement>, name: string) {
      try {
         const index = _.findIndex(selectedDivision.contacts, (x) => x.name === name);

         if (!event.target.checked) {
            setSelectedContact(selectedDivision.contacts[index]);
            setDeleteContactModal(true);
         } else {
            const divisionObj = { ...selectedDivision };
            divisionObj.contacts[index].active = event.target.checked;
            setSelectedDivision(divisionObj);

            saveDivision();
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function handleDeleteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
      try {
         setSelectedDenialCode(selectedDivision.denialCodes[index]);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteDenialCode() {
      try {
         setDeleteModal(!deleteModal);

         const divisionObj = { ...selectedDivision };

         const index = _.findIndex(divisionObj.denialCodes, (x) => x.code === selectedDenialCode.code);

         if (index > -1) {
            divisionObj.denialCodes.splice(index, 1);
            setSelectedDivision(divisionObj);
         }

         saveDivision();
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteContact() {
      try {
         setDeleteContactModal(!deleteContactModal);

         const divisionObj = { ...selectedDivision };

         const index = _.findIndex(divisionObj.contacts, (x) => x._id === selectedContact._id);

         if (index > -1) {
            divisionObj.contacts[index].active = false;
         }

         saveDivision();
      } catch (err) {
         handleError(err);
      }
   }

   async function handleClose() {
      try {
         history.push("/divisions");
      } catch (err) {
         handleError(err);
      }
   }

   /* Onboard functions */
   async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.files != null) {
         setFileUpload(event.target.files[0]);
         setFileUploadModal(true);
      }
   }

   async function uploadPacket() {
      try {
         setFileUploadModal(false);
         setUploadClicked(true);

         const formData = new FormData();

         if (fileUpload != null) {
            //Set file and set the division id.
            formData.append("OnboardingDocument", fileUpload);
            formData.append("Division_id", selectedDivision._id);

            const responseData = await setDocument(formData);

            if (responseData.success) {
               openAlertMessage("Successfully Uploaded Packet.", "success");
               setUploadClicked(false);
            } else {
               openAlertMessage(responseData.message, "warning");
               setUploadClicked(false);
               return;
            }
         }
      } catch (err) {
         setUploadClicked(false);
         handleError(err);
      }

      getPacketDate();
   }

   async function handleDownloadClick() {
      if (selectedDivision.emailTemplate) {
         switch (selectedDivision.emailTemplate) {
            case "CPG":
            case "IDS":
            default:
               window.open("https://worldgroup.blob.core.windows.net/cpgdocuments/New Customer Packet- Intermodal.pdf");
               break;
            case "MIDDLEBAY":
               window.open(
                  "https://worldgroup.blob.core.windows.net/cpgdocuments/New Customer Packet - Middle Bay.pdf",
               );
               break;
            case "BRISTOL":
               window.open("https://worldgroup.blob.core.windows.net/cpgdocuments/New Customer Packet - Bristol.pdf");
               break;
            case "UWL":
               //COME BACK TO LATER!!!!!
               window.open("https://worldgroup.blob.core.windows.net/cpgdocuments/New Customer Packet - Bristol.pdf");
               break;
            case "WDS":
               //COME BACK TO LATER!!!!!
               window.open("https://worldgroup.blob.core.windows.net/cpgdocuments/New Customer Packet - Bristol.pdf");
               break;
         }
      } else {
         openAlertMessage("New customer packet unavailable for this division!", "warning");
      }
   }

   const OnboardingPacket = () => {
      return (
         <Card style={{ width: "100%", marginTop: theme.spacing(7) }}>
            <CardHeader
               className={"DivisionDetails-card-header1"}
               style={{ height: "4rem" }}
               title={
                  <Typography noWrap variant="h6">
                     {"Onboarding Packet"}
                  </Typography>
               }
            />
            <Divider className={classes.divider} />
            <CardContent>
               <TableContainer>
                  <Table style={{ marginBottom: "1rem" }}>
                     <TableHead>
                        <TableRow>
                           <TableCell style={{ fontSize: "16px", fontWeight: 700 }}> Packet </TableCell>
                           <TableCell style={{ fontSize: "16px", fontWeight: 700 }}> Date Added </TableCell>
                           <TableCell style={{ fontSize: "16px", fontWeight: 700 }} />
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <TableRow key={`tr-${selectedDivision._id}`}>
                           <TableCell style={{ fontSize: "16px" }}> {fileName} </TableCell>
                           <TableCell style={{ fontSize: "16px" }}>{fileDate ? DateFormatter(fileDate) : ""}</TableCell>
                           <TableCell>
                              <ButtonGroup>
                                 <Tooltip title="Upload" arrow>
                                    <IconButton
                                       component="label"
                                       disabled={userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"}
                                       color="primary"
                                       classes={{
                                          label: mobileScreen ? classes.labelMobile : classes.label,
                                       }}
                                    >
                                       <input type="file" hidden accept=".pdf" onChange={handleFileChange} />

                                       {uploadClicked ? (
                                          <CircularProgress size={16} className={classes.buttonProgress} />
                                       ) : (
                                          <PublishIcon />
                                       )}
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip title="Download" arrow>
                                    <IconButton
                                       disabled={userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"}
                                       color="secondary"
                                       onClick={() => handleDownloadClick()}
                                    >
                                       <DownloadIcon />
                                    </IconButton>
                                 </Tooltip>
                              </ButtonGroup>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </TableContainer>
            </CardContent>
         </Card>
      );
   };

   return (
      <>
         <div className="DivisionDetails">
            <div className="DivisionDetails-root">
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className="DivisionDetails-header">
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Grid
                              xs={12}
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                              style={{ height: "60px" }}
                           >
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 7 : 10}
                                 justify="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <Breadcrumbs separator={<NavigateNextIcon />}>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/divisions")}
                                    >
                                       <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                          {"Divisions"}
                                       </Typography>
                                    </Link>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/divisions/details/" + match.params.id)}
                                    >
                                       <Typography noWrap variant="h6" style={{ fontWeight: 700 }}>
                                          {selectedDivision.name ? selectedDivision.name : null}
                                       </Typography>
                                    </Link>
                                 </Breadcrumbs>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 5 : 2}
                                 justify="center"
                                 alignItems="center"
                                 style={{ height: "60px" }}
                              >
                                 <ButtonGroup>
                                    <Button
                                       onClick={() => handleClose()}
                                       variant="contained"
                                       classes={{
                                          label: mobileScreen ? classes.labelMobile : classes.label,
                                       }}
                                    >
                                       <CloseIcon />
                                       Close
                                    </Button>
                                    <Button
                                       onClick={() => saveDivision()}
                                       variant="contained"
                                       color="primary"
                                       classes={{
                                          label: mobileScreen ? classes.labelMobile : classes.label,
                                       }}
                                       disabled={
                                          userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN" ||
                                          buttonClicked
                                       }
                                    >
                                       {buttonClicked ? (
                                          <CircularProgress size={20} className={classes.buttonProgress} />
                                       ) : (
                                          <SaveIcon />
                                       )}
                                       {mobileScreen ? "Save" : "Save"}
                                    </Button>
                                 </ButtonGroup>
                              </Grid>
                           </Grid>
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <Collapse in={alertMessage ? true : false}>
                           <Alert
                              variant="filled"
                              severity={alertType}
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       setAlertMessage("");
                                    }}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              }
                           >
                              {alertMessage}
                           </Alert>
                        </Collapse>
                        <div className="DivisionDetails-body">
                           <div
                              className={
                                 drawerState.DrawerOpen === true
                                    ? "DivisionDetails-layout-drawer-open"
                                    : "DivisionDetails-layout"
                              }
                           >
                              <Grid container direction={"row"} item xs={12}>
                                 <Grid container direction={"column"} item xs={mobileScreen ? 12 : 6}>
                                    <Card style={{ width: "100%" }}>
                                       <CardHeader
                                          className={"DivisionDetails-card-header1"}
                                          action={
                                             <div>
                                                <FormControlLabel
                                                   style={{ marginTop: ".25rem" }}
                                                   control={
                                                      <GreySwitch
                                                         checked={selectedDivision.active ? true : false}
                                                         onChange={(event) => onChangeActive(event)}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                            "SUPER ADMIN"
                                                         }
                                                      />
                                                   }
                                                   label={selectedDivision.active ? "Active" : "Inactive"}
                                                   labelPlacement="start"
                                                />
                                             </div>
                                          }
                                          title={
                                             <Typography noWrap variant="h6">
                                                {"Division Information"}
                                             </Typography>
                                          }
                                          style={{ height: "4rem" }}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent>
                                          <Grid container direction={"column"} item xs={12} alignItems="center">
                                             <Grid container direction={"row"} item xs={12} alignItems="center">
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-code"
                                                      label="Division Code"
                                                      variant="outlined"
                                                      name="Division Code"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      value={selectedDivision.code}
                                                      onChange={(event) => onChange(event, "code")}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-name"
                                                      label="Division Name"
                                                      variant="outlined"
                                                      name="name"
                                                      value={selectedDivision.name}
                                                      onChange={(event) => onChange(event, "name")}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </Grid>
                                             <Grid container direction={"row"} item xs={12} alignItems="center">
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-dateAdded"
                                                      label="Date Added"
                                                      variant="outlined"
                                                      name="dateAdded"
                                                      value={DateFormatter(selectedDivision.addDate)}
                                                      disabled={true}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <FormControl
                                                      variant="outlined"
                                                      style={{ width: "100%", textAlign: "left" }}
                                                   >
                                                      <InputLabel id="demo-simple-select-filled-label">
                                                         Email Templates
                                                      </InputLabel>
                                                      <Select
                                                         id="select-emailtemplate"
                                                         defaultValue=""
                                                         label="Email Template"
                                                         value={selectedDivision.emailTemplate}
                                                         onChange={(event) => onSelectChange(event)}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                            "SUPER ADMIN"
                                                         }
                                                      >
                                                         <MenuItem value={"CPG"}>{"CPG"}</MenuItem>
                                                         <MenuItem value={"IDS"}>{"IDS"}</MenuItem>
                                                         <MenuItem value={"MIDDLEBAY"}>{"Middlebay"}</MenuItem>
                                                         <MenuItem value={"BRISTOL"}>{"Bristol"}</MenuItem>
                                                         <MenuItem value={"WDS"}>
                                                            {"World Distribution Services"}
                                                         </MenuItem>
                                                         <MenuItem value={"UWL"}>{"UWL, Inc."}</MenuItem>
                                                      </Select>
                                                   </FormControl>
                                                </Grid>
                                             </Grid>
                                             <Grid container direction={"row"} item xs={12} alignItems="center">
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-prefix"
                                                      label="Application Prefix"
                                                      variant="outlined"
                                                      name="prefix"
                                                      value={selectedDivision.prefix}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      onChange={(event) => onChange(event, "prefix")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-applicationCount"
                                                      label="# of Completed Applications"
                                                      variant="outlined"
                                                      name="applicationCount"
                                                      value={applicationCounts
                                                         .filter((x) => x.item1 === selectedDivision._id)
                                                         .map((x) => {
                                                            return x.item2.toString();
                                                         })}
                                                      disabled={true}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </Grid>
                                             <Grid container direction={"row"} item xs={12} alignItems="center">
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-docuSignTemplateId"
                                                      label="DocuSign Template Id"
                                                      variant="outlined"
                                                      name="docuSignTemplateId"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !==
                                                            "SUPER ADMIN" || docuSignTemplateAssigned
                                                      }
                                                      value={selectedDivision.docuSignTemplateId}
                                                      onChange={(event) => onChange(event, "docuSignTemplateId")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <FormControl
                                                      variant="outlined"
                                                      style={{ width: "100%", textAlign: "left" }}
                                                   >
                                                      <InputLabel id="demo-simple-select-filled-label">
                                                         Company Assigned
                                                      </InputLabel>
                                                      <Select
                                                         id="select-companies"
                                                         defaultValue=""
                                                         label="Company Assigned"
                                                         onChange={(event) => onSelectChangeCompany(event)}
                                                         value={selectedDivision.company_id}
                                                         disabled={
                                                            userState.CurrentUser.security.toUpperCase() !==
                                                            "SUPER ADMIN"
                                                         }
                                                      >
                                                         {companyState.Companies.map((company) => {
                                                            return (
                                                               <MenuItem key={company._id} value={company._id}>
                                                                  {company.code + " | " + company.name}
                                                               </MenuItem>
                                                            );
                                                         })}
                                                      </Select>
                                                   </FormControl>
                                                </Grid>
                                                <Grid container item xs={mobileScreen ? 12 : 6} alignItems="center">
                                                   <TextField
                                                      id="tf-quickCreditAmount"
                                                      label="Quick Credit Amount"
                                                      variant="outlined"
                                                      name="quickCreditAmount"
                                                      InputProps={{
                                                         startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                         ),
                                                      }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      value={selectedDivision.quickCreditAmount}
                                                      onChange={(event) => onChange(event, "quickCreditAmount")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                    <Card style={{ width: "100%", marginTop: theme.spacing(7) }}>
                                       <CardHeader
                                          className={"DivisionDetails-card-header1"}
                                          style={{ height: "4rem" }}
                                          title={
                                             <Typography noWrap variant="h6">
                                                {"Credit Application Email Templates"}
                                             </Typography>
                                          }
                                       />
                                       <Divider className={classes.divider} />

                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel1}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel1")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                             >
                                                <Typography>Invitation Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-InvitationBody"
                                                      label="Invitation Body"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      name="invitationBody"
                                                      value={selectedDivision.invitationBody}
                                                      onChange={(event) => onChange(event, "invitationBody")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-InvitationTopNote"
                                                      label="Invitation Top Note"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      name="invitationTopNote"
                                                      value={selectedDivision.invitationTopNote}
                                                      onChange={(event) => onChange(event, "invitationTopNote")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel2}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel2")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                             >
                                                <Typography>Receipt Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-ReceiptFirstParagraph"
                                                      label="Receipt First Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      name="receiptFirstParagraph"
                                                      value={selectedDivision.receiptFirstParagraph}
                                                      onChange={(event) => onChange(event, "receiptFirstParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-ReceiptSecondParagraph"
                                                      label="Receipt Second Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      name="receiptSecondParagraph"
                                                      value={selectedDivision.receiptSecondParagraph}
                                                      onChange={(event) => onChange(event, "receiptSecondParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel3}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel3")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                             >
                                                <Typography>Denied Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-DeniedFirstParagraph"
                                                      label="Denied First Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      name="deniedFirstParagraph"
                                                      value={selectedDivision.deniedFirstParagraph}
                                                      onChange={(event) => onChange(event, "deniedFirstParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-DeniedSecondParagraph"
                                                      label="Denied Second Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      name="deniedSecondParagraph"
                                                      value={selectedDivision.deniedSecondParagraph}
                                                      onChange={(event) => onChange(event, "deniedSecondParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel4}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel4")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                             >
                                                <Typography>Approved Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-ApprovedParagraph"
                                                      label="Approved Bold Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="approvedBoldParagraph"
                                                      value={selectedDivision.approvedBoldParagraph}
                                                      onChange={(event) => onChange(event, "approvedBoldParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-approvedFirstParagraph"
                                                      label="Approved First Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="approvedFirstParagraph"
                                                      value={selectedDivision.approvedFirstParagraph}
                                                      onChange={(event) => onChange(event, "approvedFirstParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-approvedSecondParagraph"
                                                      label="Approved Second Paragraph"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="approvedSecondParagraph"
                                                      value={selectedDivision.approvedSecondParagraph}
                                                      onChange={(event) => onChange(event, "approvedSecondParagraph")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel5}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel5")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                             >
                                                <Typography>Contact Approved Email Template - Internal</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-ContactApproved"
                                                      label="Contact Approved Body"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="contactApprovedBody"
                                                      value={selectedDivision.contactApprovedBody}
                                                      onChange={(event) => onChange(event, "contactApprovedBody")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel6}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel6")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                             >
                                                <Typography>Contact Denied Email Template - Internal</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-ContactDenied"
                                                      label="Contact Denied Body"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="contactDeniedBody"
                                                      value={selectedDivision.contactDeniedBody}
                                                      onChange={(event) => onChange(event, "contactDeniedBody")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel7}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel7")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                             >
                                                <Typography>Duplication Notification - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-DuplicationNotification"
                                                      label="Duplication Notification"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="duplicationNotification"
                                                      value={selectedDivision.duplicationNotification}
                                                      onChange={(event) => onChange(event, "duplicationNotification")}
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justify="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded.panel8}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel8")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                             >
                                                <Typography>Duplication Notification - Internal</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justify="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="tf-ContactDuplicationNotification"
                                                      label="Contact Duplication Notification"
                                                      multiline
                                                      rows={4}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="contactDuplicationNotification"
                                                      value={selectedDivision.contactDuplicationNotification}
                                                      onChange={(event) =>
                                                         onChange(event, "contactDuplicationNotification")
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                    </Card>
                                 </Grid>
                                 <Grid container direction={"column"} item xs={mobileScreen ? 12 : 6}>
                                    <Card
                                       style={{
                                          width: "100%",
                                          minHeight: largeScreen || mediumScreen || mobileScreen ? undefined : "477px",
                                       }}
                                    >
                                       <CardHeader
                                          className={"DivisionDetails-card-header1"}
                                          style={{ height: "4rem" }}
                                          action={
                                             <div>
                                                <Button
                                                   onClick={() => {
                                                      setContactModal(true);
                                                      setContactId("");
                                                   }}
                                                   variant="contained"
                                                   classes={{
                                                      label: mobileScreen ? classes.labelMobile : classes.label,
                                                      root: classes.addNewButton,
                                                   }}
                                                   disabled={
                                                      userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                   }
                                                >
                                                   <AddIcon />
                                                   {mobileScreen ? "New" : "Contact"}
                                                </Button>
                                             </div>
                                          }
                                          title={
                                             <Typography noWrap variant="h6">
                                                {"Contact List"}
                                             </Typography>
                                          }
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent>
                                          <Grid container direction={"column"} xs={12}>
                                             <Grid container direction={"row"} xs={12}>
                                                {_.orderBy(
                                                   selectedDivision.contacts,
                                                   [(contact) => contact.name.toLowerCase()],
                                                   ["asc"],
                                                ).map((contact, index) => {
                                                   return (
                                                      <Grid
                                                         key={"grid=" + contact._id}
                                                         item
                                                         xs={mobileScreen ? 6 : 4}
                                                         style={{ display: "inline-flex", alignItems: "center" }}
                                                      >
                                                         <Grid
                                                            item
                                                            xs={mobileScreen ? 6 : 4}
                                                            style={{ display: "inline-flex", alignItems: "center" }}
                                                         >
                                                            <h4>{contact.name}</h4>
                                                         </Grid>

                                                         <Grid
                                                            item
                                                            xs={mobileScreen ? 6 : 4}
                                                            style={{ display: "inline-flex", alignItems: "center" }}
                                                         >
                                                            <FormControlLabel
                                                               style={{
                                                                  marginRight: "0px",
                                                                  fontStretch: "ultra-condensed",
                                                               }}
                                                               control={
                                                                  <ContactSwitch
                                                                     checked={contact.active ? true : false}
                                                                     onChange={(event) =>
                                                                        onChangeActiveContact(event, contact.name)
                                                                     }
                                                                     disabled={
                                                                        userState.CurrentUser.security.toUpperCase() !==
                                                                        "SUPER ADMIN"
                                                                     }
                                                                  />
                                                               }
                                                               label=""
                                                               labelPlacement="top"
                                                            />
                                                         </Grid>
                                                         <Grid
                                                            item
                                                            xs={mobileScreen ? 6 : 4}
                                                            style={{ display: "inline-flex", alignItems: "center" }}
                                                         >
                                                            <ButtonGroup>
                                                               <Tooltip title="Edit" arrow>
                                                                  <IconButton
                                                                     disabled={
                                                                        userState.CurrentUser.security.toUpperCase() !==
                                                                        "SUPER ADMIN"
                                                                     }
                                                                     color="primary"
                                                                     onClick={() => {
                                                                        setContactModal(true);
                                                                        setContactId(contact._id);
                                                                     }}
                                                                  >
                                                                     <EditIcon />
                                                                  </IconButton>
                                                               </Tooltip>
                                                            </ButtonGroup>
                                                         </Grid>
                                                      </Grid>
                                                   );
                                                })}
                                             </Grid>
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                    <Card style={{ width: "100%", marginTop: theme.spacing(7) }}>
                                       <CardHeader
                                          className={"DivisionDetails-card-header1"}
                                          action={
                                             <div>
                                                <Button
                                                   onClick={() => {
                                                      setDenialCodeModal(true);
                                                      setDenialCodeId("");
                                                   }}
                                                   variant="contained"
                                                   classes={{
                                                      label: mobileScreen ? classes.labelMobile : classes.label,
                                                      root: classes.addNewButton,
                                                   }}
                                                   disabled={
                                                      userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                   }
                                                >
                                                   <AddIcon />
                                                   {mobileScreen ? "New" : "New Denial Code"}
                                                </Button>
                                             </div>
                                          }
                                          title={
                                             <Typography noWrap variant="h6">
                                                {"Denial Codes"}
                                             </Typography>
                                          }
                                          style={{ height: "4rem" }}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent>
                                          <TableContainer>
                                             <Table style={{ marginBottom: "1rem" }}>
                                                <TableHead>
                                                   <TableRow>
                                                      <TableCell style={{ fontSize: "16px", fontWeight: 700 }}>
                                                         Code
                                                      </TableCell>
                                                      <TableCell style={{ fontSize: "16px", fontWeight: 700 }}>
                                                         Description
                                                      </TableCell>
                                                      <TableCell style={{ fontSize: "16px", fontWeight: 700 }}>
                                                         Date Added
                                                      </TableCell>
                                                      <TableCell> </TableCell>
                                                   </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                   {selectedDivision.denialCodes.map((dc, index) => {
                                                      return (
                                                         <TableRow key={`tr-${dc._id}`}>
                                                            <TableCell style={{ fontSize: "16px" }}>
                                                               {dc.code}
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: "16px" }}>
                                                               {dc.description}
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: "16px" }}>
                                                               {DateFormatter(dc.addDate)}
                                                            </TableCell>
                                                            <TableCell>
                                                               <ButtonGroup>
                                                                  <Tooltip title="Edit" arrow>
                                                                     <IconButton
                                                                        disabled={
                                                                           userState.CurrentUser.security.toUpperCase() !==
                                                                           "SUPER ADMIN"
                                                                        }
                                                                        color="primary"
                                                                        onClick={() => {
                                                                           setDenialCodeModal(true);
                                                                           setDenialCodeId(dc._id);
                                                                        }}
                                                                     >
                                                                        <EditIcon />
                                                                     </IconButton>
                                                                  </Tooltip>
                                                                  <Tooltip title="Delete" arrow>
                                                                     <IconButton
                                                                        disabled={
                                                                           userState.CurrentUser.security.toUpperCase() !==
                                                                           "SUPER ADMIN"
                                                                        }
                                                                        color="secondary"
                                                                        onClick={(event) =>
                                                                           handleDeleteClick(event, index)
                                                                        }
                                                                     >
                                                                        <DeleteForeverIcon />
                                                                     </IconButton>
                                                                  </Tooltip>
                                                               </ButtonGroup>
                                                            </TableCell>
                                                         </TableRow>
                                                      );
                                                   })}
                                                </TableBody>
                                             </Table>
                                          </TableContainer>
                                       </CardContent>
                                    </Card>
                                    <OnboardingPacket />
                                    <Card style={{ width: "100%", marginTop: theme.spacing(7) }}>
                                       <CardHeader
                                          className={"UserDetails-card-header1"}
                                          style={{ height: "4rem" }}
                                          title={
                                             <Typography noWrap variant="h6">
                                                {"Customer Activation Email Templates"}
                                             </Typography>
                                          }
                                       />
                                       <Divider className={classes.divider} />
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justifyContent="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded["panel-customer-activation-email-1"]}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel-customer-activation-email-1")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                             >
                                                <Typography>Invitation Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-invitationBody"
                                                      label="Invitation Body"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      name="invitationBody"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.invitationBody
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "invitationBody",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-invitation-top-note"
                                                      label="Invitation Top Note"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="invitationTopNote"
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.invitationTopNote
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "invitationTopNote",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justifyContent="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded["panel-customer-activation-email-2"]}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel-customer-activation-email-2")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                             >
                                                <Typography>Receipt Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-receipt-first-paragraph"
                                                      label="Receipt First Paragraph"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      name="receiptFirstParapraph"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.receiptFirstParagraph
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "receiptFirstParagraph",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-receipt-second-paragraph"
                                                      label="Receipt Second Paragraph"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="receiptSecondParagraph"
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.receiptSecondParagraph
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "receiptSecondParagraph",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justifyContent="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded["panel-customer-activation-email-3"]}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel-customer-activation-email-3")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                             >
                                                <Typography>Approved Email Template - External</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-approved-first-paragraph"
                                                      label="Approved First Paragraph"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      name="approvedFirstParapraph"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.approvedFirstParagraph
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "approvedFirstParagraph",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-approved-second-paragraph"
                                                      label="Approved Second Paragraph"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      name="approvedSecondParagraph"
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.approvedSecondParagraph
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "approvedSecondParagraph",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                       <Grid
                                          container
                                          direction={"row"}
                                          item
                                          xs={12}
                                          justifyContent="flex-start"
                                          alignItems="flex-start"
                                       >
                                          <Accordion
                                             expanded={expanded["panel-customer-activation-email-4"]}
                                             className={"CompanyDetails-Accordion"}
                                             onChange={() => accordionOnChange("panel-customer-activation-email-4")}
                                          >
                                             <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                             >
                                                <Typography>Status Email Template - Internal</Typography>
                                             </AccordionSummary>
                                             <AccordionDetails>
                                                <Grid
                                                   container
                                                   direction={"row"}
                                                   item
                                                   xs={12}
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start"
                                                >
                                                   <TextField
                                                      id="customer-activation-status-paragraph"
                                                      label="Status Paragraph"
                                                      multiline
                                                      rows={2}
                                                      variant="outlined"
                                                      name="statusParapraph"
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN"
                                                      }
                                                      value={
                                                         selectedDivision.customerActivationEmailTemplate
                                                            ?.statusParagraph
                                                      }
                                                      onChange={(event) =>
                                                         handleCustomerActivationEmailTemplateChange(
                                                            event,
                                                            "statusParagraph",
                                                         )
                                                      }
                                                      style={{ marginTop: mobileScreen ? ".5rem" : "0rem" }}
                                                      autoComplete="new-password"
                                                   />
                                                </Grid>
                                             </AccordionDetails>
                                          </Accordion>
                                       </Grid>
                                    </Card>
                                 </Grid>
                              </Grid>
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         <DenialCodeDialog
            open={denialCodeModal}
            divisionOrCompany={selectedDivision}
            setDivisionOrCompany={setSelectedDivision}
            component={"division"}
            denialCodeId={denialCodeId}
            handleClose={() => setDenialCodeModal(!denialCodeModal)}
            openAlertMessage={openAlertMessage}
         />
         <DeleteDialog
            open={deleteModal}
            handleDelete={() => deleteDenialCode()}
            handleClose={() => setDeleteModal(!deleteModal)}
            title={selectedDenialCode && `Delete ${selectedDenialCode.code}`}
            text={selectedDenialCode && `Are you sure you want to delete ${selectedDenialCode.code}?`}
         />
         <ContactDialog
            open={contactModal}
            divisionOrCompany={selectedDivision}
            setDivisionOrCompany={setSelectedDivision}
            component={"division"}
            contactId={contactId}
            handleClose={() => setContactModal(!contactModal)}
            openAlertMessage={openAlertMessage}
         />
         <DeleteContactDialog
            open={deleteContactModal}
            handleDelete={() => deleteContact()}
            handleClose={() => setDeleteContactModal(!deleteContactModal)}
            title={selectedContact && `Deactivate ${selectedContact.name}`}
            text={
               selectedContact && `You are about to deactivate contact ${selectedContact.name} do you want to continue?`
            }
         />
         <UploadDialog
            open={fileUploadModal}
            handleUpload={() => uploadPacket()}
            handleClose={() => setFileUploadModal(false)}
            title={selectedDenialCode && `Upload ${fileUpload?.name}`}
            text={
               selectedDenialCode &&
               `Are you sure you want to upload the onboarding packet for ${selectedDivision.name}? This will overwrite the current packet.`
            }
         />
      </>
   );
};

export default DivisionDetails;
