/* eslint-disable react/react-in-jsx-scope */
import { Typography } from "@material-ui/core";
/* eslint-disable prettier/prettier */
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSubHeaderStyles } from "./Styles";
/* eslint-disable prettier/prettier */
import { Card, Chip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import EmailIcon from "@material-ui/icons/Email";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import { Contacts } from "../../../types/Contacts";
import { CustomerActivation } from "../../../types/CustomerActivation";
import { DateTimeFormatterShortYear } from "../../../utilities/DateTimeFormatterShortYear";

type Props = {
   selectedCustomerActivation: CustomerActivation;
   contactList: Contacts[];
   selectedDivision: string;
};

function DeniedSubHeader({ selectedCustomerActivation, contactList, selectedDivision }: Props) {
   const classes = useSubHeaderStyles();

   return (
      <>
         <Grid container direction={"row"}>
            <Grid direction={"column"} item xs={12}>
               <Typography variant="h5" color="primary">
                  {selectedCustomerActivation.companyName + " - " + selectedCustomerActivation.applicationNumber}
               </Typography>
            </Grid>
         </Grid>
         <Grid container direction={"row"}>
            <Grid direction={"column"} item xs={6}>
               <Button
                  variant="outlined"
                  color="primary"
                  className={classes.primaryButton}
                  endIcon={<CreateIcon />}
                  style={{ marginRight: "12px" }}
               >
                  Update Customer Code
               </Button>
               <Button variant="outlined" color="primary" className={classes.primaryButton} endIcon={<CreateIcon />}>
                  Update Denial Code
               </Button>
            </Grid>
            <Grid direction={"column"} item xs={6} justifyContent="flex-end">
               <Grid container direction={"row"} alignItems="center" justifyContent="flex-end">
                  <Button
                     variant="outlined"
                     color="primary"
                     className={classes.primaryButton}
                     endIcon={<EmailIcon />}
                     style={{ marginRight: "12px" }}
                  >
                     Email
                  </Button>
                  <Button
                     variant="outlined"
                     color="primary"
                     className={classes.primaryButton}
                     endIcon={<PictureAsPdfIcon />}
                     style={{ marginRight: "12px" }}
                  >
                     View PDF
                  </Button>
                  <Button
                     variant="outlined"
                     className={classes.notesButton}
                     endIcon={<SpeakerNotesIcon />}
                     style={{ marginRight: "12px" }}
                  >
                     Notes
                  </Button>
               </Grid>
            </Grid>
         </Grid>
         <Card elevation={3} style={{ borderRadius: 24, marginBottom: "16px" }}>
            <Grid container direction={"row"}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "25px",
                  }}
                  justifyContent="space-between"
               >
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Date
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Company | Division
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Denial Code
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Customer Code
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Point of Contact
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography noWrap style={{ color: "#767676", fontWeight: 700 }}>
                        Status
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction={"row"}
                  justifyContent="space-between"
                  style={{
                     height: "50px",
                  }}
               >
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {DateTimeFormatterShortYear(selectedCustomerActivation.signatureDate)}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedDivision}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedCustomerActivation.denialCode}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {selectedCustomerActivation.companyCode}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant="h6" style={{ color: "#010440" }}>
                        {contactList.map((contact, index) => {
                           return (
                              <>
                                 {contact.name}
                                 {index < contactList.length - 1 ? ", " : ""}
                              </>
                           );
                        })}
                     </Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <div className={classes.chipContainer}>
                        <Chip
                           key={selectedCustomerActivation._id}
                           className={classes.chipRed}
                           label="Denied"
                           size="small"
                           clickable={false}
                           variant="outlined"
                        />
                     </div>
                  </Grid>
               </Grid>
            </Grid>
         </Card>
      </>
   );
}

export { DeniedSubHeader };
