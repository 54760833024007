import { Button, ButtonGroup, makeStyles, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
}));

export const UploadDialog: React.FC<any> = (props: {
   title: string;
   text: string;
   text2: string;
   open: boolean;
   handleUpload: () => void;
   handleClose: () => void;
}): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const uploadTitle = props.title || "Upload Item?";
   const uploadText = props.text || "Are you sure you want to upload this item?";
   const uploadText2 = props.text2 || "";

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            {uploadTitle}
         </DialogTitle>
         <DialogContent>
            <DialogContentText>{uploadText}</DialogContentText>
            {uploadText2 ? <DialogContentText>{uploadText2}</DialogContentText> : <></>}
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={props.handleUpload}
                  variant="contained"
                  color="primary"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <PublishIcon />
                  Upload
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default UploadDialog;
