import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import { green, red } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { TransitionProps } from "@material-ui/core/transitions";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { Applications } from "../../types/Applications";
import "./AdoptSign.css";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
   createStyles({
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      buttonProgress: {
         color: green[500],
      },
      checkIcon: {
         color: red[500],
      },
   }),
);

export const AdoptSign: React.FC<any> = (props: {
   open: boolean;
   handleClose: () => void;
   application: Applications;
   setApplication: (value: React.SetStateAction<Applications>) => void;
   division: string;
}): JSX.Element => {
   const classes = useStyles();
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [signature, setSignature] = useState<string>("");
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   async function adoptAndSign() {
      try {
         if (signature === "") {
            openAlertMessage("You must enter in your signature.", "warning");
            return;
         }

         const newApplication = { ...props.application };

         newApplication.signature = signature;

         props.setApplication(newApplication);
         props.handleClose();
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <DialogTitle
            className={
               props.division.toUpperCase() == "MIDDLEBAY"
                  ? "AdoptSign-title-middleBay"
                  : props.division.toUpperCase() == "BRISTOL"
                  ? "AdoptSign-title-bristol"
                  : "AdoptSign-title"
            }
         >
            <Grid container direction={"row"} item xs={12} alignItems={"center"} justify={"center"}>
               {"Adopt Your Signature"}
            </Grid>
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent>
            <Grid
               container
               direction={"row"}
               item
               xs={12}
               style={{ placeContent: "space-evenly", margin: "auto", marginRight: "1.5rem" }}
            >
               <TextField
                  id="ci-signature"
                  value={signature}
                  onChange={(event) => setSignature(event.target.value)}
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
               />
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Card style={{ width: "100vw" }}>
                  <CardHeader
                     className={
                        props.division.toUpperCase() == "MIDDLEBAY"
                           ? "AdoptSign-card-header-middleBay"
                           : props.division.toUpperCase() == "BRISTOL"
                           ? "AdoptSign-card-header-bristol"
                           : "AdoptSign-card-header"
                     }
                     style={{ textAlign: "center" }}
                     title={<Typography>Preview</Typography>}
                  />
                  <Paper style={{ minHeight: "65px" }}>
                     <Typography style={{ fontFamily: '"Brush Script MT"', fontSize: "36px", textAlign: "center" }}>
                        {signature ? signature : ""}
                     </Typography>
                  </Paper>
               </Card>
            </Grid>
            <Grid item xs={12} style={{ textAlignLast: "center" }}>
               <p style={{ margin: "1rem" }}>
                  By clicking adopt and sign, I agree that the signature and initials will be an electronic
                  representation of my signature and initials for all purposes when used on documents. I agree it is
                  legal and binding; just the same as a pen-and-paper signature or initial. I attest all information
                  completed is accurate and honest to the best of my knowledge.
               </p>
            </Grid>
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Cancel
               </Button>
               <Button
                  onClick={adoptAndSign}
                  variant="contained"
                  color="primary"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CreateIcon />
                  Adopt And Sign
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default AdoptSign;
