export const headerSubTitle = {
   UWL: "UWL, Inc.",
   WDS: "World Distribution Services",
   CPG: "ContainerPort Group",
   Bristol: "Bristol Transportation",
   MiddleBay: "Middle Bay Transportation",
};

export const headerIcon = {
   UWL: "/uwlLogo.png",
   WDS: "/wdsLogo.png",
   CPG: "/CPG_logo_2.svg",
   Bristol: "/bristolLogo.png",
   MiddleBay: "/mbtLogo.png",
};

export const truckIcon = {
   UWL: "/truck.png",
   WDS: "/truck.png",
   CPG: "/truckBlue.png",
   Bristol: "/truckBlue.png",
   MiddleBay: "/truckGold.svg",
};

export const emailReadIcon = {
   UWL: "/mark_email_read_green.svg",
   WDS: "/mark_email_read_green.svg",
   CPG: "/mark_email_read_blue.svg",
   Bristol: "/mark_email_read_blue.svg",
   MiddleBay: "/mark_email_read_yellow.svg",
};

export const redirectPageAfterSubmit = {
   UWL: "https://www.shipuwl.com/",
   WDS: "https://www.worldds.net/",
   CPG: "https://www.containerport.com/locations",
   Bristol: "https://www.containerport.com/locations",
   MiddleBay: "https://www.containerport.com/locations",
};

export const termsAndConditions = {
   UWL: "https://www.shipuwl.com/resources/terms-and-conditions/",
   WDS: "https://www.worldds.net/terms-conditions/",
   CPG: "https://www.containerport.com/terms-and-conditions",
   Bristol: "https://www.containerport.com/terms-and-conditions",
   MiddleBay: "",
};

// expiration
export const expirationLogo = {
   UWL: "/uwlLogoMedium.png",
   WDS: "/wdsLogoMedium.png",
   CPG: "/CPG_logo_2.svg",
   Bristol: "/bristolLogoMedium.png",
   MiddleBay: "/mbtLogoMedium.png",
};
export const keyOffIcon = {
   UWL: "/key_off_green.svg",
   WDS: "/key_off_green.svg",
   CPG: "/key_off_blue.svg",
   Bristol: "/key_off_blue.svg",
   MiddleBay: "/key_off_gold.svg",
};

export const emailToWriteWhenExpired = {
   UWL: "credit@worldgrouplogistics.com",
   WDS: "nicole.butcaris@worldds.net",
   CPG: "customeronboarding@containerport.com",
   Bristol: "customeronboarding@bristoltrans.com",
   MiddleBay: "customeronboarding@middlebaytransportation.com",
};

export const CPG_Bristol_MiddleBay = ["CPG", "Bristol", "MiddleBay"];
export const WDS_UWL = ["WDS", "UWL"];
