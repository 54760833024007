/* eslint-disable react/react-in-jsx-scope */
import { CustomerActivation } from "../../../types/CustomerActivation";
import { BankingInformation } from "./BankingInformation";
import { BillingInformation } from "./BillingInformation";
import { BillingInformationUWLWDS } from "./BillingInformationUWLWDS";
import { CHBInformation } from "./CHBInformation";
import { CompanyInformation } from "./CompanyInformation";
import { CompanyInformationUWLWDS } from "./CompanyInformationUWLWDS";
import { ESignature } from "./ESignature";

type Props = {
   selectedCustomerActivation: CustomerActivation;
   selectedDivision: string;
};

function Body({ selectedCustomerActivation, selectedDivision }: Props) {
   function isUwlWds() {
      if (selectedDivision.toLowerCase().includes("uwl") || selectedDivision.toLowerCase().includes("wds")) {
         return true;
      }

      return false;
   }

   return (
      <>
         {isUwlWds() ? (
            <CompanyInformationUWLWDS selectedCustomerActivation={selectedCustomerActivation} />
         ) : (
            <CompanyInformation selectedCustomerActivation={selectedCustomerActivation} />
         )}
         {isUwlWds() ? (
            <BillingInformationUWLWDS selectedCustomerActivation={selectedCustomerActivation} />
         ) : (
            <BillingInformation selectedCustomerActivation={selectedCustomerActivation} />
         )}
         {isUwlWds() ? (
            <CHBInformation selectedCustomerActivation={selectedCustomerActivation} />
         ) : (
            <BankingInformation selectedCustomerActivation={selectedCustomerActivation} />
         )}
         <ESignature selectedCustomerActivation={selectedCustomerActivation} />
      </>
   );
}

export { Body };
