import { Divisions } from "../../types/Divisions";

type ActionMap<M extends { [index: string]: any }> = {
   [Key in keyof M]: M[Key] extends undefined
      ? {
           type: Key;
        }
      : {
           type: Key;
           payload: M[Key];
        };
};

export enum DivisionTypes {
   Create = "GET_DIVISIONS",
}

type DivisionPayload = {
   [DivisionTypes.Create]: {
      Divisions: Divisions[];
   };
};

export type DivisionActions = ActionMap<DivisionPayload>[keyof ActionMap<DivisionPayload>];

export const divisionReducer = (state: Divisions[], action: DivisionActions) => {
   switch (action.type) {
      case DivisionTypes.Create:
         return [...action.payload.Divisions];
      default:
         return state;
   }
};
