import clsx from "clsx";
import React from "react";

import { useLandingPageStyles } from "./Styles";

import { Box, Button, Divider, Paper, Typography, useMediaQuery } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useLocation } from "react-router-dom";

const arr = new Array(5).fill("cc");
const stepIcons: Record<number, string> = {
   0: "/bill.png",
   1: "/credit.png",
   2: "/piggy-bank.png",
   3: "/trade.png",
   4: "/review.png",
};

/**
 * Comany name without abbreviation
 */
const companyNameDic: Record<string, string> = {
   WDS: "World Distribution Services",
   UWL: "UWL",
};

export default function LandingPage({ handleGoToSteps }: { handleGoToSteps: () => void }): React.ReactElement {
   const classes = useLandingPageStyles();
   const routes = useLocation();
   const matches = useMediaQuery("(min-width:1000px)");

   const routersSplitted = routes.pathname.split("/");
   const companyName = companyNameDic[routersSplitted[2]?.toUpperCase()] || routersSplitted[2]?.toUpperCase();

   return (
      <div className={classes.page}>
         <section style={{ marginBottom: "24px" }}>
            <Typography className={clsx(classes.welcomeText, classes.whiteText)}>Welcome to</Typography>
            <Typography className={clsx(classes.whiteText, classes.creditText)}>CreditApp</Typography>
         </section>

         <section>
            <Paper elevation={0} className={clsx(classes.stepContainer)}>
               <Typography color="primary" style={{ fontSize: "2rem", fontWeight: 600, marginBottom: "20px" }}>
                  Thank you for your interest in partnering with {companyName}!
               </Typography>
               <Typography style={{ fontSize: "1rem" }}>
                  To begin the credit application process, please click the <b>“GO TO THE FORM”</b> link below. This
                  will provide you with a secure URL that is specific to your organization. The URL can be shared with
                  others in your organization who may need to assist in completing the form. Simply copy it and share or
                  forward the email this page was linked from. Please keep an eye out for automated email updates
                  throughout the application process as they will dictate next steps
               </Typography>
               <div className={classes.iconsContainer}>
                  {arr.map((_, index) => (
                     <>
                        <div key={index} className={classes.step}>
                           <img src={stepIcons[index]} alt="icon" />
                        </div>
                        {index === 4 || !matches ? null : <div className={classes.line}></div>}
                     </>
                  ))}
               </div>
               <Typography style={{ fontSize: "1rem", marginBottom: "40px" }}>
                  Once we receive the completed credit application, we will begin the onboarding process.
               </Typography>
               <Divider style={{ backgroundColor: "#043F60" }} />
               <Box className={clsx(classes.buttonContainer)}>
                  <Typography style={{ fontSize: "1.5rem", fontWeight: 500 }}>
                     Thank you for the opportunity to partner with you!
                  </Typography>
                  <Button
                     className={clsx(classes.commonButton)}
                     color="primary"
                     variant="contained"
                     endIcon={<ArrowForwardIcon />}
                     onClick={() => handleGoToSteps()}
                  >
                     GO TO THE FORM
                  </Button>
               </Box>
            </Paper>
         </section>

         <section className={classes.footerContainer}>
            <div>
               <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
                  <img src="/chrome.png" width={28} height={28} alt="chrome browser" />
               </a>
               <a href="https://www.microsoft.com/en-us/edge/download" target="_blank" rel="noreferrer">
                  <img src="/microsoft.png" width={28} height={28} alt="edge browser" style={{ margin: "0px 1rem" }} />
               </a>
               <a href="https://brave.com/download/" target="_blank" rel="noreferrer">
                  <img src="/lion.png" width={28} height={28} alt="brave browser" />
               </a>
            </div>
            <p style={{ color: "#FFF" }}>For the best experience use Chrome, Edge, or Brave browsers</p>
         </section>
      </div>
   );
}
